import React, { cloneElement } from "react";
import { useSelector } from "react-redux";
import { User } from "./User";
import { Merchant } from "./Merchant";
import { Subsidiary } from "./Subsidiary";
import { Config } from "./Config";
import { Funds } from "./Funds";
import { HelpLink } from "./HelpLink";
import { BuyReader } from "./BuyReader/BuyReader";
import { BankAccount } from "./BankAccount";
import { Subsidiaries } from "./Subsidiaries";
import { CourierTracking } from "./CourierTracking";
import { UploadDocumentation } from "./UploadDocumentation";
import { StaticQRCode } from "./StaticQRCode";
import { UpgradeToProfessionalLink } from "./UpgradeToProfessionalLink";
import { QRDownload } from "./QRDownload";
import { getConfig } from "config";
import { ReadersModal } from "./ReadersModal";
import { HelpModal } from "./HelpModal";

export const HeaderMenuItems = ({ openModal, showMenu }) => {
  const qrConfig = useSelector((state) => state.login?.user?.qrConfig);

  const menuItemsConfig = getConfig("customConfig.dropdownMenuItems");

  const allPossibleMenuItems = {
    merchant: <Merchant />,
    config: <Config onClick={() => openModal("ConfigModal")} />,
    funds: <Funds onClick={() => openModal("FundsModal")} />,
    helpLink: <HelpLink />,
    buyReader: <BuyReader />,
    uploadDocumentation: <UploadDocumentation onClick={() => openModal("DocumentationModal")} />,
    bankAccount: <BankAccount onClick={() => openModal("BankAccountModal")} />,
    courierTracking: <CourierTracking onClick={() => openModal("CourierTrackingModal")} />,
    upgradeToProfessionalLink: <UpgradeToProfessionalLink />,
    staticQRCode: <StaticQRCode onClick={() => openModal("StaticQRCodeModal")} />,
    qrDownload: <QRDownload onClick={() => openModal("QRDownloadModal")} />
  };
  
  const activatedByFeatureFlags = [
    <Subsidiary />,
    <Subsidiaries onClick={() => openModal("SubsidiariesModal")} />,
    <HelpModal onClick={() => openModal("HelpModal")} />,
    <ReadersModal onClick={() => openModal("ReadersModal")} />,
  ]

  const getDisplayableItems = () => {
    let items = [
      ...activatedByFeatureFlags,
      ...menuItemsConfig.map((item) => allPossibleMenuItems[item]),
    ];
    items = items.filter((item) => {
      // QR only if we have proper data
      return !(
        item?.type?.displayName === "StaticQRCode" &&
        typeof qrConfig === "undefined"
      );
    });
    return items;
  };

  const displayableItems = getDisplayableItems();

  return (
    <>
      {showMenu && (
        <div>
          <User />
          {displayableItems.map((Item, i) => cloneElement(Item, { key: i }))}
        </div>
      )}
    </>
  );
};
