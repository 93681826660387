
import React from 'react'
import BBParser, { Tag } from 'bbcode-to-react'
import { htmlDecode } from 'js-htmlencode'

class NewLineTag extends Tag {
  constructor(renderer, settings = {}) {
    super(renderer, settings);
    this.SELF_CLOSE = true;
    this.STRIP_OUTER = true;
  }
  toReact() {
    return <br/>
  }
}


class LinkTag extends Tag {

  toReact() {
    let url = htmlDecode(this.renderer.strip(this.params[this.name] || this.getContent(true)));
    if (/javascript:/i.test(url)) {
      url = ''
    }

    if (!url || !url.length) {
      return this.getComponents();
    }

    if (this.name === 'email') {
      url = `mailto:${url}`;
    }

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {this.getComponents()}
      </a>
    );
  }
}


class MaterialDesignIconTag extends Tag {
  constructor(renderer, settings = {}) {
    super(renderer, settings);
    this.SELF_CLOSE = true;
    this.STRIP_OUTER = true;
  }
  toReact() {
    return <i className={`mdi mdi-${this.params[this.name]}`}/>
  }
}


BBParser.registerTag('br', NewLineTag)
BBParser.registerTag('link', LinkTag)
BBParser.registerTag('mdi', MaterialDesignIconTag)


export default BBParser