import __ from 'utils/i18n';
import { get } from "lodash";
import { Badge } from "reactstrap";

export const StatusCell = ({ original }) => {
  const isClosed = get(original, "isClose");
  return (
    <Badge className={`batch-status ${isClosed ? 'batch-status-closed' : 'batch-status-open'}`}>
      {isClosed ? __('dashboard.sections.batchClose.table.batchClose') : __('dashboard.sections.batchClose.table.batchOpen')}
    </Badge>
  );
};
