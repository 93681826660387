const dateFormats = {
  es: {
    day: (start, end) => start.format("LL"),
    month: (start, end) => `${start.format("D")} al ${end.format("D [de] MMMM [de] YYYY")}`,
    year: (start, end) => `${start.format("D [de] MMMM")} al ${end.format("D [de] MMMM [de] YYYY")}`,
    other: (start, end) => `${start.format("ll")} al ${end.format("ll")}`
  }
}

export default dateFormats;
