export const ACTIONS = {
  LOGIN_REQUEST: 'login/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'login/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'login/LOGIN_FAILURE',
  SAVE_USER: 'login/SAVE_USER',
  SET_FIRST_LOGIN: 'login/SET_FIRST_LOGIN',
  EDIT_USER: 'login/EDIT_USER'
}

export default ACTIONS;
