import React from "react";
import { ModalContext } from "contexts/modalContext";
import { PaymentLinkIcon } from "./PaymentLinkIcon";
import gtmEvents from "utils/gtmEvents";
import { Button } from "reactstrap";
import { PaymentDiscovery } from "./PaymentLinkDiscovery";

import __ from "utils/i18n";

export const PaymentLink = () => {
  return (
    <ModalContext.Consumer>
      {(context) => (
        <li className="dashboard-header__item-container nav-item payment-link">
          <div className="nav-link" data-name="payment-link">
            <Button
              onClick={() => {
                gtmEvents.paymentLinkButtonClicked();
                context.openModal("PaymentLinkModal");
              }}
              color="primary"
              size="sm"
              className="dashboard-header__button payment-link-radius"
            >
              <PaymentLinkIcon /> {__("dashboard.sections.paymentLink.title")}
            </Button>
          </div>
          <PaymentDiscovery />
        </li>
      )}
    </ModalContext.Consumer>
  );
};
