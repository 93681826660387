import React, { useState } from "react";
import { get } from "lodash";
import { Badge } from "reactstrap";
import moment from "moment";

import DashboardPanel from "containers/Dashboard/components/DashboardPanel";
import FlexTable from "components/FlexTable";
import __ from "utils/i18n";

import { DepositPanel } from "../DepositsPanel";
import { TooltipInformation } from "../TooltipInformation";
import { getConfig } from "config";

const depositStatusCell = ({ original }) => {
  const states = {
    PENDING: { color: "secondary", label: __("states.pending") },
    BLOCKED: { color: "danger", label: __("states.blocked") },
    SUCCESS: {
      color: "info",
      label: __("states.paid"),
      message: __("dashboard.sections.deposits.messages.success"),
    },
    REJECTED: {
      color: "danger",
      label: __("states.fail"),
      message: __("dashboard.sections.deposits.messages.rejected"),
    },
    TRANSFERRING: { color: "success", label: __("states.transferring") },
  };

  let status = (original.state || original.status).toUpperCase();
  const state = get(states, status, {});
  const color = state.color || null;
  const label = state.label || null;
  const message = state.message || __("states.withoutState");
  const date = moment(original.toDeposit).format("LL");
  const renderMessage = () => (
    <span>
      {message}
      <br />
      {date}
    </span>
  );

  const showTooltip =
    status !== "PENDING" && status !== "TRANSFERRING" && status !== "BLOCKED";

  return (
    <span className="pull-right deposit-information">
      {showTooltip && (
        <TooltipInformation
          className="deposit-information__date"
          position="top"
          index={"deposit-tooltip" + original.id}
          message={renderMessage()}
        />
      )}

      {color && <Badge color={color}>{label}</Badge>}
    </span>
  );
};

export const depositCell = ({ original }) => {
  return (
    <span> {__.currency(original.balanceAmount, original.currency.code)} </span>
  );
};

export const retentionCell = ({ original }) => {
  return (
    <span>
      {__.currency(original.withholdingTaxAmount, original.currency.code)}
    </span>
  );
};

export const tariffCell = ({ original }) => {
  return (
    <span> {
        original.commission?.value ?
        __.currency(original.commission.value, original.currency.code):
        "-"
    } </span>
  );
};

export const vatCell = ({ original }) => {
  return ( 
    <span> {
        original.commissionTaxAmount ?
        __.currency(original.commissionTaxAmount, original.currency.code):
        "-"
    } </span> 
  );
};

export const financialCostAmount = ({ original }) => {
  return (
    <span>
      {__.currency(original.financialCostAmount, original.currency.code)}
    </span>
  );
};

export const grossCell = ({ original }) => {
  return (
    <span>
      {__.currency(original.amount || original.gross, original.currency.code)}
    </span>
  );
};

export const amountReceiveCell = ({ original }) => {
  return <span> {__.currency(original.total || original.balanceAmount, original.currency.code)} </span>;
};

export const itbisRetentionCell = ({ original }) => {
  return (
    <span>
      {__.currency(original.withholdingTaxAmountItbis, original.currency.code)}
    </span>
  );
};

export const dateCell = ({ original }) => {
  return <span> {__.stringToDate(original.availableDate, "LL")} </span>;
};

export const depositDateCell = ({ original }) => {
  return <span> {__.stringToDate(original.toDeposit, "LL")} </span>;
};

export const grossAmount = ({ original }) => {
  return <span> {__.currency(original.amount, original.currency.code)} </span>
}

export const commissionAmount = ({ original }) => {
  return <span> {__.currency(original.commissionAmount, original.currency.code)} </span>
}

export const commissionTaxAmount = ({ original }) => {
  return <span> {__.currency(original.commissionTaxAmount, false )}% </span>
}

export const balanceAmount = ({ original }) => {
  return <span> {__.currency(original.balanceAmount, original.currency.code)} </span>
}

const tipAmount = ({ original }) => {
  return <span> {__.currency(original.tipAmount, original.currency.code)} </span>
}

const getDepositColumns = () => {
  const configColumns = getConfig("tableColumns.depositColumns");
  const depositColumns = [];

  const defaultColumns = {
    availableDate: {
      Header: __("dashboard.sections.sales.table.header.availableDate"),
      Cell: dateCell,
    },
    grossAmount: {
      Header: __("dashboard.sections.sales.table.header.grossAmountDeposit"),
      Cell: grossAmount,
    },
    commissionAmount: {
      Header: __("dashboard.sections.sales.table.header.commissionAmount"),
      Cell: commissionAmount,
    },
    commissionTaxAmount: {
      Header: __("dashboard.sections.sales.table.header.commissionTaxAmount"),
      Cell: commissionTaxAmount,
    },
    tipAmount: {
      Header: __("dashboard.sections.sales.table.header.tipAmount"),
      Cell: tipAmount,
    },
    balanceAmount: {
      Header: __("dashboard.sections.sales.table.header.balanceAmount"),
      Cell: balanceAmount,
    },
    toDeposit: {
      Header: __("dashboard.sections.sales.table.header.toDeposit"),
      Cell: depositDateCell,
    },
    gross: {
      Header: __("dashboard.sections.sales.table.header.grossAmount"),
      align: "right",
      Cell: grossCell,
    },
    tariff: {
      Header: __("dashboard.sections.sales.table.header.tariff"),
      align: "right",
      Cell: tariffCell,
    },
    vat:{
      Header: __('dashboard.sections.sales.table.header.vat'),
      align: 'right',
      Cell: vatCell
    },
    cft: {
      Header: __("dashboard.sections.sales.table.header.cft"),
      align: "right",
      Cell: financialCostAmount,
    },
    retention: {
      Header: __("dashboard.sections.sales.table.header.retentionAmount"),
      align: "right",
      Cell: retentionCell,
    },
    itbisRetention: {
      Header: __("dashboard.sections.sales.table.header.itbisRetention"),
      align: "right",
      Cell: itbisRetentionCell,
    },
    deposit: {
      Header: __("dashboard.sections.sales.table.header.depositAmount"),
      align: "right",
      Cell: depositCell,
    },
    amountReceive: {
      Header: __("dashboard.sections.deposits.table.header.amountReceive"),
      align: "right",
      Cell: amountReceiveCell,
    },
    status: {
      Header: __("dashboard.sections.sales.status"),
      Cell: depositStatusCell,
      width: 100,
      maxWidth: 200,
      align: "right",
    },
  };

  configColumns.forEach((item) => depositColumns.push(defaultColumns[item]));

  return depositColumns;
};

export const DepositsTable = ({ data }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [detailsData, setDetailsData] = useState({});

  const showDepositsDetail = getConfig("customConfig.deposits.showDetail");

  const classes = {
    flexTable: {
      cursor: "default",
    },
  };

  const handleDetails = (data) => {
    if (showDepositsDetail) {
      setShowDetails(true);
      setDetailsData(data);
    }
  };

  return (
    <>
      <DashboardPanel
        panelHeading={"Detalle"}
        expanded={showDetails}
        hidePanel={() => setShowDetails(false)}
      >
        <DepositPanel data={detailsData} />
      </DashboardPanel>
      {data.length > 0 && (
        <FlexTable
          style={classes.flexTable}
          data={data}
          columns={getDepositColumns()}
          onRowClick={({ index }) => handleDetails(data[index])}
        />
      )}
    </>
  );
};
