
import React from 'react'

import TextInput from './TextInput'
import NumberInput from './NumberInput'
import MaskedTextInput from './MaskedTextInput'
import SelectInput from './SelectInput'
import DateRangeInput from './DateRangeInput'
import RadioInput from './RadioInput'
import CheckboxInput from './CheckboxInput'
import AmountInput from './AmountInput'
import ImageInput from './ImageInput'
import FileInput from './FileInput'
import MaskedDateInput from './MaskedDateInput'

export { default as TextInput } from './TextInput'
export { default as NumberInput } from './NumberInput'
export { default as SelectInput } from './SelectInput'
export { default as DateRangeInput } from './DateRangeInput'
export { default as CheckboxInput } from './CheckboxInput'
export { default as RadioInput } from './RadioInput'
export { default as AmountInput } from './AmountInput'
export { default as ImageInput } from './ImageInput'
export { default as FileInput } from './FileInput'
export { default as MaskedTextInput } from './MaskedTextInput'
export { default as PasswordField } from './PasswordField'
export { default as MaskedDateInput } from './MaskedDateInput'

const Inputs = {
  text: TextInput,
  number: NumberInput,
  masked: MaskedTextInput,
  select: SelectInput,
  daterange: DateRangeInput,
  checkbox: CheckboxInput,
  radio: RadioInput,
  amount: AmountInput,
  image: ImageInput,
  file: FileInput,
  password: TextInput,
  date: MaskedDateInput
}

export const Input = (props) => {
  const Input = Inputs[props.type] || TextInput
  return <Input {...props}/>
}


