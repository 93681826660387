import React from "react";
import { useState } from "react";
import { SimpleForm } from "components/forms";
import { TextField } from "components/forms/fields";
import { Button } from "reactstrap";
import i18n from "utils/i18n";
import { useSelector} from "react-redux";
import { login, encryptCredential } from "../../../../../../../Login/resources";
import { fetchResource } from "api-provider";
import "../../../../../../../../styles/newStyles/MerchantCredentials.scss";
import LoaderContainer from "../../components/LoaderContainer";
import gtmEvents from "utils/gtmEvents";
import moment from "moment";
import {tenantId} from "../../../../../../../../config/envConfig";

export const PasswordVerificationStep = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const email = useSelector((state) => state.login.user.email);

  const updateCurrentSession = (data) => {
    // Guardar el timestamp de la sesión en localStorage
    const sessionTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    localStorage.setItem('sessionTimestamp', sessionTimestamp);

    // Guardar permisos de usuario, flags de características y autenticación en localStorage
    localStorage.setItem(`geop_permissions_${tenantId}`, JSON.stringify(data?.userPermissions));
    localStorage.setItem(`geop_feature_flags_${tenantId}`, JSON.stringify(data?.featureFlags));
    localStorage.setItem(`geop_oauth_${tenantId}`, JSON.stringify(data?.serverAuth));
  }

  const errorText = i18n(
    "dashboard.sections.merchantCredentials.step.password.errorMessage"
    );
    
    const handleSubmit = (values) => {
      const encryptedUser = encryptCredential(email);
      const encryptedPassword = encryptCredential(values.password);

      setErrorMessage("");
      setIsLoading(true);
      
      fetchResource(login(encryptedUser, encryptedPassword))
      .onSuccess((success) => {
        updateCurrentSession(success);
        setIsLoading(false);
        props.navigation.go(2);
        gtmEvents.credentialsPageView('createsPass');
      })
      .onFailure((error) => {
        if (error.length > 0) {
          setErrorMessage(error);
        } else {
          setErrorMessage(errorText);
        }
        setIsLoading(false);
      });
  };

  return (
    <div className="credentials-modal-body-steps">
      <SimpleForm onSubmit={handleSubmit}>
        <>
          {isLoading ? (
            <LoaderContainer loadingTexts={props.loadingTexts} />
          ) : (
            <>
              <div className="credentials-modal-login-step">
                <h3 className="credentials-modal-title login-step-title">
                  {i18n(
                    "dashboard.sections.merchantCredentials.step.password.title"
                  )}
                </h3>
                <label
                  htmlFor={"password"}
                  className="credentials-modal-label login-step-label"
                >
                  {i18n(
                    "dashboard.sections.merchantCredentials.step.password.passwordForm"
                  )}
                </label>
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  // className={"credentials-modal-input login-step-input "}
                  className={
                    errorMessage
                      ? "login-step-input-error"
                      : "credentials-modal-input login-step-input "
                  }
                />

                <div className="text-danger text-position">{errorMessage}</div>
              </div>
              <div className="credentials-modal-button-container">
                <Button
                  className="btn btn-primary credentials-modal-step-button credentials-modal-step-button-btn-login mx-auto"
                  color="primary"
                  type="submit"
                >
                  {i18n(
                    "dashboard.sections.merchantCredentials.step.password.button"
                  )}
                </Button>
              </div>
            </>
          )}
        </>
      </SimpleForm>
    </div>
  );
};
