
import React from 'react'
import { Form } from 'react-form'

const renderChildren = (children, props) => {
  return typeof children === 'function' ?
    children(props) : children
}

class SimpleForm extends React.Component {

  state = {
    validators: {},
    resetAll: null,
    count: 0,
    formApi: {},
    test:'',
  }

  sent = false

  componentWillReceiveProps(props) {
    if(!!props.reset && this.state.resetAll){
      this.state.resetAll()
    }

    if(props.defaultValues !== this.props.defaultValues && this.state && this.state.formApi.setAllValues){
      this.state.formApi.setAllValues(props.defaultValues)
    }
  }

  setApiState(formApi) {
    this.setState({ formApi })
  }

  onChange(formState) {
    this.props.onChange && this.props.onChange(formState.values);
  }


  setValidator(field, validator) {
    this.setState({
      validators: {
        ...this.state.validators,
        [field]: validator
      }
    })
  }


  render() {
    const { children, onSubmit, ...rest } = this.props
    return (
      <Form
        getApi={(formApi)=>{this.setApiState(formApi)}}
        onChange={this.onChange.bind(this)}
        onSubmit={(data) => {
          if (!this.sent) {
            onSubmit(data);
            this.sent = true;
          }
        }}
        {...rest}
        pure={false}
        render={(api) => (
          <form
            onSubmit={(e)=>{
              e.preventDefault();
              this.sent = false;
              api.submitForm();
            }}
            onClick={()=>{ this.setState({resetAll:api.resetAll}) }}
            onBlur={()=>{ this.setState({resetAll:api.resetAll}) }}
          >
            { renderChildren(children, {
              ...api,
              setValidator: this.setValidator.bind(this)
            })}
          </form>
        )}



      >
      </Form>
    )
  }
}

export default SimpleForm
