import React from "react";

const Stepper = ({steps}) => {
  return (
    <div className="stepper">
      {
        steps.map((item, index) => {
          let status = '';
          const nextStatus = steps[index + 1] ? steps[index + 1].isComplete : false;

          if (item.isComplete && nextStatus) {
            status = 'step-completed';
          }
          if (item.isComplete && !nextStatus) {
            status = 'step-active';
          }

          return (
            <div className={`step ${status}`} key={index}>
              <div>
                <div className="step-icon"><i className={`mdi mdi-18px mdi-chevron-right`}/></div>
              </div>
              <div>
                <div className="title">{item.name}</div>
                <div className="caption">{item.description}</div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default Stepper