import React from 'react'
import __ from 'utils/i18n'

import { get } from 'lodash'

const SearchTextInput = (props) => {
  return (
    <div className="input-group has-feedback full-search">
      <input
        type="text"
        className="form-control"
        placeholder={__('dashboard.components.oldFilterForm.fields.search.placeholder')}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
      <div className="input-group-feedback">
        <i className="mdi mdi-magnify"/>
      </div>
    </div>
  )
}

class FullTextSearch extends React.Component {

  state = {
    searchText: ''
  }

  componentWillReceiveProps(props) {
    if (props.items !== this.props.items) {
      this.setSearchText(this.state.searchText, props.items)
    }
  }

  setSearchText(searchText, items) {
    const { onChange, keys } = this.props
    this.setState({ searchText })

    if (!keys || !searchText || !searchText.length) {
      onChange && onChange(items)
    } else {
      const filtered = items.filter(item => {
        return keys.some(key => get(item, key) && get(item, key).toString().toLowerCase().includes(searchText.toLowerCase()))
      })
      onChange && onChange(filtered)
    }
  }

  render() {
    return (
      <div className="mr-auto">
        <SearchTextInput
          value={this.state.searchText}
          onChange={text => this.setSearchText(text, this.props.items)}
        />
      </div>
    )
  }

}

export default FullTextSearch