import React, { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { fetchResource } from 'api-provider';
import { getOrders, getOrder, completeOrders } from './resources';
import { DetailsModal } from './modals/DetailsModal';
import { ConfirmModal } from './modals/ConfirmModal';
import { Paginator } from 'components/Paginator';
import { OrdersList } from './components/OrdersList';
import Loader from 'components/Loader'
import { ToastComponent } from 'containers/Dashboard/components/Toast';
import { Header } from './components/Header';
import { handleCheckboxList } from './handlers/checkboxList';
import { handleFilters } from './handlers/filters';
import __ from 'utils/i18n';

const initialCheckboxList = [];
const initialFilters = {
  page_size: 20,
  page: 1,
};

const OrdersPanel = () => {
  // useReducer
  const [checkboxList, setCheckboxList] = useReducer(
    handleCheckboxList,
    initialCheckboxList
  );
  const [filters, setFilters] = useReducer(handleFilters, initialFilters);

  // useState
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  const [showDetailsOrderModal, setShowDetailsOrderModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [enableButtons, setEnableButtons] = useState(false);
  const [meta, setMeta] = useState({});
  const [links, setLinks] = useState({});

  // useSelector
  const { api } = useSelector((state) => state);
  const { token } = useSelector((state) => state.api);
  const { roleUser, accountId } = useSelector((state) => state.login?.user);

  // handlers
  const handleCheckboxChange = (orderId, checked) => {
    if (checked) setCheckboxList({ type: "add", payload: orderId });
    else setCheckboxList({ type: "remove", payload: orderId });
  };

  const handleReload = () => {
    setReload(!reload);
  };

  const handlePagination = (page) => {
    setFilters({ type: "page", payload: { page } });
  };

  const handleCompleteOrders = () => {
    let list = checkboxList;
    if (order && order.id) list = [{ id: order.id }];
    fetchResource(completeOrders({ list, token, roleUser, accountId }), {}, api)
      .onSuccess(() => {
        hideConfirmModal();
        hideDetailsOrderModal();
        handleReload();
      })
      .onFailure((err) => {
        if (err && !err.includes("404")) setRequestError(err);
      });
  };

  const handleCompleteOrder = () => {
    handleConfirmModal();
  };

  const handleConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const hideDetailsOrderModal = () => {
    setShowDetailsOrderModal(false);
    setOrder({});
  };

  const hideConfirmModal = () => {
    setShowConfirmModal(false);
  };

  // useEfects
  useEffect(() => {
    setIsFetching(true);
    setOrders([]);
    fetchResource(getOrders({ token, roleUser, accountId, filters }), {}, api)
      .onSuccess(({ data, meta, links }) => {
        if (data && data.attributes) setOrders(data.attributes);
        if (meta) setMeta(meta);
        if (links) setLinks(links);
        setIsFetching(false);
      
      })
      .onFailure((err) => {
        if (err && !err.includes("404")) setError(true);
        setIsFetching(false);
        
      });
    setCheckboxList({ type: "clear" });
  }, [reload, filters, accountId, api, roleUser, token]);

  useEffect(() => {
    if (checkboxList && checkboxList.length) setEnableButtons(true);
    else setEnableButtons(false);
  }, [checkboxList]);

  const showDetails = async (order) => {
    const { id } = order;
    setShowDetailsOrderModal(true);
    fetchResource(getOrder({ id, token, roleUser }), {}, api)
      .onSuccess((data) => {
        if (data) {
          setOrder(data);
        }
      })
      .onFailure(() => {
        setOrder({});
        setShowDetailsOrderModal(false);
      });
  };

  return (
    <div className="dashboard-card">
      <Header
        enableButtons={enableButtons}
        handleConfirmModal={handleConfirmModal}
        checkboxListLength={checkboxList.length}
        setFilters={setFilters}
        handleReload={handleReload}
        meta={meta}
        isFetching={isFetching}
      />
      {isFetching && <Loader />}
      {!isFetching && (!orders.length || error) && (
        <div className="ecommerceOrders-emptyPanel">
          <div className="dashboard-card-message">
            <i className="mdi mdi-minus-circle" />
            <h3>{__("dashboard.sections.ecommerce.orders.withoutOrders")}</h3>
            <p>{__("dashboard.sections.ecommerce.orders.notFoundOrders")}</p>
          </div>
        </div>
      )}
      {(!isFetching && orders.length && !error) && (
        <>
          <OrdersList
            orders={orders}
            showDetails={showDetails}
            handleCheckboxChange={handleCheckboxChange}
          />
          {Boolean( meta && links && meta.page && meta.page.total > meta.page.perPage ) && (
            <div className="paginatorContainer">
              <Paginator
                meta={meta}
                links={links}
                handlePagination={handlePagination}
              />
            </div>
          )}
        </>
      )}

      {showDetailsOrderModal && (
        <DetailsModal
          showModal={showDetailsOrderModal}
          submitAction={handleCompleteOrder}
          closeAction={hideDetailsOrderModal}
          order={order}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          showModal={showConfirmModal}
          closeAction={hideConfirmModal}
          submitAction={handleCompleteOrders}
          list={checkboxList}
        />
      )}
      {Boolean(requestError) && <ToastComponent error={__(requestError)} />}
    </div>
  );
};

export default OrdersPanel;
