import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from './FlashMessage';

const SuccessMessage = ({ children }) => (
  <FlashMessage type="success">
    { children }
  </FlashMessage>
)

SuccessMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
}

export default SuccessMessage;
