import React from 'react'
import Toggle from 'react-toggle';
import { Col, Row } from 'reactstrap';
import __ from "utils/i18n";

export const Activation = ({ isFormEnabled, values, setValue }) => {
    return (
        <>
            <div className="ecommerceForm-panel">
                <Row>
                    <Col className="ecommerceForm-mainTitle">
                        <span className="ecommerceForm-primary">
                            {__("store.activate_title")}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col
                        className="catalog__product--image-actions"
                        xs={12}
                        sm={12}
                        md={6}
                    >
                        <span>
                            {isFormEnabled(values.isEnabled)
                                ? __("store.activate_message")
                                : __("store.deactivated_message")}
                        </span>
                    </Col>
                    <Col xs={12} sm={12} md={{ size: 3, offset: 3 }}>
                        <div className="switch-input ecommerceForm-primary">
                            <Row>
                                <Col xs={9}>
                                    <label htmlFor="toogle-store">
                                        {isFormEnabled(values.isEnabled)
                                            ? __("store.activated")
                                            : __("store.deactivated")}
                                    </label>
                                </Col>
                                <Col xs={3}>
                                    <Toggle
                                        id="toogle-store"
                                        checked={isFormEnabled(values.isEnabled)}
                                        onChange={(e) =>
                                            setValue("isEnabled", !values.isEnabled)
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}