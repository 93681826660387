
import React from 'react'
import __ from 'utils/i18n'

class NotFound extends React.Component {
  render() {
    return (
      <div className="full-screen">
        <div className="full-message">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="146"
            height="118"
            viewBox="0 0 146 118"
          >
            <g fill="none" fill-rule="evenodd">
              <g stroke="#005AD4">
                <path
                  stroke-width="7"
                  d="M104.229 37.355v-.084C104.229 23.867 93.362 13 79.958 13c-10.677 0-19.743 6.894-22.991 16.473a17.604 17.604 0 0 0-10.726-3.624c-9.765 0-17.68 7.917-17.68 17.682 0 1.479.181 2.916.523 4.29C18.172 50.196 10 59.91 10 71.536c0 13.405 10.867 24.271 24.271 24.271h68.874c1.09.123 2.196.186 3.318.186 16.216 0 29.361-13.146 29.361-29.361 0-16.216-13.145-29.36-29.36-29.36-.752 0-1.498.027-2.235.083z"
                />
                <path
                  stroke-linecap="round"
                  stroke-width="5"
                  d="M65 80.075C67.343 76.692 70.189 75 73.537 75c3.349 0 6.062 1.692 8.14 5.075"
                />
                <g stroke-linecap="round" stroke-width="5">
                  <path d="M50.234 49.479l12.16 11.588M62.393 49.479l-12.16 11.588" />
                  <g>
                    <path d="M81.957 49.479l12.16 11.588M94.117 49.479l-12.16 11.588" />
                  </g>
                </g>
              </g>
              <path
                fill="#7693AB"
                d="M101.8 114.866c0 1.583-12.939 2.866-28.9 2.866-15.96 0-28.9-1.283-28.9-2.866C44 113.283 56.94 112 72.9 112c15.961 0 28.9 1.283 28.9 2.866"
                opacity=".143"
              />
            </g>
          </svg>

          <br/><br/>

          <h5>{__.bb("dashboard.sections.home.pageNotFound")}</h5>

          <br/><br/><br/>

          <a href={'/'}>
            <span>{__('dashboard.sections.home.goBack')}</span>
          </a>
        </div>
      </div>

    )
  }
}

export default NotFound