import React from "react";
import { useState } from "react";
import { Button } from "reactstrap";
import IconWarningCredentials from "assets/IconWarningCredentials.svg";
import { fetchResource } from "api-provider";

import LoaderContainer from "../../components/LoaderContainer";
import gtmEvents from "utils/gtmEvents";

function WarningStep(props) {
  const [isLoading, setIsLoading] = useState(false);

  const generateCredentials = () => {
    setIsLoading(true);
    fetchResource(props.resources)
      .onSuccess((success) => {
        props.setCredentials(success.data);
        props.navigation.go(3);
        setIsLoading(false);
        gtmEvents.credentialsPageView('validationCredentials');
      })
      .onFailure((error) => {
        if (error) {
          props.setErrorMessage(error);
        }
        props.navigation.go(4);
        setIsLoading(false);
      });
  };
  return (
    <div className="credentials-modal-warning-step credentials-modal-body-steps">
      {isLoading ? (
        <LoaderContainer loadingTexts={props.loadingTexts} />
      ) : (
        <>
          <img
            src={IconWarningCredentials}
            alt=""
            className="credentials-icon-warning"
          />
          <h5 className="credentials-modal-title credentials-modal-warning-step-title">
            {props.warningTexts.title}
          </h5>
          <h5 className="credentials-modal-subtitle credentials-modal-warning-step-subtitle">
            {props.warningTexts.subTitle}
          </h5>
          <div className="credentials-modal-warning-step-group-btn">
            <Button
              className="btn credentials-modal-step-button credentials-btn"
              color="primary"
              onClick={() => generateCredentials()}
            >
              {props.warningTexts.buttonText}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default WarningStep;
