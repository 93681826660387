import React from "react";
import { useSelector } from "react-redux";
import { Discovery } from "components/Discovery";
import __ from "utils/i18n";

export const WithholdingTaxDiscovery = () => {
  const { withholdingTax: showWithholdingTaxDiscovery = false } = useSelector(
    (state) => state.dashboard.discovery
  );
  const steps = [
    {
      title: __("discovery.withholdingTax.title"),
      content: __("discovery.withholdingTax.message"),
      showCloseButton: false,
      disableBeacon: true,
      placement: "bottom",
      target: ".dashboard-section-header",
    },
  ];
  // TODO eliminar showDiscovery y su implementación
  const showDiscovery = localStorage.getItem("showDiscovery");
  return (
    <>
      {showDiscovery && (
        <Discovery
          run={showWithholdingTaxDiscovery}
          steps={steps}
          discoveryId="withholdingTax"
        />
      )}
    </>
  );
};
