import React, { useContext } from 'react';
import __ from 'utils/i18n';
import { DropdownItem } from 'reactstrap';
import { PermissionsContext } from 'contexts/permissionsContext';

export const QRDownload = ({onClick}) => {
  const { userPermissions } = useContext(PermissionsContext);

  const isQRDownloadEnabled = userPermissions?.includes("user_menu_qr_download");

  return (
    <>
      {isQRDownloadEnabled && (
        <DropdownItem className="nav-menu-item" onClick={onClick}>
          <i className="mdi mdi-18px mdi-qrcode"/><span>{__('dashboard.components.qrDownload')}</span>
        </DropdownItem>
      )}
    </>
  )
}
