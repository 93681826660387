import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch as RouterSwitch, Route } from 'react-router-dom'
import { get } from 'lodash'
import { MainLoader } from 'components/MainLoader'
import { getConfig } from 'config'
import routes from './routes';
import { HelpWidget } from 'components/HelpWidget';
import { PermissionsContext } from "contexts/permissionsContext";

const MainRouter = (props) => {
  const { rehydrated, isLoading, isAuthenticated } = props;

  const { featureFlags } = useContext(PermissionsContext);

  const filteredRoutes = routes.filter(route => !route.auth || isAuthenticated);
  const basename = getConfig('customApiPath.basename') || undefined;
  const isHelpWidgetEnabled = featureFlags?.includes('help_widget');

  const routerProps = {
    onUpdate: false
  };

  if (basename) {
    routerProps['basename'] = basename;
  }

  return (
    <>
      <BrowserRouter {...routerProps}>
        <MainLoader isLoading={isLoading || !rehydrated}>
          {filteredRoutes && rehydrated && (
            <RouterSwitch>
              {filteredRoutes.map((route, index) => (
                <Route key={index} {...route} />
              ))}
            </RouterSwitch>
          )}
        </MainLoader>
      </BrowserRouter>
      {isHelpWidgetEnabled && <HelpWidget />}
    </>
  )

}

const mapStateToProps = (state) => ({
  isLoading: get(state, 'isLoading'),
  isAuthenticated: get(state, 'login.isAuthenticated'),
  rehydrated: get(state, 'rehydrated'),
  user: get(state, 'login.user.email'),
  locale: get(state, 'locale')
});

export default connect(mapStateToProps)(MainRouter);
