import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { SimpleForm } from "components/forms";
import __ from "utils/i18n";
import { APIRequest, fetchResource } from "api-provider";
import { validateFields, validEmail } from "components/forms/validators";

import { getConfig } from "config";
import { PermissionsContext } from "contexts/permissionsContext";
import { setEcommerce, clearEcommerce } from "../../actions";
import { ToastComponent } from "containers/Dashboard/components/Toast";
import { ecommerceUrl } from "config/envConfig";
import { resources } from "./api/resources";
import { InfoModal } from "./modals/Info";
import { SaveModal } from "./modals/Save";
import { DisableModal } from "./modals/Disable";
import { FAQS } from "./FAQs";
import { FaqsModal } from "./modals/Faqs";
import { Subsidiaries } from "./Subsidiaries";
import { SocialNetworks } from "./SocialNetworks";
import { StoreSlug } from "./Slug";
import { Activation } from "./Activation";
import { Information } from "./Information";
import { MaxUnitPerProduct } from "./MaxUnitPerProduct";
import { Header } from "./Header";
import { SaveForm } from "./Save";
import { StoreOptionAvailable } from './StoreOptionAvailable'

const initialStore = {
  isEnabled: false,
  maxUnitPerProduct: 10,
  locations: [],
};

/* 
  Modals:
  infoModal
  saveModal
  disableModal
  editLogoModal
  deleteLogo
*/

export const EcommercePanel = () => {
  const [showModal, setShowModal] = useState(false);
  const [slug, setSlug] = useState(false);
  const [storeState, setStoreState] = useState(initialStore);

  const [error, setError] = useState(false);

  const [nameCounter, setNameCounter] = useState(0);
  const [descriptionCounter, setDescriptionCounter] = useState(0);
  const [mainMessageCounter, setMainMessageCounter] = useState(0);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [maxUnitPerProductError, setMaxUnitPerProductError] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [FAQs, setFAQs] = useState([]);
  const [activeFAQ, setActiveFAQ] = useState(null)

  const { token } = useSelector((state) => state.api);
  const { roleUser } = useSelector((state) => state.login.user);
  const { accountId } = useSelector((state) => state.login.user);
  const { isStoreActive } = useSelector((state) => state.login.user);
  const { locations } = useSelector((state) => state.login.user);

  const { userPermissions } = useContext(PermissionsContext);
  const isEditStoreEnabled = userPermissions?.includes("ecommerce_action_edit");

  const dispatch = useDispatch();

  const accountLocations = locations || [];

  const editSuccess = (data) => {
    const store = { ...storeState, ...data };
    setStoreState((prevState) => ({ ...prevState, ...data }));
    dispatch(setEcommerce(store));
    setError(null);
  };

  const editFailure = () => {
    dispatch(clearEcommerce());
  };

  const deleteSuccess = ( data ) => {
    const store = { ...storeState.store, ...data };

    setStoreState((prevState) => ({ ...prevState, ...data }));
    setError(null);
    dispatch(setEcommerce(store));
    setShowModal(null);
  };

  const deleteFailure = (error) => {
    setError(error);
    if (error) setTimeout(() => setError(false), 10000);
    setShowModal(null);
    dispatch(clearEcommerce());
  };

  const isStoreCreated = () => {
    if (!storeState.id) {
      return false;
    }
    return true;
  };

  const checkErrors = () => {
    if (!storeState.maxUnitPerProduct) {
      setMaxUnitPerProductError(__("validators.required"));
      return false;
    }
    return true;
  };

  const handleSubmit = (values, request) => {

    if (!values.isEnabled) {
      setShowModal("disableModal");
      return;
    }

    if (typeof (values.maxUnitPerProduct) === 'string') {
      values.maxUnitPerProduct = parseInt(values.maxUnitPerProduct);
    }

    removeLogoFromPayloadIfHasntChanged(values);

    values.faqs = FAQs

    values.locations = selectedLocations;
    if (values.slug) setSlug(values.slug);
    request
      .fetch(values)
      .then(() => {
        setShowModal("saveModal");
      })
      .catch((error) => {
        setError(error);
        if (error) setTimeout(() => setError(false), 10000);
        console.log("error", error);
      });
  };

  const removeLogoFromPayloadIfHasntChanged = (values) => {
    if (values.logo === storeState?.logo) {
      delete values.logo;
    }
  };

  const isFormEnabled = (switchEnabled) => {
    return switchEnabled && isStoreActive;
  };

  const handleCheckboxChange = (location, checked, setValue) => {
    const locationsSelected = selectedLocations;
    const filtered = locationsSelected.filter((elem) => elem.id === parseInt(location));
    if (filtered.length > 0) {
      const index = locationsSelected.findIndex(
        (element) => element.id === filtered[0].id
      );
      locationsSelected[index].enabled = checked;
    }
    setSelectedLocations(locationsSelected);
    setValue("locations", locationsSelected);
  };

  useEffect(() => {
    const loadInitialSubsidiariesSelected = () => {
      let locationsSelected = [];
      if (storeState.locations && storeState.locations.length) {
        locationsSelected = accountLocations.map((location) => {
          const filtered = storeState.locations.filter(
            (storeLocation) =>
              storeLocation.id === location.id && storeLocation.enabled
          );
          return {
            id: location.id,
            name: location.name,
            enabled: Boolean(filtered.length),
          };
        });
      } else {
        locationsSelected = accountLocations.map((location) => ({
          id: location.id,
          name: location.name,
          enabled: true,
        }));
      }

      setStoreState((prevState) => ({
        ...prevState,
        locations: locationsSelected,
      }));
      setSelectedLocations(locationsSelected);

      if (storeState.faqs && storeState.faqs.length > 0) {
        setFAQs(storeState.faqs)
      }
    };

    fetchResource(resources.findStore({ accountId, token, roleUser }))
      .onSuccess((data) => {
        const store = { ...storeState, ...data };
        /*
         * TODO: this line must be refactored in order to use the setStoreState
         * function.
         * 
         * setStoreState((prevState) => ({ ...prevState, ...data }));
         */
        Object.assign(storeState, data);
        setCroppedImage(data.logo);
        setNameCounter(data.name?.length || 0);
        setDescriptionCounter(data.shortDescription?.length || 0);
        setMainMessageCounter(data.mainMessage?.length || 0);
        dispatch(setEcommerce(store));
        loadInitialSubsidiariesSelected();
      })
      .onFailure((error) => {
        dispatch(clearEcommerce());
        loadInitialSubsidiariesSelected();
      });

    // Preventing eslint warning as storeState is constantly changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ecommerceForm-dashboard-card">
      <APIRequest
        resource={resources.deactivate({
          storeId: storeState.id,
          token,
          roleUser,
        })}
        onSuccess={deleteSuccess}
        onFailure={deleteFailure}
      >
        {(request) => (
          <DisableModal
            showModal={showModal}
            setShowModal={setShowModal}
            request={request}
          />
        )}
      </APIRequest>

      <APIRequest
        resource={
          storeState.id
            ? (payload) =>
                resources.edit({
                  payload,
                  storeId: storeState.id,
                  token,
                  roleUser,
                })
            : (payload) => resources.create({ payload, token, roleUser })
        }
        onSuccess={editSuccess}
        onFailure={editFailure}
      >
        {(request) => (
          <SimpleForm
            defaultValues={storeState}
            validate={validateFields([
              { field: "slug", required: true, slug: 1 },
              { field: "name", required: 1 },
              { field: "contactPhone", required: true, integer: 1 },
              {
                field: "contactEmail",
                validate: (value) => value && validEmail()(value),
                emailMaxLength: 100,
              },
            ])}
            validateOnSubmit={true}
            onSubmit={(values) => {
              values.accountId = accountId ? accountId : null;
              if (checkErrors()) handleSubmit(values, request);
            }}
          >
            {({ values, setValue, setAllValues, ...rest }) => (
              <div className="ecommerceForm-panel-flex">
                {!isStoreActive && (
                  <Row className="ecommerceForm-alert">
                    <Col xs={12} sm={12} md="12">
                    <StoreOptionAvailable />
                    </Col>
                  </Row>
                )}
                <Header
                  isFormEnabled={isFormEnabled}
                  values={values}
                  storeState={storeState}
                  isEditStoreEnabled={isEditStoreEnabled}
                  request={request}
                  setShowModal={setShowModal}
                />
                <div
                  className={
                    isFormEnabled(values.isEnabled)
                      ? "ecommerceForm-section"
                      : "ecommerceForm-section-disabled"
                  }
                >
                  <Activation
                    isFormEnabled={isFormEnabled}
                    values={values}
                    setValue={setValue}
                  />
                  <hr />
                  <StoreSlug
                    isFormEnabled={isFormEnabled}
                    values={values}
                    storeState={storeState}
                    ecommerceUrl={ecommerceUrl}
                    slug={slug}
                    isStoreCreated={isStoreCreated}
                  />
                  <hr />
                  <Information
                    isFormEnabled={isFormEnabled}
                    values={values}
                    setValue={setValue}
                    getConfig={getConfig}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    nameCounter={nameCounter}
                    setNameCounter={setNameCounter}
                    descriptionCounter={descriptionCounter}
                    setDescriptionCounter={setDescriptionCounter}
                    mainMessageCounter={mainMessageCounter}
                    setMainMessageCounter={setMainMessageCounter}
                    croppedImage={croppedImage}
                    setCroppedImage={setCroppedImage}
                  />
                  <hr />
                  <SocialNetworks
                    isFormEnabled={isFormEnabled}
                    values={values}
                    getConfig={getConfig}
                  />
                  <hr />
                  <Row>
                    <Col xs={12} sm={12} md={6}>
                      <Subsidiaries
                        selectedLocations={selectedLocations}
                        isFormEnabled={isFormEnabled}
                        handleCheckboxChange={handleCheckboxChange}
                        values={values}
                        setValue={setValue}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <MaxUnitPerProduct
                        isFormEnabled={isFormEnabled}
                        values={values}
                        maxUnitPerProductError={maxUnitPerProductError}
                        setMaxUnitPerProductError={setMaxUnitPerProductError}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <FAQS
                    FAQs={FAQs}
                    setFAQs={setFAQs}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    activeFAQ={activeFAQ}
                    setActiveFAQ={setActiveFAQ}
                  />
                  <SaveForm
                    isFormEnabled={isFormEnabled}
                    isEditStoreEnabled={isEditStoreEnabled}
                    values={values}
                    request={request}
                    storeState={storeState}
                  />
                </div>
                {Boolean(error) && <ToastComponent error={__(error)} />}
              </div>
            )}
          </SimpleForm>
        )}
      </APIRequest>
      <FaqsModal
        showModal={showModal}
        setShowModal={setShowModal}
        FAQs={FAQs}
        setFAQs={setFAQs}
        activeFAQ={activeFAQ}
        setActiveFAQ={setActiveFAQ}
      />
      <InfoModal showModal={showModal} setShowModal={setShowModal} />
      <SaveModal
        showModal={showModal}
        setShowModal={setShowModal}
        slug={slug}
      />
    </div>
  );
};
