import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

export const TooltipInformation = ({ index, message, className, position }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className={className}>
      <i className='mdi mdi-information' id={index}></i>
      <Tooltip
        placement={position}
        isOpen={tooltipOpen}
        target={index}
        toggle={() => setTooltipOpen((prevState) => !prevState)}
      >
        {message}
      </Tooltip>
    </div>
  );
};
