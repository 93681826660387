import React, { useState } from 'react';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import __ from 'utils/i18n';

export const ActionButton = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen((prevState) => !prevState)}
    >
      <DropdownToggle className='payment-button-actions btn-secondary'>
        <i className='mdi mdi-dots-vertical' />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => props.show(true)}>
          {__('dashboard.sections.compraClick.actionButton.actions.show')}
        </DropdownItem>
        <DropdownItem onClick={() => props.clone(props.index, 'create')}>
          {__('dashboard.sections.compraClick.actionButton.actions.clone')}
        </DropdownItem>
        <DropdownItem onClick={() => props.delete(true)}>
          {__('dashboard.sections.compraClick.actionButton.actions.delete')}
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
