import React from "react";

import __ from "utils/i18n";
import { DashboardModal } from "containers/Dashboard/components/DashboardModal";
import { ecommerceUrl } from "config/envConfig";


export const SaveModal = ({showModal, setShowModal, slug}) => {
  const urlSlug = slug ? slug : "";
  return (
    <DashboardModal
      name="saveModal"
      headerText={__("store.modal.save.title")}
      showModal={showModal === "saveModal"}
      hideModal={() => setShowModal(false)}
      submitColor="success"
      submitAction={() => setShowModal(false)}
      cancelText={__("actions.close")}
      submitText={__("store.return")}
    >
      {__("store.modal.save.subtitle_link")}
      <a
        href={`${ecommerceUrl}/${urlSlug}`}
        target="_blank"
        className={"ecommerceForm-link"}
        rel="noopener noreferrer"
      >
        <b>[ {`${ecommerceUrl}/${urlSlug}`} ]</b>
      </a>
      <br></br>
      {__("store.modal.save.subtitle_description")}
    </DashboardModal>
  );
};
