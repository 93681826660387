import React from 'react'
import { isNumber } from 'lodash'
import CRUDSection from 'components/CRUDSection'
import StateBadge from 'components/StateBadge'
import __ from "utils/i18n";
import { useSelector } from 'react-redux';
import MerchantForm from './MerchantForm'
import { SimpleForm } from 'components/forms'
import { validateFields } from 'components/forms/validators'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import { getConfig } from 'config'

const resources = {
  list: (token, subsidiaryId) => ({
    endpoint: 'subsidiaries',
    method: 'GET',
    headers: {
        'X-Authentication-Token': token
    },
    cache: false,
    params: {
        subsidiaryId: subsidiaryId,
    },
    parseResponse: response => response.data.data
  }),
  create: token => payload => {
    const fields = {
      name: payload.name,
      statusId: 1,
      department: payload.department || '',
      province: payload.province || '',
      district: payload.district || '',
      floor: payload.floor || '',
      reference: payload.reference || '',
      street: payload.street || ''
    }
    
    return ({
    endpoint: 'subsidiaries',
    method: 'POST',
    headers: { "x-authentication-token": token },
    formData: fields,
    force: true
  })
  },
  update: token => ([id, payload]) => {
    const fields = {
      name: payload.name,
      statusId: payload.statusId,
      department: payload.department || '',
      province: payload.province || '',
      district: payload.district || '',      
      floor: payload.floor || '',
      reference: payload.reference || '',
      street: payload.street || ''
    }

    return({
    endpoint: 'subsidiaries/' + id ,
    method: 'PUT',
    headers: { "x-authentication-token": token, "content-type": "application/json" },
    data: fields,
    force: true
  })
  },
  delete: token => (id) => ({
    endpoint: 'subsidiaries/' + id,
    headers: { 
      "x-authentication-token": token
   },
    method: 'DELETE',
    cache: false
  })
}

const SubsidiaryUsers = ({original}) => {
  return <span>{original.userCount} {parseInt(original.userCount) === 1 ? __('dashboard.sections.merchantSubsidiaries.seller') : __('dashboard.sections.merchantSubsidiaries.sellers')}</span>
}

const SubsidiaryStatus = ({original}) => {
   const status = isNumber(original.status) ? original.statusName : original.status
   return status ? <StateBadge state={status}/> : ''
 }

const getSubsidiariesColumns = () => {
  const configColumns = getConfig("tableColumns.subsidiariesColumns")
  const subsidiariesColumns = []
  const defaultColumns  = {
    name: {
      Header: __('dashboard.sections.merchantSubsidiaries.name'),
      name: 'name',
      icon: 'flag',
      width: 300
    },
    address: {
      Header: __('dashboard.sections.merchantSubsidiaries.address'),
      name: 'address',
      width: 300,
      maxWidth: 300
    },
    sellers: {
      Header: __('dashboard.sections.merchantSubsidiaries.sellers'),
      Cell: SubsidiaryUsers,
      width: 300,
      maxWidth: 300,
      align: 'right'
    },
    state:{
      Header:  __('dashboard.sections.merchantSubsidiaries.state'),
      Cell: SubsidiaryStatus,
      name: 'enable',
      width: 50,
    }
  }

  configColumns.map(item =>{
    return subsidiariesColumns.push( defaultColumns[item] )
  })

  return subsidiariesColumns
}

const CreateMerchant = ({show, isFetching, error, onClose, onCreate}) => {
  const showSelectsAddres = getConfig('customConfig.merchant.showSelectsAddres')
  const fields = getConfig('customConfig.merchant.subsidiaries.fieldsPanel')

  let valiadtionsDefault = [{ field:"name", required:1 }]
  if(showSelectsAddres){
    valiadtionsDefault = [
      { field:"name", required:1 },
      { field:"department", required:1 },
      { field:"province", required:1 },
      { field:"district", required:1 },
    ]
  }

  let validationByField = fields.map((item)=>{
    return {field: item.name, required:1 }
  })

  const validations  = [
    ...valiadtionsDefault,
    ...validationByField
  ]
  

  return (
    <SimpleForm
      validate={validateFields(validations)}
      onSubmit={onCreate}
      validateOnSubmit={true}
      reset={show}
    >
      {formApi => (
        <Modal
          isOpen={show}
          toggle={onClose}
          size="lg">
          <ModalHeader
            toggle={onClose}>
            {__('dashboard.sections.merchantSubsidiaries.modal.newLocal')}
          </ModalHeader>
          <ModalBody>
            <MerchantForm values={formApi.values}/>
            <div className="text-danger">{error}</div>
            
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" className="btn-left" onClick={onClose}>Cerrar</Button>
            <Button color="success" disabled={isFetching || (formApi.errors && formApi.errors.length)} onClick={formApi.submitForm}>{__('actions.create')}</Button>
          </ModalFooter>
        </Modal>
      )}
    </SimpleForm>
  )
}


const EditMerchant = ({item, show, showDelete, isFetching, error, onClose, onUpdate}) => {
  let defaultValues = {}
  if(item.addressInformation){
    defaultValues = {
      ...item,
      ...item.addressInformation
    }
  }else{
    defaultValues = {
      ...item
    }
  }
  
  const showSelectsAddres = getConfig('customConfig.merchant.showSelectsAddres')
  const fields = getConfig('customConfig.merchant.subsidiaries.fieldsPanel')

  let valiadtionsDefault = [{ field:"name", required:1 }]
  if(showSelectsAddres){
    valiadtionsDefault = [
      { field:"name", required:1 },
      { field:"department", required:1 },
      { field:"province", required:1 },
      { field:"district", required:1 },
    ]
  }

  let validationByField = fields.map((item)=>{
    return {field: item.name, required:1 }
  })

  const validations  = [
    ...valiadtionsDefault,
    ...validationByField
  ]
  
  return (
    <SimpleForm
      key={show}
      defaultValues={defaultValues}
      onSubmit={values => onUpdate(item.id, values)}
      validate={validateFields(validations)}
      validateOnSubmit={true}
      reset={!show}
    >
      
      {formApi => (
        <Modal
          isOpen={show}
          toggle={onClose}
          size="lg">
          <ModalHeader
            toggle={onClose}>
            {__('dashboard.sections.merchantSubsidiaries.modal.editLocal')} {item && item.name}
          </ModalHeader>
          <ModalBody>
            <MerchantForm values={formApi.values}/>
            <div className="text-danger">{error}</div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="btn-left"
              onClick={showDelete}>{__('actions.delete')}</Button>
            <Button
              color="success"
              disabled={isFetching}
              onClick={formApi.submitForm}>{__('actions.save')}</Button>
          </ModalFooter>
        </Modal>
      )}
    </SimpleForm>
  )
}


const DeleteMerchant = ({item, show, isFetching, error, onClose, onDelete}) => {
  return (
    <div>
      <SimpleForm
        onSubmit={() => onDelete(item.id)}
        validateOnSubmit={true}
        reset={!show}
      >
        {formApi => (
          <Modal
            isOpen={show}
            toggle={onClose}
            size="lg">
            <ModalHeader>
              {__('dashboard.sections.merchantSubsidiaries.modal.deleteLocal')} {item && item.name}
            </ModalHeader>
            <ModalBody>
              {__('dashboard.sections.merchantSubsidiaries.modal.confirmDeleteLocal')}
              <div className="text-danger">{error}</div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={onClose}>{__('actions.cancel')}</Button>
              <Button
                color="danger"
                disabled={isFetching}
                onClick={formApi.submitForm}>{__('actions.delete')}</Button>
            </ModalFooter>
          </Modal>
        )}
      </SimpleForm>
    </div>
  )
}


const MerchantSection = () => {
    const token = useSelector(state => state.api.token);
    const { subsidiaryId } = useSelector((state) => state.login.user);
  
    return (
      <CRUDSection
        createButtonText={__('dashboard.sections.merchantSubsidiaries.modal.newLocal')}
        createView={CreateMerchant}
        editView={EditMerchant}
        deleteView={DeleteMerchant}
        columns={getSubsidiariesColumns()}
        noItems={{
          icon: 'minus-circle',
          title: __('dashboard.sections.merchantSubsidiaries.withoutLocals'),
          desc: __('dashboard.sections.merchantSubsidiaries.notFoundLocals')
        }}
        resources={{
          list: resources.list(token, subsidiaryId),
          create: resources.create(token),
          update: resources.update(token),
          delete: resources.delete(token)
        }}
      />
    )
  
}

export default MerchantSection
