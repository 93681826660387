import React, { useContext } from 'react';
import __ from 'utils/i18n';
import { DropdownItem } from 'reactstrap';
import { PermissionsContext } from 'contexts/permissionsContext';

export const StaticQRCode = ({ onClick }) => {
  const { userPermissions, featureFlags } = useContext(PermissionsContext);

  const isStaticQRCodeEnabled =
    featureFlags?.includes("user_menu_static_qr") &&
    userPermissions?.includes("user_menu_static_qr");

  return (
    <>
      {isStaticQRCodeEnabled && (
        <DropdownItem className="nav-menu-item" onClick={onClick}>
          <i className="mdi mdi-18px mdi-qrcode" /><span>{__('dashboard.components.qrCode')}</span>
        </DropdownItem>
      )}
    </>
  );
}
