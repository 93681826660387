import React from 'react';
import { useSelector } from 'react-redux';

export const User = () => {
  const name = useSelector((state) => state.login?.user?.name);
  const email = useSelector((state) => state.login?.user?.email);

  return (
    <div className="nav-menu-item user-profile">
      <div className="user-profile__info">
        <div className="user-profile__info__name">{name} </div>
        <div className="user-profile__info__email">{email} </div>
      </div>
    </div>
  )
}
