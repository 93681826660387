
import { ACTIONS } from './constants'

// Actions

export const setEcommerce = (ecommerce) => {
  return ({
    type: ACTIONS.SET_ECOMMERCE,
    ecommerce
  });
}

export const clearEcommerce = () => {
  return ({
    type: ACTIONS.CLEAR_ECOMMERCE
  });
}

