import React, { useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { get } from "lodash";
import { Badge, CardTitle } from "reactstrap";
import VisibilitySensor from "react-visibility-sensor";
import { getConfig } from "config";
import __ from "utils/i18n";
import Loader from "components/Loader";
import FlexTable from "components/FlexTable";
import DashboardPanel from "containers/Dashboard/components/DashboardPanel";
import { BatchCloseModal } from "../BatchCloseModal";
import { BatchClosePanel } from "../BatchClosePanel";
import { handleClose } from "../handleClose";
import { tenantId } from "config/envConfig";
import iconCreditCard from "../../../../../../../assets/icon-creditCard.png";

export const getBatchCloseName = ({ original }) => {
  return <span className="text-left"><img src={iconCreditCard} alt="Icon credit card" width={30} className="mr-3" />{original.name}</span>;
};

export const BatchNumberCell = ({ original }) => {
  const authCode = original.authorizationCode;
  return authCode ? authCode : "-";
};

export const BatchTransactionsCell = ({ original }) => {
  const quantity = original.transactionsQuantity;
  return quantity ? quantity : "-";
};

export const BatchCloseTimeCell = ({ original }) => {
  return <span>{__.stringToDate(original.dateTime)}</span>;
};

export const TotalAmountCell = ({ original }) => {
  const total = original.amount || original.totalAmount;
  return total
    ? __.currency(total, original.currencyCode || original.currency, "decimal")
    : "-";
};

export const StatusCell = ({ original }) => {
  const isClosed = get(original, "isClose");
  return (
    <Badge className={`batch-status ${isClosed ? 'batch-status-closed' : 'batch-status-open'}`}>
      {isClosed ? __('dashboard.sections.batchClose.table.batchClose') : __('dashboard.sections.batchClose.table.batchOpen')}
    </Badge>
  );
};

export const ActionsCell = ({ original }) => {
  if (get(original, "isClose") === true) {
    return <span>{__("dashboard.sections.batchClose.table.viewDetail")}</span>;
  }
  return (
    <span style={{ color: "red" }}>
      {__("dashboard.sections.batchClose.modal.headerText")}
    </span>
  );
};

const getBatchCloseColumns = () => {
  const configColumns = getConfig("tableColumns.batchCloseColumns");

  const batchCloseColumns = [];

  const defaultColumns = {
    name: {
      Header: __("dashboard.sections.batchClose.table.name"),
      Cell: getBatchCloseName,
    },
    batch: {
      Header: __("dashboard.sections.batchClose.table.batch"),
      Cell: BatchNumberCell,
    },
    transactions: {
      Header: __("dashboard.sections.batchClose.table.transactions"),
      Cell: BatchTransactionsCell,
    },
    date: {
      Header: __("dashboard.sections.batchClose.table.date"),
      Cell: BatchCloseTimeCell,
    },
    amount: {
      Header: __("dashboard.sections.batchClose.table.amount"),
      Cell: TotalAmountCell,
    },
    status: {
      Header: __("dashboard.sections.batchClose.table.status"),
      Cell: StatusCell,
    },
    action: {
      Header: __("dashboard.sections.batchClose.table.action"),
      Cell: ActionsCell,
    },
  };

  configColumns.forEach((item) => batchCloseColumns.push(defaultColumns[item]));
  return batchCloseColumns;
};

export const BatchCloseTable = ({
  data,
  isFetching,
  hasMore,
  getMore,
  error,
  subsidiariesStore,
  detailsData,
  setDetailsData,
  applyFilter,
  batchUnitModal,
  setBatchUnitModal,
  confirmBatchUnitModal,
  setConfirmUnitBatchModal,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const token = useSelector((state) => state.api?.token);
  const userId = useSelector((state) => state.login.user.id);
  const handleDetails = (data) => {
    if (data.isClose === true) {
      setShowDetails(true);
      setDetailsData(data);
    } else {
      setBatchUnitModal(true);
      setDetailsData(data);
    }
  };

  const handleCloseModal = () => {
    setConfirmUnitBatchModal(false);
  };

  const message = {
    headerText: __("dashboard.sections.batchClose.modal.headerText"),
    submitText: __("dashboard.sections.batchClose.modal.close"),
    cancelText: __("dashboard.sections.batchClose.modal.cancel"),
    acceptText: __("dashboard.sections.batchClose.modal.accept"),
    confirmText: __("dashboard.sections.batchClose.modal.confirmClose"),
    successAllCloseText: __("dashboard.sections.batchClose.modal.successClose"),
  };

  const showCancelButton =
    confirmBatchUnitModal && !batchUnitModal ? false : true;

  return (
    <div
      className={classnames({
        "panel-expanded": showDetails,
        "table-container": true,
      })}
    >
      <DashboardPanel
        expanded={showDetails}
        hidePanel={() => setShowDetails(false)}
        panelHeading={`${__(
          "dashboard.sections.batchClose.panel.headingTitle"
        )}${detailsData.authorizationCode}`}
      >
        <BatchClosePanel data={detailsData} />
      </DashboardPanel>

      <BatchCloseModal
        batchModal={batchUnitModal}
        confirmBatchModal={confirmBatchUnitModal}
        handleClose={handleClose}
        handleCloseModal={handleCloseModal}
        setBatchModal={setBatchUnitModal}
        setConfirmBatchModal={setConfirmUnitBatchModal}
        userId={userId}
        tenantId={tenantId}
        token={token}
        detailsData={detailsData}
        applyFilter={applyFilter}
        {...message}
        showCancelButton={showCancelButton}
      />
      
      {data.length > 0 && (
        <>
          {data.some((item) => item.status === "rejected") && (
            <CardTitle tag="div">
              {__("dashboard.sections.sales.rejected.description")}
            </CardTitle>
          )}
          <FlexTable
            data={data}
            columns={getBatchCloseColumns(subsidiariesStore)}
            onRowClick={({ index }) => handleDetails(data[index])}
          />
        </>
      )}
      <VisibilitySensor onChange={(visible) => visible && !error && getMore()}>
        <div style={{ height: 1, width: "100%" }} />
      </VisibilitySensor>
      {hasMore && <div className="loader-row">{isFetching && <Loader />}</div>}
    </div>
  );
};
