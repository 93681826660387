
import React from 'react';
import { InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import classNames from 'classnames';
import { get } from 'lodash';
import i18n from 'utils/i18n';

const dateRangeTheme = {
  Calendar : { width: 200 }
}

class DateRangeInput extends React.Component {

  defaultRanges = [
    {
      label: i18n('thisWeek'),
      startDate: moment().startOf('isoWeek'),
      endDate: moment()
    },
    {
      label: i18n('lastWeek'),
      startDate: moment().subtract(7, 'days').startOf('isoWeek'),
      endDate: moment().subtract(7, 'days').endOf('isoWeek')
    },
    {
      label: i18n('thisMonth'),
      startDate: moment().startOf('month'),
      endDate: moment()
    },
    {
      label: i18n('lastMonth'),
      startDate: moment().subtract(1, 'months').startOf('month'),
      endDate: moment().subtract(1, 'months').endOf('month')
    },
  ]

  constructor(props) {
    super(props)
    const startDate = moment(get(props, 'value.startDate'))
    const endDate = moment(get(props, 'value.endDate'))
    this.state = {
      startDate,
      endDate,
      editorStartDate: startDate,
      editorEndDate: endDate,
      minDate: props.future ? moment() : props.minDate && moment(props.minDate),
      maxDate: props.past ? moment() : props.maxDate && moment(props.maxDate),
      interval: props.interval || moment.duration(1, 'months'),
      isOpen: false
    }
  }

  componentDidMount() {
    const { format, onInit } = this.props
    const { startDate, endDate } = this.state
    onInit && onInit({
      startDate: format ? startDate.format(format) : startDate,
      endDate: format ? endDate.format(format) : endDate,
    })
  }

  componentWillReceiveProps(nextProps){
    const startDate = moment(get(nextProps, 'value.startDate'))
    const endDate = moment(get(nextProps, 'value.endDate'))
    const values = {startDate, endDate}

    if(nextProps.value.startDate !== this.props.value.startDate){
      this.setState({
        startDate,
        endDate,
      })
      this.setEditorRange(values)
    }
  }

  handleBack() {
    this.setRange({
      startDate: this.state.startDate.clone().subtract(this.state.interval),
      endDate: this.state.endDate.clone().subtract(this.state.interval)
    })
  }

  handleNext() {
    this.setRange({
      startDate: this.state.startDate.clone().add(this.state.interval),
      endDate: this.state.endDate.clone().add(this.state.interval)
    })
  }

  handleOpen() {
    this.setState({ isOpen: true })
  }

  handleClose() {
    this.setState({ isOpen: false })
    this.setEditorRange({
      startDate: this.state.startDate,
      endDate: this.state.endDate
    })
  }

  handleApply() {
    this.setState({ isOpen: false })
    this.setRange({
      startDate: this.state.editorStartDate,
      endDate: this.state.editorEndDate
    })
  }

  handleSetRange(range) {
    this.setState({ isOpen: false })
    this.setRange(range)
  }

  submit(startDate, endDate) {
    const { format, onChange } = this.props
    onChange && onChange({
      startDate: format ? startDate.format(format) : startDate,
      endDate: format ? endDate.format(format) : endDate,
    })
  }

  getFormattedRange() {
    return i18n.daterange(this.state.startDate, this.state.endDate, true)
  }

  isValidRange(startDate, endDate) {
    return (startDate.isSameOrBefore(endDate)) &&
      (!this.state.minDate || startDate.isSameOrAfter(this.state.minDate, 'day')) &&
      (!this.state.maxDate || endDate.isSameOrBefore(this.state.maxDate, 'day'))
  }

  setEditorRange({ startDate, endDate }) {
    this.setState({
      editorStartDate: startDate,
      editorEndDate: endDate,
    })
  }

  setRange({ startDate, endDate }) {
    if (this.isValidRange(startDate, endDate)) {
      this.setState({
        startDate,
        endDate,
        editorStartDate: startDate,
        editorEndDate: endDate,
      })
      this.submit(startDate, endDate)
    }
  }

  getRanges() {
    return this.props.ranges || this.defaultRanges
  }

  render() {
    return (
      <div className="input-date-range">
        <InputGroup size="sm">
          <InputGroupAddon
            className="input-group-btn"
            addonType="prepend"
            onClick={() => this.handleBack()}>
            <button type="button" className={
              classNames({
                "btn btn-secondary ": true,
                "disabled": this.state.startDate <= this.state.minDate
              })}>
              <i className="mdi mdi-arrow-left"/>
            </button>
          </InputGroupAddon>
          <InputGroupAddon
            addonType="prepend"
            className={classNames({
              "input-group-btn": true,
              "btn-label-date": true,
              "block": this.props.block
            })}
            onClick={() => this.handleOpen()}>
            <button type="button" className="btn btn-secondary input-date-range-value">
              { this.getFormattedRange() }
            </button>
          </InputGroupAddon>
          <InputGroupAddon className="input-group-btn" addonType="prepend" onClick={() => this.handleNext()}>
            <button type="button" style={{marginLeft:'3px'}} className={
              classNames({
                "btn btn-secondary": true,
                "disabled": this.state.endDate >= this.state.maxDate
              })}>
              <i className="mdi mdi-arrow-right"/>
            </button>
          </InputGroupAddon>
        </InputGroup>
        <div
          className={classNames({
            "outer-handler":true,
            "show":this.state.isOpen
          })}
          onClick={() => this.handleClose()}
        />
        <div
          className={classNames({
            "input-date-range-editor":true,
            "show":this.state.isOpen
          })}>
          <div>
            { this.state.isOpen &&
            <DateRange
              theme={ dateRangeTheme }
              minDate={ this.state.minDate }
              maxDate={ this.state.maxDate }
              startDate={ this.state.editorStartDate }
              endDate={ this.state.editorEndDate }
              offsetPositive={true}
              calendars={1}
              lang={"es"}
              onChange={ value => this.setEditorRange(value) }
            />
            }
          </div>
          <div className="range-buttons" style={{width:200}}>
            <div>
              { this.getRanges().map((range, index) => (
                <Button
                  key={index}
                  type="button"
                  block={true}
                  onClick={() => this.handleSetRange(range)}>
                  { i18n(`dateRangePicker.${range.label}`) }
                </Button>
              ))}
            </div>
            <div>
              <Button
                className="apply"
                type="button"
                block={true}
                onClick={() => this.handleApply()}>
                {i18n('actions.apply')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DateRangeInput