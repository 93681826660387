import React from 'react'
import { fetchResource } from 'api-provider'
import { Button } from 'reactstrap'
import Loader from 'components/Loader'
import classNames from 'classnames'
import FullTextSearch from 'components/FullTextSearch'
import FlexTable from 'components/FlexTable'
import __ from 'utils/i18n'
import { connect } from "react-redux";
import { get } from "lodash";

class CRUDList extends React.Component {
  static defaultProps = {
    items: [],
    isFetching: false,
    error: false,
    onReload: () => {},
    onSelect: () => {},
    itemName: "items",
    noItems: "No items",
    reloadText: "Reintentar",
    columns: [],
  };

  render() {
    const {
      items,
      noItems,
      isFetching,
      error,
      onReload,
      reloadText,
      columns,
      onSelect,
    } = this.props;

    return (
      <div className="dashboard-card-body">
        {isFetching && <Loader />}
        {error && (
          <div className="dashboard-card-message">
            <h3 className="text-danger">{error}</h3>
            <div onClick={onReload}>{reloadText}</div>
          </div>
        )}
        {(!items || !items.length) && (
          <div className="dashboard-card-message">
            <i className={"mdi mdi-" + (noItems.icon || "minus-circle")} />
            <h3>{noItems.title}</h3>
            <p>{noItems.desc}</p>
          </div>
        )}
        {items && items.length && (
          <FlexTable
            data={items}
            showHeader={true}
            columns={columns}
            onRowClick={({ index }) => onSelect(items[index])}
          />
        )}
      </div>
    );
  }
}

class CRUDSection extends React.Component {
  static defaultProps = {
    createButtonText: __("actions.create"),
    selectedItem: {},
    initialItems: [],
    noItems: {
      title: "No items",
      desc: "No items found",
    },
    resource: "subsidiaries",
    searchKeys: ["name"],
    columns: [],
    resources: {},
  };

  state = {
    createdItem: null,
    selectedItem: this.props.selectedItem,
    items: this.props.initialItems,
    noItems: this.props.noItems,
    filteredItems: this.props.initialItems,
    createError: false,
    updateError: false,
    deleteError: false,
    showCreate: false,
    showDelete: false,
    showUpdate: false,
    isFetching: false,
  };

  showCreate() {
    this.setState({ showCreate: true });
  }

  hideCreate() {
    this.setState({ showCreate: false, createdItem: null });
  }

  showUpdate() {
    const shouldUpdateSubsidiaries = this.props.createSubusers;
    shouldUpdateSubsidiaries && this.setState({ showUpdate: true });
  }

  hideUpdate() {
    this.setState({ showUpdate: false });
  }

  showDelete() {
    this.setState({ showDelete: true });
  }

  hideDelete() {
    this.setState({ showDelete: false });
  }

  componentDidMount() {
    this.fetchItems();
  }

  fetchItems() {
    this.setState({ isFetching: true });
    return fetchResource(this.props.resources.list)
      .then((data) => {
        this.setState({
          isFetching: false,
          items: data,
        });
      })
      .catch((error) => {
        this.setState({
          listError: error,
          isFetching: false,
        });
      });
  }

  selectItem(item) {
    this.setState({
      selectedItem: item,
    });
  }

  createItem(item) {
    this.setState({ isFetching: true });
    
    
    fetchResource(this.props.resources.create, item)
      .then(() => {
        this.hideCreate();
        this.fetchItems();
      })
      .catch((error) => {
        this.setState({
          createError: error,
          isFetching: false,
        });
      });
  }

  updateItem(id, item) {
    this.setState({ isFetching: true, selectedItem: item });

    fetchResource(this.props.resources.update, [id, item])
      .then(() => {
        this.hideUpdate();
        this.fetchItems();
      })
      .catch((error) => {
        this.setState({
          updateError: error,
          isFetching: false,
        });
      });
  }

  deleteItem(id) {
    this.setState({ isFetching: true });

    fetchResource(this.props.resources.delete, id)
      .then(() => {
        this.hideDelete();
        this.hideUpdate();
        this.fetchItems();
      })
      .catch((error) => {
        this.setState({
          error,
          isFetching: false,
        });
      });
  }

  setFilteredItems(items) {
    this.setState({
      filteredItems: items,
    });
  }

  render() {

    const { createView, editView, deleteView, className, createButtonText, createSubusers } = this.props;
    
    return (
      <div className={classNames("dashboard-card", className)}>
        {React.createElement(createView, {
          show: this.state.showCreate,
          createdItem: this.state.createdItem,
          error: this.state.createError,
          isFetching: this.state.isFetching,
          onClose: () => this.hideCreate(),
          onCreate: (item) => this.createItem(item),
        })}

        {React.createElement(editView, {
          item: this.state.selectedItem,
          show: this.state.showUpdate,
          showDelete: () => this.showDelete(),
          error: this.state.updateError,
          isFetching: this.state.isFetching,
          onClose: () => this.hideUpdate(),
          onUpdate: (id, item) => this.updateItem(id, item),
        })}

        {React.createElement(deleteView, {
          item: this.state.selectedItem,
          show: this.state.showDelete,
          error: this.state.deleteError,
          isFetching: this.state.isFetching,
          onClose: () => this.hideDelete(),
          onDelete: (id) => this.deleteItem(id),
        })}

        <div className="dashboard-card-header">
          <div>
            <FullTextSearch
              keys={["name", "address"]}
              items={this.state.items}
              onChange={(items) => this.setFilteredItems(items)}
            />
          </div>
          <div>
            {createSubusers && (
              <Button
                className="action__create-item"
                color="primary"
                onClick={() => this.showCreate()}>
                <i className="mdi mdi-plus mdi-24px mdi-left" /> {createButtonText}
              </Button>
            )}
          </div>
        </div>

        <CRUDList
          error={this.state.listError}
          items={this.state.filteredItems}
          noItems={this.state.noItems}
          isFetching={this.state.isFetching}
          onSelect={(item) => {
            this.selectItem(item);
            this.showUpdate();
          }}
          columns={this.props.columns}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createSubusers: get(state, 'login.user.createSubusers', true)
});

export default connect(mapStateToProps)(CRUDSection);
