
import { combineReducers } from 'redux'
import moment from 'moment'

import { ACTIONS } from './constants'

const defaultFilters = {
  date: {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  }
}

export default combineReducers({
  filters: (state = defaultFilters, action) => {
    switch(action.type) {
      case ACTIONS.SET_FILTERS:
        return {...state, ...action.filters}
      default:
        return state
    }
  },
})
