import React, { useState } from "react";
import { Button } from "reactstrap";
import classNames from "classnames";

import CropImage from "./CropImage";

import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import { getProductsColors } from 'utils';
import __ from 'utils/i18n'

const DeleteImage = ({ isShow, setShowModal, deleteImage }) => {
  return (
    <DashboardModal
      name="deleteImage"
      headerText={__("inputs.image.deleteImageProduct")}
      showModal={isShow}
      hideModal={() => setShowModal(false)}
      submitAction={() => deleteImage()}
      cancelText={__("actions.cancel")}
      submitText={__("actions.delete")}
    >
      {__("inputs.image.confirmDeleteImage")}
    </DashboardModal>
  );
};

const ColorList = ({ currentColor, setColor, setModified }) => {
  const colors = getProductsColors();
  return (
    <div>
      {colors.map((color, index) => (
        <button
          key={index}
          style={{ backgroundColor: "#" + color }}
          className={classNames({
            "catalog__product--color": true,
            "selected-color": currentColor === color,
          })}
          onClick={() => {
            setColor(color);
            setModified(true);
          }}
        >
          {currentColor === color ? (
            <span>
              <i className="mdi mdi-light mdi-check mdi-24px" />
            </span>
          ) : null}
        </button>
      ))}
    </div>
  );
};

const Modal = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(props.url || null);
  const [help] = useState(props.help);
  const [color, setColor] = useState(props.color || null);
  const [deleteImage, setDeleteImage] = useState(false);
  const [modified, setModified] = useState(false);
  const { showColorList } = props;

  const acceptImage = (file, url) => {
    setModified(true);
    setFile(file);
    setUrl(url);
    setDeleteImage(false);
  };

  const saveImage = () => {
    props.onSave({
      url: url || "",
      file: file || null,
      color: color || "",
      deleteImage: deleteImage || null,
    });
    props.hideModal();
  };

  const deleteImageActions = () => {
    setModified(true);
    setFile(null);
    setUrl(null);
    setDeleteImage(true);
    setShowDeleteModal(false);
  };

  return (
    <>
      <DeleteImage
        isShow={showDeleteModal}
        setShowModal={setShowDeleteModal}
        deleteImage={deleteImageActions}
      />

      <DashboardModal
        name="editImage"
        modalClass="edit-image-modal"
        headerText={__("inputs.image.editImageProduct")}
        showModal={props.showModal}
        hideModal={() => {
          setModified(false);
          props.hideModal();
        }}
        submitAction={() => {
          saveImage();
        }}
        cancelText={__("actions.cancel")}
        submitText={__("actions.save")}
        submitDisabled={!modified}
      >
        <div className="catalog__product--image-edit">
          <CropImage
            url={url}
            acceptImage={acceptImage}
            maxSize={props.maxSize}
            errorMessageAlert={props.errorMessageAlert}
          />
          {url && (
            <div className="dropzone__buttons">
              <Button
                color="secondary"
                type="button"
                onClick={() => setShowDeleteModal(true)}
              >
                {__("inputs.image.deleteImage")}
              </Button>
            </div>
          )}
        </div>
        <div>
          <p className="small-text">{__.bb(help)}</p>
        </div>
        {showColorList && (
          <ColorList
            setColor={setColor}
            setModified={setModified}
            currentColor={color}
          />
        )}
      </DashboardModal>
    </>
  );
};

export default Modal;
