import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

const next = (animating, activeTab, items) => {

  if (!items || animating) return;
  const nextIndex = activeTab === items.length - 1 ? 0 : activeTab + 1;
  return nextIndex;
}

const previous = (animating, activeTab, items) => {
  if (!items || animating) return;
  const nextIndex = activeTab === 0 ? items.length - 1 : activeTab - 1;
  return nextIndex;
}

const SliderTabs = ({ defaultTab, onChange, tabs }) => {

  const [activeTab, setActiveTab] = useState(defaultTab);

  const [animating, setAnimating] = useState(false);

  const items = tabs.map((item, index) => {
    return (
      <CarouselItem
        onExiting={() => { setAnimating(true) }}
        onExited={() => { setAnimating(false) }}
        key={index}
      >
        {item.title}
      </CarouselItem>
    );
  });

  return (
    <Carousel
      className="slider-tabs"
      activeIndex={activeTab}
      next={() => { next(animating) }}
      previous={() => { previous(animating) }}
    >
      {items}
      {(items.length > 1) &&
        <>
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={() => {
              const index = previous(animating, activeTab, tabs)
              setActiveTab(index)
              onChange && onChange(index)
            }
            } />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={() => {
              const index = next(animating, activeTab, tabs);
              setActiveTab(index);
              onChange && onChange(index);
            }
            } />
        </>
      }
    </Carousel>
  );
};

export default SliderTabs;
