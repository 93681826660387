import React, { useState } from 'react'
import { asField } from 'informed';
import Select from 'react-select';
import axios from "axios";

const SelectInput = asField(({
  fieldState,
  fieldApi,
  ForwardRef,
  onChange,
  label,
  type,
  onOpen,
  clearable,
  options,
  placeholder,
  value,
  optionBackground,
  optionHover,
  optionActive,
  ...props }) => {

  const [error, setError] = useState(false);
  const [optionsRequest, setOptionsRequest] = useState([]);

  const source = false;

  const loadOptions = () => {
    setError(false);
    axios.request(source).then(response => {
      if (source === props.source) {
        setError(false);
        setOptionsRequest(response.data)
      }
    }).catch(error => {
      setError('Error loading options');
    })
  };


  const handleOpen = () => {
    if (source && error) {
      loadOptions();
    }
  };

  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? optionActive
            : isFocused
              ? optionHover
              : optionBackground,
        ':active': {
          backgroundColor: optionActive,
        },
      };
    },

  };

  return (
    <div className="position-relative form-group">
      <label className="">{label}</label>
      <div className="input-group">
        <Select
          initialValue={fieldState.value}
          {...props}
          onOpen={() => {
            handleOpen()
            onOpen && onOpen()
          }}
          styles={colourStyles}
          placeholder={error || placeholder}
          className="Select"
          classNamePrefix={"Select"}
          clearable={clearable === true || false}
          onChange={
            option => {
              onChange && onChange(option && option.length > 0 ? option : (option.value))
              fieldApi.setValue(option.length > 0 ? option : option.value)
            }}
          options={options || optionsRequest}>
        </Select>
      </div>
      {fieldState.error ? (
        <small className="text-danger form-text text-muted">{fieldState.error}</small>
      ) : null}

    </div>

  )
}
);

SelectInput.defaultProps = {
  optionBackground: '#fff',
  optionHover: '#eaeaea',
  optionActive: '#b9b9b9',
}

export default SelectInput

