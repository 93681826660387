import { apiRequestPromise } from "../api-provider";

export function createOrder(order) {
  return apiRequestPromise({
    url: "/v2/orders",
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
    },
    withCredentials: true,
    data: order,
    transformRequest: [transformOrder],
  });
}

function transformOrder(order) {
  const { appId, userUuid, accountId, subsidiaryId, currency, product } = order;
  const { name, price } = product;

  const success = "https://www.google.com/?q=success";
  const failed = "https://www.google.com/?q=failed";

  const payload = {
    data: {
      attributes: {
        appId,
        source: "dashboard",
        currency,
        userUuid,
        accountId,
        subsidiaryId,
        items: [
          {
            name,
            unitPrice: {
              currency,
              amount: toCents(price),
            },
            quantity: 1,
          },
        ],
        redirect_url: {
          success,
          failed,
        },
      },
    },
  };

  return JSON.stringify(payload);
}

const toCents = (amount) => Math.round(amount * 100);
