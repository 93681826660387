import React from 'react';
import classNames from 'classnames';
import { sumBy } from 'lodash';
import __ from 'utils/i18n';
import { getConfig } from 'config';

const Row = ({ title, value, primary }) => (
  <div className={classNames({
    "chart-table-row": true,
    "chart-table-row-primary": primary,
    "chart-table-row-radius": primary
  })}>
    <div>{title}</div>
    <div>{value}</div>
  </div>
);

class TotalSales extends React.Component {

  render() {

    const { data, currency } = this.props;
    const showTips = getConfig('customConfig.stats.sales.charts.table.showTips');
    const showDiscount = getConfig('customConfig.stats.sales.charts.table.showDiscount');
    const showTax = getConfig('customConfig.stats.sales.charts.table.showTax');

    const totals = {
      count: sumBy(data, "count") || 0,
      tipAmount: sumBy(data, "tipAmount") || 0,
      taxAmount: sumBy(data, "taxAmount") || 0,
      discountAmount: sumBy(data, "discountAmount") || 0,
      netAmount: sumBy(data, "netAmount") || sumBy(data, "amount") || 0,
      totalAmount: sumBy(data, "amount") || 0,
    };

    return (
      <div className="chart-table">
        <Row value={__.number(totals.count)} title={__('dashboard.sections.stats.totals.totalSales')} />
        {(showTips) &&
          <Row value={__.currency(totals.tipAmount)} title={__('dashboard.sections.stats.totals.tipAmount')} />
        }

        {(showDiscount) &&
          <Row value={__.currency(totals.discountAmount)} title={__('dashboard.sections.stats.totals.discountAmount')} />
        }
        {(showTax) &&
          <Row value={__.currency(totals.taxAmount)} title={__('dashboard.sections.stats.totals.taxAmount')} />
        }

        <Row value={__.currency(totals.netAmount, currency, 'auto')} title={__('dashboard.sections.stats.totals.netAmount')} />
        <Row primary value={__.currency(totals.totalAmount, currency, 'auto')} title={__('dashboard.sections.stats.totals.totalAmount')} />
      </div>
    );
  }
}

export default TotalSales;
