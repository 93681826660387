import React from "react";
import {
    Col,
    Row,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
  import __ from "utils/i18n";
import IconWarning from 'assets/icon-warning.svg'

function ValidationErrorStep({hideModal,validationName}) {
  
    return (
      <div className="payment-link-modal">
        <ModalHeader toggle={hideModal}></ModalHeader>
  
        <ModalBody>
          <Col className="payment-link-modal__loading-validation__container">
            <Row>
              <img src={IconWarning} alt="" />
            </Row>
            <Row>
              <h5 className="payment-link-modal__steps__title">
                {__.t(`dashboard.sections.paymentLink.validations.${validationName}.title`)}
              </h5>
            </Row>
            <Row>
              <h5 className="payment-link-modal__steps__subtitle">
                {__.t(`dashboard.sections.paymentLink.validations.${validationName}.subTitle`)}
              </h5>
            </Row>
          </Col>
        </ModalBody>
  
        <ModalFooter className="payment-link-modal__loading-error__footer">
          <Button color="primary" className="payment-link-radius" onClick={hideModal} >
            {__.t("dashboard.sections.paymentLink.close")}
          </Button>
        </ModalFooter>
      </div>
    );
  }
  
  export default ValidationErrorStep;