import React from "react";
import {
    Col,
    Row,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
  import __ from "utils/i18n";
import IconError from 'assets/error-cross.svg'

function ErrorStep(props) {
    const {
      hideModal,
      retryModal,
      error
    } = props;
  
    return (
      <div className="payment-link-modal">
        <ModalHeader toggle={hideModal}></ModalHeader>
  
        <ModalBody>
          <Col className="payment-link-modal__loading-error__container">
            <Row>
              <img src={IconError} alt="" />
            </Row>
            <Row>
              <h5 className="modal-title payment-link-modal__steps__title">
                {__.t("dashboard.components.paymentLink.error.title")}
              </h5>
            </Row>
            <Row>
              <h5 className="payment-link-modal__steps__subtitle">
                {error ? error : ("")}
              </h5>
            </Row>
          </Col>
        </ModalBody>
  
        <ModalFooter className="payment-link-modal__loading-error__footer">
          <Button color="primary" className="payment-link-radius" onClick={retryModal} >
            {__.t("actions.retry")}
          </Button>
        </ModalFooter>
      </div>
    );
  }
  
  export default ErrorStep;