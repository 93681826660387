import React, { useContext } from 'react';

import DashboardTabbedSection from 'containers/Dashboard/components/dashboardSection/DashboardTabbedSection';
import DashboardSectionTab from 'containers/Dashboard/components/dashboardSection/DashboardSectionTab';

import { SalesTab } from './Sales';
import { Deposits } from './Deposits';
import { WithholdingTax } from './WithholdingTax';
import { MySalesTab } from './MySales';
import { BatchCloseTab } from './BatchClose';
import { PermissionsContext } from 'contexts/permissionsContext';

import __ from 'utils/i18n';

export const Sales = () => {
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const isSalesEnabled = featureFlags?.includes('sales_main') && userPermissions?.includes('sales_main');
  const isDepositsEnabled = featureFlags?.includes('sales_deposits') && userPermissions?.includes('sales_deposits');
  const isWithholdingTaxEnabled = featureFlags?.includes('sales_withholding') && userPermissions?.includes('sales_withholding');
  const isMySalesTabEnabled = featureFlags?.includes('sales_mysales') && userPermissions?.includes('sales_mysales');
  const isBatchCloseTabEnabled = featureFlags?.includes('batch_close');

  return (
    <DashboardTabbedSection>
      {isSalesEnabled && (
        <DashboardSectionTab
          path="sales"
          title={__("dashboard.sections.sales.tabs.Sales")}
        >
          <SalesTab />
        </DashboardSectionTab>
      )}
      {isBatchCloseTabEnabled && (
        <DashboardSectionTab
          path="batch_close"
          title={__("dashboard.sections.sales.tabs.BatchClose")}>
            <BatchCloseTab />
        </DashboardSectionTab>
      )}
      {isDepositsEnabled && (
        <DashboardSectionTab
          path="deposits"
          title={__("dashboard.sections.sales.tabs.Deposits")}
        >
          <Deposits />
        </DashboardSectionTab>
      )}
      {isWithholdingTaxEnabled && (
        <DashboardSectionTab
          path="withholding_tax"
          title={__("dashboard.sections.sales.tabs.WithholdingTax")}
        >
          <WithholdingTax />
        </DashboardSectionTab>
      )}
      {isMySalesTabEnabled && (
        <DashboardSectionTab
          path="my_sales"
          title={__("dashboard.sections.sales.tabs.MySales")}
        >
          <MySalesTab />
        </DashboardSectionTab>
      )}
    </DashboardTabbedSection>
  );
}
