import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { get } from 'lodash'
import { getConfig } from 'config'
import { SelectField } from 'components/forms'
import { FieldsPanel } from 'containers/Dashboard/components/FieldsPanel'
import __ from 'utils/i18n'
import { getLocations } from 'utils';

const DashboardAddressFields = ({values}) => {

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getLocations(setLocations);
  },[])

  const getProvinces= (departments, department) => {
    return get(departments && departments.find(item => parseInt(item.id) === parseInt(department)), 'items', [])
  }

  const getDistricts= (provinces, province) => {
    return get(provinces && provinces.find(item => parseInt(item.id) === parseInt(province)), 'items', [])
  }

  const department = get(values, 'department');
  const province = get(values, 'province');
  const departments = locations || [];
  const provinces = values && department ? getProvinces(departments, department) : [];
  const districts = values && province ? getDistricts(provinces, province) : [];
  
  const fieldsPanel = [
    {
      "type": "text",
      "name": "street",
    },
    {
      "type": "text",
      "name": "floor",
    },
    {
      "type": "text",
      "name": "reference",
    },
  ]

    const showSelectsAddres = getConfig('customConfig.merchant.showSelectsAddres')
    return (
      <div>
        {showSelectsAddres && <div>
          <Row>
            <Col lg={'6'} md={'12'}>
              <SelectField
                field={"department"}
                label={__('dashboard.components.panel.department.label')}
                placeholder={__('dashboard.components.panel.department.placeholder')}
                options={departments.map(item => ({
                  value: item.id,
                  label: item.name || item.id
                }))}
              />
            </Col>
            <Col lg={'6'} md={'12'}>
              <SelectField
                field={"province"}
                label={__('dashboard.components.panel.province.label')}
                placeholder={__('dashboard.components.panel.province.placeholder')}
                disabled={!provinces.length}
                options={provinces.map(item => ({
                  value: item.id,
                  label: item.name || item.id
                }))}
                
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectField
                field={"district"}
                label={__('dashboard.components.panel.district.label')}
                placeholder={__('dashboard.components.panel.district.placeholder')}
                disabled={!districts.length}
                options={districts.map(item => ({
                  value: item.id,
                  label: item.name || item.id
                }))}
              />
            </Col>
          </Row>
        </div>
        }
        <Row>
          <FieldsPanel configItems={fieldsPanel} />
        </Row>
      </div>
    )
  
}

export default DashboardAddressFields
