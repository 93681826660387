export const editGeoLocation = (subsidiaryId, filters) => {
  return {
    endpoint: `operations/getGeoLocationOperations.json?subsidiaryId=${subsidiaryId}`,
    method: 'POST',
    formData: {
      ...filters,
      channel: 'web',
      deviceId: 'web',
      deviceModel: 'web',
      deviceOS: 'web',
    },
  };
};

export const getUsers = (token) => {
  return {
    endpoint: 'users',
    method: 'GET',
    headers: {
      'x-authentication-token': token
    },
    parseResponse: (response) => {
      return response.data.data.map((user) => {
        return {
          value: user.id,
          label: user.fullName,
        };
      });
    },
    cache: true,
  };
};

export const getSales = (subsidiaryId, subsidiaries, token) => (payload) => {
  const formData = { ...payload };
  if (formData.inputMode && formData.inputMode.toLowerCase() === "smartpos") {
    formData.origin = "smartpos";
    delete formData.inputMode;
  }
  formData.subsidiaries =
    (subsidiaries && subsidiaries.length && subsidiaries.toString()) ||
    undefined;
  return {
    endpoint: 'sales/getSaleRecord.json',
    method: 'POST',
    headers: {
      'X-Authentication-Token': token,
    },
    params: {
      subsidiaryId,
    },
    cache: true,
    force: payload && payload.force,
    formData,
  };
};
