import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

const FlashMessage = ({ type, children }) => (
  <div data-testid="flash-message">
    <Alert color={type}>
      { children }
    </Alert>
  </div>
)

FlashMessage.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'danger']),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
}

FlashMessage.defaultProps = {
  type: 'success',
}

export default FlashMessage;
