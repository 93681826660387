
import React from "react";
import { DashboardModal } from "containers/Dashboard/components/DashboardModal";

export const BatchCloseModal = ({ 
    batchModal,
    confirmBatchModal,
    handleClose,
    handleCloseModal,
    setBatchModal,
    setConfirmBatchModal,
    userId,
    tenantId,
    token,
    detailsData,
    applyFilter,
    headerText,
    submitText,
    cancelText,
    confirmText,
    successAllCloseText,
    acceptText,
    showCancelButton,

 }) => {

  const handleSubmitAction = () => {
    if (batchModal) {
      handleClose({userId, tenantId, token, detailsData, applyFilter, setBatchModal});
      setConfirmBatchModal(true);
    } else {
      handleCloseModal();
    }
  }

  const handleHideModal = () => {
    if (confirmBatchModal) {
      setBatchModal(false);
    }
    setBatchModal(false);
    handleCloseModal();
  }

    return (
        <DashboardModal
              name="closeBatchModal"
              headerText={headerText}
              showModal={batchModal || confirmBatchModal}
              showFooter={true}
              hideSeparator={false}
              submitColor="success"
              submitText={batchModal ? submitText : acceptText}
              cancelText={cancelText}
              showCancelButton={showCancelButton}
              submitAction={() => {handleSubmitAction()}}
              hideModal={() => {handleHideModal()}}
              submitDisabled=""
            >
              {batchModal ? (<div>{confirmText} {detailsData.name}</div>) : successAllCloseText}
        </DashboardModal>
    );
}  