import React from 'react';
import NavItemLink from '../../../NavItemLink';

export const SectionsLinks = ({ sections = [] }) => {
  return (
    sections.map((section, index) => (
      <>
        {(!section.hide) &&
          <NavItemLink
            key={index}
            exact={section.exact}
            to={section.path}
            icon={section.icon}
            label={section.label || section.title}
          />
        }
      </>
    ))
  );
}