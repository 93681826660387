import React, { useState } from "react";
import Joyride from 'react-joyride';
import { Badge } from "reactstrap";
import __ from "utils/i18n";

export const ModalField = ({
    field,
    title,
    description,
    fieldIsActive,
    cleanFilters,
    applyFilters,
    children
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(!isVisible);
    }

    const callback = (e) => {
        if (e.action === 'close' || e.action === 'reset') {
            setIsVisible(false);
        }
    }

    const content = (
        <div>
            <div>
                <p className="filters-field-description mb-3">{description}</p>
            </div>
            <div>{children}</div>
            <div className="filters-field-footer">
                {cleanFilters &&
                    <button
                        disabled={!fieldIsActive}
                        className={`
                            filters-field-footer-actions 
                            filters-field-footer-actions-clean-filters 
                            ${!fieldIsActive ? 'filters-field-footer-actions-disabled' : ''}
                        `}
                        onClick={() => {
                            cleanFilters();
                            setIsVisible(false);
                        }}
                    >
                        {__("dashboard.components.filterForm.cleanfilterButton")}
                    </button>}
                {applyFilters && <button
                    className="filters-field-footer-actions filters-field-footer-actions-apply-filters"
                    onClick={() => {
                        applyFilters();
                        setIsVisible(false);
                    }}
                >
                    {__("dashboard.components.filterForm.applyfilterButton")}
                </button>}
            </div>
        </div>)

    const steps = [{
        content: content,
        placement: 'bottom',
        disableBeacon: true,
        disableScrolling: true,
        spotlightPadding: 0,
        hideFooter: true,
        target: `.${field}-field`,
        styles: {
            options: {
                width: 328,
            },
        },
    }]

    return (
        <div>
            <Joyride
                callback={callback}
                steps={steps}
                continuous={true}
                showProgress={false}
                run={isVisible}
                hideCloseButton={true}
                styles={{
                    options: {
                        width: 300,
                        zIndex: 10000,
                    },
                    tooltip: {
                        padding: 0,
                    },
                    spotlight: {
                        marginTop: '0.5px',
                        borderRadius: '30px',
                    }
                }}
            />
            <Badge
                className={`${field}-field filters-badge filters-badge-${isVisible || fieldIsActive ? 'on' : 'off'}`}
                onClick={handleClick}
                color={'tenant'}
            >
                <span className={`mdi ${isVisible || fieldIsActive ? "mdi-minus" : "mdi-plus"} mr-2`}></span>
                {title}
            </Badge>
        </div>
    );
}
