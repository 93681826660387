import React from 'react';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import { fetchResource } from 'api-provider';
import __ from 'utils/i18n';
import { deleteProduct } from '../../_shared/resources';
import { useMutation } from '@tanstack/react-query';

export const DeleteProductModal = ({
  productId,
  token,
  deleteSuccess,
  showDeleteModal,
}) => {
  const { isLoading, mutate } = useMutation({
    mutationFn: () => fetchResource(deleteProduct(token, productId)),
    onSuccess: deleteSuccess
  });

  return (
    <DashboardModal
      name='deleteProductModal'
      headerText={__(
        'dashboard.sections.catalog.components.productPanel.modal.headerText'
      )}
      showModal={() => {
        showDeleteModal(true);
      }}
      hideModal={() => {
        showDeleteModal(false);
      }}
      submitAction={mutate}
      submitDisabled={isLoading}
      submitColor='danger'
      submitText={__('actions.delete')}
      cancelText={__('actions.cancel')}
    >
      {__(
        'dashboard.sections.catalog.components.productPanel.modal.confirmDeleteProduct'
      )}
    </DashboardModal>
  );
};
