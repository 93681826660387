import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import classNames from "classnames";
import __ from "utils/i18n";
import { PermissionsContext } from "contexts/permissionsContext";
import FlexTable from "components/FlexTable";
import TableHeaderActions from "components/TableHeaderActions";
import FullTextSearch from "components/FullTextSearch";
import Loader from "components/Loader";
import DashboardPanel from "containers/Dashboard/components/DashboardPanel";
import { UserPanel } from "../UserPanel";
import { getUsersColumns } from "../../_shared/columns";

export const UsersContent = ({
  data,
  isFetching,
  error,
  reload,
  fetchUsers,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [items, setItems] = useState([]);

  const userId = useSelector((state) => state.login.user.id);

  const { userPermissions } = useContext(PermissionsContext);
  const isAddUserEnabled = userPermissions?.includes("users_action_add");
  const isEditUserEnabled = userPermissions?.includes("users_action_edit");

  const handleDetails = (data) => {
    if (isEditUserEnabled) {
      setShowDetails(true);
      setSelectedUserId((data && data.id) || "");
    }
  };

  

  const getSelectedUser = () => {
    return data.find((user) => user.id === selectedUserId) || {};
  };

  const getNameColumns = () => {
    let name = "roleName";
    const [head] = items;
    if (head?.rbacName) {
      name = "rbacName";
    }
    return name;
  };

  const selectedUserData = getSelectedUser();
  
  return (
    <div
      className={classNames({
        "dashboard-card": true,
        "panel-expanded": showDetails,
      })}
    >
      <DashboardPanel
        expanded={showDetails}
        panelHeading={
          selectedUserData.id
            ? `${__("actions.edit")} ${selectedUserData.firstName} ${
                selectedUserData.lastName
              }`
            : __("dashboard.sections.merchantUsers.newUser")
        }
        hidePanel={() => setShowDetails(false)}
      >
        <UserPanel
          isFetching={isFetching}
          data={selectedUserData}
          onSave={() => fetchUsers({ force: true })}
          onDelete={() => fetchUsers({ force: true })}
          hidePanel={() => setShowDetails(false)}
          showDetails={showDetails}
        />
        
      </DashboardPanel>

      <div className="dashboard-card-header">
        <FullTextSearch
          keys={["name", "email"]}
          items={data}
          onChange={(items) => setItems(items)}
        />
        {isAddUserEnabled && (
          <TableHeaderActions
            createItem={{
              name: __("dashboard.sections.merchantUsers.newUser"),
              action: handleDetails,
            }}
          />
        )}
      </div>

      <div className="dashboard-card-body">
        {isFetching && <Loader />}
        {!isFetching && error && (
          <div className="dashboard-card-message">
            <h3 className="text-danger">{error}</h3>
            <Button color="secondary" onClick={reload}>
              {__("actions.retry")}
            </Button>
          </div>
        )}
        {!items.length && !isFetching && !error && (
          <div className="dashboard-card-message">
            <i className="mdi mdi-minus-circle" />
            <h3>{__("dashboard.sections.merchantUsers.whithoutUsers")}</h3>
            <p>{__("dashboard.sections.merchantUsers.notFoundUsers")}</p>
          </div>
        )}
        {!isFetching && !error && items.length > 0 && (
          <FlexTable
            data={items}
            columns={getUsersColumns(userId, getNameColumns)}
            onRowClick={({ index }) => handleDetails(items[index])}
          />
        )}
      </div>
    </div>
  );
};
