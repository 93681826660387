import React from "react";
import { Table } from 'reactstrap';
import __ from 'utils/i18n';

export const Transactions = ({ batch }) => {
  return(
    <Table className="no-border bold-td-right">
      <tbody>
        <tr>
          <td>{__('dashboard.sections.batchClose.panel.transactionsQuantity')}</td>
          <td>{ batch.transactionsQuantity }</td>
        </tr>
      </tbody>
    </Table>
  );
};
