module.exports = {
  tenantId: process.env.REACT_APP_TENANT_ID,
  tenantName: process.env.REACT_APP_TENANT_NAME,
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  tenantGtmId: process.env.REACT_APP_TENANT_GOOGLE_TAG_MANAGER_ID,
  ecommerceUrl: process.env.REACT_APP_ECOMMERCE_URL,
  authExternalUrl: process.env.REACT_APP_AUTH_EXTERNAL_URL,
  onboardingUrl: process.env.REACT_APP_ONBOARDING_URL,
  offboardingUrl: process.env.REACT_APP_OFFBOARDING_URL,
  helpUrl: process.env.REACT_APP_HELP_URL,
  checkoutUrl: process.env.REACT_APP_CHECKOUT_URL,
  gtmId: process.env.REACT_APP_GEOPAGOS_GOOGLE_TAG_MANAGER_ID,
  gtmAuth: process.env.REACT_APP_GEOPAGOS_GOOGLE_TAG_MANAGER_ENV_AUTH,
  gtmName: process.env.REACT_APP_GEOPAGOS_GOOGLE_TAG_MANAGER_ENV_NAME,
  buyReaderUrl: process.env.REACT_APP_BUY_READER_LINK,
  termsAndConditionsUrl: process.env.REACT_APP_TERMS_AND_CONDITIONS,
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  cryptoKey: process.env.REACT_APP_CRYPTO_KEY,
  loginPublicKey:
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCFaZga7Ot3UuKNvm5YreE3LhGS\n" +
    "GAdTpVo0junajWABhCnGUjxZbrSL6QamzvA18Wx+HuJDIFTG47EMOMUld263HF3f\n" +
    "1kNX9LLPBsti0v+N1Yk80xG2eM88hlVDA2f3sDod+JZ1AT+sOTwn35FZy/qv7nZw\n" +
    "JTrUGLIKiCGrACI23QIDAQAB\n" +
    "-----END PUBLIC KEY-----",
  isGoogleCaptchaEnabled: process.env.REACT_APP_DASHBOARD_RECAPTCHA_ENABLED
};
