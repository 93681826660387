import React, { useEffect, useState } from 'react'
import moment from 'moment'
import __ from 'utils/i18n'
import { fetchResource } from "api-provider";
import { getConfig } from 'config';
import Loader from "components/Loader";
import { Button } from 'reactstrap'
import TableHeaderActions from "components/TableHeaderActions";
import FlexTable from 'components/FlexTable'
import StateBadge from 'components/StateBadge';
import FullTextSearch from 'components/FullTextSearch'
import { ActivateReaderWidget } from './ActivateReaderWidget';
import { useSelector } from 'react-redux';
import CardReader from '../../../../../../assets/card-reader.svg'

const resources = {
  list: (token, subsidiaryId) => ({
    endpoint: 'readers',
    method: 'GET',
    headers: {
      'X-Authentication-Token': token
    },
    params: {
      subsidiaryId: subsidiaryId,
    },
    cache: true,
    parseResponse: response => response.data.data
  })
}

const StatusCell = ({ original }) => {
  return (
    <span>
      <StateBadge state={original.status} />
    </span>
  )
}

const BatteryStatusCell = ({ original }) => {
  return (
    <span>
      { Math.round(original.lastBatteryStatus * 100) + '%' }
    </span>
  )
}

const LastUsedCell = ({ original }) => {
  return (
    <span>
      { original.lastUsed ? moment(original.lastUsed).format("DD/MM/YYYY") : '-' }
    </span>
  )
}

const ReadersSection = () => {
 const [items, setItems] = useState([]);
 const [isFetching] = useState(false);
 const [error, setError] = useState(false);

 const token = useSelector(state => state.api.token);
 const buyReaderUrl = useSelector((state) => state.login?.user?.buyReaderURL);
 const { subsidiaryId } = useSelector((state) => state.login.user);

  const openBuyReader = () => {
    window.open(buyReaderUrl)
  }

  const configColumns = getConfig("tableColumns.readersColumns")

  const getReadersColumns = () => {
    const readersColumns = []

    const defaultColumns = {
      serialNumber: {
        title: __('dashboard.sections.merchantReaders.serialNumber.title'),
        name: 'serialNumber',
        image: getConfig('dashboard.merchant.readers.icon') || CardReader,
        width: 300
      },
      type: {
        title: __('dashboard.sections.merchantReaders.type.title'),
        name: 'type',
        width: 200
      },
      subsidiaryName:  {
        title: __('dashboard.sections.merchantReaders.subsidiaryName.title'),
        name: 'subsidiaryName',
        width: 400,
        maxWidth: 400
      },
      batteryStatusCell: {
        title: __('dashboard.sections.merchantReaders.BatteryStatusCell.title'),
        Cell: BatteryStatusCell,
        width: 200,
        maxWidth: 200
      },
      lastUsedCell: {
        title: __('dashboard.sections.merchantReaders.LastUsedCell.title'),
        Cell: LastUsedCell,
        width: 200,
        maxWidth: 200,
        align: 'right'
      },
      Estado:  {
        title: 'Estado',
        Cell: StatusCell,
        align: 'right',
        width: 250,
        maxWidth: 250
      }
    }
    configColumns.map(item =>{
        return readersColumns.push( defaultColumns[item] )
      })
    return readersColumns
  }

    useEffect(() => {
      fetchResource(resources.list(token, subsidiaryId))
      .onSuccess(data => setItems(data))
      .onFailure(error => setError(error))
    },[items, token, subsidiaryId])


  return (
    <div className="dashboard-card merchant-section">
      <div className="dashboard-card-header">
        <FullTextSearch
          keys={['serialNumber', 'subsidiaryName']}
          items={items}
          onChange={items => setItems(items)}
        />
        { ActivateReaderWidget &&
          <ActivateReaderWidget/>
        }
          
        <TableHeaderActions createItem={{name: __('dashboard.sections.merchantReaders.buyReader'), action: openBuyReader}} />
      </div>
      <div className="dashboard-card-body">
        {isFetching && (<Loader/> )}
        {error && (
          <div className="dashboard-card-message">
            <h3 className="text-danger">{error}</h3>
            <Button color="secondary" onClick={() => console.log('implementar reload')}>{__('actions.retry')}</Button>
          </div>
          )}
          {!items.length && (
            <div className="dashboard-card-message">
              <i className="mdi mdi-minus-circle"/>
              <h3>{__('dashboard.sections.merchantReaders.withoutReaders')}</h3>
              <p>{__('dashboard.sections.merchantReaders.notFoundReaders')}</p>
           </div>
          )}
          {items.length && (
            <div>
              <FlexTable
                data={items}
                noData={__('dashboard.sections.merchantReaders.notHaveReaders')}
                cellHeight={48}
                columns={getReadersColumns()}
              />
            </div>
          )}
      </div>
    </div>);
}

export default ReadersSection;



