import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from './FlashMessage';

const ErrorMessage = ({ children }) => (
  <FlashMessage type="danger" data-testid="error-message">
    { children }
  </FlashMessage>
)

ErrorMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
}

export default ErrorMessage;
