import React, { useState, useCallback, useEffect } from 'react';
import classnames from "classnames";
import { useDropzone } from 'react-dropzone'
import { asField } from 'informed';
import i18n from 'utils/i18n';

const File = asField(({ fieldApi, fieldState, url, label, loadFile, disabled }) => {

  const { value } = fieldState;
  const [name, setName] = useState(null);
  const [docUrl, setDocUrl] = useState(url);
  const [file, setFile] = useState(value);
  const [error, setError] = useState(null);

  useEffect(() => {
    fieldApi.setValue(file);
    fieldApi.setError(error);
    
    // Preventing eslint warning as fieldApi is constantly changing
    // eslint-disable-next-line
  }, [name, error, url, file]);

  const onDrop = useCallback(acceptedFiles => {
    if (loadFile) {
      loadFile(acceptedFiles[0]).then((response) => {
        setError(undefined);
        setName(acceptedFiles[0].name);
        setFile(response);

      }).catch((error) => {
        setError(error);
      })
    } else {
      setName(acceptedFiles[0].name);
      setFile(acceptedFiles[0]);
    }

  }, [loadFile]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div className="position-relative form-group">
      <label className="">{label}</label>
      <div className={classnames({
        "input-file": true,
        "has-error": fieldState.error
      })}
      >
        <div className="file-content"  {...getRootProps()}>
          <input {...getInputProps()} disabled={disabled} />
          <button type="button">{i18n('dashboard.sections.compraClick.form.uploadFiles')}</button>
          {(!docUrl) &&
            <label>{name}</label>
          }
        </div>
        {(docUrl) && 
          <a href={fieldState.value} target="_blank" className="document-link" rel="noopener noreferrer">{label}</a>
        }
        {(name || docUrl) && 
          <i onClick={() => {
            if (!disabled) {
              setName(null);
              setDocUrl(null);
              setFile(null);
              setError(undefined);
            }
          }} className="mdi mdi-window-close" />
        }
      </div>
      {fieldState.error
        ? <small className="text-danger form-text text-muted">{fieldState.error}</small>
        : null
      }
    </div>
  );
});

File.defaultProps = {
  loadFile: null,
  url: null,
};

export default File;
