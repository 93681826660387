import React from "react";
import __ from "utils/i18n";

import { DashboardModal } from "containers/Dashboard/components/DashboardModal";

export const DisableModal = ({showModal, setShowModal, request}) => {
  return (
    <DashboardModal
      name="disableModal"
      headerText={__("store.modal.title")}
      showModal={showModal === "disableModal"}
      hideModal={() => setShowModal(null)}
      submitAction={() => request.fetch()}
      submitDisabled={request.isFetching}
      submitColor="success"
      submitText={__("store.confirm")}
      cancelText={__("actions.cancel")}
    >
      {__("store.modal.subtitle")}
    </DashboardModal>
  );
};
