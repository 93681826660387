import React from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';

export const Taxes = ({ batch }) => {
  return(
    <Table className="no-border bold-td-right">
      <tbody>
        <tr>
          <td>{__('dashboard.sections.batchClose.panel.tipAmount')}</td>
          <td>{__.currency((batch.tipAmount), batch.currencyCode)}</td>
        </tr>
        <tr>
          <td>{__('dashboard.sections.batchClose.panel.taxAmount')}</td>
          <td>{__.currency((batch.taxAmount), batch.currencyCode)}</td>
        </tr>
        <tr>
          <td>{__('dashboard.sections.batchClose.panel.taxRefundAmount')}</td>
          <td>{__.currency((batch.taxRefundAmount), batch.currencyCode)}</td>
        </tr>
      </tbody>
    </Table>
  )
}
