import React from 'react';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import __ from 'utils/i18n';

export const ConfirmModal = ({
  showModal = false,
  submitAction,
  closeAction,
  list = [],
}) => {
  return (
    <DashboardModal
      name='confirmModal'
      modalClass='ecommerceOrders-modal'
      headerText={__('dashboard.sections.compraClick.modals.confirmTitle', {
        count: list.length,
      })}
      showModal={showModal}
      hideModal={closeAction}
      submitAction={submitAction}
      submitColor='success'
      hideSeparator={true}
      cancelText={__('actions.cancel')}
      submitText={__('actions.accept')}
    >
      <div className='ecommerceOrders-confirm-main'>
        <span className='ecommerceOrders-confirm-message'>
          {__('dashboard.sections.compraClick.modals.confirmSubTitle', {
            count: list.length,
          })}
        </span>
      </div>
    </DashboardModal>
  );
};
