import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { tenantId } from "config/envConfig";
import { fetchResource } from "api-provider";
import { isValidToken } from "containers/Login/resources";
import { logout } from "containers/Logout/actions";
import { decodeJWT, handlePermissions, handleSessionExpiration } from "./helpers";
 
const permissionsName = `geop_permissions_${tenantId}`;
const featureFlagsName = `geop_feature_flags_${tenantId}`;

export const PermissionsContext = React.createContext({
  userPermissions: [],
  featureFlags: [],
});

export const PermissionsContextProvider = ({ children }) => {
  const token = useSelector((state) => state.api.token);
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const [featureFlags, setFeatureFlags] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token && isAuthenticated) {
      const today = moment();
      const sessionTimestamp = localStorage.getItem("sessionTimestamp");
      const expirationDate = moment(moment(sessionTimestamp).add(1, "d").format("YYYY-MM-DD HH:mm:ss"));

      let decodedPermissions = decodeJWT(permissionsName);
      let decodedFeatureFlags = decodeJWT(featureFlagsName);
      setUserPermissions(decodedPermissions ?? []);
      setFeatureFlags(decodedFeatureFlags ?? []);

      if (
        !decodedPermissions ||
        !decodedFeatureFlags ||
        !sessionTimestamp ||
        today.isSameOrAfter(expirationDate)
      ) {
        fetchResource(isValidToken(token))
          .onSuccess((data) => {
            handlePermissions(permissionsName, data.userPermissions, setUserPermissions);
            handlePermissions(featureFlagsName, data.featureFlags, setFeatureFlags);
            handleSessionExpiration(dispatch, today.format("YYYY-MM-DD HH:mm:ss"), data);
          })
          .onFailure((error) => {
            dispatch(logout());
          });
      }
    } else if (!token && isAuthenticated) {
      dispatch(logout());
    }
  }, [token, isAuthenticated, dispatch]);

  const contextValue = {
    userPermissions,
    featureFlags,
  };
  
  return (
    <PermissionsContext.Provider value={contextValue}>
      {children}
    </PermissionsContext.Provider>
  );
};
