import React from "react";
import { Modal, ModalFooter, Button, ModalHeader } from "reactstrap";
import i18n from "utils/i18n";
import ButtonLoader from "../components/ButtonLoader/ButtonLoader";

const UnsubcribeModal = ({ setIsOpen, isOpen, error, onSubmit, isLoading }) => {
  const retryModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Modal
      className="Unsubcribe-modal"
      isOpen={isOpen}
      zIndex={9999}
      centered
      size="lg"
    >
      <ModalHeader
        className="Unsubcribe-modal-header"
        toggle={() => retryModal()}
      ></ModalHeader>
      <div className="Unsubcribe-modal-content">
        <div>
          <h4 className="Unsubcribe-modal-maintitle">
            {i18n("dashboard.components.modal.unsubscribeRequest")}
          </h4>
        </div>

        <div className="Unsubcribe-modal-subtitle">
          <p>{i18n("dashboard.components.modal.unsubscribeText")}</p>
        </div>

        <hr className="Unsubcribe-modal-hr"></hr>
      </div>

      <ModalFooter className="Unsubcribe-modal-btn">
        <Button type="submit" onClick={() => retryModal()}>
          {i18n("dashboard.components.modal.btnClose")}
        </Button>
        <ButtonLoader
          className="Unsubcribe-modal-btn-send"
          text={i18n("dashboard.components.modal.btnSend")}
          onClick={onSubmit}
          isLoading={isLoading}
        />
      </ModalFooter>
    </Modal>
  );
};

export default UnsubcribeModal;
