import React from 'react';
import { Row, Col } from 'reactstrap';
import { buildTooltip, Chart } from 'components/charts/base';
import { getColumns } from './columns';
import FlexTable from 'components/FlexTable';

export const DonutChart = ({
  title,
  data = [],
  limit = 4,
  metric = "amount",
  noData,
  chartConfig = {}
}) => {


  const chart = new Chart();
  const columns = getColumns();

  chart.setConfig({
    "chart.type": "pie",
    "chart.height": 150,
    "chart.margin": [5, 5, 5, 5],
    "chart.spacing": [5, 5, 5, 5],
    "chart.plotBackgroundColor": null,
    "chart.plotBorderWidth": null,
    "chart.plotShadow": false,
    "tooltip.shared": false,
    "customTooltip": ({ x, y, total, percentage }) => (
      buildTooltip({
        title: x,
        value: `
            <div>${percentage.toFixed(2)} %</div>
            `
      })
    ),
    "plotOptions.pie": {
      innerSize: "60%",
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      }
    },
    "series": [{
      colorByPoint: true,
      data: data.slice(0, limit).map(item => ({
        name: item.name,
        y: Number(item[metric])
      })),
    }]
  })

  chart.setConfig(chartConfig);

  if ((!data || !data.length) && noData) {
    return <div>{noData}</div>
  }

  return (
    <div className="chart-container">
      {title && (
        <div className="chart-header">
          <div className="chart-title">
            {title}
          </div>
        </div>
      )}

      <div className="chart-content">
        <Row className="chart-donut chart-donut-staff">
          <Col lg="4" md="12" className="chart-donut-graph">
            {chart.render()}
          </Col>
          <Col lg="8" md="12" className="staff-chart-legend">
            <FlexTable
              data={data}
              columns={columns}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
