import React from 'react';
import {
  Row,
  Col,
  Button,
  Alert,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import __ from 'utils/i18n';

export const Step3 = (onImport) => {
  return (
    <>
      <button
        type='button'
        class='close hidden-modal'
        aria-label='Close'
        onClick={onImport}
      >
        <span aria-hidden='true'>×</span>
      </button>
      <ModalHeader>
        {__('dashboard.sections.catalog.productsImport.successImport')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md='12'>
            <Alert color='success'>
              {__('dashboard.sections.catalog.productsImport.successImport')}
              <i className='mdi mdi-check' />
            </Alert>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button disabled={false} color='success' onClick={onImport}>
          {__('dashboard.sections.catalog.productsImport.finish')}
        </Button>
      </ModalFooter>
    </>
  );
};
