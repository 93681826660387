import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { APIRequest } from 'api-provider';
import { SelectField } from 'components/forms'
import __ from 'utils/i18n';
import {connect} from 'react-redux';

const SelectDocumentType = (props) => {
  const { token } = props;
  const resources = {
    documentTypes: token => ({
      endpoint: 'documentTypes',
      cache: true,
      headers: {
        'x-authentication-token': token
      }
      
    })
  }

  return <APIRequest
    resource={resources.documentTypes(token)}
    fetch>
    {request => (
      <SelectField
        field="documentTypeId"
        noResultsText={__('dashboard.components.panel.documentTypeId.noResultsText')}
        placeholder={__('dashboard.components.panel.documentTypeId.placeholder')}
        label={__('dashboard.components.panel.documentTypeId.label')}
        options={request.response && request.response.data.map(item => ({
          value: item.id,
          label: item.name
        }))}
      />)}
  </APIRequest>
}
export class DocumentType extends React.Component {
  
  static contextTypes = {
    store: PropTypes.object
  };

  render() {
    const token = this.context.store.getState().api.token
    return (
      <SelectDocumentType token={token} />
    )
  }
}

const SelectSubsidiaries = (props) => {
  const { token } = props

  const resources = {
    documentTypes: token => ({
      endpoint: 'subsidiaries',
      headers: {
        'X-Authentication-Token': token
      },
      cache: true
    })
  }

  return <APIRequest
    resource={resources.documentTypes(token)}
    fetch>
    {request => (
      <SelectField
        field="subsidiaryId"
        noResultsText={__('dashboard.components.panel.subsidiaryId.noResultsText')}
        placeholder={__('dashboard.components.panel.subsidiaryId.placeholder')}
        label={__('dashboard.components.panel.subsidiaryId.label')}
        options={request.response && request.response.data.map(item => ({
          value: item.id,
          label: item.name
        }))}
      />)}
  </APIRequest>
}

class Subsidiaries extends React.Component {
  static contextTypes = {
    store: PropTypes.object
  };

  render() {
    const { token } = this.props;

    return (
      <SelectSubsidiaries token={token} />
    )
  }
}

const mapStateToProps = (state) => ({
  token: get(state, 'api.token'),
});

export default connect(mapStateToProps)(Subsidiaries);
