import React from 'react'
import { TextField, MaskedTextField, SelectField, AmountField } from 'components/forms'
import { get } from 'lodash'
import __ from 'utils/i18n'

export const FieldsPanel = ({ configItems, validations }) => {
  const defaultField = {
    text: Text,
    textarea: Text,
    date: Date,
    number: Text,
    amount: Amount,
    taxes: Amount,
    percentage: Amount,
    select: Select,
  }
  const fields = configItems.map(item =>{
    return(
    {
      type: item.type || '',
      name: item.name || '',
      component:get(defaultField, item.type, item.component),
      val: item.value || '',
      label: item.label || '',
      field: item.field || '',
      endpoint: item.endpoint || '',
      options: item.options || '',
      parser:get(item, 'parser', ''),
      maxLength:get(item, 'maxLength', ''),
      onInput:get(item, 'onInput', '')
    })
  })

  if (validations) {
    fields.forEach(field => {
      validations.forEach(validation => {
        if (field.name === validation.name) {
          field.disabled = validation.disabled
        }
      })
    })
  }
  return(
    <div className="fields-panel">
      {fields.map((field, i) => <field.component key={i} {...field} />)}
    </div>
  )
  
}

const Text = (props) => {
  const {type, name, disabled, parser, maxLength, onInput} = props
  return(
    <TextField
      name={name}
      type={type}
      parser={parser}
      placeholder={__('dashboard.components.panel.'+ name +'.placeholder') }
      label={__('dashboard.components.panel.'+ name +'.label')}
      disabled={disabled}
      onInput={onInput}
      {...(maxLength && type === "number" && ({ max:(10 ** maxLength -1) }))}
      {...(maxLength && type !== "number" && ({ maxLength : maxLength }))}

    />
  )
}

const Date = (props)=> {
  const {name, disabled, maxLength} = props
  return(
    <MaskedTextField
      name={name}
      type="text"
      maxLength={maxLength}
      mask={[/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholder="DD/MM/AAAA"
      label={__('dashboard.components.panel.'+ name +'.label')}
      disabled={disabled}
    />
  )
}

const Amount = (props)=> {
  const {name, disabled, parser, maxLength, type} = props
  return(
    <AmountField
      name={name}
      type={type}
      parser={parser}
      maxLength={maxLength}
      placeholder={__('dashboard.components.panel.'+ name +'.placeholder') }
      label={__('dashboard.components.panel.'+ name +'.label') }
      disabled={disabled}
    />
  )
}

const Select = (props)=> {
  const {name, options} = props

  return <SelectField
    field={name}
    label={__('dashboard.components.panel.' + name + '.label')}
    placeholder={__('dashboard.components.panel.' + name + '.placeholder')}
    noResultsText={__('dashboard.components.panel.' + name + '.noResultsText')}
    options={options}
  />
}