import { getFields } from './fields';

const formatDate = (date) => {
  const datePart = date.match(/\d+/g),
    day = datePart[0],
    month = datePart[1],
    year = datePart[2]
  return year + '-' + month + '-' + day;
}

export const saveClient = (action, token) => {
  const fields = getFields();

  const resources = {
    edit: (values) => {
      const data = {}
      fields.forEach(field => {
        if (field.name === 'birthdate' && values[field.name]) {
          const shouldFormatter = values.birthdate.indexOf('/') > 0;
          if (shouldFormatter) {
            data.birthdate = formatDate(values.birthdate)
          }
        } else {
          data[field.name] = values[field.name]
        }
      })
      data.channel = 'web'
      data.deviceId = 'web'
      data.deviceModel = 'web'
      data.deviceOS = 'web'
      return {
        endpoint: 'buyers/saveBuyer.json',
        method: 'POST',
        formData: data,
        headers: {
          'x-authentication-token': token
        }
      }
    },
    create: (values) => {
      const data = {}
      fields.forEach(field =>
        (field.name === 'birthdate' && values[field.name]) ? (
          data[field.name] = formatDate(values[field.name])
        ) : (
          data[field.name] = values[field.name]
        )
      )
      return {
        endpoint: 'buyers/saveBuyer.json',
        method: 'POST',
        formData: data,
        headers: {
          'x-authentication-token': token
        }
      }
    }
  }
  return resources[action]
}


export const getClients = (subsidiaryId, token) => (payload) => ({
  endpoint: "buyers/getBuyers.json",
  method: "POST",
  headers: {
    "X-Authentication-Token": token,
  },
  params: {
    subsidiaryId: subsidiaryId,
  },
  force: payload && payload.force,
  formData: {...payload, token},
  parseResponse: (response) => response.data.data,
});
