import { Catalog } from '../sections/Catalog';
import { Stats } from '../sections/Stats';
import { EcommerceSection } from '../sections/Ecommerce';
import { Clients } from '../sections/Clients';
import Home from '../sections/Home';
import { Sales } from '../sections/Sales';
import { Merchant } from '../sections/Merchant';
import { CompraClick } from '../sections/CompraClick';
import { CompraClickProduct } from '../sections/CompraClick/components';
import { Staff } from '../sections/Staff';
import __ from 'utils/i18n';

export const getSections = (userPermissions, featureFlags) => {
  let sections = [];

  const isHomeEnabled = featureFlags?.includes('home_section') && userPermissions?.includes('home_section');
  const isSalesEnabled = featureFlags?.includes('sales_section') && userPermissions?.includes('sales_section');
  const isStatsEnabled = featureFlags?.includes('stats_section')  && userPermissions?.includes('stats_section');
  const isCatalogEnabled = featureFlags?.includes('catalog_section')  && userPermissions?.includes('catalog_section');
  const isClientsEnabled = featureFlags?.includes('clients_section') && userPermissions?.includes('clients_section');
  const isMerchantEnabled = featureFlags?.includes('merchant_section') && userPermissions?.includes('merchant_section');
  const isCompraClickEnabled = featureFlags?.includes('compra_click_section') && userPermissions?.includes('compra_click_section');
  const isEcommerceEnabled = featureFlags?.includes('ecommerce_section') && userPermissions?.includes('ecommerce_section');
  const isStaffEnabled = featureFlags?.includes('staff_section');

  isHomeEnabled && sections.push({
    path: '/dashboard',
    exact: "true",
    title: __('dashboard.sections.home.title'),
    icon: 'mdi mdi-home',
    component: Home,
  })

  isSalesEnabled && sections.push({
    path: '/dashboard/sales',
    title: __('dashboard.sections.sales.title'),
    icon: 'mdi mdi-cart',
    component: Sales,
  })

  isStatsEnabled && sections.push({
    path: '/dashboard/stats',
    title: __('dashboard.sections.stats.title'),
    icon: 'mdi mdi-chart-line',
    component: Stats,
  })

  isCatalogEnabled && sections.push({
    path: '/dashboard/catalog',
    title: __('dashboard.sections.catalog.title'),
    icon: 'mdi mdi-format-list-bulleted',
    component: Catalog,
  })

  isClientsEnabled && sections.push({
    path: '/dashboard/clients',
    title: __('dashboard.sections.clients.title'),
    icon: 'mdi mdi-star',
    component: Clients,
  })

  isMerchantEnabled && sections.push({
    path: '/dashboard/merchant',
    title: __('dashboard.sections.merchant.title'),
    icon: 'mdi mdi-store',
    component: Merchant
  })

  isCompraClickEnabled && sections.push({
    path: '/dashboard/payment-button',
    title: __('dashboard.sections.compraClick.title'),
    icon: 'mdi mdi-credit-card',
    tabs: [
      {
        title: __('dashboard.sections.compraClick.title'),
        component: CompraClick,
      }
    ]
  })

  isCompraClickEnabled && sections.push({
    path: '/dashboard/payment-button-product',
    title: __('dashboard.sections.compraClick.title'),
    icon: 'mdi mdi-credit-card',
    hide: true,
    tabs: [{
      title: __('dashboard.sections.compraClick.title'),
      component: CompraClickProduct,
    }]
  })

  isEcommerceEnabled && sections.push({
    path: '/dashboard/ecommerce',
    title: __('store.panel.title'),
    icon: 'mdi mdi-monitor-cellphone',
    component: EcommerceSection,
  })

  isStaffEnabled && sections.push({
    path: '/dashboard/staff',
    title: __('dashboard.sections.staffs.tabs'),
    icon: 'mdi mdi-account-multiple',
    component: Staff,
  })

  return sections;
};
