import React, { useContext } from 'react';
import __ from 'utils/i18n';
import { DropdownItem } from 'reactstrap';
import { PermissionsContext } from 'contexts/permissionsContext';
import { getConfig } from 'config';

export const Config = ({ onClick }) => {
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const showCustomIconProfile = getConfig('customConfig.showCustomIconProfile');
  const isConfigEnabled =
    featureFlags?.includes("user_menu_config_modal") &&
    userPermissions?.includes("user_menu_config_modal");

  return (
    <>
      {isConfigEnabled && (
        <DropdownItem className="nav-menu-item" onClick={onClick}>
          <i className={`mdi mdi-18px ${showCustomIconProfile ? 'mdi-account-circle' : 'mdi-cog'}`} />
          <span>{__("dashboard.components.settings")}</span>
        </DropdownItem>
      )}
    </>
  );
}
