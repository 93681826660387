import React, {useContext} from "react";
import __ from 'utils/i18n';
import { PermissionsContext } from "contexts/permissionsContext";
import { DropdownItem } from 'reactstrap';

export const ReadersModal = ({ onClick }) => {
  const { featureFlags } = useContext(PermissionsContext);
  const isReadersModalEnabled = featureFlags?.includes('user_menu_readers_modal');
  
  return(
    <>
    {isReadersModalEnabled && (
      <DropdownItem className="nav-menu-item" onClick={onClick}>
        <i className="mdi mdi-18px mdi-calculator" />
        <span>{__('dashboard.components.modal.modalReaders.modalTitle')}</span>
      </DropdownItem>
    )}
    </>
  );
};
