import React from "react";
import { Form } from "informed";

import __ from "utils/i18n";
import { Text, Amount } from "components/informed";
import { required, validPrice } from "components/forms/validators";
import { getConfig } from "config";

const inputStyle = {
  fontSize: "15px",
  color: "#333333",
};

const PaymentLinkForm = (props) => {
  const { onChange, name, price } = props;

  const currency = getConfig("customConfig.currencies")[0].symbol;

  return (
    <Form onChange={onChange} className="payment-link-form block-content">
      <Text
        initialValue={name}
        field="name"
        type="text"
        validateOnBlur
        validate={required(__("validators.paymentLink.name.required"))}
        label={__.t("dashboard.components.paymentLink.form.name.label")}
        placeholder={__.t(
          "dashboard.components.paymentLink.form.name.placeholder"
        )}
        style={inputStyle}
      />

      <Amount
        field="price"
        validateOnChange
        validate={validPrice()}
        shouldDisplayListCoins={false}
        label={__.t("dashboard.components.paymentLink.form.price.label")}
        placeholder={`${currency}${__.t(
          "dashboard.components.paymentLink.form.price.placeholder"
        )}`}
        fieldState={{ value: price }}
        style={inputStyle}
      />
    </Form>
  );
}

export default PaymentLinkForm;
