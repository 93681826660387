
import React from 'react'
import MaskedInput from 'react-text-mask'

const MaskedTextInput = ({ field, className, value, onChange, ...rest }) => (
  <MaskedInput
    {...rest}
    className={"form-control " + className}
    value={value || ''}
    onChange={event => onChange && onChange(event.target.value)}
  />
)

export default MaskedTextInput
