import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import __ from 'utils/i18n';
import { DropdownItem } from 'reactstrap';
import { PermissionsContext } from 'contexts/permissionsContext';

export const Funds = ({ onClick }) => {
  const { userPermissions } = useContext(PermissionsContext);
  const funds = useSelector((state) => state.login?.user?.retirementFunds);

  const isFundsEnabled = funds && userPermissions?.includes("user_menu_funds_modal");

  return (
    <>
      {isFundsEnabled && (
        <DropdownItem className="nav-menu-item" onClick={onClick}>
          <i className= "mdi mdi-cash-multiple" />
          <span>{__("dashboard.components.WithdrawalOfFunds")}</span>
        </DropdownItem>
      )}
    </>
  );
};
