import React from "react";
import { RenderFields } from "./RenderFields";

const useFields = ({ showAmount = false, showPaymentChannel = false }) => {
  const [applicatedFields, setApplicatedFields] = React.useState([]);

  const renderFields = () => (
    <RenderFields 
      showAmount={showAmount}
      showPaymentChannel={showPaymentChannel}
      applicatedFields={applicatedFields}
      setApplicatedFields={setApplicatedFields}
    />
  )

  return {
    fields: applicatedFields,
    renderFields,
  };
};

export default useFields;
