import React from 'react';

import { StatusDropdown } from "../StatusDropdown";
import { InputSearch } from '../InputSearch';
import DateRangeInput from 'components/forms/inputs/DateRangeInput';
import moment from 'moment';

const dateRangeInputValue = {
  startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD')
};

export const Filters = ({ meta, setFilters }) => {

  const handleDateRange = ({ startDate, endDate }) => {
    const date_from = startDate.format('YYYY-MM-DD');
    const date_to = endDate.format('YYYY-MM-DD');
    if (date_from && date_to) setFilters({ type: 'date', payload: { date_from, date_to } });
    else setFilters({ type: 'remove', payload: { keys: ['date_from', 'date_to'] } });
  }

  const handleStatus = (status) => {
    setFilters({ type: 'status', payload: { status } })
  }

  return (
    <>
      <DateRangeInput
        past
        value={dateRangeInputValue}
        onChange={value => handleDateRange(value)}
      />

      <StatusDropdown
        handleStatus={handleStatus}
      />

      <InputSearch
        setFilters={setFilters}
      />

    </>
  )
}