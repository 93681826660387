import React, { useContext } from 'react';
import { Filters } from '../Filters';
import { PageSizeDropdown } from "components/PageSizeDropdown";
import { Button } from 'reactstrap';
import __ from 'utils/i18n';
import { PermissionsContext } from 'contexts/permissionsContext';

export const Header = ({
  meta,
  enableButtons,
  handleConfirmModal,
  setFilters,
  handleReload,
  checkboxListLength,
  isFetching
}) => {
  const { userPermissions } = useContext(PermissionsContext);
  const isCompleteOrderEnabled = userPermissions?.includes('orders_action_complete_order');

  const handlePageSize = (page_size) => {
    setFilters({ type: 'page_size', payload: { page_size } })
  }

  return (
    <div className='ecommerceOrders-headerContainer'>
      <div className="ecommerceOrders-filtersContainer">
        <Filters
          meta={meta}
          setFilters={setFilters}
        />
      </div>

      <div className="ecommerceOrders-tableActionsContainer">
        <div className="ecommerceOrders-pageSizeContainer">
          <PageSizeDropdown
            meta={meta}
            handlePageSize={handlePageSize}
          />
        </div>

        <div className="ecommerceOrders-headerButtonsContainer">
          {isCompleteOrderEnabled && (
            <Button
              color='primary'
              type='submit'
              onClick={handleConfirmModal}
              disabled={!enableButtons}
              className='ecommerceOrders-completeOrdersButton ecommerceOrders-actionButton'>
              <i className='mdi mdi-check-all' />
              {checkboxListLength > 1 ? __('actions.completeOrders') : __('actions.completeOrder')}
            </Button>
          )}

          <Button
            color='success'
            type='submit'
            onClick={handleReload}
            disabled={isFetching}
            className='ecommerceOrders-actionButton'>
            <i className='mdi mdi-reload' />
            {__('actions.reloadList')}
          </Button>
        </div>
      </div>
    </div>
  )
}