import React, { useState } from 'react';
import { SortingCriteria } from './SortingCriteria';
import { SortingField } from './SortingField';

export const SortingDropdowns = ({ setSorting }) => {
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingCriteria, setSortingCriteria] = useState('DESC');

  const checkSortingField = (sortingFieldSelected) => {
    if (sortingFieldSelected !== sortingField) {
      setSorting({ type: 'field', payload: { sortingFieldSelected } });
    }

    setSortingField(sortingFieldSelected);
  }

  const checkSortingCriteria = (sortingCriteriaSelected) => {
    if (sortingCriteriaSelected !== sortingCriteria) {
      setSorting({ type: 'criteria', payload: { sortingCriteriaSelected } });
    }

    setSortingCriteria(sortingCriteriaSelected);
  }

  return (
    <>
      <SortingField
        sortingField={sortingField}
        handleSortingField={checkSortingField}
      />

      <SortingCriteria
        sortingCriteria={sortingCriteria}
        handleSortingCriteria={checkSortingCriteria}
      />
    </>
  );
};
