import { PermissionsContext } from 'contexts/permissionsContext';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import __ from "utils/i18n";

export const Subsidiary = () => {
  const subsidiaryName = useSelector((state) => state.login?.user?.subsidiaryName);
  const { featureFlags } = useContext(PermissionsContext);
  const isMultisubsidiaryEnabled = featureFlags?.includes("multisubsidiary");

  return (
    <>
      { isMultisubsidiaryEnabled && subsidiaryName &&
        <div className="nav-menu-item merchant-code">
          <span>{__('dashboard.components.subsidiary')}: {subsidiaryName}</span>
        </div>
      }
    </>
  )
}
