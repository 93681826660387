import React from 'react';
import { Row, Button } from 'reactstrap';
import __ from 'utils/i18n';
import { ExtraItems } from './ExtraItems';
import { Total } from './Total';
import { Items } from './Items';
import { DepositInformation } from './DepositInformation'

export const DepositPanel = ({ data }) => {
  return (
    <>
      {data.id && (
        <Row className='transaction'>
          <div className='panel-flex'>
            <div>
              <DepositInformation data={data} />
              <Items data={data} />
              {data.withholdingTaxes && <ExtraItems data={data} />}
              <Total data={data} />
            </div>
            {false && (
              <div>
                <Button block>
                  {__('dashboard.sections.deposits.seeIncludedTransactions')}
                </Button>
              </div>
            )}
          </div>
        </Row>
      )}
    </>
  );
};
