export const fields = {
  image: {
    edit: true
  },
  name: {
    edit: false
  },
  amount: {
    edit: false
  },
  taxes: {
    edit: false
  },
  stock: {
    edit: true
  },
  language: {
    edit: true
  },
  terms: {
    edit: true
  },
  observations: {
    maxLength: 250,
    edit: true
  },
  expirationDate: {
    edit: true,
    format: 'DD/MM/YYYY HH:mm'
  },
};
