import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import __ from 'utils/i18n';
import moment from 'moment';
import { get } from "lodash";
import { Row, Col } from "reactstrap";
import { DonutChart } from 'components/charts';
import FlexTable from 'components/FlexTable';
import Loader from 'components/Loader';
import { StatsFilters } from '../StatsFilters';
import { APIRequest } from 'api-provider';
import resources from "../../resources";
import gtmEvents from 'utils/gtmEvents';

export const StatsSection = ({ path }) => {

  // useSelector
  const { token } = useSelector((state) => state.api);
  const subsidiaryId = useSelector((state) => state.login?.user?.subsidiaryId);
  const filters = useSelector((state) => state.dashboard?.stats?.filters);

  useEffect(() => {
    switch(path) {
      case 'products':
        gtmEvents.statsPageView('pageProducts');
        break;
      case 'staff':
        gtmEvents.statsPageView('pageSellers');
        break;
      default:
    }
  },[path])

  const resource = resources[path]({
    subsidiaryId,
    token,
    startDate: moment(get(filters, "date.startDate")).format("YYYY-MM-DD"),
    endDate: moment(get(filters, "date.endDate")).format("YYYY-MM-DD"),
    currencyCode: get(filters, "currency", undefined),
  });

  return (
    <APIRequest resource={resource} fetch>
      {request => (
        <div className="dashboard-card stats-section">
          <div className="dashboard-card-header">
            <StatsFilters />
          </div>
          <div className="dashboard-card-body">
            {(request.isFetching) &&
              <Loader />
            }
            {(!get(request.response, 'byCount[0]') && !request.isFetching) &&
              <div className="dashboard-card-message">
                <i className="mdi mdi-minus-circle" />
                <h3>{__(`dashboard.sections.stats.${path}.without`)}</h3>
                <p>{__(`dashboard.sections.stats.${path}.notFound`)}</p>
              </div>
            }
            {(get(request.response, 'byCount[0]') && !request.isFetching) &&
              <Row>
                <Col md="6" xs="12">
                  <DonutChart
                    title={__(`dashboard.sections.stats.${path}.moreBuy`)}
                    data={get(request.response, 'byCount', [])}
                    metric="count"
                    formatter={value => __.number(value)}
                  />
                </Col>
                <Col md="6" xs="12">
                  <DonutChart
                    title={__(`dashboard.sections.stats.${path}.moreProfitability`)}
                    data={get(request.response, 'byAmount', [])}
                    metric="amount"
                    formatter={value => __.currency(value, request.response.currencyCode, 'auto')}
                  />
                </Col>
                <Col xs="12">
                  <FlexTable
                    data={get(request.response, 'byAmount', [])}
                    columns={[
                      { Header: __('dashboard.sections.stats.products.table.description'), value: item => item.name },
                      { Header: __('dashboard.sections.stats.products.table.sales'), value: item => __.number(item.count), maxWidth: 80, align: "right" },
                      { Header: __('dashboard.sections.stats.products.table.effectiveness'), value: item => __.currency(item.amount, request.response.currencyCode, 'auto'), maxWidth: 200, align: "right" },
                    ]}
                  />
                </Col>
              </Row>
            }
          </div>
        </div>
      )}
    </APIRequest>
  );
}