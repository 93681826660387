import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Logo from "components/Logo";
import __ from "utils/i18n";
import ConfigurationModal from "containers/Dashboard/modals/ConfigurationModal";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { DashboardHeader } from "./components/DashboardHeader";
import DashboardTabbedSection from "./components/dashboardSection/DashboardTabbedSection";
import DashboardSectionTab from "./components/dashboardSection/DashboardSectionTab";
import { setFirstLogin } from "../Login/actions";
import { getConfig } from "config";
import { HelpModal, PaymentLinkModal } from "containers/Dashboard/modals";
import { ModalContext } from "../../contexts/modalContext";
import { getSections } from "./_shared/getSections";
import {
  BankAccountModal,
  SubsidiariesModal,
  CourierTrackingModal,
  StaticQRCodeModal,
  DocumentationModal,
  ReadersModal,
} from "containers/Dashboard/modals";
import { PermissionsContext } from "contexts/permissionsContext";

const DashboardSection = (props) => {
  if (props.section.component) {
    return React.createElement(props.section.component, props);
  } else if (props.section.tabs) {
    return (
      <DashboardTabbedSection>
        {props.section.tabs.map((tab, index) => (
          <DashboardSectionTab key={index} {...tab} />
        ))}
      </DashboardTabbedSection>
    );
  }
};

export const Dashboard = () => {
  // useState
  const [showFundsModal, setShowFundsModal] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showDocumentationModal, setShowDocumentationModal] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showBankAccountModal, setShowBankAccountModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showSubsidiariesModal, setShowSubsidiariesModal] = useState(false);
  const [showCourierTrackingModal, setShowCourierTrackingModal] = useState(false);
  const [showStaticQRCodeModal, setShowStaticQRCodeModal] = useState(false);
  const [showPaymentLinkModal, setShowPaymentLinkModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showReadersModal, setShowReadersModal] = useState(false);

  // useSelector
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const isFirstLogin = useSelector((state) => state.login.isFirstLogin);
  const token = useSelector((state) => state.api.token);
  const user = useSelector((state) => state.login.user);
  const buyReaderUrl = useSelector((state) => state.login?.user?.buyReaderURL);

  // useDispatch
  const dispatch = useDispatch();

  // useContext
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const isFirstLoginBuyReaderModalEnabled = featureFlags?.includes(
    "first_login_buy_reader_modal"
  );

  const sections = getSections(userPermissions, featureFlags);

  const userFundsDefaultValues = {
    title: (user && user.retirementFunds && user.retirementFunds.title) || null,
    data: (user && user.retirementFunds && user.retirementFunds.data) || null,
  };

  const openModal = (keyname) => {
    const modalOptions = {
      FundsModal: () => setShowFundsModal(true),
      ConfigModal: () => setShowConfigModal(true),
      DocumentationModal: () => setShowDocumentationModal(true),
      BuyModal: () => setShowBuyModal(true),
      BankAccountModal: () => setShowBankAccountModal(true),
      HelpModal: () => setShowHelpModal(true),
      SubsidiariesModal: () => setShowSubsidiariesModal(true),
      CourierTrackingModal: () => setShowCourierTrackingModal(true),
      StaticQRCodeModal: () => setShowStaticQRCodeModal(true),
      PaymentLinkModal: () => setShowPaymentLinkModal(true),
      ReadersModal: () => setShowReadersModal(true),
    };
    return modalOptions[keyname]();
  };
  
  const closeModal = (keyname) => {
    const modalOptions = {
      FundsModal: () => setShowFundsModal(false),
      ConfigModal: () => setShowConfigModal(false),
      DocumentationModal: () => setShowDocumentationModal(false),
      BuyModal: () => setShowBuyModal(false),
      BankAccountModal: () => setShowBankAccountModal(false),
      HelpModal: () => setShowHelpModal(false),
      SubsidiariesModal: () => setShowSubsidiariesModal(false),
      CourierTrackingModal: () => setShowCourierTrackingModal(false),
      StaticQRCodeModal: () => setShowStaticQRCodeModal(false),
      PaymentLinkModal: () => setShowPaymentLinkModal(false),
      ReadersModal: () => setShowReadersModal(false),
    };
    return modalOptions[keyname]();
  };

  const closeBuyModal = () => {
    setShowBuyModal(false);
  };

  const closeConfigModal = () => {
    setShowConfigModal(false);
    window.location.reload();
  };

  const closeDocumentationModal = () => {
    setShowDocumentationModal(false);
  };

  const closeFundsModal = () => {
    setShowFundsModal(false);
  };

  const showDeleteModal = () => {
    setDeleteModal(true);
  };

  const hideDeleteModal = () => {
    setDeleteModal(false);
  };

  const brand = (
    <span className="navbar-logo">
      <Logo />
    </span>
  );
  const reader = getConfig("customConfig.readerImage");

  const [defaultSection] = sections;

  useEffect(() => {
    let timer;
    if (isFirstLogin && isFirstLoginBuyReaderModalEnabled) {
      timer = setTimeout(() => {
        dispatch(setFirstLogin(false));
        setShowBuyModal(true);
      }, 1000);
    }
    return () => timer && clearTimeout(timer);
  }, [isFirstLogin, isFirstLoginBuyReaderModalEnabled, dispatch]);

  if (!userPermissions.length || !featureFlags.length)
    return <></>;

  return (
    <div>
      {!isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <ModalContext.Provider
          value={{
            openModal: openModal,
            closeModal: closeModal,
          }}
        >
          <div className="dashboard-main">
            <DashboardHeader
              openModal={openModal}
              closeModal={closeModal}
              brand={brand}
              home="/"
            />

            {user && ConfigurationModal && (
              <ConfigurationModal
                user={user}
                token={token}
                showModal={deleteModal}
                showDeleteModal={showDeleteModal}
                hideModal={hideDeleteModal}
                closeConfigModal={closeConfigModal}
                isOpen={showConfigModal}
                dispatch={dispatch}
              />
            )}

            {user && DocumentationModal && (
              <DocumentationModal
                user={user}
                token={token}
                showModal={deleteModal}
                showDeleteModal={showDeleteModal}
                hideModal={hideDeleteModal}
                closeDocumentationModal={closeDocumentationModal}
                isOpen={showDocumentationModal}
                dispatch={dispatch}
              />
            )}

            {user && user.retirementFunds && (
              <Modal
                isOpen={showFundsModal}
                toggle={() => closeFundsModal()}
                size="lg"
                className="dashboard-modal config-modal"
              >
                <ModalHeader>
                  {__("dashboard.components.modal.funds")}
                </ModalHeader>
                <ModalBody>
                  <Row className="mb-5">
                    <Col>{__("dashboard.components.modal.depositMoney")}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="dashboard-modal-subtitle">
                        {userFundsDefaultValues.title}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="dashboard-card-item">
                        <div className="dashboard-card-item-body color-gray">
                          {Object.entries(userFundsDefaultValues.data).map(
                            ([key, value]) => (
                              <Row key={key}>
                                <Col sm="3">
                                  <span className="strong--500">
                                    {key + ": "}
                                  </span>
                                </Col>
                                <Col sm="9">{value.toString()}</Col>
                              </Row>
                            )
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr className="mt-5" />
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => closeFundsModal()}>
                    {__("actions.close")}
                  </Button>
                </ModalFooter>
              </Modal>
            )}

            <Modal
              isOpen={showBuyModal}
              toggle={() => closeBuyModal()}
              className="dashboard-center-modal modal-lg"
            >
              <ModalHeader style={{ padding: "15px 0" }} />
              <ModalBody>
                <img src={reader} alt="reader" />
                <h2>{__("dashboard.sections.home.buyProduct")}</h2>
                <span>{__.bb("dashboard.sections.home.indicationsUse")}</span>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => closeBuyModal()}>
                  {__("dashboard.sections.home.iHaveOne")}
                </Button>
                <a href={buyReaderUrl}>
                  <Button color="primary">
                    {__("dashboard.sections.home.buyNow")}
                  </Button>
                </a>
              </ModalFooter>
            </Modal>
            {showBankAccountModal && (
              <BankAccountModal
                isOpen={showBankAccountModal}
                toggle={() => {
                  closeModal("BankAccountModal");
                }}
              />
            )}
            {showReadersModal && (
              <ReadersModal
                isOpen={showReadersModal}
                toggle={() => {
                  closeModal('ReadersModal');}}
              />
            )}
            {showHelpModal && (
              <HelpModal 
                isOpen={showHelpModal}
                toggle={() => {
                  closeModal("HelpModal");
                }}
              />
            )}
            {showSubsidiariesModal && (
              <SubsidiariesModal
                isOpen={showSubsidiariesModal}
                toggle={() => {
                  closeModal("SubsidiariesModal");
                }}
              />
            )}
            {showCourierTrackingModal && (
              <CourierTrackingModal
                isOpen={showCourierTrackingModal}
                toggle={() => {
                  closeModal("CourierTrackingModal");
                }}
              />
            )}
            {showStaticQRCodeModal && (
              <StaticQRCodeModal
                isOpen={showStaticQRCodeModal}
                toggle={() => {
                  closeModal("StaticQRCodeModal");
                }}
              />
            )}

            <PaymentLinkModal
              isOpen={showPaymentLinkModal}
              toggle={() => {
                closeModal("PaymentLinkModal");
              }}
            />
            {!!sections.length && (
              <div className="dashboard-content">
                <Switch>
                  {sections.map((section, index) => (
                    <Route
                      key={index}
                      exact={section.exact}
                      path={section.path}
                      render={(props) => (
                        <DashboardSection section={section} {...props} />
                      )}
                    />
                  ))}
                  <Redirect exact to={defaultSection.path} />
                </Switch>
              </div>
            )}
          </div>
        </ModalContext.Provider>
      )}
    </div>
  );
};
