import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { APIRequest, fetchResource } from 'api-provider';
import { SimpleForm} from 'components/forms';
import { useSelector } from 'react-redux';
import __ from 'utils/i18n'

const ButtonLoading = ({ width, message }) => (
  <div className="buy-reader-loading">
    <div className="loading" style={{ width: `${width}%` }} />
    <label>{message}</label>
  </div>
);

export const ActivateReaderWidget =  () => {
    const [width, setWidth] = useState(0);
    const [isFetching, setIsFetching] = useState(true);
    const [code, setCode] = useState(null);
    const [showCode, setShowCode] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(__('dashboard.sections.merchantReaders.verificationCode'));  
    const [startLoading, setStartLoading] = useState(false);
    const token = useSelector((state) => state.api?.token);
    const { subsidiaryId } = useSelector((state) => state.login.user);
    
  useEffect(() => {
      if (startLoading) {
        const increase = setTimeout(() => {
          setWidth(width +1)
        }, 30);
        if (width === 100) {
          setIsFetching(false)
          setWidth(0)
          code && setShowCode(true)
          !code && setShowCode(false)
          setLoadingMessage(__('dashboard.sections.merchantReaders.generateCode'))  
    
          clearTimeout(increase);
        }
      }
  }, [width, startLoading, code])

  const toggle = () => {
    setTooltipOpen(!setTooltipOpen);
  }

  const onSuccessGettingCode = (code) => {
    setCode(code);
  }

  const resources = {
    codeAvailable: (token, subsidiaryId) => ({
      endpoint: 'standalone/available-activation-code',
      method: 'GET',
      headers: {
        'X-Authentication-Token': token
      },
      params: {
        subsidiaryId: subsidiaryId,
      }
    }),
    codeActivation: (token, subsidiaryId) => ({
      endpoint: 'standalone/activation-code',
      method: 'POST',
      headers: {
        'X-Authentication-Token': token
      },
      params: {
        subsidiaryId: subsidiaryId,
      }
    })
  }

  useEffect(() => {
    setStartLoading(true);
    fetchResource(resources.codeAvailable(token, subsidiaryId)).onSuccess((data) => {
    }).onFailure(error => error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, subsidiaryId])

  return (
      <APIRequest
        resource={resources.codeActivation(token, subsidiaryId)}
        onSuccess={(value) => {
          onSuccessGettingCode(value.code);
        }}
      >
        { request => (
          <SimpleForm
            onSubmit={(values) => {
              setStartLoading(true);
              request.fetch(values);
              setIsFetching(true);
            }}
          >
            {() => (
              <div className="reader-widget">
                {isFetching && (
                  <ButtonLoading width={width} message={loadingMessage} />
                )}
                {!code && !isFetching &&(
                  <Button type="submit">Activar Poket</Button>
                )}
            
                
                {showCode && (
                  <div>
                    <p className="reader-label-code" id="TooltipExample">
                    Código de activación
                    {' '}
                    <br />
                    <b>{code}</b>
                  </p>
                  <Tooltip className="reader-tooltip" placement="top" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
                    Con este código puedes activar un lector Poket. Si lo usas o vence, puedes generar otro.
                  </Tooltip>
                  </div>
                )}
              </div>
            )}
          </SimpleForm>
        )}
      </APIRequest>
  );
  
}



