import { REHYDRATE, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";

import apiReducer from "api-provider/reducer";
import dashboardReducer from "containers/Dashboard/reducer";
import loginReducer from "containers/Login/reducer";
import courierReducer from "containers/CourierTracking/reducer";

const rehydratedReducer = (state = false, action) => {
  switch (action.type) {
    case REHYDRATE:
      return true;
    default:
      return state;
  }
};

const config = {
  key: "root",
  storage,
  blacklist: ["login.forms", "register"],
};

export default persistCombineReducers(config, {
  api: apiReducer,
  dashboard: dashboardReducer,
  login: loginReducer,
  courier: courierReducer,
  rehydrated: rehydratedReducer,
});
