import React from "react";
import { Row, Col } from 'reactstrap';
import __ from "utils/i18n";
import { getConfig } from "config";

const AccountDetailsDefault = ({ data }) => {
  const bankAccountNumberLabel = getConfig("customConfig.bankAccountNumberLabel");
  const fiscalIdKeyName = getConfig("customConfig.fiscalIdKeyName");
  const bankAccountNumber = data.cbu || data.accountNumber;

  return(
    <>
      <Row className={"bank-detail-types"}>
        <Col lg="5" sm="6" className={"bank-detail-item-type"}>
          <strong>{bankAccountNumberLabel}: </strong>
        </Col>
        <Col lg="7" sm="6" className={"bank-detail-item-type"}>
          <span>{bankAccountNumber}</span>
        </Col>
      </Row>
      <Row className={"bank-detail-types"}>
        <Col lg="5" sm="6" className={"bank-detail-item-type"}>
          <strong>{`${__("dashboard.components.modal.fiscalID")}: `}</strong>
        </Col>
        <Col lg="7" sm="6" className={"bank-detail-item-type"}>
          <span>{data[fiscalIdKeyName] || ""}</span>
        </Col>
      </Row>
    </>
  );
}

export default AccountDetailsDefault;
