import React from 'react';
import { SelectField } from 'components/forms';
import __ from 'utils/i18n';

export const SelectStockAction = ({ state, setStockAction }) => {
  const options = [
    {
      value: 1,
      label: __('dashboard.sections.catalog.products.add'),
    },
    {
      value: -1,
      label: __('dashboard.sections.catalog.products.substract'),
    },
  ];
  return (
    <SelectField
      name='stockAction'
      placeholder={__(
        'dashboard.sections.catalog.components.productPanel.form.stockAction.placeholder'
      )}
      label=''
      options={options}
      disabled='true'
      value={state}
      onChange={(value) => {
        setStockAction(value);
      }}
    />
  );
};
