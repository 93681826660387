export const getCompraClick = (token, subsidiaryId, filters = [], sorting = [], pagination = []) => ({
  headers: {
    'X-Authentication-Token': token,
  },
  endpoint: `/api/v1/compra-click/${subsidiaryId}`,
  method: 'GET',
  params: { ...filters, ...sorting, ...pagination },
  proxy: false
});

export const batchDeleteCompraClick = (array, token, subsidiaryId) => ({
  headers: {
    'X-Authentication-Token': token,
  },
  endpoint: `/api/v1/compra-click/${subsidiaryId}`,
  method: 'DELETE',
  data: {
    compraClickIds: array.toString()
  },
  proxy: false
});

export const getExtraInfo = (token, subsidiaryId) => ({
  headers: {
    'X-Authentication-Token': token,
  },
  endpoint: `/api/v1/compra-click/extra-info/${subsidiaryId}`,
  method: 'GET',
  proxy: false
});

export const uploadFile = (file, token, subsidiaryId) => ({
  headers: {
    'X-Authentication-Token': token,
  },
  endpoint: `/api/v1/compra-click/import/${subsidiaryId}`,
  method: 'POST',
  formData: {
    paymentButtons: file
  },
  proxy: false
});

export const importFile = (fileName, token, subsidiaryId) => ({
  headers: {
    'X-Authentication-Token': token,
  },
  endpoint: `/api/v1/compra-click/import/${subsidiaryId}`,
  method: 'POST',
  formData: {
    confirmation: 1,
    importFileName: fileName
  },
  proxy: false
});

export const deleteCompraClick = (subsidiaryId, buttonId, token) => ({
  headers: {
    'X-Authentication-Token': token
  },
  endpoint: `/api/v1/compra-click/${subsidiaryId}/${buttonId}`,
  method: 'DELETE',
  proxy: false
});

export const sendEmail = ({ values, token }) => {
  const data = {
    message: values.message,
    email: values.email,
    subject: values.subject,
  }

  return ({
    headers: {
      'X-Authentication-Token': token
    },
    endpoint: `/api/v1/compra-click/send-email/${values.token}`,
    method: 'POST',
    data,
    proxy: false
  })
}; 

export const uploadTermsAndConditions = (subsidiaryId, data, token) => ({
  headers: {
    'X-Authentication-Token': token,
    'Content-Type': 'multipart/form-data',
  },
  endpoint: `/api/v1/compra-click/upload-docs/${subsidiaryId}`,
  method: 'POST',
  data,
  proxy: false
});
