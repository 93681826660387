import React, { useState } from "react";
import { Card, CardBody, Button, Tooltip } from "reactstrap";
import IconCopy from "assets/icon-copy.svg";
import i18n from "utils/i18n";
import gtmEvents from "utils/gtmEvents";

function ShowCredentialStep(props) {
  const [showTooltip, setShowTooltip] = useState("");

  gtmEvents.credentialsPageView('credentialsCompleted');

  const copyToClipboard = (value, target) => {
    const clientToCopy = value;
    navigator.clipboard.writeText(clientToCopy).then(function () {
      setShowTooltip(target);
    });
  };

  return (
    <div className="credentials-modal-body-api-step">
      <h5 className="credentials-modal-title credentials-modal-title-position">
        {i18n(
          "dashboard.sections.merchantCredentials.step.showCredential.title"
        )}
      </h5>
      <h6 className="credentials-card-modal-body-api-step-label credentials-modal-title-position">
        {i18n(
          "dashboard.sections.merchantCredentials.step.showCredential.clientId"
        )}
      </h6>
      <Card className="credentials-card-modal mb-3">
        <CardBody className="credentials-card-modal-body">
          <div className="credentials-card-modal-body-content-left">
            <h5 className="credentials-card-modal-body-secret-code">
              {props.credentials.clientId}
            </h5>
          </div>
          <Button
            id="copy-id"
            onClick={() =>
              copyToClipboard(props.credentials.clientId, "client_id")
            }
          >
            <div>
              <img
                src={IconCopy}
                width="28"
                height="28"
                className="mr-3"
                alt={i18n(
                  "dashboard.sections.merchantCredentials.step.showCredential.copyButton"
                )}
              />
              <small className="credentials-card-modal-body-text-copy">
                {i18n(
                  "dashboard.sections.merchantCredentials.step.showCredential.copyButton"
                )}
              </small>
            </div>
          </Button>
          <Tooltip
            target="copy-id"
            placement="top"
            className="credentials-card-modal-tooltip"
            trigger="click"
            isOpen={showTooltip === "client_id"}
          >
            {i18n(
              "dashboard.sections.merchantCredentials.step.showCredential.tooltip"
            )}
          </Tooltip>
        </CardBody>
      </Card>
      <h6 className="credentials-card-modal-body-label credentials-modal-title-position">
        {i18n(
          "dashboard.sections.merchantCredentials.step.showCredential.clientSecrets"
        )}
      </h6>
      <Card className="credentials-card-modal">
        <CardBody className="credentials-card-modal-body">
          <div className="credentials-card-modal-body-content-left">
            <h5 className="credentials-card-modal-body-secret-code">
              {props.credentials.clientSecret}
            </h5>
          </div>
          <Button
            id="copy-secret"
            onClick={() =>
              copyToClipboard(props.credentials.clientSecret, "client_secret")
            }
            className="credentials-card-modal-body-api-step-btn-copy"
          >
            <div>
              <img
                src={IconCopy}
                width="28"
                height="28"
                className="mr-3"
                alt={i18n(
                  "dashboard.sections.merchantCredentials.step.showCredential.copyButton"
                )}
              />
              <small className="credentials-card-modal-body-text-copy">
                {i18n(
                  "dashboard.sections.merchantCredentials.step.showCredential.copyButton"
                )}
              </small>
            </div>
          </Button>
          <Tooltip
            target="copy-secret"
            placement="top"
            className="credentials-card-modal-tooltip"
            trigger="click"
            isOpen={showTooltip === "client_secret"}
          >
            {i18n("dashboard.sections.merchantShowCredentialStep.tooltip")}
          </Tooltip>
        </CardBody>
      </Card>
      <Button
        className="credentials-modal-step-button"
        color="primary"
        onClick={() => props.retryModal()}
      >
        {i18n(
          "dashboard.sections.merchantCredentials.step.showCredential.button"
        )}
      </Button>
    </div>
  );
}

export default ShowCredentialStep;
