import React from "react";
import { Button } from "reactstrap";
import { SimpleForm } from "components/forms";
import PasswordField from "components/PasswordField";
import { validateFields } from "components/forms/validators";
import __ from "utils/i18n";
import { ToastComponent } from "containers/Dashboard/components/Toast";
import { getConfig } from "config";

const ResetPassword = ({ onSubmit, success, errorMessage }) => {

  const validationRequirements = getConfig(
    "customConfig.createOrChangePasswordReqsField"
  );
  const validationRequirementsSubmit = getConfig(
    "customConfig.createOrChangePasswordReqsSubmit"
  );
  const validationRequirementsValue = getConfig("customConfig.createOrChangePasswordMinLengthValue");

  return (
    <SimpleForm
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validateOnSubmit={true}
        validate={validateFields(validationRequirementsSubmit)}
      >
      {(formApi) => (
        <>
          <h2 className="password-form-login-welcome">
            {__("resetPassword.restorePassword")}
          </h2>

          {errorMessage && (
            <div className="password-form-error-message login-error-message text-danger text-center">
              {errorMessage}
            </div>
          )}

          <PasswordField
            field="password"
            label={__("resetPassword.form.password.label")}
            requirements={validationRequirements(validationRequirementsValue)}
            minLengthValue={validationRequirementsValue}
          />

          <div>
            <Button block color="primary" type="submit" disabled={success}>
              {__("resetPassword.resetPassword")}
            </Button>
          </div>

          {success && (
            <ToastComponent
              success={__("resetPassword.resetPasswordSucces")}
              limit={1}
            >
              {(window.location.href = "/")}
            </ToastComponent>
          )}
        </>
      )}
      </SimpleForm>
  );
};

export default ResetPassword;
