import React, { useState } from "react"
import DoubleRangeSlider from "components/forms/inputs/DoubleRangeSlider"
import { ModalField } from "./ModalField";
import { getConfig } from "config";
import __ from "utils/i18n";

export const AmountField = ({ applicatedFields, setApplicatedFields }) => {
    const min = getConfig('customConfig.filters.amount.min');
    const max = getConfig('customConfig.filters.amount.max')
    const step = getConfig('customConfig.filters.amount.step');
    const initialValueMax = max * getConfig('customConfig.filters.amount.initialMaxMultiplier');
    const initialValueMin = max * getConfig('customConfig.filters.amount.initialMinMultiplier');
    const [fieldIsActive, setFieldIsActive] = useState(false);
    const [values, setValues] = useState([initialValueMin, initialValueMax]);
    
    const title = __("dashboard.components.filterForm.fields.amount.title");
    const description = __("dashboard.components.filterForm.fields.amount.description");
  
    function applyFilters() {
        setApplicatedFields({
            ...applicatedFields,
            minAmount: values[0],
            maxAmount: values[1],
        });
        setFieldIsActive(true);
    }

    function cleanFilters() {
        setApplicatedFields({
            ...applicatedFields,
            minAmount: null,
            maxAmount: null,
        });
        setFieldIsActive(false);
        setValues([initialValueMin, initialValueMax])
    }

    return (
        <ModalField
            field={'amount'}
            title={title}
            description={description}
            fieldIsActive={fieldIsActive}
            applyFilters={applyFilters}
            cleanFilters={cleanFilters}
        >
            <div>
                <DoubleRangeSlider
                    values={values}
                    setValues={setValues}
                    min={min}
                    max={max}
                    step={step}
                />
            </div>
        </ModalField>)
}