import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import __ from "utils/i18n";
import { getBankAccountIdentifierType, removeUndefinedKeys } from "./helpers";
import { DashboardModal } from "containers/Dashboard/components/DashboardModal";
import Loader from "components/Loader";
import AccountDetails from "./AccountDetails/index";
import BankAccountForm from "./BankAccountForm";
import Toggle from "react-toggle";
import { SuccessMessage, ErrorMessage } from "components/flashMessages";
import { fetchResource } from "api-provider";
import { updateAutomaticCashout, getAutomaticCashout } from "./resources";
import { useAutomaticCasshout } from "./useAutomaticCashout";

const BankAccountModal = (props) => {
  const { isOpen, toggle } = props;
  const token = useSelector((state) => state.api?.token);
  const [data, setData] = useState(null);
  const [switchDisplayForm, setSwitchDisplayForm] = useState(false);
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isFetching, setIsFeching] = useState(false);
  const [cashoutEnabledOption, setCashoutEnabledOption] = useState(false);
  const [shouldDisplayCashoutSuccess, setShouldDisplayCashoutSuccess] =
    useState(false);
  const [shouldDisplayCashoutError, setShouldDisplayCashoutError] =
    useState(false);
  const [
    shouldDisplayCashoutRequestError,
    setShouldDisplayCashoutRequestError,
  ] = useState(false);

  const CBUBankAddress = useSelector(
    (state) => state.login?.user?.cbuData?.cbu
  );
  const accountType = useSelector((state) => state.login?.user?.accountType);
  const { showAutomaticCashout } = useAutomaticCasshout(
    CBUBankAddress,
    accountType
  );

  useEffect(() => {
    setIsFeching(true);
    const resources = {
      bankAccount: () => ({
        endpoint: `account/bank-account`,
        method: "GET",
        headers: {
          "x-authentication-token": token,
        },
        parseResponse: (response) => response.data.data,
      }),
    };

    fetchResource(resources.bankAccount)
      .onSuccess((response) => {
        setData(response);
        setIsFeching(false);
      })
      .onFailure((error) => {
        setIsFeching(false);
      });
  }, [switchDisplayForm, token]);

  const toggleForm = () => {
    setSwitchDisplayForm(!switchDisplayForm);
    setMessage("");
    setHasError(false);
  };

  const onSuccess = (response, request) => {
    const cleanData = removeUndefinedKeys(request);

    setData({
      ...cleanData,
      type: getBankAccountIdentifierType(cleanData),
    });

    setSwitchDisplayForm(false);
    setMessage(__("dashboard.components.modal.bankAccountUpdated"));
    setHasError(false);
  };

  const onError = (message) => {
    setHasError(true);
    setMessage(message);
  };

  useEffect(() => {
    if(showAutomaticCashout){
      fetchResource(getAutomaticCashout(token))
        .onSuccess((data) => {
          if (data.automatic_cash_out) {
            setCashoutEnabledOption(true);

          }
        })
        .onFailure((err) => {
          setShouldDisplayCashoutRequestError(true);
        });
    }
  }, [showAutomaticCashout, token]);

  const toggleCashoutValue = (value) => {
    fetchResource(updateAutomaticCashout(token, value))
      .onSuccess((data) => {
        if (data.automatic_cash_out) {
          setShouldDisplayCashoutSuccess(true);
          return;
        }
        setShouldDisplayCashoutSuccess(false);
      })
      .onFailure((err) => {
        setShouldDisplayCashoutRequestError(true);
        setCashoutEnabledOption(!value);
      });
  };

  const onActivateAutomaticCashout = () => {
    setShouldDisplayCashoutSuccess(false);
    setShouldDisplayCashoutError(false);
    setShouldDisplayCashoutRequestError(false);

    if (!data.cbu) {
      setShouldDisplayCashoutError(true);
      setCashoutEnabledOption(false);
      return;
    }

    setCashoutEnabledOption(!cashoutEnabledOption);
    toggleCashoutValue(!cashoutEnabledOption);
  };

  return (
    <DashboardModal
      showModal={isOpen}
      hideModal={() => toggle("false")}
      headerText={__("dashboard.components.modal.bankAccount")}
      cancelText={__("actions.close")}
      modalClass="bank-account-modal"
    >
      <div>
        {isFetching ? (
          <Loader isLoading={isFetching} />
        ) : (
          <div>
            <Row className="mb-5">
              <Col>
                {__("dashboard.components.modal.bankAccountDisclaimer")}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="dashboard-card-item">
                  <div className="dashboard-card-item-body color-gray">
                    {data && (
                      <>
                        {switchDisplayForm ? (
                          <BankAccountForm
                            data={data}
                            onSuccess={onSuccess}
                            onError={onError}
                            onCancel={toggleForm}
                          />
                        ) : (
                          <AccountDetails data={data} toggleForm={toggleForm} />
                        )}
                      </>
                    )}
                  </div>
                </div>
                <>
                  {showAutomaticCashout && (
                    <div>
                      <Row>
                        <Col className="config-modal-header-modal-cashout header-cashout">
                          <div className="mb-0 config-modal-header-modal-cashout-text">
                            {__("dashboard.components.modal.automaticCashout")}
                          </div>
                        </Col>
                      </Row>
                      <Row className="toggle-wrapper">
                        <Col lg="7" className="toggle-text-wrapper">
                          {__(
                            "dashboard.components.modal.automaticCashoutDisclaimer"
                          )}
                        </Col>
                        <Col className="toggle-option" lg="4">
                          <label>
                            {cashoutEnabledOption
                              ? __("dashboard.components.modal.enabledCashout")
                              : __(
                                  "dashboard.components.modal.disabledCashout"
                                )}
                          </label>
                          <Toggle
                            id="toogle-cashout"
                            onChange={onActivateAutomaticCashout}
                            checked={cashoutEnabledOption}
                            value={cashoutEnabledOption}
                          />
                        </Col>
                      </Row>
                      {shouldDisplayCashoutSuccess && (
                        <Row>
                          <div
                            className="alert alert-success"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            {__("dashboard.components.modal.successCashout")}
                          </div>
                        </Row>
                      )}
                      {shouldDisplayCashoutError && (
                        <Row>
                          <div
                            className="alert alert-danger"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            {__("dashboard.components.modal.errorCashout")}
                          </div>
                        </Row>
                      )}
                      {shouldDisplayCashoutRequestError && (
                        <Row>
                          <div
                            className="alert alert-danger"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            {__(
                              "dashboard.components.modal.errorRequestCashout"
                            )}
                          </div>
                        </Row>
                      )}
                    </div>
                  )}
                </>
              </Col>
            </Row>
            {hasError && message && <ErrorMessage>{message}</ErrorMessage>}
            {!hasError && message && <SuccessMessage>{message}</SuccessMessage>}
          </div>
        )}
      </div>
    </DashboardModal>
  );
};

export default BankAccountModal;
