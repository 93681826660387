import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Toggle from 'react-toggle';
import i18next from 'i18next';
import moment from 'moment';
import classnames from 'classnames';
import { Text, Amount, ImageInput, File, Select, TextArea, DateTime } from 'components/informed';
import FormPanel from 'containers/Dashboard/components/FormPanel';
import { DeleteProductModal } from '../Modals/DeleteProduct';
import { required, validPrice, validations, isValidFileType } from 'components/forms/validators';
import { APIRequest, fetchResource } from 'api-provider';
import __ from 'utils/i18n';
import { uploadTermsAndConditions } from '../../resources';
import { Footer } from './Footer';
import { fields } from './fields';
import { isNull, isNumber } from 'lodash';

const resources = {
  create: ({ values, image, subsidiaryId, showTaxes, token }) => {
    const amount = __.revertNumberFormat(values?.amount?.floatValue || values?.amount?.amount);
    const taxes = __.revertNumberFormat(values?.taxes?.floatValue || values?.taxes?.amount);

    let totalAmount = amount;
    if (showTaxes && taxes > 0) {
      totalAmount += amount * taxes / 100;
    }

    const data = {
      description: values.description,
      amount: totalAmount,
      taxes,
      currency_code: values.amount.currency,
      image: image.file,
      image_url: image.url,
      max_elements: values.maxElements,
      doc_url: values.file?.data?.doc_url,
      language: values.language,
      observations: values.observations,
      expired_at: values.expiredAt,
    }

    return ({
      endpoint: `paymentButtons/${subsidiaryId}/${i18next.language}`,
      method: 'POST',
      formData: data,
      headers: {
        'x-authentication-token': token
      },
    })
  },
  update: ({ values, image, token, showTaxes }) => {
    let totalAmount = __.revertNumberFormat(values.amount.amount);
    let taxes = 0;
    if (showTaxes && values.taxes.amount > 0) {
      totalAmount += __.revertNumberFormat(values.amount.amount) * values.taxes.amount / 100;
      taxes = values.taxes.amount;
    }

    const data = {
      description: values.description,
      amount: totalAmount,
      taxes: taxes,
      currency_code: values.amount.currency,
      image: image.file,
      image_url: image.url,
      max_elements: values.maxElements,
      doc_url: values.file?.data?.doc_url,
      language: values.language,
      observations: values.observations,
      expired_at: values.expiredAt,
    }
    return ({
      endpoint: `paymentButtonsUpdate/${values.buttonToken}/${i18next.language}`,
      method: 'POST',
      headers: {
        'X-Authentication-Token': token
      },
      formData: data
    })
  }
}

const taxAmountToFloat = (str) => {
  let number = String(str).replace(',', '.');
  number = number.substring(0, number.lastIndexOf('.')).replace('.', '') + number.substring(number.lastIndexOf('.'), number.length);
  return parseFloat(number);
};

export const Panel = ({ countryTimezone, expirationDateFormat, data, onSave, hidePanel, action }) => {
  const subsidiaryId = useSelector((state) => state.login.user.subsidiaryId);
  const showTaxes = useSelector((state) => state.login.user.showEcommerceTaxesDetail);
  const maxTaxPercentage = useSelector((state) => state.login.user.taxPercentage);
  const currenciesPerSubsidiary = useSelector((state) => state.login.user.paymentButtonCurrenciesPerSubsidiary);
  const currencies = currenciesPerSubsidiary[subsidiaryId];
  const token = useSelector((state) => state.api.token)
  const isTermsAndConditionsRequired = useSelector((state) => state.login?.user?.paymentButton?.isTermsAndConditionsRequired) || false;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [image, setImage] = useState({ url: '', color: '', name: '' });
  const [buttonData, setButtonData] = useState({});
  const [expirationDateSmallText, setExpirationDateSmallText] = useState('');
  const [total, setTotal] = useState(0.00);
  const [amount, setAmount] = useState(0);
  const [taxes, setTaxes] = useState(0);

  const languages = [
    {
      name: 'common.languages.spanish',
      code: 'es',
    },
    {
      name: 'common.languages.english',
      code: 'en',
    },
  ]

  const languageOptions = languages.map(({ name, code }, index) => {
    return { label: __(name), value: code, key: index }
  });

  useEffect(() => {
    setButtonData({
      id: data?.id,
      token: data.token,
      description: data.description,
      amount: { amount: data.taxes !== 0 ? data.netAmount : data.amount, currency: data.currency ? data.currency.code : '' },
      total: { amount: data.taxes !== 0 ? data.netAmount : data.amount, currency: data.currency ? data.currency.code : '' },
      taxes: { amount: data.taxes },
      maxElements: data.maxElements,
      language: data.language || 'es',
      file: data.docUrl,
      observations: data.observations || '',
      expiredAt: data.expiredAt ? moment(data.expiredAt.date).format(expirationDateFormat) : ''
    });

    setChecked(!isNull(data.maxElements));

    setImage({
      url: data.imageUrl,
      color: '',
      name: data.description
    })

    setExpirationDateSmallText('');
  }, [data, expirationDateFormat, isFetching]
  );

  const sendFile = (file) => {
    const data = new FormData();
    data.append('doc', file);

    return (
      fetchResource(uploadTermsAndConditions(subsidiaryId, data, token))
        .onSuccess((response) => response.data?.doc_url)
        .onFailure((error) => console.log(error))
    );
  };

  const onSuccess = () => {
    onSave()
    hidePanel()
    setButtonData({ id: null })
    setIsFetching(false)
  }

  const validateTermsAndConditions = (value) => {
    if (isTermsAndConditionsRequired) {
      return isValidFileType()(value?.data?.doc_url || value) || undefined;
    } else {
      return value ? isValidFileType()(value?.data?.doc_url || value) : undefined;
    }
  };

  const validateTaxAmount = (value) => {
    const taxAmount = isNumber(value?.amount) ? value?.amount : value?.value;

    if (maxTaxPercentage && !(taxAmountToFloat(taxAmount) <= maxTaxPercentage * 100)) {
      return __('dashboard.sections.compraClick.form.invalidTaxAmount', { maxTax: maxTaxPercentage * 100 });
    }
    return undefined;
  };

  const checkExpirationDate = (value) => {
    const now = moment().tz(countryTimezone).format(expirationDateFormat);
    if (moment(value, expirationDateFormat).diff(moment(now, expirationDateFormat)) < 0) {
      setExpirationDateSmallText(__('dashboard.sections.compraClick.form.invalidExpirationDate'));
    } else {
      setExpirationDateSmallText('');
    }
  };

  const calculateTotal = (subtotal = 0, percentage = 0) => {
    let var1 = subtotal + subtotal * (percentage / 100)
    return var1.toFixed(2);
  };

  const calculateAmount = (value) => {
    setAmount(value?.floatValue);
    setTotal(calculateTotal(value?.floatValue, taxes))
  };

  const calculateTaxes = (value) => {
    setTaxes(value?.floatValue);
    setTotal(calculateTotal(amount, value?.floatValue));
  };

  return (
    <div>
      <DeleteProductModal
        buttonId={buttonData?.id}
        deleteSuccess={() => onSuccess()}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        subsidiaryId={subsidiaryId}
        token={token}
      />
      <APIRequest
        resource={resources[action]}
        onSuccess={() => onSuccess()}
        onFailure={() => setIsFetching(false)}
      >
        {request => {
          return (
            <FormPanel
              className='panel-flex'
              values={buttonData}
              onSubmit={formValues => {
                setIsFetching(true);
                const values = { ...formValues, buttonToken: buttonData?.token }
                request.fetch({ values, image, token, subsidiaryId, showTaxes })
              }}>
              <div>
                <ImageInput
                  field='image'
                  name='image'
                  data={image}
                  setFile={(file) => setImage(file)}
                  showColorList={false}
                  disabled={buttonData?.token && !fields.image.edit}
                  validate={required()}
                  errorMessageAlert={__('dashboard.sections.compraClick.imageError', { maxSize: 5 })}
                  help={__('dashboard.sections.compraClick.imageHelp', { maxSize: 5 })}
                  maxSize={5e+6}
                />

                <Text
                  validateOnBlur
                  validate={required()}
                  field='description'
                  type='text'
                  name='description'
                  placeholder={__('dashboard.sections.compraClick.form.name')}
                  label={__('dashboard.sections.compraClick.form.name')}
                  disabled={buttonData?.token && !fields.name.edit}
                  initialValue={data.description}
                />

                <Amount
                  validate={validPrice()}
                  type='amount'
                  field='amount'
                  name='amount'
                  placeholder={__('dashboard.sections.compraClick.form.price')}
                  label={__('dashboard.sections.compraClick.form.price')}
                  disabled={buttonData?.token && !fields.amount.edit}
                  currencies={currencies}
                  onValueChange={(value) => calculateAmount(value)}
                  defaultValue={buttonData?.amount}
                  initialValue={buttonData?.amount}
                />

                {showTaxes && (
                  <div>
                    <Amount
                      type='percentage'
                      field='taxes'
                      placeholder={__('dashboard.sections.compraClick.form.taxes')}
                      label={__('dashboard.sections.compraClick.form.taxes')}
                      disabled={buttonData?.token && !fields.taxes.edit}
                      validate={(value) => validateTaxAmount(value)}
                      onValueChange={(value) => calculateTaxes(value)}
                      defaultValue={buttonData?.taxes}
                      initialValue={buttonData?.taxes}
                    />
                    <label className=''>{__('dashboard.sections.compraClick.form.total')}</label>
                    <input
                      name='total'
                      className={classnames({
                        'form-control': true,
                      })}
                      disabled={true}
                      value={data?.amount || total}
                    />
                    <br />
                  </div>
                )}

                <div className='switch-input'>
                  <div>
                    <h5>{__('dashboard.sections.compraClick.stockLabel')}</h5>
                    {checked && (
                      <p>
                        {__('dashboard.sections.compraClick.infinityStock')}
                      </p>
                    )}
                    {!checked && (
                      <p>
                        {__('dashboard.sections.compraClick.finityStock')}
                      </p>
                    )}
                  </div>

                  <Toggle
                    checked={checked}
                    disabled={buttonData?.token && !fields.stock.edit}
                    onChange={e => setChecked(e.target.checked)} />
                </div>
                {checked && (
                  <Text
                    validateOnBlur
                    field='maxElements'
                    type='number'
                    placeholder={__('dashboard.sections.compraClick.form.quantity')}
                    label={__('dashboard.sections.compraClick.form.quantity')}
                    validations={validations({ required: 1, integer: 1, positiveInteger: 1, maxValue: 99999 })}
                    disabled={buttonData?.token && !fields.stock.edit}
                    initialValue={buttonData?.maxElements}
                  />
                )}

                <Select
                  validateOnChange
                  validations={required()}
                  defaultValue={languageOptions.filter(language => language.value === data.language)}
                  field='language'
                  options={languageOptions}
                  label={__('dashboard.sections.compraClick.form.language')}
                  placeholder={__('dashboard.sections.compraClick.form.language')}
                  isDisabled={buttonData?.token && !fields.language.edit}
                />

                <File
                  validate={(value) => validateTermsAndConditions(value)}
                  field='file'
                  validateOnChange
                  label={__('dashboard.sections.compraClick.termsAndConditions')}
                  loadFile={sendFile}
                  url={!!buttonData?.file}
                  disabled={buttonData?.token && !fields.terms.edit}
                  initialValue={buttonData?.file}
                />

                <TextArea
                  value={buttonData?.observations}
                  field='observations'
                  label={__('dashboard.sections.compraClick.observations')}
                  disabled={buttonData?.token && !fields.observations.edit}
                  maxLength={250}
                  initialValue={data.observations}
                />

                <DateTime
                  onChange={(value) => checkExpirationDate(value)}
                  smallText={expirationDateSmallText}
                  fieldValue={buttonData?.expiredAt}
                  field='expiredAt'
                  placeholder={__('dashboard.sections.compraClick.form.expirationDate')}
                  label={__('dashboard.sections.compraClick.form.expirationDate')}
                  disabled={buttonData?.token && !fields.expirationDate.edit}
                  initialValue={data.expiredAt?.date && moment(data.expiredAt?.date).format(expirationDateFormat)}
                />

                {(request && request.error) && (
                  <div className='request-error'>{request.error}</div>
                )}
              </div>
              <Footer
                buttonId={buttonData?.id}
                touched={false}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
                showDeleteModal={setShowDeleteModal} />
            </FormPanel>
          )
        }}
      </APIRequest>
    </div>
  );
};
