import React from "react";
import "../../../../styles/newStyles/ButtonLoader.scss"
import Loader from "components/Loader";

const LinkLoader = (props) => {
    return (
        <div>
            <button style= {{position: "relative"}} className="link-loader" type="button" onClick={props.onClick} disabled={props.isLoading}>
                {props.isLoading ? (<Loader />)
                 : (props.text)
                }
            </button>
        </div>
    )
}

export default LinkLoader;