import React, { useEffect, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";

export const ToastComponent = ({ error, success }) => {
  const notifyCallback = useCallback(
    () => toast.error(error, { theme: "colored" }),
    [error],
    toast.success(success, { theme: "colored" }),
    [success]
  );

  useEffect(() => {
    if (error) notifyCallback(error);
    return () => toast.dismiss();
  }, [error, notifyCallback]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={10000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={true}
      draggable={false}
      pauseOnHover={false}
      limit={1}
    />
  );
};
