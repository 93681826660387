/**
 * Created by rodrigo on 6/17/17.
 */

import { combineReducers } from 'redux'
import { ACTIONS } from './constants'

export default combineReducers({

  isFetching: (state = false, action) => {
    switch(action.type) {
      case ACTIONS.REQUEST_START:
        return true
      case ACTIONS.REQUEST_SUCCESS:
      case ACTIONS.REQUEST_CACHED:
      case ACTIONS.REQUEST_FAILURE:
      case ACTIONS.REQUEST_CANCELLED:
        return false
      default:
        return state
    }
  },

  token: (state = '', action) => {
    switch(action.type) {
      case ACTIONS.SET_TOKEN:
        return action.token || state;
      default:
        return state;
    }
  }
})
