import React from 'react';
import { connect } from 'react-redux';
import { hasIn, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { fetchResource } from './actions';
class APIQuery extends React.Component {

  constructor(props) {
    super(props);
    this.data = [];
    this.filters = props.filters || {};
    this.page = 0;
    this.pageSize = props.pageSize || 25;
    this.state = {
      data: [],
      error: false,
      filters: {},
      hasMore: true,
      isFetching: true,
    };
  }

  setFilters(filters) {
    this.filters = filters;
    this.setState({ filters });
    this.reload();
  }

  setData(data) {
    this.data = data;
    this.setState({ data });
  }

  reload(payload) {
    this.page = 0;
    this.setData([]);
    this.fetch(payload);
  }

  receivedData(data) {
    if (data && data.length) {
      this.page += 1;
      this.setData([...this.data, ...data]);
    }
    if (data) {
      this.setState({
        error: false,
        isFetching: false,
        hasMore: data && (data.length >= this.pageSize)
      });
    }
  }

  fetchMore() {
    if (!this.state.isFetching && this.state.hasMore) {
      this.fetch();
    }
  }

  fetch(others = {}) {
    const payload = {
      ...this.filters,
      maxResults: this.pageSize,
      page: this.page,
      ...others
    };

    this.setState({ isFetching: true });

    if (this.currentFetch) {
      this.currentFetch.cancel();
    }

    this.currentFetch = fetchResource(this.props.resource, payload, this.props.api)
      .onSuccess(data => {
        this.receivedData(data)
        this.props.onSuccess && this.props.onSuccess(data)
      })
      .onFailure((error, hash) => {

        if(error.response?.status === 401) {
          this.props.dispatch({
            type: 'dashboard/SET_DASHBOARD_ERROR_MESSAGE',
            payload: { message: 'Sesión expirada' }
          })
          this.props.history.push('/logout');
        }

        const responseContent = error.response?.data;
        const jsonApiErrorMessage = get(responseContent, 'errors[0].detail');
        const legacyErrorMessage = get(responseContent, 'response.message');
        const message = jsonApiErrorMessage || legacyErrorMessage || error.message;

        this.setState({ isFetching:false, error: message })
        this.props.onFailure && this.props.onFailure(message)
      })
      .onFailure(error => {
        let message = hasIn(error, 'message') ? error.message : error;

        if (hasIn(error, 'response')) {

          if (error.response.status === 401) {
            this.props.dispatch({ type: 'dashboard/SET_DASHBOARD_ERROR_MESSAGE', payload: { message: 'Sesión expirada' } });
            this.props.history.push("/logout");
          }

          if (hasIn(error.response.data, 'errors[0].detail')) {
            const jsonApiErrorMessage = get(error.response.data, 'errors[0].detail') && message;
            message = jsonApiErrorMessage ? jsonApiErrorMessage : message;
          }

          if (hasIn(error.response.data, 'response.message')) {
            const legacyErrorMessage = get(error.response.data, 'response.message');
            message = legacyErrorMessage ? legacyErrorMessage : message;
          }

        }

        this.setState({ isFetching: false, error: message });
        this.props.onFailure && this.props.onFailure(message);
      });

    return this.currentFetch;
  }

  cancelRequest() {
    if (this.currentFetch) {
      this.currentFetch.cancel();
      this.currentFetch = null;
    }
  }

  componentWillUnmount() {
    this.cancelRequest();
  }

  render() {

    const props = {
      ...this.props,
      ...this.state,
      setFilters: filters => this.setFilters(filters),
      reload: (payload) => this.reload(payload),
      fetchMore: () => this.fetchMore(),
      cancel: () => this.cancelRequest()
    };

    const { children, ...rest } = props;
    return (typeof children === 'function') ? children(rest) : children;
  }
}

const ConnectedAPIQuery = connect(
  ({ api }) => ({
    api: {
      token: api.token,
      endpoint: api.endpoint,
    }
  })
)(APIQuery)


export default withRouter(ConnectedAPIQuery);