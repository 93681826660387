
import React from 'react'
import PropTypes from 'prop-types'

class DashboardSectionTab extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
    component: PropTypes.any
  }

  getTitle() {
    const { component, title } = this.props
    return title || (component && component.getTitle && component.getTitle())
  }

  render() {
    const { component, children } = this.props
    return component ? React.createElement(component) : children || null
  }
}

export default DashboardSectionTab