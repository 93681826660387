import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { DateRangeInput } from 'components/forms';
import { Select } from 'components/informed';
import __ from 'utils/i18n';
import { setFilters } from '../../actions';
import { Col, Row } from 'reactstrap';
import { SwitchInput } from '../SwitchInput';
import { PermissionsContext } from 'contexts/permissionsContext';

export const StatsFilters = ({ allowCompare }) => {
  const dispatch = useDispatch();
  const { featureFlags } = useContext(PermissionsContext);

  const date = {
    startDate: moment(useSelector(state => state.dashboard?.stats?.filters?.date?.startDate)),
    endDate: moment(useSelector(state => state.dashboard?.stats?.filters?.date?.endDate))
  }

  const comparativeDate = {
    startDate: moment(useSelector(state => state.dashboard?.stats?.filters?.comparativeDate?.startDate)),
    endDate: moment(useSelector(state => state.dashboard?.stats?.filters?.comparativeDate?.endDate))
  }

  const comparison = useSelector(state => state.dashboard?.stats?.filters?.comparison);
  const stateCurrencies = useSelector(state => state.login?.user?.currencies);

  const currencies = stateCurrencies
    ? stateCurrencies.map((item) => ({
      value: item.code,
      label: item.symbol
    }))
    : [];

  const stateFiltersCurrency = useSelector(state => state.dashboard?.stats?.filters?.currency)
  const stateUserCurrencyFormat = useSelector(state => state.login?.user?.currencyFormat?.CURRENCY_DEFAULT_CODE);

  const stateCurrenciesDefaultCurrency = stateFiltersCurrency || stateUserCurrencyFormat;

  const defaultCurrency = currencies.find((currency) => currency.value === stateCurrenciesDefaultCurrency);
  const shouldChangeCurrencyStats = featureFlags?.includes('multicurrency');

  const calculateComparative = ({ startDate, endDate }) => {
    return {
      startDate: startDate.clone().subtract(moment.duration(endDate.diff(startDate))),
      endDate: endDate.clone().subtract(moment.duration(endDate.diff(startDate) + 1))
    }
  }

  const setComparison = (value) => {
    dispatch(setFilters({
      comparison: value,
      comparativeDate: calculateComparative(date),
    }));
  }

  const changeDate = (value) => {
    dispatch(setFilters({
      date: value,
      comparativeDate: calculateComparative(value),
    }));
  }

  const changeCurrency = (value) => {
    dispatch(setFilters({
      currency: value,
    }));
  }

  const changeComparativeDate = (value) => {
    dispatch(setFilters({
      comparativeDate: value,
    }));
  }

  return (
    <div className="currency-header">
      <Row>
        <Col md={10} xs={12}>
          <div className="dashboard-filters stats-section-filters">
            <DateRangeInput
              past
              value={date}
              onChange={changeDate.bind(this)}
            />
            {allowCompare && (
              <div className="stats-filters-compare-switch">
                <SwitchInput
                  label={__("dashboard.sections.stats.compare")}
                  value={comparison}
                  onChange={(value) => setComparison(value)}
                />
              </div>
            )}
            {allowCompare && comparison && (
              <DateRangeInput
                past
                value={comparativeDate}
                onChange={changeComparativeDate.bind(this)}
              />
            )}
          </div>
        </Col>
        <Col md={2} xs={12} className="align-right">
          {(shouldChangeCurrencyStats && currencies.length) &&
            <Select
              className="currency-header"
              field="currency"
              options={currencies}
              defaultValue={defaultCurrency}
              onChange={(value) => {
                changeCurrency(value)
              }}
            />
          }
        </Col>
      </Row>
    </div>
  );
}