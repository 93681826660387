
export const handleFilters = (filters, action) => {
  switch (action.type) {
    case 'page':
      return { ...filters, page: action.payload.page };
    case 'page_size':
      return { ...filters, page: 1, page_size: action.payload.page_size };
    case 'status':
      return { ...filters, page: 1, order_status: action.payload.status };
    case 'date':
      return { ...filters, page: 1, date_from: action.payload.date_from, date_to: action.payload.date_to };
    case 'order_number':
      return { ...filters, page: 1, order_number: action.payload.order_number };
    case 'remove':
      action.payload.keys && action.payload.keys.map((key) => (delete filters[key]));
      return { ...filters };
    default:
      return;
  }
}