import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';

export const Contact = ({ sale, config }) => {
    const { contactData } = config;
    const [contactDataElements, setContactDataElements] = useState([]);

    useEffect(() => {
        let contactDataFilter = contactData.filter(customerInformationItem => customerInformationItem.method(sale) && customerInformationItem.method(sale) !== '');
        setContactDataElements(contactDataFilter);
        // eslint-disable-next-line
    }, [])

    return (
      <>
        <Table className='table-contact no-border bold-td-right'>
          <thead>
            <tr>
              <th className='contact-data-th'>{__('dashboard.sections.sales.customerInformation.title')}</th>
            </tr>
          </thead>
          {contactDataElements.length > 0 ? (
            <tbody>
              {contactDataElements.map((customerInformationItem, index) =>
                <tr key={index}>
                  <td style={{ width: '100%', overflow: 'unset', textOverflow: 'unset' }}>
                    <div className="table-contact-info">
                      <span className="table-contact-info-label">{__(`dashboard.sections.sales.customerInformation.${customerInformationItem.label}`)}</span>
                      <span className="table-contact-info-value">{customerInformationItem.method(sale)}</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          ): (
            <span>{__('dashboard.sections.sales.customerInformation.noData')}</span>
          )}
        </Table>
      </>
    );
};
