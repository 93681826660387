import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Navbar as NavbarComponent,
  Nav,
} from "reactstrap";
import { useSelector } from "react-redux";
import __ from "utils/i18n";
import { getConfig } from "config/index";
import { HeaderMenuItems } from "../HeaderMenuItems";
import { PaymentLink } from "../PaymentLink";
import { SectionsLinks } from "../SectionsLinks";
import { PermissionsContext } from "contexts/permissionsContext";
import { NavHelpPhone } from "../NavHelpPhone/NavHelpPhone";

export const Navbar = ({ sections, brand, openModal, closeModal }) => {
  // useState
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const [userCollapsed, setUserCollapsed] = useState(true);

  // useSelector
  const paymentLinkConditions = useSelector(
    (state) => state.login.user.paymentLink
  );
  const accountName = useSelector((state) => state.login.user.accountName);

  let paymentLinkValidationPassed = true;
  if (
    getConfig("customConfig.paymentLink.hideWhenDisabled") &&
    paymentLinkConditions &&
    !paymentLinkConditions.enabled
  ) {
    paymentLinkValidationPassed = false;
  }

  const isNavHelpPhone = getConfig('customConfig.showNavHelpPhone');

  // useContext
  const { userPermissions, featureFlags } = useContext(PermissionsContext);

  const isPaymentLinkEnabled =
    featureFlags?.includes("payment_link_section") &&
    userPermissions?.includes("payment_link_section");

  const toggleUser = () => {
    setUserCollapsed(!userCollapsed);
  };

  const toggleNavbar = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  const hideNavbar = () => {
    setMenuCollapsed(true);
  };

  return (
    <NavbarComponent>
      <div className="navbar-toggler" data-testid="menu-icon" onClick={() => toggleNavbar()}>
        <i className="mdi mdi-menu" />
      </div>
      <div className="navbar-brand">
        <Link to="/">{brand}</Link>
      </div>
      <div className="navbar-menu">
        <Collapse isOpen={!menuCollapsed}>
          <Nav navbar onClick={() => hideNavbar()}>
            <SectionsLinks sections={sections} />
            {isPaymentLinkEnabled && paymentLinkValidationPassed && (
              <PaymentLink />
            )}
          </Nav>
        </Collapse>
      </div>
      <div className="navbar-menu-right">
        <Nav>
          {isNavHelpPhone && <NavHelpPhone />}
          <Dropdown isOpen={!userCollapsed} toggle={() => toggleUser()}>
            <DropdownToggle
              nav
              className="d-flex flex-row align-items-center text-center"
            >
              {getConfig("customConfig.showAccountLabel") && (
                <span className="lblAccount">
                  {__("dashboard.greetings")} {accountName}
                </span>
              )}
              <i className="mdi mdi-account-circle" />
            </DropdownToggle>

            <DropdownMenu persist={true} right>
              <HeaderMenuItems
                openModal={openModal}
                closeModal={closeModal}
                showMenu={!userCollapsed}
              />
              <Link to="/logout">
                <DropdownItem className="nav-menu-item">
                  <i className="mdi mdi-18px mdi-lock-outline" />
                  <span>{__("dashboard.components.logout")}</span>
                </DropdownItem>
              </Link>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </div>
    </NavbarComponent>
  );
};
