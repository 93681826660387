import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const TransparentButton = ({ color, children, ...rest }) => (
  <Button color={color} className="btn-as-link" {...rest}>{ children }</Button>
)

TransparentButton.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
}

TransparentButton.defaultProps = {
  color: 'primary',
}

export default TransparentButton;
