import React from "react";
import PropTypes from "prop-types";

import MediaQuery from "react-responsive";

import { withRouter } from "react-router-dom";
import DashboardSectionTabs from "./DashboardSectionTabs";
import DashboardSliderTabs from "../DashboardSliderTabs";
import { WithholdingTaxDiscovery } from "../../sections/Sales/WithholdingTaxDiscovery";
class DashboardTabbedSection extends React.Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  };

  state = {
    index: 0,
    render: null,
  };

  componentWillMount() {
    this.setActiveTab(this.getActiveTabIndex());
  }

  getTabs() {
    return React.Children.toArray(this.props.children);
  }

  matchPath(path) {
    const { route } = this.context.router;
    const to =
      path && path !== "/" ? route.match.url + "/" + path : route.match.url;
    return route.location.pathname.startsWith(to);
  }

  getActiveTabIndex() {
    const tabs = this.getTabs();
    const activeTab = tabs.findIndex((tab) => this.matchPath(tab.props.path));
    if (activeTab < 0) {
      const defaultTab = tabs.findIndex((tab) => tab.props.default);
      return defaultTab < 0 ? 0 : defaultTab;
    }
    return activeTab;
  }

  setActiveTab(index) {
    const tabs = this.getTabs();
    const activeTab = tabs[index];
    const { history, route } = this.context.router;
    const {
      location: { pathname },
    } = this.props;

    const lastParam = pathname.split("/").pop();

    const to =
      activeTab.props.path && activeTab.props.path !== "/"
        ? route.match.url + "/" + activeTab.props.path
        : route.match.url;

    if (!Number(lastParam)) {
      history.push(to);
    }

    this.setState({ index, render: activeTab });
  }

  render() {
    const tabs = this.getTabs();
    const { index, render } = this.state;

    return (
      <div className="dashboard-section">
        <div className="dashboard-section-header">
          <MediaQuery query="(max-width: 555px)">
            <DashboardSliderTabs
              defaultTab={index}
              onChange={(index) => this.setActiveTab(index)}
              tabs={tabs.map((tab) => ({ title: tab.props.title }))}
            />
          </MediaQuery>
          <MediaQuery query="(min-width: 556px)">
            <DashboardSectionTabs
              defaultTab={index}
              onChange={(index) => this.setActiveTab(index)}
              tabs={tabs.map((tab) => ({ title: tab.props.title }))}
            />
          </MediaQuery>
        </div>

        <div className="dashboard-section-body">
          <div className="dashboard-section-content">{render}</div>
        </div>
        <WithholdingTaxDiscovery />
      </div>
    );
  }
}


export default withRouter(DashboardTabbedSection);
