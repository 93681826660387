import React from 'react';
import { Row } from 'reactstrap';
import { Amounts } from './Amounts';
import { BatchInfo } from './BatchInfo';
import { Summary } from './Summary';
import { Taxes } from './Taxes';
import { Transactions } from './Transactions';

export const BatchClosePanel = ( batchData ) => {

  const sections = [ Summary, Transactions, Taxes, Amounts, BatchInfo ];

  return(
    <Row className="transaction">
      <div className="panel-flex">
        <div>
          {sections.map((Section, i) => (
            <Section key={i} batch={batchData.data} />
          ))}
        </div>
      </div>
    </Row>
  );
};
