import { NumberField } from 'components/forms';
import React from 'react'
import __ from "utils/i18n";

export const MaxUnitPerProduct = ({ isFormEnabled, values, maxUnitPerProductError, setMaxUnitPerProductError }) => {

    return (
        <>
            <div className="ecommerceForm-panel">
                <div className="ecommerceForm-min-panel">
                    <div className="ecommerceForm-mainTitle-container">
                        <label className="ecommerceForm-mainTitle ecommerceForm-primary">
                            {__("store.form.maxUnitPerProductTitle")}
                        </label>
                        <label>
                            {__("store.form.maxUnitPerProductInfo")}
                        </label>
                    </div>
                    <div className="ecommerceForm-maxUnitPerProduct-field-container">
                        <div className="ecommerceForm-maxUnitPerProduct-labels-container">
                            <label
                                htmlFor={"maxUnitPerProduct"}
                                className="ecommerceForm-maxUnitPerProduct-label"
                            >
                                {__("store.form.maxUnitPerProductLabelStart")}
                            </label>
                            <div className="ecommerceForm-maxUnitPerProduct-field">
                                <NumberField
                                    id={"maxUnitPerProduct"}
                                    name={"maxUnitPerProduct"}
                                    className={`${maxUnitPerProductError &&
                                        "ecommerceForm-maxUnitPerProduct-input-error"
                                        } ecommerceForm-maxUnitPerProduct-input`}
                                    placeholder={__(
                                        "store.form.maxProductPlaceholder"
                                    )}
                                    disabled={!isFormEnabled(values.isEnabled)}
                                />
                            </div>
                            <label
                                htmlFor={"maxUnitPerProduct"}
                                className="ecommerceForm-maxUnitPerProduct-label"
                            >
                                {__("store.form.maxUnitPerProductLabelEnd")}
                            </label>
                        </div>
                        <div className="ecommerceForm-maxUnitPerProduct-error-container">
                            <span>{maxUnitPerProductError}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}