
import { ACTIONS } from './constants'

// Actions

export const removeDiscovery = (discoveryId) => {
  return ({
    type: ACTIONS.REMOVE_DISCOVERY,
    discoveryId
  });
}
