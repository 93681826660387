import React, { useState } from 'react';
import { get } from 'lodash';
import {
  Row,
  Col,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import { fetchResource } from 'api-provider';
import __ from 'utils/i18n';
import { uploadProducts, downloadExportFile } from '../../../_shared/resources';
import ButtonLoader from 'containers/Dashboard/components/ButtonLoader/ButtonLoader';
import { useQuery, useMutation } from '@tanstack/react-query';
import { OneLineWarning } from 'containers/Dashboard/components/OneLineWarning';

const maxUploadSize = 1e6;
const supportedFileType = 'csv';

export const Step1 = ({ token, subsidiaryId, goStep, onHide }) => {
  const [errorMessage, setErrorMessage] = useState();

  const handleSuccess = (response) => {
    if (
      get(response, 'success') === 'OK' &&
      (response.data.createQuantity || response.data.updateQuantity)
    ) {
      goStep('importResult', response.data);
    } else {
      goStep('importError', response);
    }
  };

  const downloadExportFileQuery = useQuery({
    queryKey: ['downloadExportFile'],
    queryFn: () => downloadExportFile(token, subsidiaryId),
    enabled: false
  });
  
  const uploadProductsMutation = useMutation({
    mutationFn: (file) => fetchResource(uploadProducts(file, token, subsidiaryId)),
    onSuccess: (response) => handleSuccess(response),
    onError: (error) => goStep('importError', error)
  });

  return (
    <div>
      <button
        type='button'
        class='close hidden-modal'
        aria-label='Close'
        onClick={onHide}
      >
        <span aria-hidden='true'>×</span>
      </button>
      <ModalHeader>
        {`${__('dashboard.sections.catalog.productsImport.importCatalog')} ${__(
          'dashboard.sections.catalog.productsImport.step1'
        )}`}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md='12'>
            <h4 className='import-modal__subtitle'>
              <i className='mdi mdi-arrow-down' />
              {__(
                'dashboard.sections.catalog.productsImport.downloadSpreadsheet'
              )}
            </h4>
            <p>
              {__('dashboard.sections.catalog.productsImport.uploadFileCsv')}
            </p>
              <ButtonLoader
                text={__("dashboard.sections.catalog.productsImport.downloadSpreadsheet")}
                isLoading={downloadExportFileQuery.isFetching}
                color='primary' 
                size='sm' className='pull-right' 
                onClick={downloadExportFileQuery.refetch} />
          </Col>
        </Row>
        <Row>
          <Col md='12'>
            <h4 className='import-modal__subtitle'>
              <i className='mdi mdi-arrow-up' />
              {__('dashboard.sections.catalog.productsImport.uploadFile')}
            </h4>
            <div className='file-field-dropzone'>
              {uploadProductsMutation.isLoading ? (
                <div>
                  {__('dashboard.sections.catalog.productsImport.uploading')}
                </div>
              ) : (
                <Dropzone
                  className='no-dropzone'
                  activeClassName='dropzoneActive'
                  rejectClassName='dropzoneReject'
                  accept={`.${supportedFileType}`}
                  maxSize={maxUploadSize}
                  onDrop={(files, errors) => {
                    setErrorMessage(null);
                    if (files.length === 1) {
                      uploadProductsMutation.mutate(files[0]);
                    } else if (errors.length === 1 && errors[0].size > maxUploadSize) {
                      setErrorMessage(__('dashboard.sections.catalog.productsImport.possibleImportErrors.maxUploadSize'));
                    } else if (errors.length === 1 && !errors[0].type.includes(supportedFileType)) {
                      setErrorMessage(__('dashboard.sections.catalog.productsImport.possibleImportErrors.invalidUploadType'));
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <i className='mdi mdi-image' />
                      <p>
                        {__(
                          'dashboard.sections.catalog.productsImport.dragFile'
                        )}
                        <br />
                        {__('dashboard.sections.catalog.productsImport.click')}
                      </p>
                    </div>
                  )}
                </Dropzone>
              )}
            </div>
            { errorMessage &&
              <OneLineWarning className={'one-line-warning-catalog-import'} message={errorMessage} />
            }
          </Col>
        </Row>
      </ModalBody>
      <div className='modal-separator'>
        <hr />
      </div>
      <ModalFooter>
        <Button onClick={onHide}>
          {__('dashboard.sections.catalog.productsImport.cancel')}
        </Button>
      </ModalFooter>
    </div>
  );
};
