export const getUsers = (token) => (payload) => ({
  endpoint: 'users',
  method: 'GET',
  headers: {
    'X-Authentication-Token': token,
  },
  cache: true,
  force: payload && payload.force,
  formData: payload,
  parseResponse: (response) => response.data.data,
});

export const deleteUser = (id, token) => ({
  endpoint: `users/${id}`,
  method: 'DELETE',
  headers: {
    'X-Authentication-Token': token,
  },
  formData: {
    id,
    token,
    channel: 'web',
    deviceId: 'web',
    deviceModel: 'web',
    deviceOS: 'web',
  },
});
