const headers = (token, roleUser = null) => {
  var headerObject = {
    'X-Authentication-Token': token,
  };

  if (roleUser) {
    headerObject['X-USER-ROLE'] = roleUser.toUpperCase();
  }

  return headerObject;
};

export const getOrders = ({ token, roleUser, accountId, filters }) => {

  return {
    endpoint: `/api/v1/orders/account/${accountId}`,
    method: 'GET',
    headers: headers(token, roleUser),
    cache: false,
    proxy: false,
    params: {...filters}
  }
}

export const getOrder = ({ id, token, roleUser }) => {

  return {
    endpoint: `/api/v1/orders/${id}`,
    method: 'GET',
    headers: headers(token, roleUser),
    cache: false,
    proxy: false,
  }
}

export const completeOrders = ({ list, token, roleUser, accountId }) => {

  return {
    headers: headers(token, roleUser),
    proxy: false,
    endpoint: `/api/v1/order/account/${accountId}/complete`,
    method: 'POST',
    cache: false,
    data: {
      orders: list
    },
  }
}
