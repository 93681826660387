
import { ACTIONS } from './constants';

const compraClickReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.SET_COMPRA_CLICK_PRODUCT:
      return { ...action.compraClick }
    default:
      return state
  }
};

export default compraClickReducer;
