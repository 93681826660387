import moment from 'moment';
import numeral from 'numeral';
import i18next from 'i18next';
import { assignIn } from 'lodash';
import currencies from '../data/currencies';
import datarangeLocales from '../locales/daterange';
import BBParser from './BBParser';
import { getConfig } from '../config';
import locales from '../locales';
import 'moment-timezone';

const numberFormats = {
  'short': '0a',
  'rounded': '0,0',
  'decimal': '0,0.00',
  'auto': '0[.]00a',
}

export const translate = (key, payload) => {
  return i18next.t(key, payload)
}

export const i18n = assignIn(translate, i18next, {

  initialize: (defaultLocale) => {

    i18next
      .init({
        lng: localStorage.getItem('locale') || defaultLocale,
        defaultNS: 'translations',
        resources: locales
      })

    moment.locale(localStorage.getItem('locale') || defaultLocale);

    i18next.on('languageChanged', (lng) => {
      localStorage.setItem('locale', lng);
      moment.locale(lng);
      numeral.locale(lng);
    })

    i18next.changeLanguage(defaultLocale);
  },

  addResources(locale, ns, catalog, overwrite = true) {
    i18next.addResourceBundle(locale, ns, catalog, true, overwrite);
  },

  changeLanguage: (locale) => {
    i18next.changeLanguage(locale);
  },

  getLanguage: () => i18next.language,

  t: (key, payload) => {
    return i18next.t(key, payload);
  },

  n: (key, payload) => {
    return i18next.t(key, payload);
  },

  bb: (key, payload) => {
    return BBParser.toReact(i18next.t(key, payload));
  },

  number: (value, format = 'rounded') => {
    const numberFormat = numberFormats[format] || format || '0,0';
    return value && numeral(value).format(numberFormat);
  },

  revertNumberFormat: (value) => {
    return numeral(value).value();
  },

  unit: (value, unit, numberFormat) => {
    const formatted = i18n.number(value || 0, numberFormat);
    const quantity = value === 1 ? 'one' : 'other';
    return i18n.t(`units.${unit}.${quantity}`, { count: formatted });
  },

  currency: (amount, currency, format = 'decimal', emptyCharacter = '-', customMoneySymbol= null) => {
    const preciseAmounts = true;
    const match = currencies.find(c => c.number === currency);
    const decimals = i18n.resolveDecimals(match);
    
    const formatter = ({
      'short': '0a',
      'rounded': '0,0',
      'decimal': `0,${(0).toFixed(decimals)}`,
      'auto': (!preciseAmounts && amount > 1000) ? `0[.]0a` : `0,${(0).toFixed(decimals)}`,
    })[format] || format;
    
    const formattedNumber = (amount === false || amount === undefined) ? emptyCharacter : i18n.number(amount, formatter) + ' ';

    if(customMoneySymbol){
      return `${customMoneySymbol} ${formattedNumber}`;
    }
    if (match && match.symbol) {
      return `${match.symbol} ${formattedNumber}`;
    }
    if (match) {
      return `${formattedNumber}${match.code}`;
    }
    return formattedNumber;
  },
  
  resolveDecimals: (match) => {
    return (match && match.digits !== undefined) ? match.digits : 2;
  },

  currencyName: (currency) => {
    const currencyConfig = getConfig('customConfig.currencies', currencies);
    const match = currencyConfig.find(c => parseInt(c.value, 10) === parseInt(currency, 10) || c.code === currency);
    return match.label;
  },

  date: (value, format, localTimezone) => {
    if (localTimezone) {
      return moment(value).tz(localTimezone).format(format || 'L HH:mm');
    } else {
      return moment(value).utc(false).format(format || 'L HH:mm');
    }
  },

  stringToDate: (value, format) => {
    return moment(value).format(format || 'L HH:mm');
  },

  daterange: (startDate, endDate) => {
    const locale = datarangeLocales[i18next.language];
    const start = moment(startDate).locale(i18next.language);
    const end = moment(endDate).locale(i18next.language);

    if (locale) {
      if (start.isSame(end, 'day'))
        return locale.day(start, end);
      if (start.isSame(end, 'month'))
        return locale.month(start, end);
      if (start.isSame(end, 'year'))
        return locale.year(start, end);
      return locale.other(start, end);
    }
    return `${start.format('ll')} - ${end.format('ll')}`;
  }
})
window.i18n = i18n;
export default i18n;
