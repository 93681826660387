import React from 'react'

const cardSources = {
  visa: "https://geopagos.s3.amazonaws.com/images/cards/small/visa.png",
  amex: "https://geopagos.s3.amazonaws.com/images/cards/small/amex.png",
  mastercard: "https://geopagos.s3.amazonaws.com/images/cards/small/mastercard.png",
  cash: "https://geopagos.s3.amazonaws.com/images/cards/small/efectivo.png",
  default: "https://geopagos.s3.amazonaws.com/images/cards/small/credito_generica.png"
}

const CardIcon = ({src}) => {
  const cardName = src.issuerName?.toLowerCase() || ''
  const cardSrc = src.issuerImageUrl || cardSources[cardName] || cardSources.cash
  return (
    <span className="card-icon">
      <img className="card-img" alt={cardName} src={cardSrc}/>
    </span>
  )
}

export default CardIcon
