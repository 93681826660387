import React from 'react'
import { TextField } from 'components/forms'
import DashboardAddressFields from 'containers/Dashboard/DashboardAddressFields';
import __ from "utils/i18n";

const MerchantForm = (props) => {
  const Address = DashboardAddressFields

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <TextField
            field="name"
            type="text"
            label={__('dashboard.sections.merchantSubsidiaries.form.name.label')}
            placeholder={__('dashboard.sections.merchantSubsidiaries.form.name.label')}
            help={__('dashboard.sections.merchantSubsidiaries.form.name.help')}
          />
        </div>
      </div>
      <Address values={props.values}/>
    </div>
  )
}

export default MerchantForm
