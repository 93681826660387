import React from 'react';
import DashboardTabbedSection from 'containers/Dashboard/components/dashboardSection/DashboardTabbedSection';
import DashboardSectionTab from 'containers/Dashboard/components/dashboardSection/DashboardSectionTab';
import { APIRequest } from 'api-provider';
import { StaffContent } from './components/StaffContent/StaffContent';
import { useSelector } from 'react-redux';
import { getStaff } from './resources';
import __ from 'utils/i18n';


export const Staff = () => {
  const { subsidiaryId } = useSelector(state => state.login.user);
  const { token } = useSelector(state => state.api);
  
  return (
    <DashboardTabbedSection>
        <DashboardSectionTab title={__("dashboard.sections.staffs.tabs")}>
            <APIRequest resource={getStaff(subsidiaryId, token)} fetch>
              {request => (
                <StaffContent
                  isFetching={request.isFetching}
                  data={request.response || []}
                  error={request.error}
                />
              )}
            </APIRequest>
        </DashboardSectionTab>
    </DashboardTabbedSection>
  );
}

