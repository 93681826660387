import React, { useState } from 'react';
import { StatusDropdownComponent } from './StatusDropdownComponent';
export const StatusDropdown = ({ handleStatus }) => {
  const [status, setStatus] = useState("");

  const checkStatus = (statusSelected) => {
    if (statusSelected !== status) handleStatus(statusSelected);
    setStatus(statusSelected);
  }

  return (
    <StatusDropdownComponent
      handleStatus={checkStatus}
    />
  )
}