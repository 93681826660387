
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import __ from "utils/i18n";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classNames from "classnames";

export class DashboardModal extends React.Component {
  render() {
    const {
      children,
      showModal,
      hideModal = () => false,
      modalClass,
      headerText,
      cancelText,
      submitText,
      submitColor,
      submitIcon,
      submitAction,
      submitDisabled,
      hideSeparator,
      showFooter,
      hideClose,
      size,
      showCancelButton = true,
    } = this.props;

    let modalClassNames = {
      "dashboard-modal": true,
    };

    if (modalClass) {
      modalClassNames = {
        ...modalClassNames,
        [modalClass]: true,
      };
    }

    const modalClasses = classNames(modalClassNames);

    const toggle = () => hideModal();

    return (
      <div>
        <Modal
          isOpen={showModal}
          toggle={toggle}
          className={modalClasses}
          zIndex={1000000}
          size={size}
        >
          <ModalHeader toggle={toggle}>{headerText || "Modal"}</ModalHeader>

          <ModalBody>{children}</ModalBody>

          {!hideSeparator && (
            <div className="modal-separator">
              {" "}
              <hr />{" "}
            </div>
          )}
          {showFooter && (
            <ModalFooter>
              {!hideClose && hideModal && showCancelButton && (
                <Button color="secondary" onClick={hideModal} className="payment-link-radius">
                  {cancelText || __("actions.close")}
                </Button>
              )}
              {submitAction && (
                <Button
                  color={submitColor || __("actions.succes")}
                  onClick={submitAction}
                  disabled={submitDisabled}
                  className="payment-link-radius"
                >
                  {submitIcon && <i className={submitIcon}></i>}{" "}
                  {submitText || __("actions.send")}
                </Button>
              )}
            </ModalFooter>
          )}
        </Modal>
      </div>
    );
  }
}

DashboardModal.defaultProps = {
  showFooter: true,
  size: "lg",
};
