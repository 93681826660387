import React from 'react'
import classNames from 'classnames'

class DashboardPanel extends React.Component {

  render() {
    const containerClasses = classNames({
      'dashboard-panel-container': true,
      'expanded': this.props.expanded
    })

    const outerClasses = classNames({
      'dashboard-panel-bg': true,
      'show': this.props.expanded
    })

    return (
      <div id="dashboard-panel-container" className={containerClasses}>
        <div id="dashboard-panel" className='dashboard-panel'>
          <div className="dashboard-panel__heading">
            {this.props.panelHeading} <button
              className="dashboard-panel__heading--close"
              onClick={this.props.hidePanel}><i className="mdi mdi-close mdi-24px"/></button>
          </div>
          <div className="dashboard-panel__content">
            { this.props.children }
          </div>
        </div>
        <div
          className={ outerClasses }
          onClick={ this.props.hidePanel }>
        </div>
      </div>
    )
  }
}

export default DashboardPanel
