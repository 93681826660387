import React from 'react';
import { CardChart } from '../_shared/CardChart';
import __ from 'utils/i18n';
import FlexTable from 'components/FlexTable';
import { getColumns } from './columns';

export const ProdcutCard = ({ title, item = {} }) => {
  

  const sortProducts = (a, b) => {
    return b.quantity - a.quantity;
  }

  const orderData = (data) => {
    if (!data) return [];
    const orderedProducts = data.sort((a, b) => sortProducts(a, b));
    return orderedProducts;
  }

  const orderedData = orderData(item.value);

  const columns = getColumns();

  return (
    <CardChart title={__(title)}>
      <FlexTable
        data={orderedData}
        columns={columns}
        showHeader={false}
      />
    </CardChart>
  );
}