import React from "react";
import { Button } from "reactstrap";
import { SimpleForm, TextField } from "components/forms";
import { validateFields } from "components/forms/validators";
import __ from "utils/i18n";

const RequestEmailPassword = ({ onSubmit, success, errorMessage }) => {

  return (
    <SimpleForm
      onSubmit={(values) => onSubmit(values)}
      validateOnSubmit={true}
      validate={validateFields([
        {
          field: "email",
          isEmail: 1,
        },
      ])}
    >
      {(formApi) => (
          <>
            <h2 className="password-form-login-welcome">
              {__("resetPassword.restorePassword")}
            </h2>

            {success ? (
              <div className="password-form-text-center">
                <h4>{__.bb("resetPassword.verifyYourEmail")}</h4>
              </div>
            ) : (
              <div>
                {errorMessage && (
                  <div className="password-form-error-message text-danger password-form-text-center">
                    {errorMessage}
                  </div>
                )}

                <TextField
                  name="email"
                  label={__("resetPassword.form.email.label")}
                  placeholder={__("resetPassword.form.email.placeholder")}
                />

                <div className="password-form-footer">
                  <Button
                    block
                    color="primary"
                    type="submit"
                    className="login-btn"
                  >
                    {__("resetPassword.resetPassword")}
                  </Button>
                </div>
              </div>
            )}
          </>
      )}
    </SimpleForm>
  );
};

export default RequestEmailPassword;
