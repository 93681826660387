import CardIcon from "components/CardIcon";
import getDescription from "./resources/getDescription";
import getTransactionStatus from "./resources/getTransactionStatus";
import { Badge } from "reactstrap";
import __ from 'utils/i18n';

export const SaleDescriptionCell = ({ original }) => {
  const status = getTransactionStatus(original);
  const description = getDescription(original);

  return (
    <span className='pull-content'>
      <span className='pull-left' style={{ lineHeight: '33px' }}>
        <CardIcon src={original.payment} />
        <span>{description}</span>
      </span>
      <span className='pull-right'>
        {status === 'annulled' && (
          <Badge color='default'>
            {__('dashboard.sections.sales.canceled')}
          </Badge>
        )}
        {status === 'refund' && (
          <Badge color='warning'>{__('dashboard.sections.sales.return')}</Badge>
        )}
        {status === 'reverted' && (
          <Badge color='warning'>{__('dashboard.sections.sales.reverted')}</Badge>
        )}
      </span>
    </span>
  );
};
