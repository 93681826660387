const accountTypes = [
  {
    value: '0',
    name: 'Cuenta corriente'
  },
  {
    value: '9',
    name: 'Chequera electrónica'
  },
  {
    value: '3',
    name: 'Cuenta RUT'
  },
  {
    value: '2',
    name: 'Cuenta de ahorro'
  },
  {
    value: '4',
    name: 'Cuenta vista'
  },
]

export default accountTypes;
