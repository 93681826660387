import React from 'react';
import __ from 'utils/i18n';
import StateBadge from 'components/StateBadge';
import { getConfig } from 'config';

const UserName = (userId) => ({ original }) => {
  const name = original.firstName;
  const surname = original.lastName;
  const fullname = surname ? `${name} ${surname}` : name;
  const iconClass = (userId === original.id) ? "account-circle" : "account";

  return (
    <span>
      <i className={`ft-icon mdi mdi-${iconClass}`} />
      <span className="user--name">{fullname || "-"}</span>
    </span>
  );
};

const UserStatus = ({ original }) => {
  const status = original.status || original.enableName;
  return status ? <StateBadge state={status} /> : "";
};

const UserSubsidiaries = ({ original }) => {
  if (original.subsidiaryName) {
    return original.subsidiaryName;
  } else {
    const subsidiaries =
      original.subsidiary.length === 1
        ? original.subsidiary[0].subsidiaryName
        : `${original.subsidiary.length} sucursales`;
    return <span className="user--subsidiaries">{subsidiaries}</span>;
  }
};

export const getUsersColumns = (userId, getNameColumns) => {
  const usersColumns = [];
  const configColumns = getConfig("tableColumns.usersColumns");
  
  const defaultColumns = {
    name: {
      Header: __('dashboard.sections.merchantUsers.name'),
      Cell: UserName(userId),
      showIcon: false,
      width: 350,
    },
    email: {
      Header: __('dashboard.sections.merchantUsers.email'),
      name: 'email',
      width: 300,
      maxWidth: 300,
    },
    role: {
      Header: __('dashboard.sections.merchantUsers.role'),
      name: getNameColumns(),
      width: 250,
      maxWidth: 250,
    },
    local: {
      Header: __('dashboard.sections.merchantUsers.local'),
      Cell: UserSubsidiaries,
      width: 300,
      maxWidth: 300,
    },
    state: {
      Header: __('dashboard.sections.merchantUsers.state'),
      Cell: UserStatus,
      width: 150,
      maxWidth: 150,
      align: 'right',
    },
  };

  configColumns.forEach((item) => usersColumns.push(defaultColumns[item]));

  return usersColumns;
};
