import React, { useState, useEffect } from "react";
import { PaginatorComponent } from "./PaginatorComponent";
import { PaginatorInfoComponent } from "./PaginatorInfoComponent";

export const Paginator = ({ links, meta, handlePagination }) => {
  const [pages, setPages] = useState([]);

  const { page: {
    currentPage = 1,
    from = 1,
    lastPage = 1,
    to,
    total
  } = null } = meta;

  const { next, prev } = links;

  const checkPagination = (page) => {
    if(page !== currentPage) handlePagination(page);
  }

  useEffect(() => {
    const pages = [];
    if (!meta || !meta.page) return pages;
    for (let index = currentPage > 2 ? currentPage - 2 : 1; index <= lastPage; index++) {
      if (pages.length < 5) {
        pages.push({
          number: index
        })
      }
    }
    setPages(pages);
  }, [currentPage, lastPage, meta]);

  return (
    <div className="paginatorContainer">
      <PaginatorComponent
        pages={pages}
        hasPrevious={prev}
        hasNext={next}
        last={lastPage}
        current={currentPage}
        handlePagination={checkPagination}
        />
      <PaginatorInfoComponent 
        from={from}
        to={to}
        total={total}
      />
    </div>
  )
}
