import React from 'react'
import Dropzone from 'react-dropzone'

import __ from 'utils/i18n'

export default class FileInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      file: null
    }
  }

  changeFile(newFile) {
    this.setState({
      file: newFile[0]
    })
  }

  onFileDrop(file) {
    const accepted = file.accepted
    const rejected = file.rejected

    if (accepted.length) {
      this.changeFile(accepted)
    }

    if (rejected.length) {
      alert(__('image.alert'))
    }
  }

  render() {

    return (
      <div>
        <Dropzone
          className="dropzone"
          activeClassName="dropzoneActive"
          rejectClassName="dropzoneReject"
          accept={this.props.accept}
          maxSize={1e+6}
          onDrop={(accepted, rejected) => {
            this.onFileDrop({ accepted, rejected })
          }}>
          <i className="mdi mdi-image"/>
          <p>{__('image.selectImage')}</p>
        </Dropzone>
      </div>
    )
  }
}
