import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import queryString from "query-string";
import { APIRequest } from "api-provider";
import { getUsers } from "../../_shared/resources";
import { getConfig } from "config";
import __ from "utils/i18n";
import { Field, Form, SelectField } from "components/forms";
import { CustomField } from "components/forms/fields";
import DateRangeInput from "components/forms/inputs/DateRangeInput";
import { backendUrl, tenantId } from "config/envConfig";
import ButtonLoader from "containers/Dashboard/components/ButtonLoader/ButtonLoader";
import { isValidArray } from "utils";

// TODO refactorizar componente para usar estos campos directamente
const options = getConfig('customConfig.sales.options');

export const TransactionsReportWidget = ({ filters, token }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const subsidiaries = useSelector(
    (state) => state.dashboard.sales.filters?.subsidiaries || filters?.subsidiaries
  );

  const optionsWithTranslation = isValidArray(options.type)
    ? options.type.map((elem) => ({ ...elem, label: __(elem.label) }))
    : [];

  const hasStatus = options.status.length > 0;
  const showFilterSubsidiariesWidget = getConfig(
    "customConfig.sales.showFilterSubsidiariesWidget"
  );

  const downloadReport = async (values) => {
    setErrorMessage();
    setIsLoading(true);
    const query = queryString.stringify({
      subsidiary: values.subsidiary,
      subsidiaries: values.subsidiaries?.join(),
      startDate: moment(values.dateRange.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.dateRange.endDate).format("YYYY-MM-DD"),
      transactionTypes: values.type,
      status: values.status,
      users: values.users,
      downloadType: values.format,
    });
    try {
      const downloadOperationResponse = await fetch(`${backendUrl}/proxy/api/v1/downloadOperations?${query}`, {
        method: "GET",
        headers: {
          "x-client-id": tenantId,
          "x-Authentication-Token": token,
          "x-request-download": "stream",
        },
      });
      if (downloadOperationResponse.status === 204) {
        setErrorMessage(__("dashboard.sections.sales.transactionReportNoTXInPeriod"));
      } else if (downloadOperationResponse.status > 300) {
        setErrorMessage(__("dashboard.sections.sales.transactionReportError"));
      } else {
        const reader = downloadOperationResponse.body.getReader();
        const stream = new ReadableStream({
          start(controller) {
            async function pump() {
              const { done, value } = await reader.read();
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            }
            return pump();
          },
        });
        // Create a new response out of the stream
        const streamResponse = new Response(stream);
        const blob = await streamResponse.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `sales_${Date.now()}.${values.format}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      };
    } catch (error) {
      setErrorMessage(__("dashboard.sections.sales.transactionReportError"));
    }
    setIsLoading(false);
  }

  return (
    <Form
      defaultValues={{
        subsidiary: filters.subsidiaryId,
        subsidiaries: subsidiaries,
        dateRange: {
          startDate: filters.startDate,
          endDate: filters.endDate,
        },
        type: "",
        status: "",
        users: "",
        format: "xls",
      }}
    >
      {(form) => (
        <>
          <Button className="buttom-radius" onClick={() => {
            setShowModal(true);
            setErrorMessage();
          }}>
            {__("actions.export")}
          </Button>
          <Modal
            className="reportWidgetModal"
            isOpen={showModal}
            toggle={() => setShowModal(false)}
          >
            <ModalHeader>
              {__("dashboard.sections.sales.transactionReport")}
            </ModalHeader>
            <ModalBody>
              <div className="text-center">
                <CustomField field="dateRange" onChange={() => setErrorMessage()}>
                  <DateRangeInput />
                </CustomField>
              </div>
              <div>
                {errorMessage &&
                  <div className='one-line-warning one-line-warning-transaction-report'>
                    <i className="mdi mdi-information buttom-radius"></i>
                    <p>{errorMessage}</p>
                  </div>
                }
              </div>
              <div className="row">
                <div className={hasStatus ? "col-md-6" : "col-md-12"}>
                  <Field
                    block
                    field="type"
                    label={__("transactions.types.label")}
                    type="select"
                    options={optionsWithTranslation}
                  />
                </div>
                {hasStatus && (
                  <div className="col-md-6">
                    <Field
                      block
                      field="status"
                      label={__("transactions.status.label")}
                      type="select"
                      options={options.status}
                    />
                  </div>
                )}
              </div>
              <APIRequest resource={getUsers(token)} fetch>
                {(request) => {
                  const options = [
                    {
                      value: null,
                      label: __(
                        "dashboard.sections.sales.form.users.placeholder"
                      ),
                    },
                  ];
                  request &&
                    request.response &&
                    request.response.map((user) => options.push(user));
                  return (
                    <SelectField
                      className="text-center"
                      name="users"
                      label={__("dashboard.sections.sales.form.users.label")}
                      placeholder={__(
                        "dashboard.sections.sales.form.users.placeholder"
                      )}
                      options={options}
                    />
                  );
                }}
              </APIRequest>

              <Field
                block
                field="format"
                label={__("dashboard.sections.sales.form.format.label")}
                placeholder={__(
                  "dashboard.sections.sales.form.format.placeholder"
                )}
                type="select"
                options={options.formats}
              />
              {showFilterSubsidiariesWidget && (
                <p className="text-center mt-4">
                  {__("dashboard.sections.sales.form.disclaimer")}
                </p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                className="buttom-radius"
                onClick={() => setShowModal(false)}
              >
                {__("actions.cancel")}
              </Button>
              <ButtonLoader
                text={__("actions.download")}
                isLoading={isLoading}
                onClick={() => {
                  downloadReport(form.values);
                }}
              />
            </ModalFooter>
          </Modal>
        </>
      )}
    </Form>
  );
};
