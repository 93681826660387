// In loving memory of carlosPato.js 03/03/2022
import { get, merge, assign } from 'lodash'
import { customConfig } from './default/customConfig';
import { tableColumns } from './default/tableColumns';
import { tenantCustonConfig, tenantTableColumns } from './tenantConfig';

const config = {
  customConfig: merge(customConfig, tenantCustonConfig),
  tableColumns: assign({}, tableColumns, tenantTableColumns )
}

export const getConfig = (path, defaultValue) => (get(config, path, defaultValue));