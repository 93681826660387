import React, { useState } from 'react';
import { Col, Row, Button } from 'reactstrap';
import __ from 'utils/i18n';
import { useSelector } from 'react-redux';
import { Text, TextArea } from 'components/informed';
import FormPanel from 'containers/Dashboard/components/FormPanel';
import { required, validations } from 'components/forms/validators';
import { APIRequest } from 'api-provider';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import { sendEmail } from '../../../resources';

const FormFields = () => {
  return (
    <Row>
      <Col>
        <Text
          validateOnBlur
          validate={validations({
            required: 1,
            emails: 1,
          })}
          field='email'
          type='text'
          placeholder={__('dashboard.sections.compraClick.actionButton.multipleEmails')}
          label='Email'
        />

        <Text
          validateOnBlur
          validate={required()}
          field='subject'
          type='text'
          placeholder={__(
            'dashboard.sections.compraClick.actionButton.subject'
          )}
          label={__('dashboard.sections.compraClick.actionButton.subject')}
        />

        <TextArea
          validateOnBlur
          validate={required()}
          field='message'
          label={__('dashboard.sections.compraClick.actionButton.message')}
        />
      </Col>
    </Row>
  );
};

const ModalFooter = ({ hideModal }) => {
  return (
    <div className='modal-footer'>
      <Button
        color='secondary'
        onClick={() => {
          hideModal(false);
        }}
      >
        {__('actions.close')}
      </Button>

      <Button color='success' type='submit'>
        {__('dashboard.sections.compraClick.actionButton.tabs.sendEmail')}
      </Button>
    </div>
  );
};

export const SendEmail = ({ data, hideModal }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const token = useSelector((state) => state.api.token);

  return (
    <div>
      <DashboardModal
        name='sendEmail'
        headerText={__(
          'dashboard.sections.compraClick.actionButton.tabs.sendEmail'
        )}
        showModal={showSuccessModal}
        hideModal={() => {
          setShowSuccessModal(false);
          hideModal(false);
        }}
        cancelText='Ok'
      >
        {__('dashboard.components.modal.sendEmail')}
      </DashboardModal>

      <APIRequest
        resource={sendEmail}
        onSuccess={() => setShowSuccessModal(true)}
      >
        {(request) => (
          <FormPanel
            onSubmit={(formValues) => {
              const values = { ...formValues, token: data.token };
              request.fetch({ values, token });
            }}
          >
            <div>
              <FormFields />
              {request.error && (
                <Row>
                  <Col md='12'>
                    <div className='request-error'>{request.error}</div>
                  </Col>
                </Row>
              )}
            </div>
            
            <ModalFooter hideModal={hideModal} />
          </FormPanel>
        )}
      </APIRequest>
    </div>
  );
};
