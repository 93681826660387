import React from "react";
import {
    Container,
    Col,
    Row,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    Input,
    Tooltip,
  } from "reactstrap";
import gtmEvents from "utils/gtmEvents";
import __ from "utils/i18n";
import { useClipboard } from "@chakra-ui/hooks";
import { getConfig } from "config";
import "../../../../../styles/components/PaymentLinkModal.scss"

function CopyStep(props) {
    const { paymentLink, hideModal, resetModal } = props;

  
    const { onCopy: copyPaymentLink, hasCopied } = useClipboard(paymentLink.url);
  
    return (
      <div className="payment-link-modal" >
        <ModalHeader toggle={hideModal}></ModalHeader>
  
        <ModalBody>
          <Container className="payment-link-modal__copy-step__container">
            <Row>
              <Col>
                <h5 className="modal-title text-center payment-link-modal__steps__title">
                  {__.t("dashboard.components.paymentLink.created.title")}
                </h5>
              </Col>
            </Row>
  
            <Row>
              <Col className="payment-link-modal__copy-step__link-container">
                <p className="text-center payment-link-modal__copy-step__description">
                  {__.t(
                    "dashboard.components.paymentLink.created.copy.description"
                  )}
                </p>
  
                <InputGroup className="payment-link-modal__copy-step__input-group">
                  <Input
                    value={paymentLink.url}
                    type="url"
                    disabled
                    id="payment-link-modal__copy-step__input"
                    className="text-truncate"
                  />
                  <Button
                    color="primary"
                    onClick={() => {
                      gtmEvents.paymentLinkUrlCopied();
                      copyPaymentLink();
                    }}
                    disabled={hasCopied}
                    id="copy-link-button"
                  >
                    {__.t("dashboard.components.paymentLink.created.copy.button")}
                  </Button>
                </InputGroup>
  
                <Tooltip
                  target="copy-link-button"
                  placement="top"
                  isOpen={hasCopied}
                  className="payment-link-modal__copy-step__tooltip"
                >
                  {__.t("dashboard.components.paymentLink.created.copy.tooltip")}
                </Tooltip>
              </Col>
            </Row>
          </Container>
        </ModalBody>
  
        <div className="modal-separator">
          <hr />
        </div>
  
        <ModalFooter>
          {getConfig('customConfig.paymentLink.createAnotherOptionEnabled') && (
            <Button color="secondary" className="payment-link-radius" onClick={resetModal} >
            {__.t("actions.createAnother")}
          </Button>
          )}
          
          <Button color="primary" className="payment-link-radius" onClick={hideModal}>
            {__.t("actions.finish")}
          </Button>
        </ModalFooter>
      </div>
    );
  }
  
  
  
  export default CopyStep;