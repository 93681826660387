import React, { useState } from "react";
import { SimpleForm } from 'components/forms'

import { DashboardModal } from "containers/Dashboard/components/DashboardModal";
import __ from "utils/i18n";
import { FormGroup } from "reactstrap";
import { TextField } from "components/forms";

export const FaqsModal = ({ showModal, setShowModal, FAQs, setFAQs, activeFAQ, setActiveFAQ }) => {

  const [faqTitle, setFaqTitle] = useState(null)
  const [faqBody, setFaqBody] = useState(null)
  const [faqTitleToUpdate, setFaqTitleToUpdate] = useState(null)
  const [faqBodyToUpdate, setFaqBodyToUpdate] = useState(null)

  const handleChange = () => {
    setActiveFAQ({
      faqTitle: faqTitle,
      faqBody: faqBody
    })
  }

  const handleSubmit = () => {
    setFAQs(prevState => (
      [...prevState, activeFAQ]
    ))
    setFaqTitle(null)
    setFaqBody(null)
    setActiveFAQ(null)
  }

  const handleUpdate = (activeFAQ) => {
    const newArr = [...FAQs]
    const remainingFAQs = newArr.filter((faq, index) => index !== activeFAQ.index)
    setFAQs([...remainingFAQs, { faqTitle: faqTitleToUpdate, faqBody: faqBodyToUpdate }]);
  }

  const styles = {
    container: { backgroundColor: "#FAFAFA" }
  }

  return (
    <>
      {activeFAQ?.toEdit ? (
        <DashboardModal
          name="faqsModal"
          headerText={__("store.form.fields.editFAQ")}
          showModal={showModal === "faqsModal"}
          submitAction={() => ((handleUpdate(activeFAQ), setShowModal(false)))}
          hideModal={() => setShowModal(false)}
          hideClose={true}
          hideSeparator={true}
          submitDisabled={faqTitleToUpdate && faqBodyToUpdate ? null : true}
          submitColor="success"
          submitText={__("actions.accept")}
        >
          <div className="p-5" style={styles.container}>
            <div className="mb-4">
              <label htmlFor={"faqTitleToUpdate"}
                className="ecommerceForm-field-label"
              >
                {__("store.form.fields.title")}
              </label>
              <input
                id={"faqTitleToUpdate"}
                name={"faqTitleToUpdate"}
                type={"text"}
                className={"form-control"}
                placeholder={__("store.form.fields.FAQTitlePlaceholder")}
                onChange={(e) => setFaqTitleToUpdate(e.target.value)}
                defaultValue={activeFAQ?.activeFAQ?.faqTitle}
              />
            </div>
            <div>
              <label htmlFor={"faqBodyToUpdate"}
                className="ecommerceForm-field-label"
              >
                {__("store.form.fields.answer")}
              </label>
              <textarea
                id={"faqBodyToUpdate"}
                name={"faqBodyToUpdate"}
                className={"form-control"}
                placeholder={__("store.form.fields.FAQBodyPlaceholder")}
                onChange={(e) => setFaqBodyToUpdate(e.target.value)}
                defaultValue={activeFAQ?.activeFAQ?.faqBody}
              />
            </div>
          </div>
        </DashboardModal>
      ) : (
        <DashboardModal
          name="faqsModal"
          headerText={__("store.form.fields.newFAQ")}
          showModal={showModal === "faqsModal"}
          submitAction={() => ((handleSubmit(), setShowModal(false)))}
          hideModal={() => setShowModal(false)}
          hideClose={true}
          hideSeparator={true}
          submitDisabled={faqTitle && faqBody ? null : true}
          submitColor="success"
          submitText={__("actions.accept")}
        >
          <div className="p-5" style={styles.container}>
            <SimpleForm>
              <FormGroup className="mb-4">
                <label htmlFor={"faqTitle"}
                  className="ecommerceForm-field-label"
                >
                  {__("store.form.fields.title")}
                </label>
                <TextField
                  id={"faqTitle"}
                  name={"faqTitle"}
                  type={"text"}
                  placeholder={__("store.form.fields.FAQTitlePlaceholder")}
                  onChange={(e) => ((handleChange(), setFaqTitle(e)))}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor={"faqBody"}
                  className="ecommerceForm-field-label"
                >
                  {__("store.form.fields.answer")}
                </label>
                <TextField
                  id={"faqBody"}
                  name={"faqBody"}
                  type={"textarea"}
                  placeholder={__("store.form.fields.FAQBodyPlaceholder")}
                  onChange={(e) => ((handleChange(), setFaqBody(e)))}
                />
              </FormGroup>
            </SimpleForm>
          </div>
        </DashboardModal>
      )}
    </>
  );
};
