import { getConfig } from 'config';
import React from 'react';
import { Range } from 'react-range';
import __ from "utils/i18n";

const DoubleRangeSlider = ({ values, setValues, min, max, step }) => {

  const handleChange = (newValues) => {
    setValues(newValues);
  };

  const currency = getConfig("customConfig.currencies")[0];
  const minimum = __("dashboard.components.filterForm.fields.amount.minimum");
  const maximum = __("dashboard.components.filterForm.fields.amount.maximum");

  const renderTrack = ({ props, children }) => (
    <div
      data-testid='double-range-slider-empty-track'
      className='double-range-slider-empty-track'
      {...props}
      style={{
        ...props.style
      }}
    >
      <div
        data-testid='double-range-slider-full-track'
        className='double-range-slider-full-track'
        style={{
          width: `${(values[1] - values[0]) * 100 / (max - min)}%`,
          left: `${(values[0] - min) * 100 / (max - min)}%`,
        }}
      />
      {children}
    </div>
  )

  const renderThumb = ({ props }) => (
    <div
      className='double-range-slider-thumb'
      style={{
        ...props.style
      }}
      {...props}
    />
  )

  const getFormatedAmount = (value) => {
    const userLanguage = localStorage.getItem("locale");
    const language = userLanguage === "es" ? "es-ES" : "en-US";
    return `${currency.symbol}${value?.toLocaleString(language)}`;
  }

  return (
    <div className='double-range-slider'>
      <Range
        values={values}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
        renderTrack={renderTrack}
        renderThumb={renderThumb}
      />
      <div className="double-range-slider-values">
        <p>
          {getFormatedAmount(values[0])}
          {" - "}
          {getFormatedAmount(values[1])}
        </p>
      </div>
      <div className="double-range-slider-description">
        <p>{minimum} - {maximum}</p>
      </div>
    </div>);
};

export default DoubleRangeSlider;
