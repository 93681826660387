import React from "react";
import {
  FormGroup,
  Label,
  FormText,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { Field } from "react-form";
import Dropzone from "react-dropzone";

import {
  TextInput,
  NumberInput,
  SelectInput,
  DateRangeInput,
  AmountInput,
  ImageInput,
  FileInput,
  MaskedTextInput,
  MaskedDateInput,
} from "../inputs";
import classNames from "classnames";
import TransparentButton from "./TransparentButton";

const inputTypes = {
  text: TextInput,
  number: NumberInput,
  select: SelectInput,
  daterange: DateRangeInput,
};

const renderChildren = (children, props) => {
  return typeof children === "function"
    ? children(props)
    : React.cloneElement(React.Children.only(children), props);
};
let className = "btn-secondary";
export const CustomField = (props) => {
  const {
    name,
    field,
    type,
    children,
    component,
    label,
    help,
    disabled,
    leftAddon,
    rightAddon,
    onChange,
    formApi,
    ...rest
  } = props;
  return (
    <Field field={field || name}>
      {(fieldApi) => {
        const { setValue, setTouched, setError, value, error, touched } =
          fieldApi;

        return (
          <FormGroup
            className={classNames({
              "has-error": !disabled && touched && error,
              disabled: disabled,
            })}
          >
            {label && <Label>{label}</Label>}

            <InputGroup>
              
              {renderChildren(component || children, {
                ...rest,
                value: (field || name) && value,
                onChange: (val) => {
                  onChange && onChange(val);
                  setValue(val);
                  setError();
                },
                onBlur: () => {
                  setTouched();
                  //setError()
                  className = "btn-secondary";
                },
                onFocus: () => {
                  setTouched();
                  //setError()
                  className = "btn-primary";
                },
                btnclassname: className,
              })}
              {rightAddon && (
                <InputGroupAddon addonType="prepend">
                  {rightAddon}
                </InputGroupAddon>
              )}
            </InputGroup>

            {!disabled && touched && error && (
              <FormText className="text-danger">{error}</FormText>
            )}
            {help && <FormText>{help}</FormText>}
          </FormGroup>
        );
      }}
    </Field>
  );
};

export const PictureField = ({
  name,
  children,
  component,
  disabled,
  setFile,
  ...rest
}) => {
  return (
    <Field field={name} pure={false}>
      {({ setValue, value, setTouched, error, touched }) => {
        return (
          <FormGroup
            className={classNames({
              "has-error": touched && error,
              disabled: disabled,
            })}
          >
            {renderChildren(component || children, {
              ...rest,
              value: name && value,
              onChange: (val) => {
                setValue(val);
                setTouched(val);
                setFile && setFile(val);
              },
              onBlur: () => setTouched(),
            })}
          </FormGroup>
        );
      }}
    </Field>
  );
};

export const FileField = ({ name, label, help, ...rest }) => {
  return (
    <Field field={name}>
      {({ value, setValue, error, touched }) => {
        const file = value ? value : null;
        return (
          <Dropzone
            {...rest}
            className={classNames({
              "file-field-dropzone": true,
              "has-file": file,
            })}
            accept="image/jpeg, image/png, application/pdf, application/x-pdf"
            acceptClassName="has-accept"
            rejectClassName="has-reject"
            disableClick={!!file}
            onDrop={(files) => {
              files.length && setValue(files[0]);
            }}
          >
            <div className="file-field-left">
              <i className="mdi mdi-attachment" />
            </div>
            <div className="file-field-right">
              <div className="file-field-label">{label}</div>
              {file ? (
                <div className="file-field-info">
                  <span>
                    {`${file && file.name} (${
                      file && parseInt(file.size / 1024)
                    }k)`}
                  </span>
                  <i
                    className="mdi mdi-close"
                    onClick={() => {
                      setValue(null);
                    }}
                  />
                </div>
              ) : (
                <div className="file-field-help">{help}</div>
              )}
            </div>
          </Dropzone>
        );
      }}
    </Field>
  );
};

export const FilterField = (props) => {
  const Input = props.type && inputTypes[props.type];
  return (
    <CustomField {...props}>
      <InputGroup>
        <Input {...props} />
        <InputGroupAddon
          addonType="prepend"
          size="sm"
          color="secondary"
          onClick={props.onDisable}
        >
          <i className="btn-close el el-remove-sign" />
        </InputGroupAddon>
      </InputGroup>
    </CustomField>
  );
};

export const ImportFileField = (props) => (
  <FileField {...props}>
    <FileInput {...props} />
  </FileField>
);

export const ProductImageField = (props) => (
  <PictureField {...props}>
    <ImageInput {...props} />
  </PictureField>
);

export const ClientImageField = (props) => (
  <PictureField {...props}>
    <ImageInput {...props} />
  </PictureField>
);

export const TextField = (props) => (
  <CustomField {...props}>
    <TextInput {...props} />
  </CustomField>
);

export const NumberField = (props) => (
  <CustomField {...props}>
    <NumberInput {...props} />
  </CustomField>
);

export const AmountField = (props) => (
  <CustomField {...props}>
    <AmountInput {...props} />
  </CustomField>
);

export const SelectField = (props) => (
  <CustomField {...props}>
    <SelectInput {...props} />
  </CustomField>
);

export const MaskedTextField = (props) => (
  <CustomField {...props}>
    <MaskedTextInput {...props} />
  </CustomField>
);

export const DateField = (props) => (
  <CustomField {...props}>
    <MaskedDateInput {...props} />
  </CustomField>
);

export const InputField = ({ children, type, ...rest }) => {
  if (type) return React.createElement(children, rest);
};

export const CheckboxField = (props) => (
  <Field field={props.name}>
    {({ setValue, value }) => (
      <div className="form-check">
        <label className="form-check-label">
          <input
            checked={value || false}
            onChange={(e) => setValue(e.target.checked)}
            disabled={props.disabled}
            className="form-check-input"
            type="checkbox"
            value=""
          />
          <i className="mdi mdi-check" />
          <span>{props.label}</span>
        </label>
      </div>
    )}
  </Field>
);

export { TransparentButton };
