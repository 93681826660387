import { getConfig } from "config";

const headers = (token, contentType = "application/json") => {
  var headerObject = {
    "Content-Type": contentType,
    "X-Authentication-Token": token,
  };

  return headerObject;
};

export const getAutomaticCashout = (token) => {
  return {
    baseUrl: getConfig("apiEndpoint"),
    endpoint: `cash-out/automatic`,
    method: "GET",
    headers: headers(token),
    cache: false,
  };
};

export const updateAutomaticCashout = (token, automaticCashoutOption) => {
  return {
    baseUrl: getConfig("apiEndpoint"),
    endpoint: `cash-out/automatic`,
    method: "POST",
    headers: headers(token),
    cache: false,
    data: {
      automatic_cash_out: automaticCashoutOption,
    },
  };
};

export const getBanks = (token) => {
  return {
    baseUrl: getConfig("apiEndpoint"),
    endpoint: `v2/banks`,
    method: "GET",
    headers: headers(token),
    cache: false,
  };
};

export const getBanksById = (token, id) => {
  return {
    baseUrl: getConfig("apiEndpoint"),
    endpoint: `v2/banks/${id}`,
    method: "GET",
    headers: headers(token),
    cache: false,
  };
};
