
import React from 'react'
import { InputGroupAddon } from 'reactstrap'

export class InputGroupIconButton extends React.Component {
  render () {
    const { icon, onClick, type, disabled } = this.props
    return (
      <InputGroupAddon addonType="prepend" onClick={onClick}>
        <button
          type={type}
          className={`btn btn-secondary ${this.props.children ? icon : ''}`}
          disabled={disabled ? disabled : null}>
          {this.props.children ? this.props.children : <i className={icon}/>}
        </button>
      </InputGroupAddon>
    )
  }
}