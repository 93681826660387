import React from 'react';
import { Card, CardTitle, CardText, CardBody } from 'reactstrap';
import classNames from 'classnames';

export const CardChart = ({ title, icon, content, size, children }) => {
  return (
    <Card
      className={classNames({
        "chart-card": true,
        "chart-card-lg": size === "lg",
      })}
    >
      <CardBody>
        {title && (
          <CardTitle>
            {icon && <i className={icon} />}
            <span>{title}</span>
          </CardTitle>
        )}
        {content && <CardText tag="div">{content}</CardText>}
        {children && <CardText tag="div">{children}</CardText>}
      </CardBody>
    </Card>
  );
};