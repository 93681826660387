import { TextField } from 'components/forms';
import React, { useState } from 'react'
import { Button, Col, Row, Tooltip } from 'reactstrap';
import __ from "utils/i18n";

export const StoreSlug = ({ isFormEnabled, values, storeState, ecommerceUrl, slug, isStoreCreated }) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [urlInputTooltipOpen, setUrlInputTooltipOpen] = useState(false);

    const openStoreNewTab = (slug) => {
        const link = ecommerceUrl + "/" + slug;
        window.open(link, "_blank");
    };

    const checkSlugName = (slug) => {
        if (storeState && slug === storeState.slug) {
            return true;
        }
        return false;
    };

    const slugAvailable = (slug) => {
        return slug !== "undefined" && slug !== null && slug !== "";
    };

    const copyToClipboard = (slug) => {
        const linkToCopy = `${ecommerceUrl}/${slug}`;
        navigator.clipboard.writeText(linkToCopy).then(function () {
            // show toaster
        });
    };

    const showTooltip = () => {
        setTooltipOpen(true);
        setInterval(() => {
            setTooltipOpen(false);
        }, 3000);
    };

    return (
        <>
            <div className="ecommerceForm-panel">
                <Row>
                    <Col className="ecommerceForm-mainTitle">
                        <label className="ecommerceForm-primary">
                            {__("store.panel.linkTitle")}
                        </label>
                    </Col>
                </Row>
                <Row className="ecommerceForm-link-panel-container">
                    <Col xs={12} sm={12} md={4}>
                        <label htmlFor={"slug"}>
                            {__("store.form.subtitle")}
                        </label>
                    </Col>
                    <Col xs={12} sm={12} md={5}>
                        <label htmlFor={"slug"} className="form-label">
                            {__("store.form.storeUrl") +
                                (values.slug === undefined ? "" : values.slug)}
                        </label>
                        <div className="ecommerceForm-urlSection-container">
                            <div className="ecommerceForm-urlInput-container">
                                <TextField
                                    id={"slug"}
                                    name={"slug"}
                                    type={"text"}
                                    maxLength={30}
                                    placeholder={__("store.form.storeUrlPlaceholder")}
                                    disabled={!isFormEnabled(values.isEnabled)}
                                />
                            </div>
                            <i
                                id="TooltipUrlInput"
                                className="mdi mdi-help-circle-outline mdi-24px ecommerceForm-pointer"
                                onMouseOver={() => {
                                    if (isFormEnabled(values.isEnabled)) {
                                        setUrlInputTooltipOpen(true);
                                    }
                                }}
                                onMouseOut={() => {
                                    setUrlInputTooltipOpen(false);
                                }}
                            />
                            <Tooltip
                                placement="top"
                                isOpen={urlInputTooltipOpen}
                                target="TooltipUrlInput"
                                toggle={() =>
                                    setUrlInputTooltipOpen((prevState) => !prevState)
                                }
                            >
                                {__("store.form.storeUrlInputHelpMessage")}
                            </Tooltip>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={3}>
                        <div className="ecommerceForm-linkSection-container">
                            {isFormEnabled(values.isEnabled) &&
                                slugAvailable(values.slug) &&
                                isStoreCreated() &&
                                checkSlugName(values.slug) && (
                                    <Row>
                                        <Col align={"center"} xs={12}>
                                            <Tooltip
                                                placement="top"
                                                isOpen={tooltipOpen}
                                                target="TooltipLinkSection"
                                            >
                                                {__("store.form.storeUrlCopied")}
                                            </Tooltip>
                                            <Button
                                                className="ecommerceForm-link-button-enable"
                                                id="TooltipLinkSection"
                                                onClick={() => {
                                                    if (values.isEnabled) {
                                                        copyToClipboard(values.slug);
                                                        showTooltip();
                                                    }
                                                }}
                                            >
                                                <i className="mdi mdi-link-variant mdi-24px ecommerceForm-icon-button" />
                                                {__("store.slug.shareStoreLink")}
                                            </Button>
                                        </Col>
                                        <Col align={"center"} xs={12}>
                                            <Button
                                                className="ecommerceForm-link-button-enable"
                                                onClick={() => {
                                                    if (values.isEnabled) {
                                                        openStoreNewTab(values.slug);
                                                    }
                                                }}
                                            >
                                                <i className="mdi mdi-eye mdi-24px ecommerceForm-icon-button" />
                                                {__("store.form.visitStore")}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            {(!isFormEnabled(values.isEnabled) ||
                                !slugAvailable(values.slug) ||
                                !isStoreCreated() ||
                                !checkSlugName(values.slug)) && (
                                    <Row>
                                        <Col align={"center"} xs={12}>
                                            <Button
                                                className="ecommerceForm-link-button-disable"
                                                disabled={!isFormEnabled(values.isEnabled)}
                                            >
                                                <i className="mdi mdi-link-variant mdi-24px ecommerceForm-icon-button" />
                                                {__("store.slug.shareStoreLink")}
                                            </Button>
                                        </Col>
                                        <Col align={"center"} xs={12}>
                                            <Button
                                                className="ecommerceForm-link-button-disable"
                                                disabled={!isFormEnabled(values.isEnabled)}
                                            >
                                                <i className="mdi mdi-eye mdi-24px ecommerceForm-icon-button" />
                                                {__("store.form.visitStore")}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}