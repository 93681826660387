import React from 'react'
import { asField } from 'informed';
import MaskedInput from 'react-text-mask'
import classnames from "classnames";
import moment from 'moment'

const Date = asField(({ fieldState, fieldApi, forwardedRef, label, field, ...props }) => {
    const formatBirthdate = (original) => {
        if(!original){
            return ''
        }

        if( moment(original).isValid()){
            return moment(original).format('DD/MM/YYYY')
        }

        return original
    }

    return(
      <div className="position-relative form-group">
        <label className="">{label}</label>
        <div className="input-group">
          <MaskedInput
            value={formatBirthdate(fieldState.value)}
            className={classnames({
              "form-control": true,
              "has-error": fieldState.error
            })}
            mask={[/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholder="DD/MM/AAAA"
            {...props}
            onChange={(e)=>{
              fieldApi.setValue(e.target.value, field)
            }}
          />
        </div>

        {fieldState.error ? (
          <small className="text-danger form-text text-muted">{fieldState.error}</small>
        ) : null}
      </div>
    )
  }
);

export default Date

