import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import { UploadResults } from './ImportUpload';
import { uploadFile } from '../../../resources';
import __ from 'utils/i18n';
import { fetchResource } from 'api-provider';
import { backendUrl } from 'config/envConfig';
import { tenantId } from 'config/envConfig'; 

export const ImportModal = ({
  token,
  showImport,
  onClose,
  subsidiaryId,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [uploadOk, setUploadOk] = useState(null);
  const [results, setResults] = useState([]);

  const downloadFile = () => {
    fetch(`${backendUrl}/proxy/api/paymentButtonsImportTemplate?subsidiaryId=${subsidiaryId}&token=${token}`, {
      method: 'GET',
      headers: {
        'x-client-id': tenantId,
        'x-Authentication-Token': token,
        'x-request-download': 'stream'
      },
    })
      .then(response => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      .then(stream => new Response(stream))
      .then(response => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `paymentButtons_${Date.now()}.csv`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
  };

  const onUpload = (file) => {
    setIsFetching(true);
    fetchResource(uploadFile(file, token, subsidiaryId))
      .then((response) => {
        const { data, success, errors } = response.data;
        setIsFetching(false);

        if (success === 'OK') {
          setUploadOk(true);
          setResults(data);
        } else {
          setUploadOk(false);
          setResults(errors || null);
        }
      })
      .catch((error) => {
        setIsFetching(false);
        setUploadOk(false);
        setResults(error);
      });
  };

  return (
    <Modal
      className='dashboard-modal import-modal'
      isOpen={showImport}
      toggle={onClose}
      zIndex={3000}
    >
      <div>
        <button
          type='button'
          className='close hidden-modal'
          aria-label='Close'
          onClick={onClose}
        >
          <span aria-hidden='true'>×</span>
        </button>

        <ModalHeader>
          {__('dashboard.sections.compraClick.import.title')}
        </ModalHeader>

        <ModalBody>
          {!isFetching && uploadOk !== null && (
            <UploadResults
              uploadOk={uploadOk}
              results={results}
              subsidiaryId={subsidiaryId}
              token={token}
            />
          )}
          {(isFetching || !uploadOk) && (
            <div>
              <Row>
                <Col md='12'>
                  <h4 className='import-modal__subtitle'>
                    <i className='mdi mdi-arrow-down' />
                    {__('dashboard.sections.compraClick.import.template.title')}
                  </h4>
                  <p>
                    {__(
                      'dashboard.sections.compraClick.import.template.message'
                    )}
                  </p>

                  <Button color='primary' size='sm' className='pull-right' onClick={downloadFile}>
                    {__(
                      'dashboard.sections.compraClick.import.template.button'
                    )}
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col md='12'>
                  <h4 className='import-modal__subtitle'>
                    <i className='mdi mdi-arrow-up' />
                    {__('dashboard.sections.compraClick.import.upload.title')}
                  </h4>
                  <div className='file-field-dropzone'>
                    {isFetching && (
                      <div>
                        {__(
                          'dashboard.sections.compraClick.import.upload.message.uploading'
                        )}
                      </div>
                    )}
                    {!isFetching && (
                      <Dropzone
                        className='no-dropzone'
                        activeClassName='dropzoneActive'
                        rejectClassName='dropzoneReject'
                        accept='.csv'
                        maxSize={1e6}
                        onDrop={(files, errors) => {
                          if (files.length === 1) {
                            onUpload(files[0]);
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <i className='mdi mdi-image' />
                            <p>
                              {__(
                                'dashboard.sections.compraClick.import.upload.message.drag'
                              )}
                              <br />
                              {__(
                                'dashboard.sections.compraClick.import.upload.message.click'
                              )}
                            </p>
                          </div>
                        )}
                      </Dropzone>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </ModalBody>

        <div className='modal-separator'>
          <hr />
        </div>

        <ModalFooter>
          <Button onClick={onClose}>
            {__('dashboard.sections.catalog.productsImport.cancel')}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};
