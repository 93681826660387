import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { APIQuery } from 'api-provider';
import { getConfig } from 'config';
import __ from 'utils/i18n';
import Loader from 'components/Loader';
import FiltersForm from 'components/Filters/FiltersForm';
import { PermissionsContext } from 'contexts/permissionsContext';
import { FiltersSubsidiariesWidget } from '../Sales/components/Filters';
import { TransactionsReportWidget } from '../Sales/components/TransactionsReport';
import { getSales } from '../Sales/_shared/resources';
import { SalesTable } from '../Sales/components/SalesTable';
import gtmEvents from 'utils/gtmEvents';

export const MySalesTab = () => {
  const { subsidiaryId } = useSelector(state => state.login.user);
  const { token } = useSelector(state => state.api);
  const subsidiariesStore = useSelector(state => state.login.user.subsidiaries) || [];
  const selectedSubsidiaries = useSelector(state => state.dashboard.sales.filters.subsidiaries);
  const [subsidiaries, setSubsidiaries] = useState(subsidiariesStore.length ? selectedSubsidiaries : [subsidiaryId]);
  const [showSubsidiaries, setShowSubsidiaries] = useState(false);
  const [filters, setFilters] = useState({});
  const { userPermissions, featureFlags } = useContext(PermissionsContext);

  let api_query;

  const changeSubsidiaries = (subsidiaries, reload) => {
    setSubsidiaries(subsidiaries);
    setShowSubsidiaries(false);
  };

  useEffect(() => {
    gtmEvents.salesPageView('pageSales');
  }, [])

  const isExportSalesEnabled =
    featureFlags?.includes('sales_action_export_sales') &&
    userPermissions?.includes('sales_action_export_sales');
  const showFilterSubsidiariesWidget = getConfig("customConfig.sales.showFilterSubsidiariesWidget");

  useEffect(() => {
    api_query.setFilters(filters);
    api_query.reload();
  }, [filters, api_query]);

  return (
    <APIQuery
      pageSize={20}
      key={subsidiaries}
      resource={getSales(subsidiaryId, subsidiaries, token)}
    >
      {(query) => {
        api_query = query;
        return (
          <div className="dashboard-card">
            <div className="dashboard-card-header">
              <FiltersForm
                showAmount={true}
                showPaymentChannel={true}
                startDate={filters.startDate}
                endDate={filters.endDate}
                onChange={(value) => {
                  setFilters(value)
                }}
              >
                {isExportSalesEnabled && (
                  <TransactionsReportWidget
                    filters={{
                      ...query.filters,
                      subsidiaryId,
                    }}
                    token={token}
                  />
                )}
                {showFilterSubsidiariesWidget && (
                  <FiltersSubsidiariesWidget
                    expanded={showSubsidiaries}
                    subsidiaries={subsidiaries}
                    showSubsidiaries={setShowSubsidiaries}
                    onSave={changeSubsidiaries}
                  />
                )}
              </FiltersForm>
            </div>
            <div className="dashboard-card-body">
              {query.isFetching && <Loader />}
              {query.error && (
                <div className="dashboard-card-message">
                  <span>{query.error}</span>
                </div>
              )}
              {(!query.data.length && !query.isFetching && !query.error) && (
                <div className="dashboard-card-message">
                  <i className="mdi mdi-minus-circle" />
                  <h3>{__("dashboard.sections.stats.withoutSales")}</h3>
                  <p>{__("dashboard.sections.stats.notFoundSales")}</p>
                </div>
              )}
              {(query.data.length > 0 && !query.isFetching && !query.error) &&
                <SalesTable
                  data={query.data}
                  isFetching={query.isFetching}
                  getMore={query.fetchMore}
                  hasMore={query.hasMore}
                  reload={query.reload}
                  subsidiariesStore={subsidiariesStore}
                />
              }
            </div>
          </div>
        );
      }}
    </APIQuery>
  );
};
