import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import Modal from "./Modal";
import { asField } from "informed";
import __ from "utils/i18n";

const ImageInput = asField(
  ({ fieldState, fieldApi, errorMessage, ...props }) => {
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState(props.data.name);
    const [url, setUrl] = useState(props.data.url);
    const [color, setColor] = useState(props.data.color);
    const { showColorList, disabled } = props;
    const imageClasses = {
      backgroundColor: color ? "#" + color : "",
    };

    useEffect(() => {
      setName(props.data.name);
      setUrl(props.data.url);
      setColor(props.data.color);
      fieldApi.setValue(props.data);
    }, [props.data, fieldApi]);

    return (
      <>
        <Modal
          name="productModal"
          showModal={showModal}
          hideModal={() => setShowModal(false)}
          showColorList={showColorList}
          onSave={(values) => {
            props.setFile(values);
            fieldApi.setValue(values);
            fieldState.error && fieldApi.setError();
          }}
          help={props.help}
          url={url}
          color={color}
          maxSize={props.maxSize}
          errorMessageAlert={props.errorMessageAlert}
        />
        <Row>
          <Col className="catalog__product--image-actions" md="12">
            <span
              style={url ? {} : imageClasses}
              className="catalog__product--image"
              onClick={() => {
                !disabled && setShowModal(true);
              }}
            >
              {url && (
                <img
                  alt=""
                  className="img-cover"
                  src={url}
                  height="68px"
                  width="68px"
                />
              )}
              {name && <span>{name.charAt(0)}</span>}
              {(!url && !name) && <i className="mdi mdi-image mdi-36px" />}
            </span>
            {!disabled && (
              <Button
                className="catalog__product--edit-image float-right"
                type="button"
                onClick={() => setShowModal(true)}
              >
                <i className="mdi mdi-pencil" /> {__("inputs.image.editImage")}
              </Button>
            )}
          </Col>
        </Row>
        {fieldState.error && (
          <Row>
            <Col>
              <small className="text-danger form-text text-muted">
                {fieldState.error}
              </small>
            </Col>
          </Row>
        )}
      </>
    );
  }
);

ImageInput.defaultProps = {
  data: {
    url: "",
    name: "",
    color: "",
  },
  showColorList: true,
  disabled: false,
  help: "inputs.image.selectImageProduct",
  errorMessageAlert: "dashboard.sections.compraClick.imageError",
  maxSize: 1e6,
};

export default ImageInput;
