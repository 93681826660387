import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { TransactionReceiptView } from '../TransactionReceipt';

export const SalePanel = (props) => {
  const [data, setData] = useState(props.data);

  const { subsidiaryId } = useSelector((state) => state.login.user);

  const saleSubsidiary = get(props.data, 'invocationExtraData.subsidiaryId');

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      subsidiaryId: saleSubsidiary || subsidiaryId,
    }))
  }, [saleSubsidiary, subsidiaryId]);

  return <TransactionReceiptView data={data} />
};
