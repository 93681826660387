import React, { useState } from "react";
import Cropper from "react-cropper";

import { DashboardModal } from "containers/Dashboard/components/DashboardModal";
import __ from "utils/i18n";

export const ImageCrooper = ({showModal, setShowModal, setCroppedImage, updatedImage, setValue}) => {
  const [cropper, setCropper] = useState("");

  const resizeCroppedCanvas = (canvas) => {
    return new Promise((resolve, reject) => {
      const croppedImageSrc = canvas.toDataURL();
      const resizedImage = new Image();
      resizedImage.src = croppedImageSrc;

      resizedImage.onload = () => {
        const ctx = canvas.getContext("2d");
        canvas.width = 500;
        canvas.height = 160;
        ctx.drawImage(resizedImage, 0, 0, 500, 160);
        resolve(canvas);
      };
    });
  };

  const getCroppedImage = async () => {
    if (cropper) {
      const canvas = cropper.getCroppedCanvas();
      const resizedImage = await resizeCroppedCanvas(canvas);
      const image = resizedImage.toDataURL();
      setCroppedImage(image);
      setValue("logo", image);
      setShowModal(false);
    }
  };

  return (
    <>
      {updatedImage && (
        <DashboardModal
          name="editLogoModal"
          headerText={__("store.logo.modalTitle")}
          showModal={showModal === "editLogoModal"}
          hideModal={() => setShowModal(false)}
          submitColor="success"
          submitAction={() => getCroppedImage()}
          cancelText={__("actions.close")}
          submitText={__("actions.save")}
        >
          <Cropper
            style={{ height: 400, width: "100%" }}
            initialAspectRatio={3}
            preview=".img-preview"
            src={updatedImage}
            viewMode={0}
            cropBoxResizable={false}
            dragMode={"move"}
            toggleDragModeOnDblclick={false}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        </DashboardModal>
      )}
    </>
  );
};
