import React from 'react';
import __ from 'utils/i18n';
import { APIRequest } from 'api-provider';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import { deleteCompraClick } from '../../../resources';

export const DeleteProductModal = ({
  buttonId,
  deleteSuccess,
  showDeleteModal,
  setShowDeleteModal,
  subsidiaryId,
  token,
}) => {
  return (
    <APIRequest
      resource={deleteCompraClick(subsidiaryId, buttonId, token)}
      onSuccess={() => {
        deleteSuccess();
        setShowDeleteModal(false);
      }}
    >
      {(request) => (
        <DashboardModal
          name='deleteProductModal'
          headerText={__(
            'dashboard.sections.catalog.components.productPanel.modal.headerText'
          )}
          showModal={showDeleteModal}
          hideModal={() => setShowDeleteModal(false)}
          submitAction={() => request.fetch({ buttonId })}
          submitDisabled={request.isFetching}
          submitColor='danger'
          submitText={__('actions.delete')}
          cancelText={__('actions.cancel')}
        >
          {__(
            'dashboard.sections.catalog.components.productPanel.modal.confirmDeleteProduct'
          )}
        </DashboardModal>
      )}
    </APIRequest>
  );
};
