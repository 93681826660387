import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Joyride, { STATUS } from "react-joyride";

import { fetchResource } from "api-provider";
import { removeDiscovery } from "./actions";
import { setReady } from "./resource";
import __ from "utils/i18n";

/* Active discoveries:
* paymentLink 
* withholdingTax
*/

export const Discovery = ({
  run = false,
  steps,
  discoveryId,
  options = {},
}) => {
  const [isFetching, setIsFeching] = useState(false);

  const token = useSelector((state) => state.api?.token);

  const dispatch = useDispatch();

  const baseColor = "#3c6bfd";
  const overlayColor = "rgba(0, 0, 0, 0.4)";
  const textColor = "#fff";
  const buttonsStyles = {
    borderRadius: 0,
    color: "#fff",
    border: "solid 1px #fff",
  };
  const {
    continuous = true,
    disableOverlayClose = true,
    showSkipButton = false,
    hideCloseButton = true,
  } = options;

  const setDiscoveryReady = ({ status }) => {
    if (status !== STATUS.READY || isFetching || !discoveryId) return;
    setIsFeching(true);
    dispatch(removeDiscovery(discoveryId));
    fetchResource(setReady(discoveryId, token));
    setIsFeching(false);
  };

  return (
    <Joyride
      callback={setDiscoveryReady}
      continuous={continuous}
      run={run}
      disableOverlayClose={disableOverlayClose}
      showSkipButton={showSkipButton}
      hideCloseButton={hideCloseButton}
      steps={steps}
      classname="discovery"
      locale={{
        next: __("discovery.buttons.next"),
        skip: __("discovery.buttons.skip"),
        close: __("discovery.buttons.close"),
        last: __("discovery.buttons.last"),
        back: __("discovery.buttons.back"),
      }}
      styles={{
        buttonNext: {
          ...buttonsStyles,
        },
        buttonBack: {
          ...buttonsStyles,
        },
        options: {
          zIndex: 10000,
          arrowColor: baseColor,
          backgroundColor: baseColor,
          overlayColor,
          primaryColor: baseColor,
          textColor,
        },
      }}
    />
  );
};
