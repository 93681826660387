import React, { useState } from "react";
import isUndefined from 'lodash/isUndefined'
import { useStep } from "@flywire/react-hooks";
import { Modal } from "reactstrap";

import CopyStep from "./steps/CopyStep";
import CreateStep from "./steps/CreateStep";
import ErrorStep from "./steps/ErrorStep";
import LoadingStep from "./steps/LoadingStep";
import ValidationErrorStep from "./steps/ValidationErrorStep";
import { useSelector } from "react-redux";
import { getConfig } from 'config/index';

const defaultPaymentLink = {
  name: undefined,
  price: undefined,
  url: null,
  isValid: false,
};

function PaymentLinkModal(props) {
  const [paymentLink, setPaymentLink] = useState(defaultPaymentLink);
  const [cleanForm, setCleanForm] = useState(false);
  const [error, setError] = useState(false);

  const paymentLinkValidations = useSelector((state) => state.login.user.paymentLink);
  const userRole = useSelector((state) => state.login.user.userRole);

  function onChange(formState) {
    const name = formState?.values?.name;
    const _price = formState?.values?.price?.floatValue;
    const price = isUndefined(_price) ? defaultPaymentLink.price : _price;
    const isValid = !formState.invalid;

    if (
      !cleanForm &&
      (name !== paymentLink.name ||
        price !== paymentLink.price ||
        isValid !== paymentLink.isValid)
    ) {
      setPaymentLink((prevState) => ({
        ...prevState,
        name,
        price,
        isValid,
      }));
    }
  }
  let validationName = '';
  const paymentLinkForm = { onChange, name: paymentLink.name, price: paymentLink.price };


  const evaluateValidations = () => {
    let canCreateLink = true;
    if (paymentLinkValidations) {
      if (typeof userRole?.toLowerCase === 'function' && userRole.toLowerCase() === 'administrador')  {
        if (getConfig('customConfig.paymentLink.biometricValidation') && !paymentLinkValidations.biometricScan) {
          canCreateLink = false;
          validationName = 'biometricScan'
        }
      }
      if (getConfig('customConfig.paymentLink.bankAccountValidation') && !paymentLinkValidations.bankAccount) {
        canCreateLink = false;
        validationName = 'bankAccount'
      }
    }
    return canCreateLink;
  }

  const getSteps = () => {
    const validations = evaluateValidations();
    if (!validations) {
      return [
        {
          id: "error",
          component: ValidationErrorStep,
        }
      ];
    } else {
      return [
        {
          id: "create",
          component: CreateStep,
        },
        {
          id: "loading",
          component: LoadingStep,
        },
        {
          id: "copy",
          component: CopyStep,
        },
        {
          id: "error",
          component: ErrorStep,
        },
      ]; 
    }
  }
  
  const steps = getSteps();
  const { step, navigation, reset } = useStep({steps});
  const { component: StepComponent } = step;

  const resetModal = () => {
    reset();
    setCleanForm(true);
    setTimeout(() => setCleanForm(false), 1000);
    setPaymentLink(defaultPaymentLink);
  }

  const hideAndResetModal = () => {
    resetModal();
    props.toggle(false);
    setTimeout(() => setCleanForm(false), 1000);
  };

  const retryModal = () => {
    reset();
  }

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={hideAndResetModal}
      zIndex={100}
      centered
      size="lg"
    >
      <StepComponent
        form={paymentLinkForm}
        paymentLink={paymentLink}
        setPaymentLink={setPaymentLink}
        hideModal={hideAndResetModal}
        resetModal={resetModal}
        retryModal={retryModal}
        navigation={navigation}
        error={error}
        setError={setError}
        validationName={validationName}
      />
    </Modal>
  );
}

export default PaymentLinkModal;
