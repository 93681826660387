import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Button } from "reactstrap";
import classNames from "classnames";
import { isEmpty } from "lodash";
import __ from "utils/i18n";
import { PermissionsContext } from "contexts/permissionsContext";
import FlexTable from "components/FlexTable";
import TableHeaderActions from "components/TableHeaderActions";
import FullTextSearch from "components/FullTextSearch";
import Loader from "components/Loader";
import DashboardPanel from "containers/Dashboard/components/DashboardPanel";
import { ClientPanel } from "../ClientPanel";
import { getConfig } from "config";

export const ClientsContent = ({
  fetchClients,
  token,
  data,
  isFetching,
  error,
  reload,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [items, setItems] = useState([]);
  const [action, setAction] = useState("create");
  const [clientData, setClientData] = useState({});

  const { userPermissions } = useContext(PermissionsContext);
  const isAddClientEnabled = userPermissions?.includes("clients_action_add");
  const isEditClientEnabled = userPermissions?.includes("clients_action_edit");
  const configColumns = getConfig("tableColumns.clientsColumns");

  const getSelectedClient = (data, selectedClientId) => {
    const client =
      data.length > 0 &&
      (data.find((client) => client?.id === selectedClientId) || {});
    if (!isEmpty(client)) {
      if (typeof client.lastName === "undefined") {
        client.firstName = client.fullName || "";
        client.lastName = "";
      }
    }
    return client;
  };

  const selectedClientData = getSelectedClient(clientData, selectedClientId);
  const name = selectedClientData.lastName
    ? `${selectedClientData.firstName} ${selectedClientData.lastName}`
    : selectedClientData.fullName || selectedClientData.firstName;

  const ClientName = ({ original }) => {
    const name = original.firstName || "";
    const surname = original.lastName || "";
    const fullname = original.fullName || "";
    const colName = name ? name + " " + surname : fullname;

    return (
      <div className="client--name">
        <span className="client--image">
          <i className="mdi mdi-account mdi-24px" />
        </span>
        <span>{colName || "-"}</span>
      </div>
    );
  };

  const ClientEmail = ({ original }) => {
    return <span>{original.email}</span>;
  };

  const ClientBirthdate = ({ original }) => {
    if (original.birthdate !== "") {
      return <span>{original.birthdate}</span>;
    }
  };

  const handleShowDetails = (clientData, action) => {
    if (isEditClientEnabled) {
      setShowDetails(true);
      setSelectedClientId(clientData?.id || null);
      setAction(action || "create");
    }
  };

  const handleHideDetails = () => {
    setShowDetails(false);
    setSelectedClientId(null);
  };

  const onSave = () => {
    return fetchClients({ force: true });
  };

  const onDelete = () => {
    return fetchClients({ force: true });
  };

  const handleItems = (items) => {
    setItems(items);
  };

  const getClientsColumns = () => {
    const clientsColumns = [];
    const defaultColumns = {
      firstName: {
        Header: __("dashboard.sections.clients.table.firstName"),
        Cell: ClientName,
        width: 300,
      },
      email: {
        Header: __("dashboard.sections.clients.table.email"),
        Cell: ClientEmail,
        width: 300,
      },
      birthdate: {
        Header: __("dashboard.sections.clients.table.birthdate"),
        Cell: ClientBirthdate,
        width: 200,
        maxWidth: 300,
      },
    };

    configColumns.forEach((item) => clientsColumns.push(defaultColumns[item]));

    return clientsColumns;
  };

  useEffect(() => {
    data.forEach((client) =>
      Object.keys(client).forEach((key) => {
        if (key === "birthdate" && client[key] !== "") {
          client[key] = moment(client[key]).format("DD/MM/YYYY");
        }
      })
    );

    setClientData(data);
  }, [data]);

  return (
    <div
      className={classNames({
        "dashboard-card": true,
        "panel-expanded": showDetails,
      })}
    >
      <DashboardPanel
        expanded={showDetails}
        panelHeading={
          selectedClientData.id
            ? `${__("actions.edit")} ${name}`
            : __("dashboard.sections.clients.newClient")
        }
        hidePanel={handleHideDetails}
      >
        <ClientPanel
          isFetching={isFetching}
          data={selectedClientData}
          onSave={() => onSave()}
          onDelete={() => onDelete()}
          hidePanel={handleHideDetails}
          action={action}
          showDetails={showDetails}
          token={token}
        />
      </DashboardPanel>

      <div className="dashboard-card-header">
        <FullTextSearch
          keys={["name", "email", "firstName"]}
          items={clientData}
          onChange={(items) => handleItems(items)}
        />
        {isAddClientEnabled && (
          <TableHeaderActions
            createItem={{
              name: __("dashboard.sections.clients.newClient"),
              action: handleShowDetails,
            }}
          />
        )}
      </div>

      <div className="dashboard-card-body">
        {isFetching ? (
          <Loader />
        ) : error ? (
          <div className="dashboard-card-message">
            <h3 className="text-danger">{error}</h3>
            <Button color="secondary" onClick={reload}>
              {__("actions.retry")}
            </Button>
          </div>
        ) : !items.length ? (
          <div className="dashboard-card-message">
            <i className="mdi mdi-minus-circle" />
            <h3>{__("dashboard.sections.clients.withoutClients")}</h3>
            <p>{__("dashboard.sections.clients.notFoundClients")}</p>
          </div>
        ) : (
          <FlexTable
            data={items}
            columns={getClientsColumns()}
            onRowClick={({ index }) => handleShowDetails(items[index], "edit")}
          />
        )}
      </div>
    </div>
  );
};
