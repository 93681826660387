import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { get, sumBy, hasIn } from 'lodash';
import { withRouter } from 'react-router-dom';
import { fetchResource } from 'api-provider';
import { StatsFilters } from '../StatsFilters';
import Loader from 'components/Loader';
import { AggregatedSales, SalesByDate, TotalSales } from "../../charts/index";
import { Row, Col } from 'reactstrap';
import resources from '../../resources';
import __ from 'utils/i18n';
import gtmEvents from 'utils/gtmEvents';

const Sales = () => {

  const [isStarting, setIsStarting] = useState(true);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isFetchingComparativeData, setIsFetchingComparativeData] = useState(false);
  const [data, setData] = useState({});
  const [comparativeData, setComparativeData] = useState({});
  const dataFetchRef = useRef();
  const comparativeDataFetchRef = useRef();

  const api = useSelector(state => state.api);
  const filters = useSelector(state => state.dashboard?.stats?.filters);
  const subsidiaryId = useSelector(state => state.login?.user?.subsidiaryId);
  const token = useSelector(state => state.api?.token);

  const currency = get(data, "currencyCode", "");

  const isLoading = (isStarting || isFetchingData || isFetchingComparativeData);
  const noData = !get(data, "byDate[0]");

  const sizes = [
    0, 10, 50, 100, 500, 1000, 5000, 10000, 50000, 1e5, 1e6, 1e7, 1e8,
  ];

  const aggregators = {
    dayOfWeek: {
      key: item => moment(item.date).format("d"),
      keys: [1, 2, 3, 4, 5, 6, 0],
      metrics: {
        category: (items, key) => __(`daysOfWeek.${key}`).substring(0, 2),
        count: items => sumBy(items, 'count'),
        amount: items => sumBy(items, 'amount')
      }
    },
    hourOfDay: {
      key: item => Math.floor(parseFloat(item.hour) / 2) * 2,
      keys: [...Array(12).keys()].map(k => k * 2),
      metrics: {
        category: (items, key) => key,
        title: (items, key) => `${key} a ${key + 2} hs`,
        count: items => sumBy(items, 'count'),
        amount: items => sumBy(items, 'amount')
      }
    },
    sizeOfSale: (currency) => ({
      key: (item) =>
        item.amount > 0 ? sizes.findIndex((size) => item.amount < size) : null,
      metrics: {
        category: (items, key) => `${__.number(sizes[key - 1], 'short')} - ${__.number(sizes[key], 'short')}`,
        count: items => sumBy(items, 'count'),
        amount: items => sumBy(items, 'amount')
      }
    }),
  }

  const fetch = () => {
    dataFetchRef.current && dataFetchRef.current.cancel();
    setIsStarting(false);
    setIsFetchingData(true);
    dataFetchRef.current = fetchResource(resources.sales(subsidiaryId, token), {
      startDate: moment(filters.date.startDate).format("YYYY-MM-DD"),
      endDate: moment(filters.date.endDate).format("YYYY-MM-DD"),
      currencyCode: get(filters, 'currency', undefined),
    }, api).onSuccess(response => {
      setIsFetchingData(false);
      setData(response);
    }).onFailure(error => {
      setIsFetchingData(false);
      if (hasIn(error, 'response.status') && error.response.status === 401) {
        this.props.history.push("/logout");
      }

    })

    if (filters.comparison) {
      comparativeDataFetchRef.current && comparativeDataFetchRef.current.cancel();
      setIsFetchingComparativeData(true);
      comparativeDataFetchRef.current = fetchResource(resources.sales(subsidiaryId, token), {
        startDate: moment(filters.comparativeDate.startDate).format("YYYY-MM-DD"),
        endDate: moment(filters.comparativeDate.endDate).format("YYYY-MM-DD"),
        currencyCode: get(filters, 'currency', undefined),
      }, api).onSuccess(response => {
        setIsFetchingComparativeData(false);
        setComparativeData(response);
      }).onFailure(error => {
        setIsFetchingData(false);

        if (hasIn(error, 'response.status') && error.response.status === 401) {
          this.props.history.push("/logout");
        }
      })
    }
  }

  const fetchCallBack = useCallback(fetch, [filters, api, subsidiaryId, token]);

  useEffect(() => {
    fetchCallBack();
    gtmEvents.statsPageView('pageSales');
  }, [filters, fetchCallBack]);

  return (
    <div className="dashboard-card stats-section">
      <div className="dashboard-card-header">
        <StatsFilters allowCompare={true} />
      </div>
      <div className="dashboard-card-body">
        {(isLoading) &&
          <Loader />
        }
        {(!isLoading && noData) &&
          <div className="dashboard-card-message">
            <i className="mdi mdi-minus-circle" />
            <h3>{__('dashboard.sections.stats.withoutSales')}</h3>
            <p>{__('dashboard.sections.stats.notFoundSales')}</p>
          </div>
        }
        {(!isLoading && !noData) &&
          <div>
            <div className="chart-col">
              <SalesByDate
                data={get(data, "byDate", [])}
                comparativeData={filters.comparison && get(comparativeData, "byDate", [])}
                date={filters.date}
                comparativeDate={filters.comparativeDate}
                currency={currency}
              />
            </div>
            <Row>
              <Col xl="4" lg="12" xs="12" className="chart-col">
                <AggregatedSales
                  title={__('dashboard.sections.stats.aggregated.byDate')}
                  data={get(data, "byDate", [])}
                  comparativeData={filters.comparison && get(comparativeData, "byDate", [])}
                  aggregator={aggregators.dayOfWeek}
                  currency={currency}
                />
              </Col>
              <Col xl="4" lg="6" xs="12" className="chart-col">
                <AggregatedSales
                  title={__('dashboard.sections.stats.timeOfDay')}
                  data={get(data, "byHour", [])}
                  comparativeData={filters.comparison && get(comparativeData, "byHour", [])}
                  aggregator={aggregators.hourOfDay}
                  currency={currency}
                />
              </Col>
              <Col xl="4" lg="6" xs="12" className="chart-col">
                <AggregatedSales
                  title={__('dashboard.sections.stats.sizeOfSale')}
                  data={get(data, "byDate", [])}
                  comparativeData={filters.comparison && get(comparativeData, "byDate", [])}
                  aggregator={aggregators.sizeOfSale(currency)}
                  currency={currency}
                />
              </Col>
            </Row>
            <TotalSales
              data={get(data, "byDate", [])}
              currency={currency}
            />
          </div>
        }
      </div>
    </div>
  );
};

export const StatsSales = withRouter(Sales);