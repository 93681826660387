
import React from 'react';
import __ from 'utils/i18n';

import moment from 'moment';
import groupBy from 'lodash/groupBy';
import isArray from 'lodash/isArray';

import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "reducers";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { persistStore } from 'redux-persist';
import { getConfig } from 'config'

export const renderChildren = (children, props) => {
  return typeof children === 'function' ?
    children(props) : children
}

export const isValidArray = (arr) => {
  return isArray(arr) && arr.length > 0
}

export const injectProps = (children, props) => {
  return typeof children === 'function' ?
    children(props) :
    React.Children.map(children, item => React.cloneElement(item, props))
}

export const validateEmail = (email) => {
  // eslint-disable-next-line
  return !!(email && email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
}

export const validateEmails = (emails) => {
  return emails && emails.split(',').every(email => validateEmail(email.trim()))
}

export const formatNumber = (value, decimals = 2) => {
  console.warn("Deprecated 'formatNumber', please use __.number from i18n")
  return __.number(value || 0, decimals ? 'decimal' : 'rounded')
}

export const formatAmount = (value, currency, decimals) => {
  console.warn("Deprecated 'formatAmount', please use __.currency from i18n")
  return __.currency(value, currency, decimals)
}

export const formatDateRange = (start, end, year = false) => {
  console.warn("Deprecated 'formatDateRange', please use __.daterange from i18n")
  return __.daterange(start, end)
}

export const aggregateBy = (items, aggregator) => {
  if (!items || !items.length)
    return []
  const { key, keys, metrics } = aggregator

  const groups = groupBy(items, key)
  const groupParis = keys ?
    keys.map(k => [k, groups[k] || []]) :
    Object.values(groups).map(items => [key(items[0]), items || []]).filter(([k, items]) => k !== null)

  return groupParis.map(([key, items]) => (
    Object.keys(metrics).reduce((result, metric) => {
      return { ...result, [metric]: metrics[metric](items, key) }
    }, { key })
  ))
}

export const prettyJson = (json) => {
  return <div><pre>{JSON.stringify(json, null, 2)}</pre></div>
}

export const dateRange = (startDate, endDate) => {
  const days = moment(endDate).diff(startDate, 'd')
  const dates = []
  for (let i = 0; i <= days; i++) {
    dates.push(moment(startDate).add(i, 'd'))
  }
  return dates
}

export const completeDays = (items, { startDate, endDate }) => {
  const itemsByDate = items.reduce((itemsByDate, item) => {
    itemsByDate[moment(item.date).format('YYYY-MM-DD')] = item
    return itemsByDate
  }, {})
  const days = moment(endDate).diff(startDate, 'd')
  const result = []
  for (let i = 0; i <= days; i++) {
    const date = moment(startDate).add(i, 'd').format('YYYY-MM-DD')
    result.push(itemsByDate[date] || { date })
  }
  return result
}

export const text2plain = value => {
  return value ? value.replace(/[^\w\d\s]/g, '').replace(/^[\s]+/, '') : ''
}

export const text2number = value => {
  return value ? value.replace(/[^\d]/g, '') : ''
}

export const newStore = () => {
  const store = createStore(
    rootReducer,
    undefined,
    compose(
      applyMiddleware(thunk, createLogger()),
    )
  )
  return store
}

export const persistLocalStore = (action, data) => {
  const store = newStore();
  persistStore(store, null, () => {
    store.dispatch(action(data));
  })
}

export const getProductsColors = () => getConfig('customConfig.products.colors', ["666666", "e34b56", "ff840f", "ffbf0d", "19b959", "0aa4bf", "0077cc", "2d3baf", "7e31c6"]);

export const getChartsColors = () =>  getConfig('customConfig.charts.colors', ["#005DD1", "#00D9C5", "#C04041", "#FFBF0D"]);

export const getSalesChartColor = () => getConfig('customConfig.charts.sales.colors', ["#585858", "#878787"]);

export const copyToClipboard = (valueForClipboard) => {
  const textArea = document.createElement('textarea');
  textArea.value = valueForClipboard;

  textArea.style.position = 'fixed';
  textArea.style.top = '0px';
  textArea.style.left = '0px';
  textArea.style.clip = 'rect(0px, 0px, 0px, 0px)';

  textArea.setAttribute('readonly', ''); // without it, the native keyboard will pop up (so we show it is only for reading)

  document.body.appendChild(textArea);

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    const range = document.createRange();
    range.selectNodeContents(textArea);

    const selection = window.getSelection();
    selection.removeAllRanges(); // remove previously selected ranges
    selection.addRange(range);
    textArea.setSelectionRange(0, valueForClipboard.length); // this line makes the selection in iOS
  } else {
    textArea.select(); // this line is for all other browsers except ios
  }

  try {
    return document.execCommand('copy'); // if copy is successful, function returns true
  } catch (e) {
    return false; // return false to show that copy unsuccessful
  } finally {
    document.body.removeChild(textArea); // delete textarea from DOM
  }
};

export const replaceObjectKeysOnString = (string, parameters) => {
  if (!string) {
    return '';
  }

  let replaced = string;

  Object.keys(parameters).forEach(key => {
    replaced = replaced.replace(`{${key}}`, parameters[key]);
  });

  return replaced;
};

export const getLocations = (setLocations) => {
  return fetch('/locations.json')
  .then( res => res.json())
  .then( res => setLocations(res))
  .catch(error => console.error('Error:', error));
}
