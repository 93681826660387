import React, { useContext } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import __ from 'utils/i18n';
import { PermissionsContext } from 'contexts/permissionsContext';

export const PanelFooter = ({ isEdition, sameUser, isFetching, showDeleteModal, touched }) => {
  const { userPermissions } = useContext(PermissionsContext);
  const isDeleteUserEnabled = userPermissions?.includes('users_action_delete');

  return (
    <Row>
      {isEdition && isDeleteUserEnabled && (
        <Col md='6'>
          <Button
            color='secondary'
            type='button'
            block={true}
            onClick={() => showDeleteModal(true)}
            disabled={isFetching}
          >
            <i className='mdi mdi-delete' />
            {__('actions.delete')}
          </Button>
        </Col>
      )}
      <Col md={isEdition && isDeleteUserEnabled ? '6' : '12'}>
        <Button
          color='success'
          type='submit'
          block={true}
          disabled={isEmpty(touched) || isFetching}
        >
          <i className='mdi mdi-check' />
          {isEdition || sameUser ? __('actions.save') : __('actions.create')}
        </Button>
      </Col>
    </Row>
  );
};
