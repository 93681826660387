import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
import { PermissionsContext } from 'contexts/permissionsContext';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import { filter } from 'lodash';
import { SimpleForm, TextField, SelectField } from 'components/forms';
import { isEmpty } from 'lodash';
import __ from 'utils/i18n';
import Loader from 'components/Loader';
import { fetchResource } from 'api-provider';
import { validateFields } from 'components/forms/validators';
import {
  validateCategory,
  editCategory,
  deleteCategory,
} from '../../_shared/resources';
import gtmEvents from 'utils/gtmEvents';
import { useMutation } from '@tanstack/react-query';

export const CategoryPanel = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categories, setCategories] = useState([
    {
      value: '',
      label: __('dashboard.sections.catalog.products.notCategory'),
    },
  ]);
  const [categoryData, setCategoryData] = useState({});
  const [parentCategory, setParentCategory] = useState({});
  const [error, setError] = useState(false);

  const { subsidiaryId } = useSelector((state) => state.login.user);
  const { token } = useSelector((state) => state.api);
  const { deep, parentCategoryId, categoryId, categoryName } = props.data;

  const { userPermissions } = useContext(PermissionsContext);
  const isDeleteCategoryEnabled = userPermissions?.includes('categories_action_delete');

  const editSuccess = () => {
    props.onSave();
    props.hidePanel();
    gtmEvents.newCategoryAction();
  };

  const editFailure = (error) => {
    if (error) {
      setError(error);
    }
  };

  const deleteSuccess = () => {
    props.onDelete();
    setShowDeleteModal(false);
    props.hidePanel();
  };

  const deleteFailure = (error) => {
    console.log(error);
  };

  const deleteCategoryMutation = useMutation({
    mutationFn: () => fetchResource(deleteCategory(token, categoryId)),
    onSuccess: deleteSuccess,
    onError: deleteFailure
  });

  const editCategoryMutation = useMutation({
    mutationFn: (values) => fetchResource(editCategory(values, token, subsidiaryId)),
    onSuccess: editSuccess,
    onError: editFailure
  });

  useEffect(() => {
    const filteredCategories = filter(props.categories, (category) => {
      return validateCategory(props.categories, categoryId, category);
    });

    let categoriesList = [];

    filteredCategories.forEach((item) =>
      categoriesList.push({
        value: item.categoryId,
        label: item.categoryName,
      })
    );

    const newParentCategory = deep
      ? deep.find((item) => item.categoryId === parentCategoryId)
      : [];

    setCategories(categoriesList);
    setCategoryData({
      categoryName: categoryName || '',
      categoryId: categoryId || 0,
      parentCategory: parentCategoryId || 0,
    });
    setParentCategory({
      value: newParentCategory ? newParentCategory.categoryId : undefined,
      label: newParentCategory ? newParentCategory.categoryName : undefined,
    });
    setError(false);
  }, [categoryId, categoryName, parentCategoryId, deep, props.categories]);

  return (
    <div>
      <DashboardModal
        name='deleteCategoryModal'
        headerText={__(
          'dashboard.sections.catalog.components.categoryPanel.modal.headerText'
        )}
        showModal={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        submitAction={deleteCategoryMutation.mutate}
        submitDisabled={deleteCategoryMutation.isLoading}
        submitColor='danger'
        submitText={__('actions.delete')}
        cancelText={__('actions.cancel')}
      >
        {__(
          'dashboard.sections.catalog.components.categoryPanel.modal.confirmDeleteCategory'
        )}
      </DashboardModal>
      <SimpleForm
        defaultValues={categoryData}
        validate={validateFields([{ field: 'categoryName', required: 1 }])}
        validateOnSubmit={true}
        reset={!props.showDetails}
        onSubmit={(values) => {
          setCategoryData(values);
          editCategoryMutation.mutate(values);
        }}
      >
        {({ values, touched }) => (
          <div className='panel-flex'>
            <div>
              <i className='category-folder mdi mdi-folder mdi-48px' />
              <TextField
                name='categoryName'
                placeholder={__(
                  'dashboard.sections.catalog.components.categoryPanel.form.categoryName.placeholder'
                )}
                label={__(
                  'dashboard.sections.catalog.components.categoryPanel.form.categoryName.label'
                )}
              />
              <SelectField
                key={categoryData.parentCategory}
                defaultValue={parentCategory}
                name='parentCategory'
                noResultsText={__(
                  'dashboard.sections.catalog.components.categoryPanel.form.parentCategory.noResultsText'
                )}
                placeholder={__(
                  'dashboard.sections.catalog.components.categoryPanel.form.parentCategory.placeholder'
                )}
                label={__(
                  'dashboard.sections.catalog.components.categoryPanel.form.parentCategory.label'
                )}
                options={categories}
              />
              {error && (
                <Row>
                  <Col md='12'>
                    <div className='request-error'>{error}</div>
                  </Col>
                </Row>
              )}
            </div>
            <div>
              <Row>
                {editCategoryMutation.isLoading ? (
                  <Loader absolute={false} />
                ) : (
                  <>
                    {categoryData.categoryId && isDeleteCategoryEnabled ? (
                      <Col md='6'>
                        <Button
                          color='secondary'
                          type='button'
                          block={true}
                          onClick={() => setShowDeleteModal(true)}
                        >
                          {__('actions.delete')}
                        </Button>
                      </Col>
                    ) : null}
                    <Col md={categoryData.categoryId && isDeleteCategoryEnabled ? '6' : '12'}>
                      <Button
                        color='success'
                        type='submit'
                        block={true}
                        disabled={isEmpty(touched) || editCategoryMutation.isLoading}
                      >
                        {categoryData.categoryId
                          ? __('actions.save')
                          : __('actions.create')}
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </div>
        )}
      </SimpleForm>
    </div>
  );
};
