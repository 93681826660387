import React from 'react'
import Select from 'react-select'
import axios from 'axios'

class SelectInput extends React.Component {

  state = {
    isLoading: false,
    error: false,
    options: []
  }

  loadOptions(source) {
    this.setState({ isLoading:true, error:false })
    axios.request(source).then(response => {
      if (source === this.props.source) {
        this.setState({
          options:response.data,
          isLoading:false,
          error:false
        })
      }
    }).catch(error => {
      this.setState({
        isLoading: false,
        error: 'Error loading options'
      })
    })
  }

  componentDidMount() {
    if (this.props.source) {
      this.loadOptions(this.props.source)
    }
  }

  componentWillReceiveProps(props) {
    if (props.source !== this.props.source) {
      this.loadOptions(props.source)
    }
  }

  handleOpen() {
    if (this.props.source && this.state.error) {
      this.loadOptions(this.props.source)
    }
  }

  render() {
    const { clearable, onChange, options, placeholder, onOpen, isLoading, value, optionBackground, optionHover, optionActive, ...rest } = this.props

    const colourStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? optionActive
              : isFocused
                ? optionHover
                : optionBackground,
          ':active': {
            backgroundColor: optionActive,
          },
        };
      },
    };
    // eslint-disable-next-line
    const selectedOption = typeof value === 'object' ? value : (options && options[options.findIndex(item => item.value == value)]) || '';
    // TO DO: Cambiar == a === ésto hace que matcheen los IDs de provincias que vienen de la BBDD como strings, con los IDs INT del combo select. No corregir esto sin antes tipar correctamente esos IDs

    return (
      <Select
        {...rest}
        value={ selectedOption }
        onOpen={() => {
          this.handleOpen()
          onOpen && onOpen()
        }}
        styles={colourStyles}
        placeholder={this.state.error || placeholder}
        isLoading={isLoading || this.state.isLoading}
        className="Select select-radius"
        classNamePrefix={"Select"}
        clearable={clearable === true || false}
        onChange={option => onChange && onChange(option && option.length > 0 ? option : (option.value))}
        options={options || this.state.options}>
      </Select>
    )
  }
}

SelectInput.defaultProps={
  optionBackground: '#fff',
  optionHover: '#eaeaea',
  optionActive: '#b9b9b9',
}

export default SelectInput
