import React from "react";
import Helmet from "react-helmet";
import "../../styles/newStyles/HelpWidget.scss";

export const HelpWidget = () => {
  return (
    <div className="help-widget">
      <Helmet>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=c341a8e0-577b-42a8-875c-67c1f7588eb2"
        >
          {" "}
        </script>
      </Helmet>
    </div>
  );
};
