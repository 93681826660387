import React from 'react';
import IconError from 'assets/error-cross.svg';
import '../../../styles/newStyles/LoginError.scss';
import __ from 'utils/i18n';

export const LoginError = ({ title = 'Unexpected Error', description, loginUrl }) => (
  <div className='login-error__container'>
    <img src={IconError} alt='' />
    <h5 className='login-error__title'>{title}</h5>
    <h5 className='login-error__subtitle'>{description}</h5>
    <p>
      <a className='login-error__button' href={loginUrl}>{__('actions.retry')}</a>
    </p>
  </div>
);
