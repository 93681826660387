import React from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';
import { getConfig } from "config/index";


export const Items = ({ sale, config }) => {
  const { items, totalAmount, currency } = config.items;
  const tipItem = sale.tipAmount;
  
  const showTip = getConfig("customConfig.sales.transactionReceipt.showTip") && tipItem > 0;

  return (
    <>
      {sale.items && sale.items.length > 0 && (
        <Table className='no-border'>
          <tbody>
            {items(sale) &&
              items(sale).map((item, index) => ( 
                <tr key={index}>
                  <td>
                    {item.quantity > 1 ? `${item.quantity} x ` : ''}
                    {item.productName
                      ? item.productName
                      : __('dashboard.sections.sales.whithoutDescription')}
                  </td>
                  <td>
                    {__.currency(
                      item.productPrice * item.quantity,
                      currency(sale)
                    )}
                  </td>
                </tr>
              ))}
              {showTip && (
                <tr>
                  <td>{__('dashboard.sections.sales.tip')}</td>
                  <td>{__.currency(tipItem, currency(sale))}</td>
                </tr>
              )}
          </tbody>
        </Table>
      )}

      {sale.recharge && (
        <Table className='no-border'>
          <tbody>
            <tr>
              <td>{sale.recharge.companyName}</td>
              <td>{__.currency(totalAmount(sale), currency(sale))}</td>
            </tr>
          </tbody>
        </Table>
      )}

      {sale.service && (
        <Table className='no-border'>
          <tbody>
            <tr>
              <td>{sale.service.companyName}</td>
              <td>{__.currency(totalAmount(sale), currency(sale))}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};
