import {combineReducers} from "redux";
import {ACTIONS} from "containers/CourierTracking/constants";

export default combineReducers({
  orders: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.SET_ORDERS:
        return action.orders
      default:
        return state
    }
  }
})