import React from 'react';
import { connect } from 'react-redux';

import { get } from 'lodash';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { validateFields, validEmail } from 'components/forms/validators';

import { SimpleForm, TextField } from 'components/forms';
import { APIRequest } from 'api-provider';

import __ from 'utils/i18n';
import { getConfig } from 'config';

class SendInvoiceButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSent: false,
    };
  }

  onSuccess() {
    this.setState({ isSent: true });
    setTimeout(() => this.setState({ isOpen: false }), 1000);
  }

  handleClick() {
    this.props.onClick && this.props.onClick();
    this.setState({ isOpen: true, isSent: false });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  render() {
    const { data, title, icon, token, ...rest } = this.props;

    const resource = (props) => {   
      const params = {
        email: props.email,
        userId: props.userId,
        transactionId: props.id,
        invoiceNumber: props.invoiceNumber,
        token: token,
        channel: "web",
        deviceId: "web",
        deviceMode: "web",
        deviceOS: "web",
      };
    
      if (getConfig("customConfig.sales.operationId")) {
        params.operationId = props.operationId;
      }
    
      return {
        endpoint: "invoices/sendInvoice.json",
        method: "POST",
        params: {
          subsidiaryId: props.subsidiaryId,
        },
        formData: params,
      };
    };
    
    return (
      <APIRequest resource={resource} onSuccess={() => this.onSuccess()}>
        {(request) => (
          <>
            <Button {...rest} onClick={() => this.handleClick()}>
              <i className={icon} />
              <span>{title}</span>
            </Button>
            <Modal isOpen={this.state.isOpen} toggle={() => this.handleClose()}>
              <SimpleForm
                validate={validateFields([
                  { field: 'email', validate: value => value && validEmail()(value)}
                ])}
                onSubmit={(values) => {
                  request.fetch({
                    ...data,
                    email: values.email,
                  });
                }}
              >
                {(form) => (
                  <>
                    <ModalHeader toggle={() => this.handleClose()}>
                      {title}
                    </ModalHeader>

                    <ModalBody>
                      {request.isFetching && (
                        <h4 className="text-center">
                          {__(
                            "dashboard.sections.sales.sendInvoice.messages.sending"
                          )}
                        </h4>
                      )}
                      {this.state.isSent && (
                        <h4 className="text-center text-success">
                          {__(
                            "dashboard.sections.sales.sendInvoice.messages.success"
                          )}
                        </h4>
                      )}
                      {(!request.isFetching || !this.state.isSent) && (
                        <>
                          <TextField
                            name="email"
                            placeholder={__(
                              "dashboard.sections.sales.sendInvoice.messages.emailPlaceholder"
                            )}
                            label={__(
                              "dashboard.sections.sales.sendInvoice.messages.emailLabel"
                            )}
                          />
                          {request.error && (
                            <h6 className="text-center text-danger">
                              {__(
                                "dashboard.sections.sales.sendInvoice.messages.error"
                              )}
                            </h6>
                          )}
                        </>
                      )}
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        color="secondary"
                        onClick={() => this.handleClose()}
                      >
                        {__(
                          "dashboard.sections.sales.sendInvoice.messages.close"
                        )}
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={
                          form.errors || request.isFetching || this.state.isSent
                        }
                      >
                        {__(
                          "dashboard.sections.sales.sendInvoice.messages.send"
                        )}
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </SimpleForm>
            </Modal>
          </>
        )}
      </APIRequest>
    );
  }
}

const mapStateToProps = (state) => ({
  token: get(state, 'api.token'),
});

export default connect(mapStateToProps)(SendInvoiceButton);
