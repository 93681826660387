import React, { useState } from "react";
import "../../../../../../../../styles/newStyles/MerchantCredentials.scss";
import { PasswordVerificationStep } from "../steps/PasswordVerificationStep";

import WarningStep from "../steps/WarningStep";
import ErrorStep from "../steps/ErrorStep";
import ShowCredentialStep from "../steps/ShowCredentialStep";
import { useStep } from "@flywire/react-hooks";
import { useSelector } from "react-redux";
import i18n from "utils/i18n";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const RegenerateWorkflowsModal = ({ clientId, setIsOpen, isOpen }) => {
  const [credentials, setCredentials] = useState({});
  const token = useSelector((state) => state.api.token);
  const steps = [
    {
      id: "password-verification",
      component: PasswordVerificationStep,
    },
    {
      id: "password-verification",
      component: PasswordVerificationStep,
    },
    {
      id: "warning",
      component: WarningStep,
    },
    {
      id: "show-credentials",
      component: ShowCredentialStep,
    },
    {
      id: "error",
      component: ErrorStep,
    },
  ];
  const { step, navigation, reset } = useStep({ steps });
  const { component: StepComponent } = step;

  const resources = {
    regenerateCredentials: () => {
      return {
        endpoint: `/api/v1/applications/credentials/${clientId}/regenerate`,
        headers: {
          "Access-Control-Allow-Origin": true,
          "X-Authentication-Token": token,
        },
        proxy: false,
        method: "POST",
      };
    },
  };

  const warningStepTexts = {
    title: i18n("dashboard.sections.merchantCredentials.step.regenerate.title"),
    subTitle: i18n(
      "dashboard.sections.merchantCredentials.step.regenerate.subtitle"
    ),
    buttonText: i18n(
      "dashboard.sections.merchantCredentials.step.regenerate.button"
    ),
  };

  const loadingStepTexts = {
    title: i18n(
      "dashboard.sections.merchantCredentials.step.loaderRegenerate.title"
    ),
    subTitle: i18n(
      "dashboard.sections.merchantCredentials.step.loaderRegenerate.subtitle"
    ),
  };

  const retryModal = () => {
    setIsOpen(!isOpen);
    reset();
    window.location.reload();
  };

  return (
    <Modal
      id="modal-credential"
      className="credentials-modal"
      isOpen={isOpen}
      zIndex={100}
      centered
      size="lg"
    >
      <ModalHeader
        className="credentials-modal-header"
        toggle={() => retryModal()}
      ></ModalHeader>
      <ModalBody className="credentials-modal-body">
        <StepComponent
          navigation={navigation}
          credentials={credentials}
          setCredentials={setCredentials}
          resources={resources.regenerateCredentials}
          warningTexts={warningStepTexts}
          loadingTexts={loadingStepTexts}
          retryModal={retryModal}
        />
      </ModalBody>
    </Modal>
  );
};

export default RegenerateWorkflowsModal;
