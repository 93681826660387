import React from 'react';

import thunk from 'redux-thunk';
import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import { QueryClientProvider } from '@tanstack/react-query';

import rootReducer from 'reducers';
import MainRouter from 'router';
import { queryClient } from './data-fetching';
import { PermissionsContextProvider } from 'contexts/permissionsContext';
import 'styles/index.scss';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  undefined,
  compose(
    composeEnhancers(applyMiddleware(thunk, createLogger()))
  ),
)

export const CoreApp = () => {
  persistStore(store, null)

  return (
    <Provider store={store}>
      <PermissionsContextProvider>
        <QueryClientProvider client={queryClient}>
          <MainRouter />
        </QueryClientProvider>
      </PermissionsContextProvider>
    </Provider>
  )
}
