import React, {useState} from 'react';
import DashboardPanel from 'containers/Dashboard/components/DashboardPanel';
import FullTextSearch from 'components/FullTextSearch';
import Loader from 'components/Loader';
import classNames from "classnames";
import __ from 'utils/i18n';
import { StaffPanel } from '../StaffPanel/StaffPanel';
import FlexTable from 'components/FlexTable';

export const StaffContent = ({data, isFetching, error}) => {

  const [ items, setItems ] = useState([]);
  const [ showModalDetails, setShowModalDetails ] = useState(false);
  const [ values, setValues ] = useState([]);

  // Table Columns
  const userName = ({ original }) => {
    return original.name;
  }

  const userEmail = ({ original }) => {
    return original.email;
  }

  const userLastAccess = ({ original }) => {
    return original.lastAccess;
  }

  const userStatus = ({ original }) => {
    return original.enabled ? __('dashboard.sections.staffs.enabled') : __('dashboard.sections.staffs.disabled');
  }

  const columns = [{
    Header: __('dashboard.sections.staffs.staffName'),
    Cell: userName,
    width: 300
  }, {
    Header: __('dashboard.sections.staffs.staffEmail'),
    Cell: userEmail,
    width: 300,
  }, {
    Header: __('dashboard.sections.staffs.staffLastAccess'),
    Cell: userLastAccess,
    width: 200,
    maxWidth: 300
  }, {
    Header: __('dashboard.sections.staffs.staffStatus'),
    Cell: userStatus
}];

const showDetails = values => {
  if(values) {
    setShowModalDetails(true);
    setValues(values)
  }
};

  return(
    <div
      className={classNames({
        "dashboard-card": true,
        "panel-expanded": showModalDetails
      })}>

      <DashboardPanel
        expanded={showModalDetails}
        panelHeading={values && values.name}
        hidePanel={()=>setShowModalDetails(false)}>
        <StaffPanel data={values}/>
      </DashboardPanel>
      <div className="dashboard-card-header">
        <FullTextSearch
          keys={['name', 'email']}
          items={data}
          onChange={items => setItems(items)}
        />
      </div>
      <div className="dashboard-card-body">
        { isFetching && <Loader /> }
        { !isFetching && error === false && (items.length > 0) ? (
          <FlexTable
            data={items}
            columns={columns}
            cellHeight={70}
            onRowClick={({index}) => showDetails(items[index])}
          />
        ) : (
          <div className="dashboard-card-message">
            <i className="mdi mdi-minus-circle"/>
            <h3>{__('dashboard.sections.staffs.without')}</h3>
            <p>{__('dashboard.sections.staffs.notFound')}</p>
          </div>
        )}
        { !isFetching && error !== false && (
          <div className="dashboard-card-message">
            <h3 className="text-danger">{__('dashboard.sections.staffs.errorServer')}</h3>
          </div>
        )}
      </div>
    </div>
  )
}
