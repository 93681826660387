import React, {useContext, useState} from "react";
import { useSelector } from "react-redux";
import __ from "utils/i18n";
import {Button, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { PermissionsContext } from "contexts/permissionsContext";
import { Link } from "react-router-dom";
import { getConfig } from "config";
import { buyReaderUrl } from "config/envConfig";
import { fetchResource } from '../../../../../../../api-provider/actions';
import {getExternalReaderBuyUrl} from "./resources";

export const BuyReader = () => {
  const token = useSelector((state) => state.api?.token);
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const buyReaderLink = useSelector((state) => state.login?.user?.buyReaderURL) || `${buyReaderUrl}?token=${token}`;

  const isBuyReaderEnabled =
    buyReaderLink &&
    featureFlags?.includes("user_menu_buy_reader") &&
    userPermissions?.includes("user_menu_buy_reader");

  const showActivatePoket = getConfig("customConfig.activatePoket");

  const [isOpenModal, setOpenModal] = useState(false);
  const getExternalUrl = () => {
    fetchResource(getExternalReaderBuyUrl(token))
      .then((url) => {
        let win = window.open(url, '_blank');
        win.focus();
      })
      .catch((error) => {
        setOpenModal(true)
      });
  }
  const toggle = () => {
    setOpenModal(!isOpenModal)
  }

  const handleClick = () =>{

    if(featureFlags?.includes("dynamic_buy_reader_link")){
      getExternalUrl()
    }else{
      let win = window.open(buyReaderLink, '_blank');
      win.focus();
    }
  }

  return (
    <>
      <div>
        {isBuyReaderEnabled && (
          <a onClick={handleClick} target="_blank" rel="noopener noreferrer">
            <DropdownItem className="nav-menu-item buy-reader">
              <i className="mdi mdi-18px mdi-calculator" />
              <span>{__("dashboard.components.buyReader")}</span>
            </DropdownItem>
          </a>

        )}
      </div>
      <div>
        {showActivatePoket && showActivatePoket && (
          <Link to="/dashboard/merchant/readers">
            <DropdownItem className="nav-menu-item">
              <i className="mdi mdi-18px mdi-asterisk" />
              <span>Activar Poket</span>
            </DropdownItem>
          </Link>
        )}
      </div>

      <Modal isOpen={isOpenModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{__('dashboard.buyReader.modal.error.title')}</ModalHeader>
        <ModalBody>
          {__('dashboard.buyReader.modal.error.body')}
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>
            {__('dashboard.buyReader.modal.error.button')}
          </Button>
        </ModalFooter>
      </Modal>

    </>
  );
}
