import React, { useState, useEffect } from "react";
import "../../../../../../styles/newStyles/MerchantCredentials.scss";
import { Button } from "reactstrap";
import { Container, Col } from "reactstrap";
import CreateWorkflowsModal from "./modal/workflows/CreateWorkflowsModal";
import { CredentialList } from "./components/CredentialList";
import { fetchResource } from "api-provider";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import i18n from "utils/i18n";

import newCredentials from "../../../../../../assets/new-credentials.svg";
import credentialsCreate from "../../../../../../assets/credentialsCreate.svg";
import gtmEvents from "utils/gtmEvents";

export function MerchantCredentials() {
  const [listItems, setListItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const token = useSelector((state) => state.api.token);

  useEffect(() => {
    gtmEvents.credentialsPageView('credentials');
    const resources = {
      getCredentialsList: () => {
        setIsLoading(true);
        return {
          endpoint: "/api/v1/applications/credentials",
          headers: {
            "X-Authentication-Token": token,
            "Access-Control-Allow-Origin": true,
          },
          proxy: false,
          method: "GET",
        };
      },
    };

    fetchResource(resources.getCredentialsList)
      .onSuccess((response) => {
        setIsLoading(false);
        setListItems(response.data);
      })
      .onFailure((error) => {
        setIsLoading(false);
      });
  }, [token]);

  const toggleModal = () => {
    setShowModal(true);
  };

  return (
    <div className="dashboard-card ">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {listItems.length > 0 ? (
            <div className="dashboard-card credentials-card p-0">
              <Container fluid className="credentials-card-container">
                <div className="credentials-card-row">
                  <>
                    <Col>
                      <div className="credentials-table credentials-table-title">
                        {i18n(
                          "dashboard.sections.merchantCredentials.credentiaList.title"
                        )}

                        <Button
                          className="btn btn-primary credentials-btn"
                          color="primary"
                          type="button"
                          onClick={() => toggleModal()}
                        >
                          <img
                            src={newCredentials}
                            alt=""
                            className="credentials-card-btn-icon mr-3"
                          />

                          {i18n(
                            "dashboard.sections.merchantCredentials.credentiaList.buttonNewCredential"
                          )}
                        </Button>
                      </div>
                      <CreateWorkflowsModal
                        setIsOpen={setShowModal}
                        isOpen={showModal}
                      />
                      <div className="credentials-table-list">
                        <table className="credentials-table-position">
                          <tr className="credentials-table-content">
                            <th className=" credentials-table-text-name">
                              {i18n(
                                "dashboard.sections.merchantCredentials.credentiaTable.nameText"
                              )}
                            </th>

                            <th className=" credentials-table-text-actions">
                              {i18n(
                                "dashboard.sections.merchantCredentials.credentiaTable.actionText"
                              )}
                            </th>
                          </tr>
                        </table>
                      </div>
                      <CredentialList items={listItems} />
                    </Col>
                  </>
                </div>
              </Container>
            </div>
          ) : (
            <div className="credentials-main">
              <img
                src={credentialsCreate}
                alt=""
                className="credentials-card-btn-icon"
              />
              <h2 className="credentials-main-title">
                {i18n("dashboard.sections.merchantCredentials.mainTitle")}
              </h2>

              <h5 className="credentials-main-subtitle">
                {i18n("dashboard.sections.merchantCredentials.subtitle")}
              </h5>

              <Button
                className="btn btn-primary"
                color="primary"
                type="button"
                onClick={() => toggleModal()}
              >
                {i18n("dashboard.sections.merchantCredentials.button")}
              </Button>

              <CreateWorkflowsModal
                setIsOpen={setShowModal}
                isOpen={showModal}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
