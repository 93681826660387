const headers = (token, roleUser = null) => {
  var headerObject = {
    'X-Authentication-Token': token,
  };

  if (roleUser) {
    headerObject['X-USER-ROLE'] = roleUser.toUpperCase();
  }

  return headerObject;
};

export const resources = {
  findStore: ({accountId, token, roleUser}) => ({
    endpoint: `/api/v1/stores/account/${accountId}`,
    method: 'GET',
    headers: headers(token, roleUser),
    cache: true,
    proxy: false,
  }),
  create: ({payload, token, roleUser}) => ({
    endpoint: '/api/v1/stores',
    method: 'POST',
    headers: headers(token, roleUser),
    data: {
      ...payload,
    },
    proxy: false,
  }),
  edit: ({payload, storeId, token, roleUser}) => ({
    endpoint: `/api/v1/stores/${storeId}`,
    method: 'PUT',
    headers: headers(token, roleUser),
    data: payload,
    proxy: false,
  }),
  deactivate: ({storeId, token, roleUser}) => ({
    endpoint: `/api/v1/stores/${storeId}`,
    method: 'PUT',
    headers: headers(token, roleUser),
    data: {
      isEnabled: false,
    },
    proxy: false,
  }),
};
