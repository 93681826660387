import classNames from "classnames";
import getTransactionStatus from "./resources/getTransactionStatus";
import __ from 'utils/i18n';

export const TotalAmountCell = ({ original }) => {
  const type = original.type;
  const status = getTransactionStatus(original);
  const amount =
    status === 'refund' || type === 'ADJUSTMENT_DEBIT'
      ? -original.totalAmount
      : original.totalAmount;
  return (
    <span
      className={classNames({
        'text-amount': true,
        'text-amount-warn': status !== 'closed' || type === 'ADJUSTMENT_DEBIT',
      })}
      >
      {__.currency(amount, original.currency, 'decimal')}
    </span>
  );
};
