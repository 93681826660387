import React, { useContext } from "react";
import DashboardTabbedSection from "containers/Dashboard/components/dashboardSection/DashboardTabbedSection";
import DashboardSectionTab from "containers/Dashboard/components/dashboardSection/DashboardSectionTab";
import { PermissionsContext } from "contexts/permissionsContext";
import  MerchantSection  from "./subsections/MerchantSubsidiaries";
import ReadersSection from "./subsections/MerchantReaders";
import { Users } from "./subsections/Users";
import { getConfig } from "config";
import __ from "utils/i18n";
import { MerchantCredentials } from "./subsections/Credentials";

export const Merchant = () => {
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const isUsersTabEnabled =
    featureFlags?.includes("merchant_users") &&
    userPermissions?.includes("merchant_users");
  const isCredentialsTabEnabled =
    featureFlags?.includes("merchant_credentials") &&
    userPermissions?.includes("merchant_credentials");

    const showReaderTabEnabled = getConfig('customConfig.showReader');
    const showLocalTabEnabled = getConfig('customConfig.showLocal')
    
  return (
    <DashboardTabbedSection>
      {isUsersTabEnabled && (
        <DashboardSectionTab
          path="users"
          title={__("dashboard.sections.merchantUsers.title")}
        >
          <Users />
          
        </DashboardSectionTab>
      )}
      {showReaderTabEnabled && (
        <DashboardSectionTab
          path="readers"
          title={__("dashboard.sections.merchantReaders.title")}
        > 
          <ReadersSection />
        </DashboardSectionTab>
      )}
      {showLocalTabEnabled && (
        <DashboardSectionTab
          path="local"
          title={__("dashboard.sections.merchantSubsidiaries.title")}
        > 
          <MerchantSection />
        </DashboardSectionTab>
      )}
      {isCredentialsTabEnabled && (
        <DashboardSectionTab
          path="credentials"
          title={__("dashboard.sections.merchantCredentials.title")}
        >
          <MerchantCredentials />
        </DashboardSectionTab>
      )}
    </DashboardTabbedSection>
  );
};
