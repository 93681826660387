import __ from "utils/i18n";
import React from "react";
import { DashboardModal } from "../components/DashboardModal";
import helpDark from "../../../assets/help-dark.svg";
import oneClickProductsDark from "../../../assets/one-click-products-dark.svg";
import sendEmailDark from "../../../assets/send-email-dark.svg";
import { getConfig } from "config";

const HelpModal = (props) => {
  const { isOpen, toggle } = props;
  const { frequentQuestionsURL, contactFormURL, contactEmail } = getConfig("customConfig.helpModal");
  const openUrl = (url) => window.open(url, "_blank");

  return (
    <>
      {isOpen && (
        <DashboardModal
          showModal={isOpen}
          hideModal={() => toggle("false")}
          headerText={__("dashboard.components.modal.helpModal.title")}
          cancelText={__("actions.close")}
          modalClass="bank-account-modal"
        >
          <div className="help-modal-description">
            <p>{__("dashboard.components.modal.helpModal.description")}</p>
          </div>

          <div>
            {frequentQuestionsURL && (
              <div className="help-modal-card" onClick={() => openUrl(frequentQuestionsURL)}>
                <img src={helpDark} alt="" className="mr-3" />
                <p className="help-modal-title">{__("dashboard.components.modal.helpModal.frequentQuestions")}</p>
                <i className="mdi mdi-18px mdi mdi-chevron-right help-modal-arrow"></i>
              </div>
            )}

            {contactFormURL && (
              <div className="help-modal-card" onClick={() => openUrl(contactFormURL)}>
                <img src={oneClickProductsDark} alt="" className="mr-3" />
                <p className="help-modal-title">{__("dashboard.components.modal.helpModal.contactForm")}</p>
                <i className="mdi mdi-18px mdi mdi-chevron-right help-modal-arrow"></i>
              </div>
            )}

            {contactEmail && (
              <div className="help-modal-card" onClick={() => openUrl(`mailto:${contactEmail}`)}>
                <img src={sendEmailDark} alt="" className="mr-3" />
                <p className="help-modal-title help-modal-email-desktop">{contactEmail}</p>
                <p className="help-modal-title help-modal-email-mobile">{ __("dashboard.components.modal.helpModal.alternativeEmailText")} </p>
                <i className="mdi mdi-18px mdi mdi-chevron-right help-modal-arrow"></i>
              </div>
            )}
          </div>
        </DashboardModal>
      )}
    </>
  );
};

export default HelpModal;