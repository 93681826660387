
import React from 'react'
import { Redirect } from 'react-router-dom'

import {
  Login,
  Dashboard,
  Logout,
  NotFound,
  ForgotPassword,
  CreatePassword,
} from 'containers'

const routes = [
  { path:'/login/callback', component:Login },
  { path:'/login', component:Login },
  { path:'/logout', component:Logout },

  { path:'/create-password/:token', component:CreatePassword },
  { path:'/reset-password/:token', component:ForgotPassword },
  { path:'/reset-password', component:ForgotPassword },

  { path:'/dashboard', auth:true, component:Dashboard },
  { path:'/', exact:true, auth:true, render:() => <Redirect to="/dashboard"/> },

  { path:'/', auth:true, component:NotFound },
  { path:'/', component:Login },
]

export default routes;
