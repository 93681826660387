import React from 'react';
import { SelectField, SimpleForm } from 'components/forms';
import __ from 'utils/i18n';

export const SortingField = ({ sortingField, handleSortingField }) => {
  const options = [
    { value: 'amount', label: 'Monto' },
    { value: 'createdAt', label: 'Fecha' },
    { value: 'description', label: 'Descripcion' },
    { value: 'maxElements', label: 'Stock' },
  ];

  return (
    <div className='statusDropdownContainer'>
      <SimpleForm
        defaultValues={{
          sorting_field: sortingField ? sortingField : 'createdAt',
        }}
      >
        <SelectField
          name='sorting_field'
          placeholder={
            sortingField
              ? sortingField
              : __('dashboard.sections.compraClick.sorting.dropdownField')
          }
          options={options.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
          value={sortingField}
          onChange={(value) => handleSortingField(value)}
        />
      </SimpleForm>
    </div>
  );
};
