import React from 'react'
import { Button } from 'reactstrap'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'

class TableHeaderActions extends React.Component {
  render() {
    const { createItem, tableActions } = this.props
    return (
      <div className="actions-header">
        { createItem && createItem.action && <Button
          className="action__create-item action-create-item-radius"
          color="primary"
          onClick={createItem.action}>
          {/*onBlur={createItem.onBlur || ''}>*/}
            <i className="mdi mdi-plus mdi-24px mdi-left"/> {createItem.name}
          </Button> }

        { (tableActions && tableActions.length > 0) && <UncontrolledDropdown className="select-filters">
          <DropdownToggle caret>
            Acciones
          </DropdownToggle>
          <DropdownMenu right>
            { tableActions
              .map((item, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => this.runAction(item.action)}>
                  <i className={item.icon}/> {item.name}
                </DropdownItem>
              )) }
          </DropdownMenu>
        </UncontrolledDropdown> }
      </div>
    )
  }
}

export default TableHeaderActions
