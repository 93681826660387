import React, { useEffect } from "react";
import { Button } from "reactstrap";
import IconError from 'assets/error-cross.svg';
import i18n from "utils/i18n";
import gtmEvents from "utils/gtmEvents";

function ErrorStep(props) {

  useEffect(()=>{
    gtmEvents.credentialsPageView('credentialDenied');
  }, []);

  return (
    <div className="credentials-modal-body-steps credentials-modal-error-step">  
        <img src={IconError} alt="" className="error-step-icon" />
        <div className="credentials-modal-error-step-content">
            <h5 className="credentials-modal-title credentials-modal-error-step-title text-center"> {i18n("dashboard.sections.merchantCredentials.step.error.title")}</h5>
            <h5 className="credentials-modal-subtitle credentials-modal-error-step-subtitle text-center"> {props.errorMessage}</h5>
        </div>
        <Button onClick={() => props.retryModal()} color="primary" className="credentials-modal-step-button credentials-btn" >
          {i18n("dashboard.sections.merchantCredentials.step.error.cancelButton")}
        </Button>
    </div>
  );
}

export default ErrorStep;