import React, { useState } from 'react';
import { Alert, Button, Col, Row } from 'reactstrap';
import { importFile } from '../../../resources';
import __ from 'utils/i18n';
import { fetchResource } from 'api-provider';

export const ImportResults = ({ data, token, subsidiaryId }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [importOk, setImportOk] = useState(null);

  const onImport = () => {
    setIsFetching(true);
    fetchResource(
      importFile(data.importFileName, token, subsidiaryId)
    ).then((response) => {
      setIsFetching(false);

      if (response?.data?.success === 'OK') {
        setImportOk(true);
      } else {
        setImportOk(false);
      }
    });
  };

  return (
    <div>
      {isFetching && (
        <div>
          {__('dashboard.sections.compraClick.import.upload.message.importing')}
        </div>
      )}

      {importOk === null && (
        <div>
          <Row>
            <Col md='12'>
              <h4 className='import-modal__subtitle'>
                <i className='mdi mdi-file' />
                {__(
                  'dashboard.sections.catalog.productsImport.elementsImports'
                )}
              </h4>
              {data.createQuantity ? (
                <p>
                  {__(
                    'dashboard.sections.compraClick.import.upload.message.import',
                    { count: data.createQuantity }
                  )}
                </p>
              ) : (
                <p>
                  {__(
                    'dashboard.sections.compraClick.import.upload.message.import_empty'
                  )}
                </p>
              )}
            </Col>
          </Row>

          {data.createQuantity !== 0 && (
            <Row>
              <Button color='success' onClick={() => onImport()}>
                {__('dashboard.sections.catalog.productsImport.import')}
              </Button>
            </Row>
          )}
        </div>
      )}

      {!isFetching && importOk && (
        <Col md='12'>
          <Alert color='success'>
            <i className='mdi mdi-success' />
            {__('dashboard.sections.compraClick.import.success')}
          </Alert>
        </Col>
      )}
    </div>
  );
};
