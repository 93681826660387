import React from "react";
import { useSelector, useDispatch } from "react-redux";
import __ from "utils/i18n";
import { DropdownItem } from "reactstrap";
import { APIRequest } from "api-provider";
import { setCourierOrder } from "containers/CourierTracking/actions";

const resources = {
  courierTracking: (subsidiaryId, token) => ({
    endpoint: `subsidiary/${subsidiaryId}/courier-package-tracking`,
    method: "GET",
    headers: {
      "x-authentication-token": token,
    },
  }),
};

export const CourierTracking = ({ onClick }) => {
  const dispatch = useDispatch();

  const subsidiaryId = useSelector((state) => state.api?.user.subsidiaryId);
  const token = useSelector((state) => state.api?.token);

  const setCourierTracking = (data) => {
    dispatch(setCourierOrder(data.items));
  };

  return (
    <APIRequest
      resource={resources.courierTracking(subsidiaryId, token)}
      onSuccess={(data) => setCourierTracking(data)}
      fetch
    >
      {(request) => {
        return (
          <>
            {request.response && (
              <DropdownItem className="nav-menu-item" onClick={onClick}>
                <div className="courier-tracking-menu-item">
                  <i className="mdi mdi-18px mdi-package-variant-closed" />
                  <span className="title">
                    {__("dashboard.components.courierTracking.menu.title")}
                  </span>
                  <span className="caption">
                    {__("dashboard.components.courierTracking.menu.caption")}
                  </span>
                </div>
              </DropdownItem>
            )}
          </>
        );
      }}
    </APIRequest>
  );
};
