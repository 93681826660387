import React from 'react'
import { Button } from 'reactstrap';
import __ from "utils/i18n";

export const SaveForm = ({ isFormEnabled, isEditStoreEnabled, values, request, storeState }) => {

    return (
        <>
            <div className="ecommerceForm-saveButton-container">
                <Button
                    color="success"
                    type="submit"
                    className="ecommerceForm-saveButton"
                    disabled={
                        isEditStoreEnabled && (request.isFetching ||
                            !(storeState || isFormEnabled(values.isEnabled)))
                    }
                >
                    {__("actions.save")}
                </Button>
            </div>
        </>
    )
}