import React from 'react';
import __ from 'utils/i18n';
import moment from 'moment';
import { isNull } from 'lodash';
import { Badge } from 'reactstrap';

const CheckboxCell = (handleCheckboxChange, checkboxList) => ({ original }) => {
  return (
    <div className='catalog__product--name'>
      <span>
        <input
          id={`checkbox-${original.id}`}
          name='checkOrder'
          onChange={e => handleCheckboxChange(e.target.value, e.target.checked)}
          type='checkbox'
          value={original.id}
          checked={checkboxList ? checkboxList.includes(original.id) : false}
        />
      </span>
    </div>
  );
};

const statusCell = (expirationDateFormat, countryTimezone) => ({ original }) => {
  if (isNull(original.disabledAt)) {
    if (parseInt(original.maxElements) === 0) {
      return <Badge color='warning'>{__('dashboard.sections.compraClick.notAvailable')}</Badge>
    } else {
      if (original.expiredAt) {
        const now = moment().tz(countryTimezone).format(expirationDateFormat);
        const expirationDate = moment(original.expiredAt.date).format(expirationDateFormat)
        if (moment(expirationDate, expirationDateFormat).diff(moment(now, expirationDateFormat)) < 0) {
          return <Badge color='warning'>{__('dashboard.sections.compraClick.expired')}</Badge>
        }
      }
      return <Badge color='success'>{__('dashboard.sections.compraClick.available')}</Badge>
    }
  } else {
    if (original.disabledStatusKey === 'has_successful_payment') {
      return <Badge color='success' title={__('dashboard.sections.compraClick.blockedApproved')}>
        {__('dashboard.sections.compraClick.blockedApprovedLabel')}
      </Badge>
    } else if (original.disabledStatusKey === 'reached_max_failed_attempts') {
      return <Badge color='danger' title={__('dashboard.sections.compraClick.blockedDenied')}>
        {__('dashboard.sections.compraClick.blocked')}
      </Badge>
    }
  }
};

const quatityCell = ({ original }) => {
  if (!!original.maxElements) {
    return original.maxElements;
  } else {
    return '∞';
  }
};

const AmountCell = ({ original }) => {
  return (
    <span className='text-amount'>
      {__.currency(original.amount, original.currency.code, 'decimal')}
    </span>
  );
};

const ButtonDescriptionCell = ({ original }) => {
  const image = original.imageUrl ? original.imageUrl : null
  const imageClasses = {
    backgroundColor: original.backgroundColor ? '#' + original.backgroundColor : ''
  };

  return (
    <div className='catalog__product--name'>
      <span
        style={original.imageUrl ? {} : imageClasses}
        className='catalog__product--image'>
        {original.imageUrl
          ? <img
            className={'img-cover ' + imageClasses}
            src={image}
            height='45px'
            width='45px'
            alt=''
          />
          : <span>{original.description.charAt(0)}</span>
        }
      </span>
      <span>
        {original.description}
      </span>
    </div>
  );
};

export const getPaymentButtonColumns = (
  setShowShareModal,
  handleCheckboxChange,
  checkboxList,
  countryTimezone,
  expirationDateFormat,
  checkboxAll,
  getActions,
  selectRow,
  setShowDeleteModal
) => {
  const configColumns = [
    'checkbox',
    'description',
    'quatity',
    'status',
    'amount',
    'actions'
  ];

  const salesColumns = [];

  const defaultColumns = {
    checkbox: {
      Header: checkboxAll,
      Cell: CheckboxCell(handleCheckboxChange, checkboxList),
      width: 30,
      maxWidth: 200,
      cancelClick: true,
    },
    description: {
      id: 'productName',
      Header: __('dashboard.sections.compraClick.name'),
      Cell: ButtonDescriptionCell,
      width: 200
    },
    quatity: {
      Header: __('dashboard.sections.compraClick.quantity'),
      Cell: quatityCell,
      width: 200,
      maxWidth: 300
    },
    amount: {
      Header: __('dashboard.sections.compraClick.amount'),
      Cell: AmountCell,
      width: 100,
      maxWidth: 200,
      align: 'right'
    },
    status: {
      Header: __('dashboard.sections.compraClick.status'),
      Cell: statusCell(expirationDateFormat, countryTimezone),
      width: 100,
      maxWidth: 200,
      align: 'right'
    },
    actions: {
      Header: '',
      Cell: getActions(setShowShareModal, selectRow, setShowDeleteModal),
      width: 100,
      maxWidth: 200,
      align: 'right',
      cancelClick: true
    }
  }

  configColumns.map(item => (
    defaultColumns[item] && salesColumns.push(defaultColumns[item])
  ))

  return salesColumns;
};