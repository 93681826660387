import React from 'react';
import { asField } from 'informed';
import classnames from "classnames";

const Checkbox = asField(({ id, fieldState, fieldApi, onClick, onChange, label, help, type, ...props }) => {
    const unicId = 'checkbox_' + id;
    return(
      <div className="position-relative form-group checkbox">
        <label htmlFor={unicId}>
          <input
            id={unicId}
            type="checkbox"
            fieldState={fieldState}
            fieldApi={fieldApi}
            className={classnames({
              "form-control": true,
              "has-error": fieldState.error
            })}
            onChange={(e)=>{
              fieldApi.setValue(e.target.checked);
              onChange && onChange(e.target.checked);
              fieldApi.setError(undefined);
            }}
            onClick={()=>{onClick && onClick()}}
            {...props}
            checked={fieldState.value}
          />
          <span>{label}</span>

        </label>

        {fieldState.error
          ? <small className="text-danger form-text text-muted">{fieldState.error}</small>
          : <small className="form-text">{help}</small>
        }

      </div>

    )
  }
);

export default Checkbox;

