
import { ACTIONS } from './constants'
import { setToken } from 'api-provider'
import { tenantId } from 'config/envConfig';

// Actions
export const logout = () => (dispatch) => {
  dispatch(setToken(''));
  dispatch({ type: ACTIONS.LOGOUT });

  localStorage.removeItem(`geop_permissions_${tenantId}`);
  localStorage.removeItem(`geop_feature_flags_${tenantId}`);
  localStorage.removeItem(`geop_oauth_${tenantId}`);
  localStorage.removeItem('sessionTimestamp');
  localStorage.removeItem('persist:root');
  
  window.location.href = "/";
}
