import React, { useContext } from "react";
import { PermissionsContext } from "contexts/permissionsContext";
import __ from "utils/i18n";
import DashboardTabbedSection from "containers/Dashboard/components/dashboardSection/DashboardTabbedSection";
import DashboardSectionTab from "containers/Dashboard/components/dashboardSection/DashboardSectionTab";
import { StatsSales } from "./components/StatsSales";
import { StatsSection } from "./components/StatsSection";
import { getConfig } from "config";

export const Stats = () => {
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const statsEnabled = getConfig("customConfig.stats.statsEnabled");

  const isSalesTabEnabled =
    featureFlags?.includes("stats_sales") &&
    userPermissions?.includes("stats_sales");

  return (
    <DashboardTabbedSection>
      {isSalesTabEnabled && (
        <DashboardSectionTab
          path="sales"
          title={__("dashboard.sections.stats.tabs.sales")}
          component={StatsSales}
        />
      )}
      {statsEnabled.map(
        (stat) =>
          userPermissions?.includes(`stats_${stat}`) &&
          featureFlags?.includes(`stats_${stat}`) && (
            <DashboardSectionTab
              path={stat}
              title={__(`dashboard.sections.stats.tabs.${stat}`)}
            >
              <StatsSection path={stat} />
            </DashboardSectionTab>
          )
          )}
    </DashboardTabbedSection>
  );
};
