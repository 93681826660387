import React from 'react';
import { SelectField, SimpleForm } from 'components/forms'
import __ from 'utils/i18n';

export const StatusDropdownComponent = ({ status, handleStatus }) => {
  
  const options = [
    { value: "EXPIRED", label: __('dashboard.sections.ecommerce.orders.status.EXPIRED') },
    { value: "PENDING", label: __('dashboard.sections.ecommerce.orders.status.PENDING') },
    { value: "FAILED", label: __('dashboard.sections.ecommerce.orders.status.FAILED') },
    { value: "FAIL_CHECKOUT", label: __('dashboard.sections.ecommerce.orders.status.FAIL_CHECKOUT') },
    { value: "SUCCESS", label: __('dashboard.sections.ecommerce.orders.status.SUCCESS') },
    { value: "COMPLETED", label: __('dashboard.sections.ecommerce.orders.status.COMPLETED') }
  ];

  return (
    <div className='statusDropdownContainer'>
      <SimpleForm>
        <SelectField
          name="status"
          placeholder={status ? status : __('dashboard.sections.ecommerce.orders.status.dropdownPlaceholder')}
          options={options.map(option => (
            { label: option.label, value: option.value }
          ))}
          value={status}
          onChange={(value) => {
            handleStatus(value)
          }
          }
        />
      </SimpleForm>
    </div>
  )
}