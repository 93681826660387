import React from 'react'
import { BasicText, asField } from 'informed';
import { InputGroup } from 'reactstrap';
import classnames from "classnames";

const Color = asField(({ fieldState, fieldApi, onChange,  label, ...props }) => {
    return(
      <div className="position-relative form-group">
        <label className="">{label}</label>
        <InputGroup>
          <BasicText
            className={classnames({
              "form-control": true,
              "color-widget": true,
              "has-error": fieldState.error
            })}
            fieldState={fieldState}
            onChange={(e)=>{
              fieldApi.setValue(e.target.value);
              onChange(e.target.value);
            }}
            fieldApi={fieldApi}
            {...props}
          />
          <div className="input-group-prepend">
            <input
              type="color"
              value={fieldState.value}
              onChange={(e)=>{
                fieldApi.setValue(e.target.value);
                onChange(e.target.value);
              }}/>
              <div className="widget" style={{backgroundColor: fieldState.value}}></div>
          </div>
        </InputGroup>
        {fieldState.error ? (
          <small className="text-danger form-text text-muted">{fieldState.error}</small>
        ) : null}

      </div>

    )
  }
);

export default Color

