import React from "react";
import { SimpleForm, TextField } from "components/forms";
import __ from 'utils/i18n';
import '../../../../../../styles/newStyles/staffSection.scss';
import { Link } from "react-router-dom";

export const StaffPanel = ( data ) => {

   // Crear endpoint en Jarvis para que devuelva dinámicamente esta info
  const userPrivileges = [
    {
      id:1,
      label: __('dashboard.sections.staffs.userTab.seeOwnHistory')
    },
    {
      id:2,
      label: __('dashboard.sections.staffs.userTab.seeEverybodyHistory')
    },
    {
      id:3,
      label: __('dashboard.sections.staffs.userTab.changeBranchPhoto')
    },
    {
      id:4,
      label: __('dashboard.sections.staffs.userTab.editCatalog')
    },
    {
      id:5,
      label: __('dashboard.sections.staffs.userTab.readFeedbacks')
    },
    {
      id:6,
      label: __('dashboard.sections.staffs.userTab.closeBatches')
    },
    {
      id:7,
      label: __('dashboard.sections.staffs.userTab.annulTransactions')
    },
    {
      id:8,
      label: __('dashboard.sections.staffs.userTab.makeRefunds')
    },
  ]

  return(
    <div className="panel-flex staff-panel">
      <div className="fields-panel">
        <div>
          <span className="staff-panel-image"><i className="mdi mdi-account mdi-24px"></i></span>
          <hr />
          <Link className="btn btn-link staff-panel-link" to={`/dashboard/sales/sales/${data.id}`}>{__('dashboard.sections.staffs.salesHistory')}</Link>
          <hr />
        </div>
        <SimpleForm style={{ height: 'fit-content' }} values={data.data}>
          <TextField
            type="text"
            field="firstName"
            id="firstName"
            label={__('dashboard.sections.staffs.staffName')}
            disabled={true} />
          <TextField
            type="text"
            field="lastName"
            id="lastName"
            label={__('dashboard.sections.staffs.staffLastName')}
            disabled={true} />
          <TextField
            type="text"
            field="email"
            id="email"
            label={__('dashboard.sections.staffs.staffEmail')}
            disabled={true} />
        </SimpleForm>
        <h3 className="staff-panel-subtitle">{__('dashboard.sections.staffs.userPrivileges')}</h3>
        <hr />
        <ul className="staff-panel-list-privileges">
          {userPrivileges.map((item) => (
            <li key={item.id}>
              <i className="mdi mdi-check mr-2"></i>
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
