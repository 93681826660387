export const handlePagination = (pagination, action) => {
  switch (action.type) {
    case 'page':
      return { ...pagination, page: action.payload.page };
    case 'page_size':
      return { ...pagination, page: 1, page_size: action.payload.page_size };
    default:
      return pagination;
  };
};

export const handleSorting = (sorting, action) => {
  switch (action.type) {
    case 'field':
      return { ...sorting, sorting_field: action.payload.sortingFieldSelected };
    case 'criteria':
      return { ...sorting, sorting_criteria: action.payload.sortingCriteriaSelected };
    default:
      return sorting;
  };
};
