import i18n from "./i18n";
import _ from 'lodash';
import moment from 'moment';
import { getConfig } from "config";

const dateAdapter = (dateTimes, format = 'L HH:mm') => {
    if (_.isEmpty(dateTimes)) {
        return "";
    }
    if (dateTimes.dateTime) {
        return i18n.stringToDate(dateTimes.dateTime, format);
    }
    if (dateTimes.dateTimeTimestamp) {
        const timestamp = dateTimes.dateTimeTimestamp;
        const convertToMilliseconds = getConfig('customConfig.convertTimestampSecondsToMilliseconds');
        const formatedTimestamp = convertToMilliseconds ? (timestamp * 1000) : timestamp;
        return i18n.date(formatedTimestamp, format);
    }
    if (dateTimes.dateTimeTimestampUTC) {
        if (dateTimes.localTimezone) {
            return i18n.date(dateTimes.dateTimeTimestampUTC, format, dateTimes.localTimezone);
        }
        return i18n.date(dateTimes.dateTimeTimestampUTC, format, moment.tz?.guess(true));
    }
    return "";
}

export default dateAdapter;