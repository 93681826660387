import React from 'react'
import { BasicText, TextArea, asField } from 'informed';
import classnames from "classnames";

const Text = asField(({ fieldState, fieldApi, onChange, label, help, type, ...props }) => {
    const Input = type === 'textarea' ? TextArea : BasicText;
    return(
      <div className="position-relative form-group">
        <label className="">{label}</label>
        <div className="input-group">
          <Input
            className={classnames({
              "form-control": true,
              "has-error": fieldState.error
            })}
            fieldState={fieldState}
            fieldApi={fieldApi}
            onChange={(val)=>{
              onChange && onChange(val);
              fieldApi.setError(undefined);
            }}
            {...props}
          />
        </div>
        {fieldState.error
          ? <small className="text-danger form-text text-muted">{fieldState.error}</small>
          : <small className="form-text">{help}</small>
        }

      </div>

    )
  }
);

export default Text

