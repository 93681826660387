import React from 'react'
import __ from "utils/i18n";

export const Subsidiaries = ({ selectedLocations, isFormEnabled, handleCheckboxChange, values, setValue }) => {

    return (
        <>
            <div className="ecommerceForm-panel">
                <div className="ecommerceForm-min-panel">
                    <div className="ecommerceForm-mainTitle-container">
                        <label className="ecommerceForm-mainTitle ecommerceForm-primary">
                            {__("store.form.availablesSubsidiariesTitle")}
                        </label>
                        <label>
                            {__("store.form.availablesSubsidiariesInfo")}
                        </label>
                    </div>
                    <div className="ecommerceForm-subsidiaries-container">
                        {selectedLocations.map((location, index) => (
                            <div
                                key={index}
                                className="ecommerceForm-subsidiary-field"
                            >
                                <input
                                    id={`checkbox-${location.id}`}
                                    name={location.name}
                                    className="ecommerceForm-subsidiary-checkbox"
                                    onChange={(e) =>
                                        handleCheckboxChange(
                                            e.target.value,
                                            e.target.checked,
                                            setValue
                                        )
                                    }
                                    type="checkbox"
                                    checked={location.enabled}
                                    value={location.id}
                                    disabled={!isFormEnabled(values.isEnabled)}
                                />
                                <label
                                    htmlFor={`checkbox-${location.id}`}
                                    className="ecommerceForm-subsidiary-label"
                                >
                                    {location.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}