import React, { useContext } from "react";
import { useSelector } from "react-redux";
import DashboardTabbedSection from "containers/Dashboard/components/dashboardSection/DashboardTabbedSection";
import DashboardSectionTab from "containers/Dashboard/components/dashboardSection/DashboardSectionTab";
import { Categories } from "./components/Categories";
import { Products } from "./components/Products";
import { PermissionsContext } from "contexts/permissionsContext";

import __ from "utils/i18n";

export const Catalog = () => {
  const { subsidiaryId } = useSelector((state) => state.login.user);
  const { token } = useSelector((state) => state.api);

  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const isProductsEnabled =
    featureFlags?.includes("catalog_products") &&
    userPermissions?.includes("catalog_products");
  const isCategoriesEnabled =
    featureFlags?.includes("catalog_categories") &&
    userPermissions?.includes("catalog_categories");

  // TODO validar que tenant usa newTabs
  // const newTabs = getConfig("dashboard.catalog.tabs") || null;
  const newTabs = null;

  return (
    <DashboardTabbedSection>
      {isProductsEnabled && (
        <DashboardSectionTab
          path="products"
          title={__("dashboard.sections.catalog.tabs.products")}
        >
          <Products />
        </DashboardSectionTab>
      )}

      {isCategoriesEnabled && (
        <DashboardSectionTab
          path="categories"
          title={__("dashboard.sections.catalog.tabs.categories")}
        >
          <Categories />
        </DashboardSectionTab>
      )}
      {newTabs && (
        <>
          {newTabs.map((tab, index) => (
            <DashboardSectionTab key={index} path={tab.title} title={tab.title}>
              <tab.component subsidiaryId={subsidiaryId} token={token} />
            </DashboardSectionTab>
          ))}
        </>
      )}
    </DashboardTabbedSection>
  );
};
