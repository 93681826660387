import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import { Modal } from 'reactstrap';
import { Step1, Step1Error, Step2, Step3 } from './Steps';

const steps = [
  {
    key: 'import',
    component: Step1,
  },
  {
    key: 'importError',
    component: Step1Error,
  },
  {
    key: 'importResult',
    component: Step2,
  },
  {
    key: 'uploadImages',
    component: Step3,
  },
];

export const ProductsImport = ({ showImport, onClose }) => {
  const [step, setStep] = useState();
  const [data, setData] = useState({});

  const { subsidiaryId } = useSelector((state) => state.login.user);
  const { token } = useSelector((state) => state.api);

  const StepComponent = step && step.component;

  const goStep = (step, data) => {
    setStep(find(steps, { key: step }));
    setData(data);
  };

  useEffect(() => {
    if (showImport) {
      setStep(steps[0]);
    }
  }, [showImport])

  if (!step) return null;

  return (
    <Modal
      className='dashboard-modal import-modal'
      isOpen={showImport}
      toggle={onClose}
      zIndex={3000}
    >
      <StepComponent
        token={token}
        subsidiaryId={subsidiaryId}
        onHide={onClose}
        data={data}
        goStep={(step, data) => goStep(step, data)}
      />
    </Modal>
  );
};
