const getInstallmentType = (transaction) => {
  if (
    transaction.installments &&
    transaction.installments > 1
  ) {
    return transaction.installmentsAlias;
  } else {
    return 'Normal';
  }
};

export default getInstallmentType;
