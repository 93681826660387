import React, { useContext } from "react";
import __ from "utils/i18n";
import { DropdownItem } from "reactstrap";
import { PermissionsContext } from 'contexts/permissionsContext';

export const HelpModal = ({ onClick }) => {
  const { featureFlags } = useContext(PermissionsContext);
  const isHelpModalEnabled = featureFlags?.includes("user_menu_help_modal");

  return (
    <>
      {isHelpModalEnabled && (
        <DropdownItem className="nav-menu-item" onClick={onClick}>
          <i className="mdi mdi-18px mdi-help-circle" />
          <span>{__("dashboard.components.helpModal")}</span>
        </DropdownItem>
      )}
    </>
  );
};
