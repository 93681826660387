import { TextField } from 'components/forms';
import React, { useState } from 'react'
import classNames from "classnames";
import { Col, Row } from 'reactstrap';
import Dropzone from "react-dropzone";
import __ from "utils/i18n";
import { ImageCrooper } from '../modals/Crooper';
import { DeleteLogoModal } from '../modals/DeleteImage';

let dropzoneRef;

export const Information = ({
    isFormEnabled,
    values,
    getConfig,
    setValue,
    nameCounter,
    setNameCounter,
    descriptionCounter,
    setDescriptionCounter,
    mainMessageCounter,
    setMainMessageCounter,
    croppedImage,
    setCroppedImage,
    showModal,
    setShowModal
}) => {

    const [updatedImage, setUpdatedImage] = useState(null);

    const handleDescriptionField = (value) => {
        if (value) setDescriptionCounter(value.length);
        else setDescriptionCounter(0);
    };

    const handleNameField = (value) => {
        if (value) setNameCounter(value.length);
        else setNameCounter(0);
    };

    const handleMessageField = (value) => {
        if (value) setMainMessageCounter(value.length);
        else setMainMessageCounter(0);
    };

    const encodeFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setUpdatedImage(reader.result);
                resolve(reader.result);
            };
            reader.onerror = (error) => reject(error);
        });
    };

    const onImageDrop = (images, setFormValue) => {
        const { accepted, rejected } = images;

        if (accepted.length) {
            encodeFile(accepted[0]).then((encodedLogo) =>
                setFormValue("logo", encodedLogo)
            );
        }
        setShowModal("editLogoModal");
        if (rejected.length) {
            alert(__("inputs.image.alertSize", { maxSize: 1 }));
        }
    };

    return (
        <>
            <div className="ecommerceForm-panel">
                <div className="ecommerceForm-mainTitle-container">
                    <label className="ecommerceForm-mainTitle ecommerceForm-primary">
                        {__("store.form.configurationTitle")}
                    </label>
                    <label className="ecommerceForm-mainSubtitle">
                        {__("store.form.configurationInfo")}
                    </label>
                </div>

                <div className="ecommerceForm-configuration-section-container">
                    <Row className="ecommerceForm-configuration-row-container">
                        <Col xs={12} sm={12} md={3}>
                            <label
                                htmlFor={"name"}
                                className="ecommerceForm-field-label"
                            >
                                {__("store.form.fields.merchant")}
                            </label>
                            <label
                                htmlFor={"name"}
                                className="ecommerceForm-field-description"
                            >
                                {__("store.form.fields.merchantDescription")}
                            </label>
                        </Col>
                        <Col
                            className="ecommerceForm-merchant-field"
                            xs={12}
                            sm={12}
                            md={3}
                        >
                            <div className="ecommerceForm-field-help-container">
                                <label className="ecommerceForm-field-caracter-count">
                                    {nameCounter}/50
                                </label>
                            </div>
                            <TextField
                                id={"name"}
                                name={"name"}
                                type={"text"}
                                maxLength={50}
                                placeholder={__(
                                    "store.form.fields.merchantPlaceholder"
                                )}
                                disabled={!isFormEnabled(values.isEnabled)}
                                onChange={(e) => handleNameField(e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3}>
                            <label className="ecommerceForm-field-label">
                                {__("store.logo.title")}
                            </label>
                            <label className="ecommerceForm-field-description">
                                {__("store.logo.description")} (
                                {__("store.logo.message")})
                            </label>
                        </Col>
                        <Col xs={12} sm={12} md={3}>
                            <div className="ecommerceForm-field-logo-container">
                                <div className="commerceForm-field-dropzone">
                                    <Dropzone
                                        className={classNames({ dropzone: true })}
                                        ref={(node) => {
                                            dropzoneRef = node;
                                        }}
                                        activeClassName="dropzoneActive"
                                        rejectClassName="dropzoneReject"
                                        accept="image/jpeg, image/png"
                                        maxSize={1e6}
                                        multiple={false}
                                        maxFiles={1}
                                        disabled={!isFormEnabled(values.isEnabled)}
                                        onDrop={(accepted, rejected) => {
                                            onImageDrop({ accepted, rejected }, setValue);
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {croppedImage ? (
                                                    <img
                                                        className="img-cover ecommerceForm-img-cover"
                                                        src={croppedImage}
                                                        alt="ecommerce-logo"
                                                    />
                                                ) : (
                                                    <label className="ecommerceForm-default-image">
                                                        <i className="mdi mdi-image mdi-48px" />
                                                    </label>
                                                )}
                                            </div>
                                        )}
                                    </Dropzone>
                                    <ImageCrooper
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        updatedImage={updatedImage}
                                        setCroppedImage={setCroppedImage}
                                        setValue={setValue}
                                    />
                                    <DeleteLogoModal
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        setValue={setValue}
                                        setUpdatedImage={setUpdatedImage}
                                        setCroppedImage={setCroppedImage}
                                    />
                                </div>
                                <div className="ecommerceForm-dropzoneInfo">
                                    <button
                                        type="button"
                                        className="ecommerceForm-button-icon"
                                        onClick={(e) => {
                                            if (isFormEnabled(values.isEnabled)) {
                                                e.preventDefault();
                                                dropzoneRef.open();
                                            }
                                        }}
                                    >
                                        <label className="ecommerceForm-icon">
                                            <i className="mdi mdi-pencil" />
                                        </label>
                                    </button>
                                    <button
                                        type="button"
                                        className="ecommerceForm-button-icon"
                                        onClick={(e) => {
                                            if (isFormEnabled(values.isEnabled)) {
                                                e.preventDefault();
                                                if (updatedImage || croppedImage) {
                                                    setShowModal("deleteLogo");
                                                }
                                            }
                                        }}
                                    >
                                        <label className="ecommerceForm-icon">
                                            <i className="mdi mdi-delete" />
                                        </label>
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={3}>
                            <label
                                htmlFor={"shortDescription"}
                                className="ecommerceForm-field-label"
                            >
                                {__("store.form.fields.description")}
                            </label>
                            <label
                                htmlFor={"shortDescription"}
                                className="ecommerceForm-field-description"
                            >
                                {__("store.form.fields.descriptionInfo")}
                            </label>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={3}
                            className="ecommerceForm-merchant-field"
                        >
                            <div className="ecommerceForm-field-help-container">
                                <label className="ecommerceForm-field-caracter-count">
                                    {descriptionCounter}/30
                                </label>
                            </div>
                            <TextField
                                id={"shortDescription"}
                                name={"shortDescription"}
                                type={"text"}
                                maxLength={30}
                                placeholder={__(
                                    "store.form.fields.descriptionPlaceholder"
                                )}
                                disabled={!isFormEnabled(values.isEnabled)}
                                onChange={(e) => handleDescriptionField(e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3}>
                            <label
                                htmlFor={"mainMessage"}
                                className="ecommerceForm-field-label"
                            >
                                {__("store.form.fields.mainMessage")}
                            </label>
                            <label
                                htmlFor={"mainMessage"}
                                className="ecommerceForm-field-description"
                            >
                                {__("store.form.fields.mainMessageDescription")}
                            </label>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={3}
                            className="ecommerceForm-merchant-field colWidthFull"
                        >
                            <div className="ecommerceForm-field-help-container">
                                <label className="ecommerceForm-field-caracter-count">
                                    {mainMessageCounter}/80
                                </label>
                            </div>
                            <TextField
                                id={"mainMessage"}
                                name={"mainMessage"}
                                type={"text"}
                                maxLength={80}
                                placeholder={__(
                                    "store.form.fields.mainMessagePlaceholder"
                                )}
                                disabled={!isFormEnabled(values.isEnabled)}
                                onChange={(e) => handleMessageField(e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} className="colWidthFull">
                            <label
                                htmlFor={"contactPhone"}
                                className="ecommerceForm-field-label"
                            >
                                {__("store.form.fields.phone")}
                            </label>
                            <label
                                htmlFor={"contactPhone"}
                                className="ecommerceForm-field-description"
                            >
                                {__("store.form.fields.phoneDescription")}
                            </label>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={3}
                            className="ecommerceForm-merchant-field colWidthFull"
                        >
                            <TextField
                                id={"contactPhone"}
                                name={"contactPhone"}
                                placeholder={__(
                                    "store.form.fields.phonePlaceholder"
                                )}
                                type={"tel"}
                                disabled={!isFormEnabled(values.isEnabled)}
                                onInput={(e) => {
                                    if (e.target.value.length) {
                                        e.target.value = e.target.value.slice(
                                            0,
                                            getConfig(
                                                "customConfig.ecommerceStore.phoneLength"
                                            )
                                        );
                                        e.target.value = e.target.value.replace(
                                            getConfig(
                                                "customConfig.ecommerceStore.phoneRegexValidation"
                                            ),
                                            ""
                                        );
                                    }
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} className="colWidthFull">
                            <label
                                htmlFor={"contactEmail"}
                                className="ecommerceForm-field-label"
                            >
                                {__("store.form.fields.email")}
                            </label>
                            <label
                                htmlFor={"contactEmail"}
                                className="ecommerceForm-field-description"
                            >
                                {__("store.form.fields.emailDescription")}
                            </label>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={3}
                            className="ecommerceForm-merchant-field colWidthFull"
                        >
                            <TextField
                                id={"contactEmail"}
                                name={"contactEmail"}
                                type={"text"}
                                placeholder={__(
                                    "store.form.fields.emailPlaceholder"
                                )}
                                disabled={!isFormEnabled(values.isEnabled)}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
