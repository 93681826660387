import { TextField } from 'components/forms';
import React from 'react'
import { Col, Row } from 'reactstrap';
import __ from "utils/i18n";

export const SocialNetworks = ({ isFormEnabled, values, getConfig }) => {

    return (
        <>
            <div className="ecommerceForm-panel">
                <div className="ecommerceForm-mainTitle-container">
                    <label className="ecommerceForm-mainTitle ecommerceForm-primary">
                        {__("store.form.socialNetworkTitle")}
                    </label>
                    <label className="ecommerceForm-mainSubtitle">
                        {__("store.form.socialNetworkInfo")}
                    </label>
                </div>
                <Row>
                    <div className="ecommerceForm-socialNetwork-container">
                        <Col xs={12} sm={12} md={2}>
                            <label
                                htmlFor={"socialWhatsapp"}
                                className="ecommerceForm-field-label"
                            >
                                <i className="mdi mdi-whatsapp" />
                                {__("store.form.fields.whatsapp")}
                            </label>
                            <label
                                htmlFor={"socialWhatsapp"}
                                className="ecommerceForm-field-description"
                            >
                                {__("store.form.fields.whatsappDescription")}
                            </label>
                        </Col>
                        <Col
                            className="ecommerceForm-merchant-field"
                            xs={12}
                            sm={12}
                            md={2}
                        >
                            <TextField
                                id={"socialWhatsapp"}
                                name={"socialWhatsapp"}
                                placeholder={__(
                                    "store.form.fields.whatsappPlaceholder"
                                )}
                                type={"tel"}
                                onInput={(e) => {
                                    if (e.target.value.length) {
                                        e.target.value = e.target.value.slice(
                                            0,
                                            getConfig(
                                                "customConfig.ecommerceStore.phoneLength"
                                            )
                                        );
                                        e.target.value = e.target.value.replace(
                                            getConfig(
                                                "customConfig.ecommerceStore.phoneRegexValidation"
                                            ),
                                            ""
                                        );
                                    }
                                }}
                                disabled={!isFormEnabled(values.isEnabled)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={2}>
                            <label
                                htmlFor={"socialInstagram"}
                                className="ecommerceForm-field-label"
                            >
                                <i className="mdi mdi-instagram" />
                                {__("store.form.fields.instagram")}
                            </label>
                            <label
                                htmlFor={"socialInstagram"}
                                className="ecommerceForm-field-description"
                            >
                                {__("store.form.fields.instagramDescription")}
                            </label>
                        </Col>
                        <Col
                            className="ecommerceForm-merchant-field"
                            xs={12}
                            sm={12}
                            md={2}
                        >
                            <TextField
                                id={"socialInstagram"}
                                name={"socialInstagram"}
                                type={"text"}
                                maxLength={30}
                                placeholder={__(
                                    "store.form.fields.instagramPlaceholder"
                                )}
                                disabled={!isFormEnabled(values.isEnabled)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={2}>
                            <label
                                htmlFor={"socialFacebook"}
                                className="ecommerceForm-field-label"
                            >
                                <i className="mdi mdi-facebook" />
                                {__("store.form.fields.facebook")}
                            </label>
                            <label
                                htmlFor={"socialFacebook"}
                                className="ecommerceForm-field-description"
                            >
                                {__("store.form.fields.facebookDescription")}
                            </label>
                        </Col>
                        <Col
                            className="ecommerceForm-merchant-field"
                            xs={12}
                            sm={12}
                            md={2}
                        >
                            <TextField
                                id={"socialFacebook"}
                                name={"socialFacebook"}
                                type={"text"}
                                maxLength={30}
                                placeholder={__(
                                    "store.form.fields.facebookPlaceholder"
                                )}
                                disabled={!isFormEnabled(values.isEnabled)}
                            />
                        </Col>
                    </div>
                </Row>
            </div>
        </>
    )
}