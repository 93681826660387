import React from "react";
import { LogoLogin } from "components/LogoLogin";
import { Button } from "reactstrap";
import { SimpleForm, CheckboxField } from "components/forms";
import { TextField } from "components/forms/fields";
import { Row, Col } from "reactstrap";
import __ from "utils/i18n";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import { getConfig } from "config";
import "../../../styles/newStyles/LoginForm.scss";
import { RegisterLink } from "./RegisterLink"
import { HelperText } from "./HelperText"

export const LoginForm = ({
  error,
  onSubmit,
  form,
  endpoint,
  isLoading,
  setError,
}) => {

  const isCustomLogin = getConfig('customConfig.showCustomLogin');
  const showRegisterLink = getConfig('customConfig.showRegisterLink')
  const showHelperText = getConfig('customConfig.login.showHelperText')

  return (
    <SimpleForm
      onChange={() => setError(null)}
      onSubmit={onSubmit}
      values={{
        user: form.user,
        remember: form.remember,
        endpoint: endpoint,
      }}
    >
      <div className={`login-container ${isCustomLogin ? 'custom-login-content' : ''} `}>
        {isCustomLogin && <h1 className="login-welcome-starter">{__('login.titleStarter')}</h1>}

        <LogoLogin />
        <h2 className="login-welcome text-center">{__("login.title")}</h2>

        <>
          {error && (
            <div className="login-error-message text-danger text-center">
              {error}
            </div>
          )}

          <div>
            <label htmlFor="user" className="form-label">
              {" "}
              {__("login.user")}{" "}
            </label>
            <TextField
              id="user"
              name="user"
              type="text"
              maxLength={75}
              className={error ? "input-color-error" : ""}
              required
            />
          </div>

          <div>
            <label htmlFor="password" className="form-label">
              {" "}
              {__("login.password")}{" "}
            </label>
            <TextField
              id="password"
              name="password"
              type="password"
              className={`login-password-input ${
                error ? "input-color-error" : ""
              }`}
              required
            />
          </div>

          <div className="display-none">
            <TextField name="endpoint" />
          </div>
        </>

        <Row className="form-bottom">
          <Col sm="6">
            <CheckboxField name="remember" label={__("login.remember")} />
          </Col>
        </Row>

        <div className="login-button-container">
          {isLoading ? (
            <Loader absolute={false} />
          ) : (
            <Button
              className="btn btn-primary login-button buttom-radius"
              color="primary"
              type="submit"
            >
              {__("login.button")}
            </Button>
          )}
          <Link to="/reset-password" className="forgot-password-link">
            {__("login.forgot")}
          </Link>
        </div>

        {showRegisterLink && <RegisterLink />}

        {showHelperText && <HelperText />}
      </div>
    </SimpleForm>
  );
};
