
import React from 'react';
import { Chart } from './base';
import { getSalesChartColor } from 'utils';
export const formatComparison = (current, previous) => {

  if (typeof (current) === 'undefined') {
    current = 0
  }

  const percent = previous ?
    `${(100 * (current - previous) / previous).toFixed(2)} %` : '&#8734 %'

  const type = ((current > previous) ? 'major' : '') +
    ((current === previous) ? 'equal' : '') +
    ((current < previous) ? 'minor' : '')

  const icon = ({
    major: 'mdi mdi-chevron-up',
    minor: 'mdi mdi-chevron-down',
    equal: 'mdi mdi-minus'
  })[type]

  return `
    <span class="comparison">
      <span class="comparison-icon icon-${type}">
        <span class="${icon}"></span>
      </span>
      <span class="comparison-text">${percent}</span>
    </span>
  `
}

export class ComparativeLineChart extends React.Component {

  render() {

    const { data, comparativeData, metric, formatter, tooltip, type } = this.props
    const { chartConfig } = this.props

    if (!data || !data.length) {
      return <span>No data</span>
    }

    const chart = new Chart()

    const series = [
      {
        zIndex: 2,
        data: data && data.map(item => ({
          y: item[metric] || 0,
          item
        }))
      }
    ]

    if (comparativeData && comparativeData.length) {
      series.push({
        zIndex: 1,
        data: comparativeData && comparativeData.map(item => ({
          y: item[metric] || 0,
          item
        }))
      })
    }

    chart.setConfig({
      "chart.type": type || "line",
      "xAxis.categories": data.map(item => item.category),
      "yAxis.min": 0,
      "yAxis.labels.formatter": function () {
        return formatter ? formatter(this.value) : this.value
      },
      "customTooltip": tooltip,
      "series": series,
      "colors": getSalesChartColor(),
    })

    chart.setConfig(chartConfig)

    return chart.render()
  }
}
