import React from 'react';
import { SelectField, SimpleForm } from 'components/forms'
import __ from 'utils/i18n';

export const PageSizeComponent = ({ perPage, handlePageSize }) => {
  const options = [10, 20, 50, 100];

  return (
    <SimpleForm>
      <div className='pageSizeContainer'>
        <span style={{ fontWeight: 'bold' }}>{__('dashboard.paginator.registerQuantity')}</span>
        <SelectField
          name="sizePage"
          placeholder={perPage}
          options={options.map(option => (
            { label: option, value: option }
            ))}
          value={perPage}
          onChange={(value) => {
            handlePageSize(value)
          }
        }
        />
      </div>
    </SimpleForm>
  )
}