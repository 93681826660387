import React, { useEffect, useState }  from "react";
import { FormGroup } from "reactstrap";
import { RadioGroup } from "react-form";
import __ from "utils/i18n";
import { SimpleForm, TextField } from "components/forms";
import { TransparentButton } from "components/forms/fields";
import { SelectField } from 'components/forms';
import {APIRequest, fetchResource} from "api-provider";
import RadioInput from "components/forms/inputs/RadioInput";
import { useSelector } from "react-redux";
import { MainLoader } from "components/MainLoader";
import { getConfig } from "config";
import accountTypeLabel from "data/accountTypeLabel";
import '../../../../styles/newStyles/bankAccountModal.scss';
import {getBanks} from "./resources";

const BankAccountForm = (props) => {
  const { data, onSuccess, onError, onCancel } = props;

  const bankAccountNumberLabel = getConfig("customConfig.bankAccountNumberLabel");
  const bankAccountAlternativeNumberLabel = getConfig("customConfig.bankAccountNumberAlternativeLabel");
  const bankAccountIdentifierKeyName = getConfig("customConfig.bankAccountIdentifierKeyName");
  const bankAccountIdentifierKeyValueLength = getConfig("customConfig.bankAccountIdentifierKeyLength");
  const bankAccountAlternativeIdentifierKeyName = getConfig("customConfig.bankAccountAlternativeIdentifierKeyName");
  const bankAccountNumberDataType = getConfig("customConfig.bankAccountNumberDataType");
  const bankAccountNumberAlternativeDataType = getConfig("customConfig.bankAccountNumberAlternativeDataType");
  const isBankAccountSelectFormActive = getConfig('customConfig.showBankAccountSelectForm');

  const token = useSelector((state) => state.api?.token);
    
  const resources = {
    bankAccount: (payload) => {
      const payloadData = {
        bankAccount: payload,
      };

      return ({
        endpoint: 'account/bank-account',
        headers: {
          "x-authentication-token": token,
        },
        data: payloadData,
        method: 'PUT',
      });
    },
  };

  const [bankId] = useState(data.bankId);
  const [banks, setBanks] = useState([]);
  useEffect(()=>{
    fetchResource(getBanks(token)).onSuccess((data)=>{
        setBanks(data.data);
      }
    ).onFailure((error)=>{
        console.log(error)
      }
    );
  },[token]);

  return (
    <div>
      <APIRequest
        resource={resources.bankAccount}
        onSuccess={onSuccess}
        onFailure={onError}
      >
        {(request) => (
          <SimpleForm onSubmit={request.fetch} defaultValues={data}>
            {({ form, values }) => (
              <MainLoader isLoading={request.isFetching}>
                <header>
                  <h6 className="bank-account-modal-disclaimer-edition">{__("dashboard.components.modal.bankAccountEditionDisclaimer")}</h6>
                </header>
                {isBankAccountSelectFormActive ?
                <>
                  <FormGroup>
                    <SelectField
                      label={__("dashboard.components.modal.bank")}
                      field="bankId"
                      options={
                        banks.map(option => ({
                          value: option.attributes._id,
                          label: option.attributes.name
                        }))
                      }
                      value={bankId}
                    />
                  </FormGroup>
                  <FormGroup>
                    <SelectField
                      label={__("dashboard.components.modal.bankType")}
                      field="bankAccountType"
                      options={accountTypeLabel.map(option => ({
                        value: option.value,
                        label: option.name
                      }))}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      name="accountNumber"
                      minlength={5}
                      maxLength={17}
                      label={'Número de cuenta'}
                      pattern={'^[0-9]*$'}
                    />
                  </FormGroup>
                </>
                :
                <>
                  <FormGroup>
                    {bankAccountAlternativeIdentifierKeyName && (
                      <RadioGroup field="type">
                        <RadioInput
                          label={bankAccountAlternativeNumberLabel}
                          value={bankAccountAlternativeIdentifierKeyName}
                        />
                      </RadioGroup>
                    )}
                    <RadioGroup field="type">
                      <RadioInput
                        label={bankAccountNumberLabel}
                        value={bankAccountIdentifierKeyName}
                      />
                    </RadioGroup>
                  </FormGroup>

                  <FormGroup>
                    {values.type === bankAccountAlternativeIdentifierKeyName && (
                      <TextField
                        type={bankAccountNumberAlternativeDataType}
                        name={bankAccountAlternativeIdentifierKeyName}
                        placeholder={bankAccountAlternativeNumberLabel}
                        
                      />
                    )}
                    {values.type === bankAccountIdentifierKeyName && (
                      <TextField
                        type={bankAccountNumberDataType}
                        name={bankAccountIdentifierKeyName}
                        placeholder={bankAccountNumberLabel}
                        maxLength={bankAccountIdentifierKeyValueLength}
                      />
                    )}
                  </FormGroup>
                </>
                }
                <FormGroup className="bank-account-modal-form-actions">
                  <TransparentButton
                    style={{ color: "black" }}
                    type="button"
                    onClick={onCancel}
                  >
                    {__("actions.cancel").toUpperCase()}
                  </TransparentButton>
                  <TransparentButton color="primary" type="submit">
                    <strong>{__("actions.save").toUpperCase()}</strong>
                  </TransparentButton>
                </FormGroup>
              </MainLoader>
            )}
          </SimpleForm>
        )}
      </APIRequest>
    </div>
  );
};

export default BankAccountForm;
