import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { SimpleForm } from 'components/forms';
import { FieldsPanel } from 'containers/Dashboard/components/FieldsPanel';
import { validateFields, validEmail } from 'components/forms/validators';
import { APIRequest } from 'api-provider';
import { getUserFields } from '../../_shared/fields';
import { DeleteModal } from '../DeleteModal';
import { PanelFooter } from './PanelFooter';
import queryString from 'query-string';
import gtmEvents from 'utils/gtmEvents';
import { getConfig } from 'config';

export const UserPanel = ({ data, onSave, hidePanel, onDelete, showDetails }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(false);
  const userId = useSelector((state) => state.login.user.id);
  const token = useSelector((state) => state.api.token);
  const EditEnableDocumentUserForm = getConfig('customConfig.editDocumentUserForm');

  const validationsConfigEnableDocument = [
    {
      name: 'email',
      disabled: !!data.id,
    },
    {
      name: 'enable',
      disabled: !!data.enable,
    },
  ];

  const validationsConfig = [
    {
      name: 'email',
      disabled: !!data.id,
    },
    {
      name: 'document',
      disabled: !!data.id,
    },
    {
      name: 'enable',
      disabled: !!data.enable,
    },
  ];
  
  const validationsConfigEditEnable = EditEnableDocumentUserForm ? validationsConfigEnableDocument : validationsConfig;
  const fields = getUserFields();

  const validations = fields.map((item) => {
    if (item.name === 'uniqueCode') {
      return {field: item.name};
    }

    if (item.name === 'email') {
      return { field: item.name, validate: value => value && validEmail()(value), required: 1 };
    }

    if (item.name === 'document') {
      if(item.required === 1){
        return {field: item.name, required: 1};
      }
      return {field: item.name};
    }

    return { field: item.name, required: 1 };
  });

  const showModal = (status) => {
    setShowDeleteModal(status);
  };

  const editSuccess = (data) => {
    onSave(data);
    hidePanel(data);
    gtmEvents.newUserAction();
    
  };

  const editFailure = (data) => {
    setError(data);
  };

  const deleteSuccess = () => {
    onDelete().onSuccess((response) => {
      setShowDeleteModal(false);
      hidePanel();
    });
  };

  const editResources = ({ values, token }) => {
    const data = {};
    fields.forEach((field) => {
      if (
        (values.id && field.name === 'email')
         ||
        (!EditEnableDocumentUserForm && values.id && field.name === 'document')
      ) {
        return false;
      } else {
        data[field.name] = values[field.name];
      }
    });
    
    if (values.subsidiary) {
      const subsidiary = 'subsidiary[]';
      data[subsidiary] = values.subsidiary.map(
        (subsidiary) => subsidiary.value
      );
    }

    const idParam = values.id ? `/${values.id}` : '';
    const method = idParam ? 'PUT' : 'POST';

    return {
      endpoint: `users${idParam}`,
      method,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', "x-authentication-token": token },
      data: queryString.stringify(data),
    };
  };

  useEffect(() => {
    let subsidiariesArray = [];
    if (data.subsidiary) {
      subsidiariesArray = (data.subsidiary).map((subsidiary) => ({
        label: subsidiary.subsidiaryName,
        value: subsidiary.subsidiaryId,
      }));
    }

    setUserData({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      document: data.document,
      documentTypeId: data.documentTypeId,
      phone: data.phone,
      roleId: data.roleId,
      statusId: data.statusId,
      mobilePhone: data.mobilePhone,
      subsidiaryId: data.subsidiaryId,
      enable: data.enable,
      nationalIdNumber: data.nationalIdNumber,
      rbac: data.rbac,
      subsidiary: subsidiariesArray,
      uniqueCode: data.uniqueCode,
      
    });

  }, [data]);

  return (
    <div>
      {showDeleteModal && (
        <DeleteModal
          id={data.id}
          token={token}
          deleteSuccess={deleteSuccess}
          showModal={showDeleteModal}
          hideModal={showModal}
        />
      )}
      <APIRequest resource={editResources} onSuccess={editSuccess} onFailure={editFailure}>
        {(request) => {
          return (
            <SimpleForm
              defaultValues={userData}
              validateOnSubmit={true}
              validate={validateFields(validations)}
              reset={!showDetails}
              onSubmit={
                (values) => { request.fetch({ values, token }); }
              }
            >
              {({ values, touched }) => {
                return (
                  <div className='panel-flex'>
                    <div>
                      <FieldsPanel
                        configItems={fields}
                        validations={validationsConfigEditEnable}
                      />
                      {request.error && (
                        <Row>
                          <Col md='12'>
                            <div className='request-error'>{request.error}</div>
                          </Col>
                        </Row>
                      )}
                    </div>
                    <div>
                      <>
                        {error && (
                          <div className='error-block'>
                            <Row>
                              <Col md='12'>
                                <div className='request-error'>{error}</div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        <PanelFooter
                          isEdition={userData.id && userData.id !== userId}
                          sameUser={userData.id === userId}
                          touched={touched}
                          isFetching={request.isFetching}
                          showDeleteModal={showModal}
                        />
                      </>
                    </div>
                  </div>
                );
              }}
            </SimpleForm>
          );
        }}
      </APIRequest>
    </div>
  );
};
