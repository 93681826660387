

import React from 'react'
import { Link, Route, withRouter } from 'react-router-dom'

const NavItemLink = withRouter(props => (
  <Route path={props.to} exact={props.exact} children={({ match }) => (
    <li className="nav-item">
      <Link
        to={props.to}
        className={'nav-link' + (match ? ' active' : '')}
        {...props}>
        { props.icon && <i className={props.icon}/> }
        <span>{ props.label }</span>
      </Link>
    </li>
  )}/>
))

export default NavItemLink
