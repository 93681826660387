import { ACTIONS } from "./constants";

// TODO iniciar el estado en {}
const discoveryReducer = (state = { paymentLink: true, withholdingTax: true }, payload) => {
  switch (payload.type) {
    case ACTIONS.REMOVE_DISCOVERY:
      delete state[payload.discoveryId];
      return { ...state };
    default:
      return state;
  }
};

export default discoveryReducer;