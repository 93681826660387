import React from 'react';
import { useSelector } from 'react-redux';
import __ from 'utils/i18n';

export const Merchant = () => {
  const merchantCode = useSelector((state) => state.login?.user?.merchantCode);

  return (
    <>
      {merchantCode && (
        <div className="nav-menu-item merchant-code">
          <span>
            {__("dashboard.components.code")}: {merchantCode}
          </span>
        </div>
      )}
    </>
  );
};
