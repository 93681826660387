import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, FormGroup, Tooltip, Input, Button } from 'reactstrap';
import Toggle from 'react-toggle';
import numeral from 'numeral';
import { SimpleForm, SelectField, ProductImageField } from 'components/forms';
import { FieldsPanel } from 'containers/Dashboard/components/FieldsPanel';
import { validateFields } from 'components/forms/validators';
import { fetchResource } from 'api-provider';
import { getConfig } from 'config';
import __ from 'utils/i18n';
import { ecommerceUrl } from 'config/envConfig';
import { getCategoriesBySubsidiaryId } from '../../_shared/resources';
import { PermissionsContext } from 'contexts/permissionsContext';
import { DeleteProductModal } from './DeleteProductModal';
import { PanelFooter } from './PanelFooter';
import { SelectStockAction } from './SelectStockAction';
import gtmEvents from 'utils/gtmEvents';
import { setCompraClickProduct } from 'containers/Dashboard/sections/CompraClick/actions';
import { useMutation } from '@tanstack/react-query';

const validations = [
  { field: 'productName', required: 1 },
  { field: 'productPrice', validPrice: 1 },
];

export const ProductPanel = ({
  onSave,
  hidePanel,
  onDelete,
  showDetails,
  data,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productData, setProductData] = useState({
    productName: "",
    productImage: {},
    productPrice: {},
    taxes: {},
    stockControl: 0,
    stockAvailableQuantity: 0,
    stockReservedQuantity: 0,
  });
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(false);
  const [stockAction, setStockAction] = useState(1);
  const [productImage, setProductImage] = useState({});
  const [stockControlUpdate, setStockControlUpdate] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [createCompraClickTooltip, setCreateCompraClickTooltip] = useState(false);

  const currenciesAllowedForCompraClick = useSelector(state => state.login.user.paymentButtonCurrenciesPerSubsidiary);
  const { subsidiaryId } = useSelector((state) => state.login.user);
  const { token } = useSelector((state) => state.api);
  const ecommerceSlug = useSelector(
    (state) => state.dashboard.ecommerce?.slug
  );
  const ecommerceTaxes = useSelector((state) => state.login.user.showEcommerceTaxesDetail);
  
  const dispatch = useDispatch();
  
  const { featureFlags } = useContext(PermissionsContext);
  const isStockControlEnabled = featureFlags?.includes('stock_control');
  const isCompraClickEnabled = featureFlags?.includes('compra_click_section');
  const ecommerceIsEnable = featureFlags?.includes('ecommerce_section');
  const maxQuantity = getConfig('customConfig.products.maxStockQuantityInput');
  const fields = getConfig('customConfig.catalog.fieldsPanel');
  const currentProductId = productData.productId;
  const allowCreatingCompraClick = currenciesAllowedForCompraClick && currenciesAllowedForCompraClick[subsidiaryId] && currenciesAllowedForCompraClick[subsidiaryId]
  .findIndex(item => item.code === productData?.productPrice?.currency) !== -1;

  const tooltipTimer = () => {
    setTimeout(() => {
      setTooltipOpen(true);
      setInterval(() => {
        setTooltipOpen(false);
      }, 3000);
    });
  };
  
  const getLink = (productHash) => {
    const paramNameSubsidiaryId = getConfig('customConfig.paramNameSubsidiaryId');
    return `${ecommerceUrl}/${ecommerceSlug}/product/${productHash}?${paramNameSubsidiaryId}=${subsidiaryId}`; 
  }

  const copyToClipboard = (productHash) => {
    if (tooltipOpen) return;
    navigator.clipboard.writeText(getLink(productHash)).then(() => {
      tooltipTimer();
    });
  };

  const openStoreNewTab = (productHash) => {
    window.open(getLink(productHash), '_blank');
  };

  const setStockControl = (value, values) => {
    setProductData(prevState => ({
      ...prevState,
      ...values,
      stockControl: value ? 1 : 0,
    }));
    setStockControlUpdate(true);
  };

  const setStockQuantity = (e, values) => {
    e.target.value = Math.abs(e.target.value);

    if (e.target.value > maxQuantity) {
      e.target.value = maxQuantity;
    }

    setProductData(prevState => ({
      ...prevState,
      ...values,
      stockQuantity: e.target.value,
    }));
  };

  const editSuccess = () => {
    onSave();
    setProductImage({});
    hidePanel();
    setError(false);
    gtmEvents.newProductAction();
  };

  const deleteSuccess = () => {
    onDelete();
    setShowDeleteModal(false);
    hidePanel();
  };

  const onRequestFailure = (data) => {
    if (data) {
      setError(data);
    }
  };

  const taxAmountToFloat = (str) => {
    let number = String(str).replace(',', '.');
    number = number.substring(0, number.lastIndexOf('.')).replace('.', '') + number.substring(number.lastIndexOf('.'), number.length);
    return parseFloat(number);
  };

  const editResources = (values, productHash, categoryHash) => {
    const taxes = [{ amount: values.taxes ? taxAmountToFloat(values.taxes.amount) / 100 : 0 }];

    const data = {
      productId: values.productId || undefined,
      productHash: productHash || undefined,
      backgroundColor: values.productImage.color,
      categoryId: values.categoryId || undefined,
      categoryHash: categoryHash || undefined,
      file: productImage.file || undefined,
      stockControl: values.stockControl || 0,
      stockQuantity: values.stockQuantity
        ? values.stockQuantity * values.stockAction
        : 0,
      deleteProductImage: values.productImage.deleteProductImage || undefined,
      channel: 'web',
      deviceId: 'web',
      deviceModel: 'web',
      deviceOS: 'web',
    };

    fields.forEach((field) => {
      if (field.name === 'productPrice') {
        data.productPrice = __.revertNumberFormat(values.productPrice.amount);
        data.currencyCode = values.productPrice.currency;
        data.productPrice = data.productPrice.toFixed(2);
        return;
      }

      if (field.name === 'taxes') {
        data.taxes = JSON.stringify(taxes);
        return;
      }
      
      data[field.name] = values[field.name];
    });


    return {
      endpoint: 'inventories/saveProduct.json',
      method: 'POST',
      headers: {
        'X-Authentication-Token': token,
      },
      formData: { ...data, token },
      params: {
        subsidiaryId: subsidiaryId || '',
      },
    };
  };

  const createCompraClick = (value) => {
    let amount = 0;
    let taxes = 0;

    if (ecommerceTaxes) {
      amount = value.productPrice.amount;
      taxes = value.taxes.amount;
    } else {
      amount = value.taxes.amount ? (value.productPrice.amount * (1 + value.taxes.amount / 100)) : value.productPrice.amount;
    }

    const data = {
      id: value.productId,
      imageUrl: value.productImage.url || undefined,
      amount: __.revertNumberFormat(amount.toFixed(2)),
      currency: { code: value.productPrice.currency },
      description: value.productName,
      taxes
    };

    dispatch(setCompraClickProduct(data));

    setTimeout(() => {
      window.location.href = '/dashboard/payment-button-product';
    }, 1000);
  }

  const editProductMutation = useMutation({
    mutationFn: (values) => fetchResource(editResources(values, data.productHash, data.categoryHash)),
    onSuccess: editSuccess,
    onError: (error) => onRequestFailure(error)
  });

  useEffect(() => {
    fetchResource(getCategoriesBySubsidiaryId(subsidiaryId, token))
      .onSuccess(({ data }) => {
        const options = [
          {
            value: "0",
            label: __('dashboard.sections.catalog.products.notCategory'),
          },
        ];

        if (data[0]) {
          data.forEach((category) => {
            options.push({
              value: category.categoryId,
              label: category.categoryName,
            });
          });
        }

        setCategories(options);
      })
      .onFailure((error) => {
        console.log(error);
      });
  }, [subsidiaryId, token]);

  useEffect(() => {
    setProductData({
      productName: data.productName || "",
      productId: data.productId || 0,
      productHash: data.productHash || "",
      productDescription: data.productDescription || "",
      productImage: {
        url: data.productImageUrl || "",
        color: data.backgroundColor || "",
      },
      productPrice: {
        amount: data.productPrice || 0,
        currency: data.currencyCode || getConfig("customConfig.currencies")[0].value,
      },
      taxes: { amount: (data.taxes && data.taxes[0]) ? data.taxes[0].amount * 100 : 0 },
      stockControl: data.stockControl || 0,
      stockQuantity: data.stockQuantity || 0,
      stockAvailableQuantity: data.stockAvailableQuantity || 0,
      stockReservedQuantity: data.stockReservedQuantity || 0,
      categoryId: data.categoryId || 0,
    })
    setStockAction(data.stockAction || 1)
    setStockControlUpdate(data.stockControlUpdate || false)
  }, [data])

  return (
    <>
      {showDeleteModal && (
        <DeleteProductModal
          productId={data.productId}
          token={token}
          deleteSuccess={deleteSuccess}
          showDeleteModal={() => setShowDeleteModal(false)}
        />
      )}
            <SimpleForm
              defaultValues={productData}
              validateOnSubmit={true}
              validate={validateFields(validations)}
              reset={!showDetails}
              onChange={() => setError(false)}
              onSubmit={(values) => {
                setProductData(values);
                editProductMutation.mutate(values);
              }}
            >
              {({ values, touched }) => {
                return (
                  <div className='panel-flex'>
                    <div>
                      <ProductImageField
                        name='productImage'
                        data={values}
                        setFile={(values) => setProductImage(values)}
                      />
                      {(isCompraClickEnabled) && (
                        <>
                          <div id='createPaymentButtonWrapper' className={`${!allowCreatingCompraClick && 'disabled-button-wrapper'}`}>
                            <Button
                              id='createPaymentButton'
                              className={`btn ${!allowCreatingCompraClick && 'disabled disabled-button'}`}
                              block={true}
                              onClick={() => createCompraClick(productData)} type='button'
                            >
                              {__('dashboard.sections.compraClick.title')}
                            </Button>
                          </div>

                          {!allowCreatingCompraClick && (
                            <Tooltip
                              placement='top'
                              isOpen={createCompraClickTooltip}
                              target='createPaymentButtonWrapper'
                              toggle={() => setCreateCompraClickTooltip(prevState => !prevState)}
                            >
                              {__('dashboard.components.panel.productCreateCompraClick.tooltip')}
                            </Tooltip>
                          )}

                          <hr />
                        </>
                      )}
                      <FieldsPanel configItems={fields} />
                      <SelectField
                        name='categoryId'
                        placeholder={__(
                          'dashboard.sections.catalog.components.productPanel.form.categoryId.placeholder'
                        )}
                        label={__(
                          'dashboard.sections.catalog.components.productPanel.form.categoryId.label'
                        )}
                        options={categories}
                      />
                      {Boolean(ecommerceIsEnable && values.productHash) && (
                        <div className='productPanel-flex-row-container'>
                          <>
                            {tooltipOpen && (
                              <Tooltip
                                placement='top'
                                isOpen={true}
                                target='TooltipLinkSection'
                              >
                                {__('store.form.storeUrlCopied')}
                              </Tooltip>
                            )}

                            <a
                              href='#/'
                              id='TooltipLinkSection'
                              onClick={() => {
                                copyToClipboard(values.productHash);
                              }}
                            >
                              <i className='mdi mdi-link-variant mdi-24px'></i>
                              {__('store.slug.shareStoreLink')}
                            </a>
                          </>
                          <>
                            <a
                              href='#/'
                              onClick={() => {
                                openStoreNewTab(values.productHash);
                              }}
                            >
                              <i className='mdi mdi-eye mdi-24px'></i>
                              {__('store.form.showOnStore')}
                            </a>
                          </>
                        </div>
                      )}
                      {isStockControlEnabled && (
                        <>
                          <hr />
                          <Row>
                            <Col
                              className='catalog__product--image-actions'
                              md='8'
                            >
                              <p style={{ marginBottom: '0.5%' }}>
                                <b>
                                  {__(
                                    'dashboard.sections.catalog.products.stockControl'
                                  )}
                                </b>
                              </p>
                            </Col>
                            <Col
                              className='catalog__product--image-actions'
                              md='4'
                            >
                              <div style={{ float: 'right' }}>
                                <div className='switch-input'>
                                  <Toggle
                                    checked={values.stockControl}
                                    onChange={() =>
                                      setStockControl(
                                        !values.stockControl,
                                        values
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className='catalog__product--image-actions'
                              md='12'
                            >
                              <small className='text-secondary'>
                                {values.stockControl
                                  ? __(
                                    'dashboard.sections.catalog.products.smallStockControlActive'
                                  )
                                  : __(
                                    'dashboard.sections.catalog.products.smallStockControl'
                                  )}
                              </small>
                            </Col>
                          </Row>
                          {Boolean(values.stockControl) && (
                            <>
                              <Row style={{ marginTop: '5%' }}>
                                <Col md='8'>
                                  <SelectStockAction
                                    state={stockAction}
                                    setStockAction={(data) =>
                                      setStockAction(data)
                                    }
                                  />
                                </Col>
                                <Col md='4'>
                                  <FormGroup check>
                                    <Input
                                      type='number'
                                      min='0'
                                      step='1'
                                      style={{ textAlign: 'right' }}
                                      value={values.stockQuantity}
                                      onChange={(e) =>
                                        setStockQuantity(e, values)
                                      }
                                      placeholder='Cantidad'
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div className='block-content block-spacing'>
                                <Row>
                                  <Col md='8'>
                                    <p>
                                      {__(
                                        'dashboard.sections.catalog.products.availableQuantity'
                                      )}
                                    </p>
                                  </Col>
                                  <Col md='4'>
                                    <p style={{ textAlign: 'right' }}>
                                      <b>
                                        {numeral(
                                          values.stockAvailableQuantity
                                        ).format('0,0')}
                                      </b>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md='12'>
                                    <small className='text-secondary'>
                                      {__(
                                        'dashboard.sections.catalog.products.conditionalZeroWarning'
                                      )}
                                    </small>
                                  </Col>
                                </Row>
                              </div>
                              {Boolean(values.stockReservedQuantity) && (
                                <div className='block-content block-spacing'>
                                  <Row>
                                    <Col md='8'>
                                      <p>
                                        {__(
                                          'dashboard.sections.catalog.products.reservedQuantity'
                                        )}
                                      </p>
                                    </Col>
                                    <Col md='4'>
                                      <p style={{ textAlign: 'right' }}>
                                        <b>
                                          {numeral(
                                            values.stockReservedQuantity
                                          ).format('0,0')}
                                        </b>
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md='12'>
                                      <small className='text-secondary'>
                                        {__(
                                          'dashboard.sections.catalog.products.reservedQuantityInfo'
                                        )}
                                      </small>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      {error && (
                        <div className='error-block'>
                          <Row>
                            <Col md='12'>
                              <div className='request-error'>{error}</div>
                            </Col>
                          </Row>
                        </div>
                      )}
                      <PanelFooter
                        productId={currentProductId}
                        touched={touched}
                        isFetching={editProductMutation.isLoading}
                        showDeleteModal={() => setShowDeleteModal(true)}
                        state={stockControlUpdate}
                      />
                    </div>
                  </div>
                );
              }}
            </SimpleForm>
    </>
  );
};
