import React, { useContext } from 'react';
import __ from 'utils/i18n';
import { DropdownItem } from 'reactstrap';
import { PermissionsContext } from "contexts/permissionsContext";

export const BankAccount = ({ onClick }) => {
  const { userPermissions, featureFlags } = useContext(PermissionsContext);

  const isBankAccountEnabled =
    featureFlags?.includes("user_menu_bank_account") &&
    userPermissions?.includes("user_menu_bank_account");

  return (
    <>
      {isBankAccountEnabled && (
        <DropdownItem className="nav-menu-item" onClick={onClick}>
          <i className="mdi mdi-18px mdi-bank" /><span>{__('dashboard.components.bankAccount')}</span>
        </DropdownItem>
      )}
    </>
  )
}
