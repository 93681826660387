import React from "react";

import { DashboardModal } from "containers/Dashboard/components/DashboardModal";
import StorePreviewImage from "assets/ecommerce.png";
import __ from "utils/i18n";

export const InfoModal = ({showModal, setShowModal}) => {
  return (
    <DashboardModal
      name="infoModal"
      headerText={__("store.form.info.infoModalTitle")}
      showModal={showModal === "infoModal"}
      submitAction={() => setShowModal(false)}
      hideModal={() => setShowModal(false)}
      hideClose={true}
      hideSeparator={true}
      submitColor="success"
      submitText={__("actions.accept")}
    >
      <div className="ecommerceForm-info-modal-container">
        <span className="ecommerceForm-info-modal-text">
          {__("store.form.info.infoModalMessage")}
        </span>
        <img
          className="ecommerceForm-info-modal-image"
          src={StorePreviewImage}
          alt="Tienda"
        />
      </div>
    </DashboardModal>
  );
};
