import { backendUrl, tenantId } from 'config/envConfig';
import { find } from 'lodash';

const headers = (token, roleUser = null) => {
  var headerObject = {
    'Content-Type': 'application/vnd.api+json',
    'X-Authentication-Token': token,
  };

  if (roleUser) {
    headerObject['X-USER-ROLE'] = roleUser.toUpperCase();
    headerObject['x-client-id'] = tenantId;
  }

  return headerObject;
};

export const validateCategory = (categories, categoryId, category) => {
  if (categoryId === category.categoryId) {
    return false;
  }

  while (category) {
    if ((category.parentCategoryId === categoryId) || (category.categoryId === category.parentCategoryId)) {
      return false;
    }

    category = find(
      categories,
      (currentCategory, category) => currentCategory.categoryId === category.parentCategoryId
    );
  }

  return true;
};

export const uploadProducts = (file, token, subsidiaryId) => ({
  endpoint: `v1/products/import`,
  method: 'POST',
  formData: {
    products: file,
    subsidiaryId,
  },
  headers: {
    'X-Authentication-Token': token,
  },
});

export const importProducts = (fileName, token, subsidiaryId) => ({
  endpoint: `v1/products/import`,
  method: 'POST',
  formData: {
    confirmation: 1,
    importFileName: fileName,
    subsidiaryId,
  },
  headers: {
    'X-Authentication-Token': token,
  },
});

export const getProducts = (subsidiaryId, token) => (payload) => ({
  endpoint: 'inventories/getProducts.json',
  method: 'POST',
  headers: {
    'X-Authentication-Token': token,
  },
  params: {
    subsidiaryId,
  },
  cache: false,
  parseResponse: (response) => response.data.data,
});

export const findStore = (token, roleUser, accountId) => ({
  endpoint: `/api/v1/stores/account/${accountId}`,
  method: 'GET',
  headers: headers(token, roleUser),
  cache: true,
  proxy: false,
});

export const getCategories = (subsidiaryId, token) => (payload) => ({
  endpoint: 'inventories/getCategories.json',
  method: 'POST',
  headers: {
    'X-Authentication-Token': token,
  },
  params: {
    subsidiaryId,
  },
  parseResponse: (response) => response.data.data,
});

export const editCategory = (values, token, subsidiaryId) => ({
  endpoint: 'inventories/saveCategory.json',
  method: 'POST',
  headers: {
    "X-Authentication-Token": token,
  },
  formData: {
    categoryName: values.categoryName,
    categoryId: values.categoryId || 0,
    parentCategoryId: values.parentCategory || 0,
    subsidiaryId: subsidiaryId,
    channel: 'web',
    deviceId: 'web',
    deviceModel: 'web',
    deviceOS: 'web',
    token: token,
  },
  params: {
    subsidiaryId: subsidiaryId || '',
  },
});

export const deleteCategory = (token, categoryId) => ({
  endpoint: 'inventories/deleteCategory.json',
  method: 'POST',
  headers: {
    'X-Authentication-Token': token,
  },
  formData: {
    categoryId,
    channel: 'web',
    deviceId: 'web',
    deviceModel: 'web',
    deviceOS: 'web',
  }
});

export const getCategoriesBySubsidiaryId = (subsidiaryId, token) => ({
  endpoint: "inventories/getCategories.json",
  method: "POST",
  params: {
    subsidiaryId: subsidiaryId,
  },
  headers: headers(token),
});

export const deleteProduct = (token, productId) => ({
  endpoint: 'inventories/deleteProduct.json',
  method: 'POST',
  headers: {
    'X-Authentication-Token': token,
  },
  formData: {
    productId,
    channel: 'web',
    deviceId: 'web',
    deviceModel: 'web',
    deviceOS: 'web',
  },
});

export const downloadExportFile = (token, subsidiaryId) => {
  fetch(`${backendUrl}/proxy/api/v1/products/export?subsidiaryId=${subsidiaryId}`, {
    method: 'GET',
    headers: {
      'x-client-id': tenantId,
      'x-Authentication-Token': token,
      'x-request-download': 'stream'
    },
  })
    .then(response => {
      const reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        }
      })
    })
    // Create a new response out of the stream
    .then(stream => new Response(stream))
    .then(response => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `product_export_${Date.now()}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
}
