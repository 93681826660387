import React from 'react';
import PropTypes from 'prop-types';
import { DashboardModal } from '../components/DashboardModal';
import { fetchResource } from 'api-provider/actions'
import __ from 'utils/i18n';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import ImageQR from 'assets/qrestatico.svg';
import Loader from 'components/Loader';
import ErrorMessage from 'components/flashMessages/ErrorMessage';

class StaticQRCodeModal extends React.Component {
  constructor({ token }) {
    super();

    this.state = {
      isLoading: false,
      downloadFailed: false,
    };

    this.resources = {
      download: () => {
        return {
          endpoint: "account/qr",
          method: "POST",
          headers: { "X-Authentication-Token": token },
        };
      },
    };
  }

  fetchQRData = () => {
    this.setState({ isLoading: true });

    fetchResource(this.resources.download)
      .onSuccess((data) => {
        this.download(data.pdfUrl);
        this.setState({ isLoading: false });
      })
      .onFailure((error) => {
        console.log(error);
        this.setState({
          downloadFailed: true,
          isLoading: false,
        });
      });
  };

  download = (url) => {
    let win = window.open(url, "_blank");
    win.focus();
  };

  render() {
    const { isOpen, toggle } = this.props;

    return (
      <DashboardModal
        showModal={isOpen}
        hideModal={() => toggle("false")}
        headerText={__("dashboard.components.modal.qrCode.headerText")}
        cancelText={__("actions.close")}
        modalClass="static-qr-code-modal"
      >
        <Row className="box-info">
          <Col>
            <Row>
              <Col lg="2">
                <img src={ImageQR} alt=""/>
              </Col>
              <Col lg="7">
                <p style={{ color: "#333333" }}>
                  <strong>
                    {__("dashboard.components.modal.qrCode.downloadAndPrint")}
                  </strong>
                </p>
                <p
                  style={{ color: "#999999" }}
                  dangerouslySetInnerHTML={{
                    __html: __(
                      "dashboard.components.modal.qrCode.putOnAVisibleLocation"
                    ),
                  }}
                ></p>
              </Col>
              <Col lg="3">
                {!this.state.isLoading ? (
                  <Button
                    color="link"
                    type="button"
                    onClick={() => this.fetchQRData()}
                  >
                    <strong>{__("actions.download")}</strong>
                  </Button>
                ) : (
                  <Loader isLoading={true} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.downloadFailed && (
              <ErrorMessage>
                {__("dashboard.components.modal.qrCode.downloadError")}
              </ErrorMessage>
            )}
          </Col>
        </Row>
      </DashboardModal>
    );
  }
}

StaticQRCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  token: get(state, 'api.token'),
});

export default connect(mapStateToProps)(StaticQRCodeModal);
