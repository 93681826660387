import React from 'react';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import __ from "utils/i18n";
import _ from "lodash";
import Stepper from "components/Stepper";
import { APIRequest } from "api-provider";
import { connect } from "react-redux";
import get from "lodash/get";
import { MainLoader } from "components/MainLoader";

const resources = {
  courierTrackingOrder: (subsidiaryId, token, orderId) => ({
    endpoint: `subsidiary/${subsidiaryId}/courier-package-tracking/${orderId}`,
    method: 'GET',
    headers: {
      'x-authentication-token': token
    }
  })
}

class CourierTrackingModal extends React.Component {

  render() {
    const { subsidiaryId, token } = this.props;
    const order = _.first(this.props.courierOrders);
    return (
      <APIRequest
        resource={resources.courierTrackingOrder(subsidiaryId, token, order.code)}
        fetch
      >
        {request => {
          return (
            <MainLoader isLoading={request.isFetching}>
              {request.response && (
                <DashboardModal
                  name="courierTrackingModal"
                  headerText={__('dashboard.components.courierTracking.modal.title')}
                  showModal={this.props.isOpen}
                  showFooter={true}
                  hideSeparator={false}
                  hideModal={() => this.props.toggle(false)}
                  modalClass="courier-tracking-modal"
                >
                  <Stepper steps={request.response.steps} />
                  <div className="modal-separator inside-separator">
                    <hr />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <p>{__('dashboard.components.courierTracking.modal.orderNumber')}</p>
                    </div>
                    <div className="col-md-6 text-right text-bold">
                      <p>{request.response.code}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <p>{__('dashboard.components.courierTracking.modal.address')}</p>
                    </div>
                    <div className="col-md-6 text-right text-bold">
                      <p>{request.response.shipping.address}</p>
                    </div>
                  </div>
                </DashboardModal>
              )}
            </MainLoader>
          )
        }
        }
      </APIRequest>
    )
  }
}

const mapStateToProps = (state) => ({
  subsidiaryId: get(state, 'login.user.subsidiaryId'),
  token: get(state, 'api.token'),
  courierOrders: get(state, 'courier.orders'),
});

export default connect(mapStateToProps)(CourierTrackingModal);
