
import { combineReducers } from 'redux'

import { ACTIONS } from './constants'
import LOGOUT from '../Logout/constants'

export default combineReducers({

  isAuthenticated: (state = false, action) => {
    switch (action.type) {
      case ACTIONS.LOGIN_SUCCESS:
        return true
      case ACTIONS.LOGIN_FAILURE:
      case LOGOUT.LOGOUT:
        return false
      default:
        return state
    }
  },

  form: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.LOGIN_REQUEST:
        return {
          user: action.remember && action.user,
          remember: action.remember
        }
      default:
        return state
    }
  },

  user: (state = null, action) => {
    switch (action.type) {
      case ACTIONS.LOGIN_SUCCESS:
      case ACTIONS.EDIT_USER:
        return action.data
      case ACTIONS.LOGIN_FAILURE:
      case LOGOUT.LOGOUT:
        return null
      default:
        return state
    }
  },

  isFirstLogin: (state = false, action) => {
    switch (action.type) {
      case ACTIONS.SET_FIRST_LOGIN:
        return action.value
      default:
        return state
    }
  }
})
