const isCBUFromOrigin = (bankAccountAddressCBU, processorName) => {
  return (
    bankAccountAddressCBU.slice(0, 3) === processorNameCodeEntity[processorName]
  );
};

const processorNameCodeEntity = {
  MACRO: "285",
  BBVA_AR: "017",
  SANTANDER_AR: "072",
  SUPERVIELLE: "027",
  PATAGONIA: "034",
};

export default isCBUFromOrigin;
