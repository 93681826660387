import React from "react";
import "../../../../styles/newStyles/ButtonLoader.scss"
import Loader from "components/Loader";

const ButtonLoader = (props) => {
    
    return (
        <button style={{position: "relative"}} className="btn-loader buttom-radius" type="button" onClick={props.onClick} disabled={props.isLoading}>
            {props.isLoading ? (<Loader className="loader-size"/>)
                : (props.text)
            }
        </button>
    )
}

export default ButtonLoader;