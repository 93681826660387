import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { fetchResource } from "api-provider";
import { getConfig } from "config";
import Loader from "components/Loader";
import FlexTable from "components/FlexTable";
import { PermissionsContext } from "contexts/permissionsContext";
import { getWithholdingTax } from "./resources";
import { backendUrl, tenantId } from "config/envConfig";
import __ from "utils/i18n";
import LinkLoader from "containers/Dashboard/components/ButtonLoader/LinkLoader";
import gtmEvents from "utils/gtmEvents";

export const WithholdingTax = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [withholdingTax, setwithholdingTax] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { api } = useSelector((state) => state);
  const { token } = useSelector((state) => state.api);
  const { subsidiaryId } = useSelector((state) => state.login?.user);
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const isDownloadCertificateEnabled =
    featureFlags?.includes("withholding_action_export_withholding") &&
    userPermissions?.includes("withholding_action_export_withholding");
  const currencyCode = getConfig('customConfig.customCurrencyCode');

  useEffect(() => {
    gtmEvents.salesPageView('pageRetentions');
    
    fetchResource(getWithholdingTax({ subsidiaryId, token }), {}, api)
      .onSuccess(({ data }) => {
        if (data && data) setwithholdingTax(data);
        setIsFetching(false);
      })
      .onFailure((err) => {
        if (err && !err.includes("404")) setError(true);
        setIsFetching(false);
      });
  }, [api, subsidiaryId, token]);

  const periodCell = ({ original }) => {
    return (
      <span className="pull-content">
        <span className="pull-left" style={{ lineHeight: "33px" }}>
          {__.stringToDate(original.period, "LL")}
        </span>
      </span>
    );
  };

  const vatCell = ({ original }) => {
    return <span>{__.currency(original.ivaAmount, original.currency || currencyCode)}</span>;
  };

  const profitsCell = ({ original }) => {
    return <span>{__.currency(original.iiggAmount, original.currency || currencyCode)}</span>;
  };

  const juridisctionTaxCell = ({ original }) => {
    return <span>{__.currency(original.iibbAmount, original.currency || currencyCode)}</span>;
  };

  const juridisctionTaxSirtacCell = ({ original }) => {
    return <span>{__.currency(original.iibbSirtacAmount, original.currency || currencyCode)}</span>;
  };

  const withholdingAmountCell = ({ original }) => {
    return <span>{__.currency(original.total, original.currency || currencyCode)}</span>;
  };

  const certificatesCell = ({ original }) => {
    if (!original.certificateUrl) return null;

    return (
      isDownloadCertificateEnabled && (
        <LinkLoader
          className="btn btn-link"
          text={__("actions.download")}
          isLoading={isLoading}
          onClick={(e) =>
            downloadCertificate(original.certificateUrl, original.period)
          }
        ></LinkLoader>
      )
    );
  };

  const downloadCertificate = (certificateUrl, fileName = "certificate") => {

    gtmEvents.withholdingsDownloadButtonClick('downloadButton');

    setIsLoading(true);
    fetch(
      `${backendUrl}/proxy/api/v2/withholding-tax-certificates${certificateUrl}`,
      {
        method: "GET",
        headers: {
          "x-client-id": tenantId,
          "x-Authentication-Token": token,
          "x-request-download": "stream",
        },
      }
    )
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getWithholdingTaxColumns = () => {
    const configColumns = getConfig("tableColumns.withholdingTaxColumns");
    const withholdingTaxColumns = [];

    const defaultColumns = {
      period: {
        Header: __("dashboard.sections.sales.table.header.period"),
        Cell: periodCell,
      },
      vat: {
        Header: __("dashboard.sections.sales.table.header.withholdingVat"),
        align: "center",
        Cell: vatCell,
      },
      profits: {
        Header: __("dashboard.sections.sales.table.header.profits"),
        align: "center",
        Cell: profitsCell,
      },
      juridisctionTax: {
        Header: __("dashboard.sections.sales.table.header.juridisctionTax"),
        align: "center",
        Cell: juridisctionTaxCell,
      },
      juridisctionTaxSirtac: {
        Header: __(
          "dashboard.sections.sales.table.header.juridisctionTaxSirtac"
        ),
        align: "center",
        Cell: juridisctionTaxSirtacCell,
      },
      withholdingAmount: {
        Header: __("dashboard.sections.sales.table.header.withholdingAmount"),
        align: "center",
        Cell: withholdingAmountCell,
      },
      certificates: {
        Header: __("dashboard.sections.sales.table.header.certificates"),
        align: "center",
        Cell: certificatesCell,
      },
    };

    configColumns.forEach((item) =>
      withholdingTaxColumns.push(defaultColumns[item])
    );

    return withholdingTaxColumns;
  };

  return (
    <>
      <div className="dashboard-card">
        <div className="dashboard-card-body">
          {isFetching && <Loader />}
          {!isFetching && (error || !withholdingTax.length) && (
            <div className="dashboard-card-message">
              <i className="mdi mdi-minus-circle" />
              <h3>{__("dashboard.sections.sales.withholdingTax.empty")}</h3>
              <p>{__("dashboard.sections.sales.withholdingTax.notFound")}</p>
            </div>
          )}
          {withholdingTax.length > 0 && !isFetching && !error && (
            <div className="table-container">
              <FlexTable
                columns={getWithholdingTaxColumns()}
                data={withholdingTax}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
