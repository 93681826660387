import React from 'react';
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import __ from 'utils/i18n';

export const Step1Error = ({ onHide, data }) => {
  return (
    <div>
      <button
        type='button'
        class='close hidden-modal'
        aria-label='Close'
        onClick={onHide}
      >
        <span aria-hidden='true'>×</span>
      </button>
      <ModalHeader>Error</ModalHeader>
      <ModalBody>
        {data && data.errors
          ? data.errors.map((error, key) => <p key={key}>{error.message}</p>)
          : <p>{__('dashboard.sections.catalog.productsImport.possibleImportErrors.uploadImportError')}</p>}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onHide}>
          {__('dashboard.sections.catalog.productsImport.close')}
        </Button>
      </ModalFooter>
    </div>
  );
};
