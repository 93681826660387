import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { loginSuccess, loginFailure, loginRequest } from './actions';
import { setEndpoint } from 'api-provider';
import { login, loginCallback, encryptCredential } from './resources';
import { LoginForm } from './components/LoginForm';
import { LoginError } from './components/LoginError';
import { tenantId, authExternalUrl, isGoogleCaptchaEnabled } from 'config/envConfig';
import { getConfig } from "config";
import { fetchResource } from "api-provider";
import Loader from "components/Loader";
import ReCAPTCHA from 'react-google-recaptcha';
import __ from "utils/i18n";

export const Login = () => {
  const refCaptcha = useRef(null);

  const { endpoint } = useSelector(state => state.api);
  const { form } = useSelector(state => state.login);
  const { isAuthenticated } = useSelector(state => state.login);

  const [errorMessage, setErrorMessage] = useState(null);
  const [authServerError, setAuthServerError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const authExternalLogin = getConfig('customConfig.authExternalLogin');

  const googleCaptcha = JSON.parse(isGoogleCaptchaEnabled) && getConfig('customConfig.googleCaptcha');

  const handleCaptcha = async () => {
    const token = await refCaptcha.current.executeAsync();
    refCaptcha.current.reset();

    return token;
  }

  const handleLogin = ({ user, remember }) => {
    setErrorMessage(null);
    dispatch(loginRequest(user, remember))
  }

  const handleSuccess = useCallback((data) => {
    const sessionTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');

    localStorage.setItem('sessionTimestamp', sessionTimestamp);
    localStorage.setItem(`geop_permissions_${tenantId}`, JSON.stringify(data?.userPermissions));
    localStorage.setItem(`geop_feature_flags_${tenantId}`, JSON.stringify(data?.featureFlags));
    localStorage.setItem(`geop_oauth_${tenantId}`, JSON.stringify(data?.serverAuth));

    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) =>
          value &&
          !key.includes('userPermissions') &&
          !key.includes('featureFlags') &&
          !key.includes('serverAuth')
      ),
    );

    dispatch(loginSuccess(filteredData));
    window.location.href = "/dashboard";
  }, [dispatch])

  const handleFailure = (error) => {
    setErrorMessage(error && error.length ? error : __("login.error"));
    dispatch(loginFailure(error))
  }

  const handleSubmit = async (values) => {
    let token;

    if (googleCaptcha) {
      token = await handleCaptcha();
    }

    const encryptedUser = encryptCredential(values.user);
    const encryptedPassword = encryptCredential(values.password);

    dispatch(setEndpoint(values.endpoint))
    setTimeout(() => {
      handleLogin(values);
    }, 100)

    setIsLoading(true);
    fetchResource(login(encryptedUser, encryptedPassword, token))
      .onSuccess((result) => {
        setIsLoading(false);
        handleSuccess(result);
      })
      .onFailure((error) => {
        setIsLoading(false);
        handleFailure(error);
      });
  }

  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem('persist:root');
    }, 1000);
  }, [])

  useEffect(() => {
    if (authExternalLogin) {
      const query = new URLSearchParams(window.location.search);
      const callback = `${window.location.protocol}//${window.location.host}/login/callback?`;
      const externalAuthFullUrl = new URL(`?callbackUrl=${callback}`, authExternalUrl);

      if (!window.location.pathname.includes('/login/callback') || !query.has('uuid')) {
        // External URL redirect
        window.location.replace(externalAuthFullUrl);
        return;
      }

      setIsLoading(true);
      fetchResource(loginCallback(query.get('uuid')))
        .onSuccess(data => {
          handleSuccess(data);
          setIsLoading(false);
          window.location.href = "/dashboard";
          return;
        })
        .onFailure((error) => {
          setAuthServerError({
            title: __('login.errorAuthServer'),
            description: error.response?.data?.message || error.response?.data?.error_description || error.response?.data?.response?.message || error,
            loginUrl: externalAuthFullUrl.toString(),
          });
          setIsLoading(false);
        });
    }
  }, [authExternalLogin, handleSuccess])

  if (authServerError) {
    return (
      <>
        <LoginError title={authServerError?.title} description={authServerError?.description} loginUrl={authServerError?.loginUrl} />
      </>
    )
  }

  return (
    <div className='body-login'>
      {isLoading && !isAuthenticated && authExternalLogin && <Loader absolute={true} />}
      {!isAuthenticated && !authExternalLogin && (
        <>
          <LoginForm
            isLoading={isLoading}
            endpoint={endpoint}
            form={form}
            error={errorMessage}
            setError={setErrorMessage}
            onSubmit={values => handleSubmit(values)}
          />
          {googleCaptcha && (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_DASHBOARD_RECAPTCHA_SITE_KEY}
              ref={refCaptcha}
              size="invisible"
              render="explicit"
            />
          )}
        </>
      )}
    </div>
  );
};
