import __ from 'utils/i18n';
const classNames = require("classnames");
const { default: getTransactionStatus } = require("./resources/getTransactionStatus");


export const CashbackAmountCell = ({ original }) => {
  const type = original.type;
  const status = getTransactionStatus(original);
  const amount = original.cashbackAmount;
  return (
    <span
      className={classNames({
        'text-amount': true,
        'text-amount-warn': status !== 'closed' || type === 'ADJUSTMENT_DEBIT',
      })}
    >
      {__.currency(amount, original.currency, 'decimal')}
    </span>
  );
};
