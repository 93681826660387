
export const handleCheckboxList = (list, action) => {
  switch (action.type) {
    case 'add':
      return [...list, action.payload];
    case 'remove':
      const filtered = list.filter(order => order.id !== action.payload.id);
      return [...filtered];
    case 'clear':
      return [];
    default:
      return list;
  }
}