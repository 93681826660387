import React from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';

export const Items = ({ data }) => {
  const { taxes, subtotal, tip, currency } = data;

  return (
    <Table className='no-border'>
      <tbody>
        <tr>
          <td>
            <span>{__('dashboard.sections.deposits.amount')}</span>
          </td>
          <td>{__.currency(subtotal, currency.code)}</td>
        </tr>
        {taxes.map((tax, index) => {
          return (
            <tr key={index}>
              <td>
                <span>
                  {__('dashboard.sections.deposits.tax')}
                  {tax.name.toUpperCase()}
                </span>
              </td>
              <td>{__.currency(tax.value, currency.code)}</td>
            </tr>
          );
        })}
        <tr>
          <td>
            <span>{__('dashboard.sections.deposits.tip')}</span>
          </td>
          <td>{__.currency(tip, currency.code)}</td>
        </tr>
      </tbody>
    </Table>
  );
};
