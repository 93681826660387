
import { ACTIONS } from './constants';

import { setToken } from 'api-provider';

// Actions

export const loginRequest = (user, remember) => ({
  type: ACTIONS.LOGIN_REQUEST,
  user,
  remember
})

export const loginSuccess = (data) => (dispatch) => {
  dispatch(setToken(data.token))
  dispatch({
    type: ACTIONS.LOGIN_SUCCESS,
    data
  })
}

export const loginFailure = (error) => (dispatch) => {
  dispatch(setToken(''))
  dispatch({
    type: ACTIONS.LOGIN_FAILURE,
    error
  })
}

export const setFirstLogin = (value = true) => ({
  type: ACTIONS.SET_FIRST_LOGIN,
  value
})

// CORE ACTIONS
export const editUser = (data) => (dispatch) => {
  dispatch({
    type: ACTIONS.EDIT_USER,
    data
  })
}
