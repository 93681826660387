import React from "react";
import __ from "utils/i18n";
import { onboardingUrl } from "config/envConfig";
import { Row } from "reactstrap";

export const RegisterLink = () => {
  return (
    <Row className="login-register">
      <p>
        {__("login.registerText")} <Link href={onboardingUrl} />
      </p>
    </Row>
  );
};

const Link = ({ href }) => {
  return <a href={href}>{__("login.registerLink")}</a>;
};
