import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class DashboardSectionTabs extends React.Component {
  constructor(props) {
    super(props);
    this.updateUnderline = this.updateUnderline.bind(this);
  }

  static propTypes = {
    defaultTab: PropTypes.number,
    onChange: PropTypes.func,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        component: PropTypes.any,
      })
    ),
  };

  state = {
    activeTab: this.props.defaultTab || 0,
  };

  componentDidMount() {
    this.setState({ activeTab: this.props.defaultTab || 0 });
    window.addEventListener("resize", this.updateUnderline);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateUnderline);
  }

  selectTab(index) {
    const { onChange } = this.props;
    this.setState({ activeTab: index });
    onChange && onChange(index);
  }

  updateUnderline() {
    const activeTab = this.props.activeTab || this.state.activeTab;
    const currentElement = this[`tab-${activeTab}`];
    this.underline.style = {};
    this.underline.style.left = `${currentElement.offsetLeft}px`;
    this.underline.style.width = `${currentElement.clientWidth}px`;
  }

  componentDidUpdate() {
    this.updateUnderline();
  }

  render() {
    const { tabs } = this.props;
    const activeTab = this.props.activeTab || this.state.activeTab;

    return (
      <div className="dashboard-section-tabs">
        {tabs.map((tab, index) => (
          <div
            key={index}
            ref={(c) => (this[`tab-${index}`] = c)}
            onClick={() => this.selectTab(index)}
            className={classNames({
              "dashboard-section-tab": true,
              active: activeTab === index,
            })}
          >
            <div className="dashboard-section-tab-content">{tab.title}</div>
          </div>
        ))}
        <div
          ref={(c) => (this.underline = c)}
          className="dashboard-section-tabs-underline"
        />
      </div>
    );
  }
}

export default DashboardSectionTabs;
