import React from "react";

import __ from "utils/i18n";

export const HelperText = () => {
  return (
    <p
      className="login-helper-text"
      dangerouslySetInnerHTML={{ __html: __("login.helperText") }}
    />
  );
};
