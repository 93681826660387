import React, { useState } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import __ from 'utils/i18n';
import BigRadioInput from 'components/forms/inputs/BigRadio';
import Color from 'components/informed/Color';
import { Text } from 'components/informed';
import { copyToClipboard } from 'utils';
import { checkoutUrl } from 'config/envConfig';

const ButtonPreview = ({ data, name, buttonColor, textColor, showImage }) => {
  return (
    <Col md='6' sm='12' className='preview'>
      <h3 className='preview-title'>
        {__('dashboard.sections.compraClick.actionButton.preview')}
      </h3>
      <div className='preview-button'>
        {showImage && data.imageUrl && (
          <>
            <label>{data.description}</label>
            <span>
              {__.currency(data.amount, data.currency.code, 'decimal')}
            </span>
            <img src={data.imageUrl} alt='' />
          </>
        )}
        <button style={{ backgroundColor: buttonColor, color: textColor }}>
          {name}
        </button>
      </div>
    </Col>
  );
};

const ButtonSettings = ({
  name,
  setName,
  showImage,
  setShowImage,
  buttonColor,
  setButtonColor,
  textColor,
  setTextColor,
  token,
  hasImage,
}) => {
  const color = textColor.replace('#', '%23');
  const background = buttonColor.replace('#', '%23');
  const htmlCode = `<iframe 
    style="width: 210px;border: none;height: 325px; display: inline;"
    title="${name}"
    src="${checkoutUrl}payment_button?token=${token}&color=${color}&background=${background}&text=${name}&hasimage=${showImage}">
    <p>Your browser does not support iframes.</p>
    </iframe>`;

  return (
    <Col md='6' sm='12'>
      <Form className='dashboard-panel-container'>
        <Row>
          {hasImage && (
            <Col md='12'>
              <BigRadioInput
                value={showImage}
                onChange={() => {
                  setShowImage(true);
                }}
                title={__(
                  'dashboard.sections.compraClick.actionButton.includeImage'
                )}
              />
            </Col>
          )}
          <Col md='12'>
            <BigRadioInput
              value={!showImage}
              onChange={() => {
                setShowImage(false);
              }}
              title={__(
                'dashboard.sections.compraClick.actionButton.onlyButton'
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Text
              initialValue={name}
              field='description'
              type='text'
              placeholder={__(
                'dashboard.sections.compraClick.actionButton.buttonText'
              )}
              label={__(
                'dashboard.sections.compraClick.actionButton.buttonText'
              )}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md='6'>
            <Color
              label={__(
                'dashboard.sections.compraClick.actionButton.buttonColor'
              )}
              initialValue={buttonColor}
              onChange={(color) => {
                setButtonColor(color);
              }}
            />
          </Col>
          <Col md='6'>
            <Color
              label={__(
                'dashboard.sections.compraClick.actionButton.textColor'
              )}
              initialValue={textColor}
              onChange={(color) => {
                setTextColor(color);
              }}
            />
          </Col>
        </Row>

        <FormGroup>
          <Label>
            {__('dashboard.sections.compraClick.actionButton.htmlCode')}
          </Label>
          <Input type='textarea' name='text' value={htmlCode} id='copyText' />
        </FormGroup>
      </Form>
    </Col>
  );
};

const ModalFooter = ({ hideModal }) => {
  const copyHtmlCode = () => {
    copyToClipboard(document.getElementById('copyText').value);
  };
  
  return (
    <div className='modal-footer'>
      <Button
        color='secondary'
        onClick={() => {
          hideModal(false);
        }}
      >
        {__('actions.close')}
      </Button>

      <Button
        color='success'
        onClick={() => {
          copyHtmlCode();
        }}
      >
        {__('dashboard.sections.compraClick.actionButton.copyHtmlCode')}
      </Button>
    </div>
  );
};

export const Iframe = ({ data, hideModal }) => {
  const hasImage = data.imageUrl;

  const [showImage, setShowImage] = useState(hasImage ? true : false);
  const [name, setName] = useState('Pagar ahora');
  const [textColor, setTextColor] = useState('#ffffff');
  const [buttonColor, setButtonColor] = useState('#e4002b');

  return (
    <div>
      <Row>
        <ButtonPreview
          name={name}
          buttonColor={buttonColor}
          textColor={textColor}
          data={data}
          showImage={showImage}
        />
        <ButtonSettings
          hasImage={hasImage}
          token={data.token}
          textColor={textColor}
          setTextColor={setTextColor}
          buttonColor={buttonColor}
          setButtonColor={setButtonColor}
          name={name}
          setName={setName}
          showImage={showImage}
          setShowImage={setShowImage}
        />
      </Row>
      <ModalFooter hideModal={hideModal} />
    </div>
  );
};
