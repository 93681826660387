import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { APIQuery } from "api-provider";
import { Button } from "reactstrap";
import __ from "utils/i18n";
import Loader from "components/Loader";
import FiltersForm from "components/Filters/FiltersForm";
import { BatchCloseModal } from "./components/BatchCloseModal";
import { PermissionsContext } from "contexts/permissionsContext";
import { TransactionsReportWidget } from "./components/TransactionsReport";
import { getBatchClose } from "./_shared/resources";
import { handleClose } from "./components/handleClose";
import { BatchCloseTable } from "./components/BatchCloseTable";
import { tenantId } from "config/envConfig";


export const BatchCloseTab = () => {
  const { subsidiaryId } = useSelector((state) => state.login.user);
  const { token } = useSelector((state) => state.api);
  const subsidiariesStore =
    useSelector((state) => state.login.user.subsidiaries) || [];
  const selectedSubsidiaries = useSelector(
    (state) => state.login.user.subsidiaries
  );
  const subsidiaries = subsidiariesStore.length
    ? selectedSubsidiaries.map((s) => s.subsidiaryId)
    : [subsidiaryId];
  const [filters, setFilters] = useState({});
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const userId = useSelector((state) => state.login.user.id);
  const [detailsData, setDetailsData] = useState({});
  const [batchModal, setBatchModal] = useState(false);
  const [batchUnitModal, setBatchUnitModal] = useState(false);
  const [confirmBatchModal, setConfirmBatchModal] = useState(false);
  const [confirmBatchUnitModal, setConfirmUnitBatchModal] = useState(false);
  const isExportBatchCloaseEnabled =
    featureFlags?.includes("batch_section_export_batch") &&
    userPermissions?.includes("batch_section_export_batch");
  const applyFilter = () => {
    api_query.setFilters(filters);
    api_query.reload();
  };

  let api_query;
  let data;
  let allIsClose;

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line
  }, [filters, api_query]);

  const handleCloseModal = () => {
    setConfirmBatchModal(false);
    setConfirmUnitBatchModal(false);
  };

  const message = {
    headerText: __("dashboard.sections.batchClose.modal.headerTextAllClose"),
    submitText: __("dashboard.sections.batchClose.modal.close"),
    cancelText: __("dashboard.sections.batchClose.modal.cancel"),
    acceptText: __("dashboard.sections.batchClose.modal.accept"),
    confirmText: __("dashboard.sections.batchClose.modal.confirmAllClose"),
    successAllCloseText: __(
      "dashboard.sections.batchClose.modal.successAllClose"
    ),
  };

  const showCancelButton = confirmBatchModal && !batchModal ? false : true;

  return (
    <APIQuery
      pageSize={20}
      key={subsidiaries}
      resource={getBatchClose(token, {
        subsidiaryId,
        page: 1,
        maxResults: 25,
        startDate: filters.startDate,
        endDate: filters.endDate,
      })}
    >
      {(query) => {
        data = query.data;
        allIsClose = data.every(
          (item) => item.hasOwnProperty("isClose") && item.isClose === true
        );
        api_query = query;
        return (
          <div className="dashboard-card">
            <div className="dashboard-card-header">
              <FiltersForm
                showAmount={true}
                startDate={filters.startDate}
                endDate={filters.endDate}
                onChange={(filters) => {
                  setFilters(filters);
                }}
                showReverted={filters.showReverted}
              >
                {isExportBatchCloaseEnabled && (
                  <TransactionsReportWidget
                    filters={{
                      ...query.filters,
                      subsidiaryId,
                    }}
                    token={token}
                  />
                )}

                {!allIsClose && (
                  <Button
                    block={true}
                    onClick={() => setBatchModal(true)}
                    className="btn-danger ml-2"
                    type="button"
                  >
                    {__("dashboard.sections.batchClose.closeAll.label")}
                  </Button>
                )}
              </FiltersForm>
            </div>

            <BatchCloseModal
              batchModal={batchModal}
              confirmBatchModal={confirmBatchModal}
              handleClose={handleClose}
              handleCloseModal={handleCloseModal}
              setBatchModal={setBatchModal}
              setConfirmBatchModal={setConfirmBatchModal}
              userId={userId}
              tenantId={tenantId}
              token={token}
              detailsData={detailsData}
              applyFilter={applyFilter}
              {...message}
              showCancelButton={showCancelButton}
            />

            <div className="dashboard-card-body">
              {query.isFetching && <Loader />}
              {query.error && (
                <div className="dashboard-card-message">
                  <span>{query.error}</span>
                </div>
              )}
              {!query.data.length && !query.isFetching && !query.error && (
                <div className="dashboard-card-message">
                  <i className="mdi mdi-minus-circle" />
                  <h3>{__("dashboard.sections.batchClose.withoutBatchs")}</h3>
                  <p>{__("dashboard.sections.batchClose.notFoundBatchs")}</p>
                </div>
              )}
              {query.data.length > 0 && !query.isFetching && !query.error && (
                <BatchCloseTable
                  data={query.data}
                  isFetching={query.isFetching}
                  getMore={query.fetchMore}
                  hasMore={query.hasMore}
                  reload={query.reload}
                  subsidiariesStore={subsidiariesStore}
                  showModal={batchUnitModal}
                  setDetailsData={setDetailsData}
                  detailsData={detailsData}
                  applyFilter={applyFilter}
                  handleCloseModal={handleCloseModal}
                  batchUnitModal={batchUnitModal}
                  setBatchUnitModal={setBatchUnitModal}
                  confirmBatchUnitModal={confirmBatchUnitModal}
                  setConfirmUnitBatchModal={setConfirmUnitBatchModal}
                />
              )}
            </div>
          </div>
        );
      }}
    </APIQuery>
  );
};
