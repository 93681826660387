import React from 'react'
import { asField } from 'informed';
import classnames from "classnames";

const TextArea = asField(({ fieldState, fieldApi, ForwardRef, onChange, label, type, value, ...props }) => {
      return(
        <div className="position-relative form-group">
            <label className="">{label}</label>
            <div className="input-group">
                <textarea
                  className={classnames({
                      "form-control": true,
                      "has-error": fieldState.error
                  })}
                  fieldState={fieldState}
                  onChange={(e)=>{
                    onChange && onChange(e.target.value);
                    fieldApi.setValue(e.target.value);
                    fieldApi.setError(undefined);
                  }}
                  {...props}
                >{ value }</textarea>
               </div>
            {fieldState.error ? (
              <small className="text-danger form-text text-muted">{fieldState.error}</small>
            ) : null}

        </div>
      )
  }
);

export default TextArea

