const tenantCustonConfig = {
  brandName: "MIO",
  dropdownMenuItems: ["bankAccount", "config", "buyReader"],
  bankAccountIdentifierKeyLength: 20,
  automaticCashout: {
    isEnabled: false,
  },
  termsAndConditions: false,
  currencies: [{ value: "214", label: "Pesos", code: "DOP", symbol: "RD$" }],
  currencyDecimals:true,
  sales: {
    options: {
      type: [
        { value: "", label: "transactions.optionVoid" },
        { value: "SALE", label: "transactions.types.SALE" },
        { value: "ANNULMENT", label: "transactions.types.ANNULMENT" },
        { value: "COMMISSION", label: "transactions.types.COMMISSION" },
        { value: "ADJUSTMENT", label: "transactions.types.ADJUSTMENT" },
        { value: "CHARGEBACK", label: "transactions.types.CHARGEBACK" },
      ],
      status: [
        { value: "", label: "Todos" },
        { value: "DENIED", label: "Denegada" },
        { value: "APPROVED", label: "Aprobada" },
        { value: "SENT", label: "Enviada" },
        { value: "ERROR", label: "Error" },
        { value: "SYSTEM_ERROR", label: "Error del sistema" },
        { value: "TIMEOUT", label: "Timeout" },
        { value: "PENDING", label: "Pendiente" },
        { value: "NOT_AVAILABLE", label: "No disponible" },
      ],
      formats: [{ value: "xls", label: "Excel" }],
    },

    transactionReceipt: {
      showTip: true,
      summary: [
        {
          label: "Titular de tarjeta",
          method: (sale) => sale.payment.cardHolderName,
        },
        {
          label: "Tasa comisión",
          method: (sale) => sale.commission + "%",
        },
        {
          label: "Fecha de disponibilidad",
          method: (sale) => sale.availableDate,
        },
        {
          label: "N° de referencia",
          method: (sale) => sale.referenceNumber,
        },
        {
          label: "Nº de autorización",
          method: (sale) => sale.authorizationCode,
        },
      ],
      qr: [
        {
          label: "Titular de tarjeta",
          method: (sale) => sale.payment.cardHolderName,
        },
        {
          label: "Tasa comisión",
          method: (sale) => sale.commission + "%",
        },
        {
          label: "Fecha de disponibilidad",
          method: (sale) => sale.availableDate,
        },
        {
          label: "N° de referencia",
          method: (sale) => sale.referenceNumber,
        },
        {
          label: "Nº de autorización",
          method: (sale) => sale.authorizationCode,
        },
      ],
    },
  },
  deposits: {
    options: {
      formats: [
        { value: 'xls', label: 'Excel' },
        { value: 'pdf', label: 'PDF' }
      ],
    },
  },
};

const tenantTableColumns = {
  salesColumns: ["description", "date", "amount"],
  depositColumns: [
    "availableDate",
    "toDeposit",
    "grossAmount",
    "commissionAmount",
    "itbisRetention",
    "tipAmount",
    "balanceAmount",
    "status",
  ],
};

export { tenantCustonConfig, tenantTableColumns };
