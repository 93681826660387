import React, { useState } from "react";
import "../../../../../../../../styles/newStyles/MerchantCredentials.scss";
import { NameStep } from "../steps/NameStep";
import { PasswordVerificationStep } from "../steps/PasswordVerificationStep";
import WarningStep from "../steps/WarningStep";
import ErrorStep from "../steps/ErrorStep";
import ShowCredentialStep from "../steps/ShowCredentialStep";
import i18n from "utils/i18n";
import { useStep } from "@flywire/react-hooks";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const CreateWorkflowsModal = ({ setIsOpen, isOpen }) => {
  const [credentials, setCredentials] = useState({});
  const token = useSelector((state) => state.api.token);
  const [credentialName, setCredentialName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(
    i18n("dashboard.sections.merchantCredentials.step.error.subtitle")
  );

  const steps = [
    {
      id: "name-verification",
      component: NameStep,
    },
    {
      id: "password-verification",
      component: PasswordVerificationStep,
    },
    {
      id: "warning",
      component: WarningStep,
    },
    {
      id: "show-credentials",
      component: ShowCredentialStep,
    },
    {
      id: "error",
      component: ErrorStep,
    },
  ];

  const { step, navigation, reset } = useStep({ steps });
  const { component: StepComponent } = step;

  const warningStepTexts = {
    title: i18n("dashboard.sections.merchantCredentials.step.warning.title"),
    subTitle: i18n(
      "dashboard.sections.merchantCredentials.step.warning.subtitle"
    ),
    buttonText: i18n(
      "dashboard.sections.merchantCredentials.step.warning.button"
    ),
  };

  const loadingStepTexts = {
    title: i18n("dashboard.sections.merchantCredentials.step.loader.title"),
    subTitle: i18n(
      "dashboard.sections.merchantCredentials.step.loader.subtitle"
    ),
  };

  const resources = {
    createCredential: () => {
      return {
        endpoint: "/api/v1/applications/credentials",
        headers: {
          "Access-Control-Allow-Origin": true,
          "X-Authentication-Token": token,
        },
        proxy: false,
        method: "POST",
        data: {
          name: credentialName,
        },
      };
    },
  };

  const retryModal = () => {
    setIsOpen(!isOpen);
    reset();
    window.location.reload();
  };

  return (
    <Modal
      id="modal-credential"
      className="credentials-modal"
      isOpen={isOpen}
      zIndex={9999}
      centered
      size="lg"
    >
      <ModalHeader
        className="credentials-modal-header"
        toggle={() => retryModal()}
      ></ModalHeader>
      <ModalBody className="credentials-modal-body">
        <StepComponent
          navigation={navigation}
          credentials={credentials}
          setCredentials={setCredentials}
          resources={resources.createCredential}
          warningTexts={warningStepTexts}
          loadingTexts={loadingStepTexts}
          retryModal={retryModal}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
          setCredentialName={setCredentialName}
        />
      </ModalBody>
    </Modal>
  );
};

export default CreateWorkflowsModal;
