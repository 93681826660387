
import React from 'react'
import classNames from 'classnames'
import __ from "utils/i18n";
import { Field } from 'react-form'
import { Input, FormGroup, Label, InputGroup } from 'reactstrap'
import { InputGroupIconButton } from 'components/InputGroupIconButton'

class PasswordField extends React.Component {

  state = {
    passIsVisible: false
  }

  switchPassVisibility() {
    this.setState(prevState => (
      { passIsVisible: !prevState.passIsVisible }
    ));
  }

  render() {
    const { field, requirements, label, minLengthValue } = this.props
    return (
      <Field field={field}>
        {({ value, setValue, setTouched, error, touched, setError }) => (
          <FormGroup
            className={classNames({
              'has-error': touched && error,
            })}>
            <Label>{ __(label) }</Label>
            <InputGroup className="primary-input-group input-with-addon">
              <Input
                type={ this.state.passIsVisible ? 'text' : 'password' }
                getRef={el => this.password = el}
                value={value}
                onBlur={() => setTouched()}
                onChange={e => {
                  setError()
                  setValue(e.target.value)
                }}
              />
              <InputGroupIconButton
                icon={ this.state.passIsVisible ? 'mdi mdi-eye-off' : 'mdi mdi-eye' }
                type="button"
                className="addon"
                onClick={() => this.switchPassVisibility()}>
              </InputGroupIconButton>
            </InputGroup>
            <div className="new-input-requirements"> {
              requirements.map(({ test, label }, index) => (
                <div
                  key={index}
                  className={classNames({
                    "new-input-requirement": true,
                    "valid": test(value)
                  })}>
                  { __(label).replace('{{minLengthValue}}', minLengthValue) }
                </div>
              ))
            }
            </div>
          </FormGroup>
        )}
      </Field>
    )
  }
}

export default PasswordField
