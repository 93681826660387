import React from 'react';
import { Card, CardTitle, CardBody } from 'reactstrap';
import classNames from 'classnames';

export const EmptyCard = ({ title, icon, message }) => {
  return (
    <Card
      className={classNames({
        "chart-card": true,
        "chart-card-lg": false,
      })}
    >
      <CardBody>
        {title && (
          <CardTitle>
            <span>{title}</span>
          </CardTitle>
        )}
        <div className="card-empty">
          {icon && <i className={icon} />}
          <p>{message}</p>
        </div>
      </CardBody>
    </Card>
  );
};
