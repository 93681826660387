import React, { useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { Input } from 'components/forms/inputs';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  FormGroup,
  Label,
  DropdownItem
} from 'reactstrap';
import __ from 'utils/i18n';
import { getFields } from './fields';

export const Filters = ({ handleApplyFilters, ...props }) => {
  const currencies = useSelector((state) => state.login.user.currencies) || [];

  const [fields, setFields] = useState(getFields(currencies));

  const handleFilters = (filters) => {
    return fields.reduce(
      (filters, field) => {
        if (field.enabled && field.value) {
          filters[field.name] = field.value;
        }
        else {
          delete filters[field.name];
        }
        return filters;
      }, { ...filters }
    );
  };

  const [filters, setFilters] = useReducer(handleFilters, []);

  const enableField = (name) => {
    const enabledFields = fields.map(field =>
      field.name === name ? { ...field, enabled: true } : field
    );
    setFields(enabledFields);
    setFilters()
  };

  const disableField = (name) => {
    const disabledFields = fields.map(field =>
      field.name === name ? { ...field, enabled: false } : field
    );

    setFields(disabledFields);
    setFilters();
  };

  const changeField = (name, value) => {
    const changedFields = fields.map(field =>
      field.name === name ? { ...field, value: value } : field
    );

    setFields(changedFields);
    setFilters();
  };

  return (
    <div>
      <div className="filters-header">
        <div>
          {fields &&
            fields.length > 0 ? (
            <UncontrolledDropdown className="select-filters">
              <DropdownToggle caret className="btn btn-secondary">
                {__('dashboard.components.oldFilterForm.title')}
              </DropdownToggle>
              <DropdownMenu>
                {fields
                  .filter(field => !field.enabled)
                  .map((field, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => enableField(field.name)}>
                      {__(`dashboard.components.oldFilterForm.fields.${field.name}.label`)}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : null}
          <div className="actions-header">
            <Button
              className="action__create-item btn btn-primary"
              color="primary"
              onClick={() => handleApplyFilters(filters)}
            >
              <i className="mdi mdi-filter mdi-24px mdi-left" />
              {__('dashboard.components.oldFilterForm.filterButton')}
            </Button>
          </div>
        </div>

        <div>
          {props.children}
        </div>
      </div>
      {fields.some(field => field.enabled) && (
        <Row className="filters-content">
          {fields.map((field, index) => {
            const FieldComponent = field.component || null;
            return (
              <>
                {field.enabled && (
                  <Col key={index} sm="4" xs="12">
                    <FormGroup>
                      <Label>{__(`dashboard.components.oldFilterForm.fields.${field.name}.label`)}</Label>
                      <InputGroup>
                        {!FieldComponent && (
                          <>
                            <Input
                              type="text"
                              bssize="sm"
                              {...field}
                              onChange={value => changeField(field.name, value)}
                              placeholder={__(`dashboard.components.oldFilterForm.fields.${field.name}.placeholder`)}
                            />
                            {(field.type === 'checkbox') && (
                              <p>{__(`dashboard.components.oldFilterForm.fields.${field.name}.label`)}</p>
                            )}
                          </>
                        )}

                        {FieldComponent && (
                          <FieldComponent
                            onChange={value => changeField(field.name, value)}
                          />
                        )}

                        {!field.alwaysVisible && (
                          <InputGroupAddon
                            addonType="prepend"
                            bssize="sm"
                            color="secondary"
                            onClick={() => disableField(field.name)}>
                            <i className="btn-close mdi mdi-close-circle" />
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                )}
              </>
            )
          })}
        </Row>
      )}
    </div>
  );
};
