export const resetPassword = ({ email }) => ({
  endpoint: 'users/resetPassword.json',
  method: 'POST',
  formData: {
    email,
    channel: 'web',
    deviceId: 'web',
    deviceModel: 'web',
    deviceOS: 'web'
  }
});

export const changePassword = ({ password, token }) => ({
  endpoint: 'users/changePassword.json',
  method: 'POST',
  formData: {
    k: token,
    password: password,
    confirmationPassword: password,
    channel: 'web',
    deviceId: 'web',
    deviceModel: 'web',
    deviceOS: 'web'
  }
})
