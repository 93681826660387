import React, { useState } from "react";
import { MainLoader } from 'components/MainLoader';
import { fetchResource } from 'api-provider';
import ResetPassword from './Reset';
import RequestEmailPassword  from './RequestEmail';
import { resetPassword, changePassword } from './resources';
import '../../styles/newStyles/PasswordForm.scss';
import loginLogo from "assets/brand-logo-login.svg";
import brandLogo from "assets/brand-logo.svg"
import { getConfig } from "config";


export const ForgotPassword = (props) => {
  const { token } = props.match.params;
  const isCustomLogin = getConfig('customConfig.showCustomLogin');
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const handleResetPassword = (values) => {
    setIsFetching(true);
    fetchResource(changePassword({...values, token}))
      .onSuccess((data)=> {setData(data)})
      .onFailure((error)=> {setErrorMessage(error)})
    setIsFetching(false);
  }
  const handleRequestEmailPassword = (values) => {
    setIsFetching(true);
    fetchResource(resetPassword(values))
      .onSuccess((data)=> {setData(data)})
      .onFailure((error)=> {setErrorMessage(error)})
    setIsFetching(false);
  }

  return (
    <div className={`password-form ${isCustomLogin ? 'custom-password-form' : ''}`}>
      <div className="password-form-login-container">
        <div className="password-form-login-logo">
        <div className="login-logo mx-auto">
          <img src={isCustomLogin ? brandLogo : loginLogo} alt="Logo"/>
        </div>
        </div>    
          <MainLoader isLoading={isFetching}>
            {token ? (
              <ResetPassword
                endpoint={props.endpoint}
                form={props.form}
                errorMessage={errorMessage}
                success={data}
                onSubmit={(values) => {
                  handleResetPassword(values);
                }}
              />
            ) : (
              <RequestEmailPassword
                endpoint={props.endpoint}
                form={props.form}
                success={data}
                onSubmit={(values) => {
                  handleRequestEmailPassword(values)
                }}
              />
            )}
          </MainLoader>
      </div>
    </div>
  );
};
