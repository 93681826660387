import React, { useEffect, useState } from "react";
import { DashboardModal } from "containers/Dashboard/components/DashboardModal";
import moment from 'moment'
import __ from 'utils/i18n'
import { fetchResource } from "api-provider";
import { getConfig } from 'config';
import FlexTable from 'components/FlexTable'
import { useSelector } from 'react-redux';
import Loader from "components/Loader";

const ReadersModal = (props) => {
  const { isOpen, toggle } = props;
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const token = useSelector(state => state.api.token)
  const { subsidiaryId } = useSelector((state) => state.login.user);
  const configColumns = getConfig("tableColumns.modalReadersColumns")

  const SubsidiaryNameCell = ({ original }) => {
    return (
      <span>{original.lastUser || '-'}</span>
    )
  };

  const EnabledCell = ({ original }) => {
    return (
      <span>{original.enabledReader}</span>
    )
  }

  const BatteryStatusCell = ({ original }) => {
    return (
      <span>
        {original.lastBatteryStatus ? (Math.round(original.lastBatteryStatus * 100) + '%') : '-'}
      </span>
    )
  };

  const LastUsedCell = ({ original }) => {
    return (
      <span>
        {moment(original.lastUsed).format("DD/MM/YYYY") || '-'}
      </span>
    )
  };

  const getReadersColumns = () => {
    const modalReadersColumns = []
    const defaultColumns = {
      subsidiaryName: {
        title: __('dashboard.components.modal.modalReaders.subsidiaryName'),
        name: 'subsidiaryName',
        Cell: SubsidiaryNameCell,
        width: 400,
        maxWidth: 400
      },
      serialNumber: {
        title: __('dashboard.components.modal.modalReaders.serialNumber'),
        name: 'serialNumber',
        image: getConfig('dashboard.merchant.readers.icon'),
        width: 300
      },
      batteryStatusCell: {
        title: __('dashboard.components.modal.modalReaders.BatteryStatusCell'),
        Cell: BatteryStatusCell,
        width: 200,
        maxWidth: 200
      },
      enabledReader: {
        title: __('dashboard.components.modal.modalReaders.enabledReader'),
        name: 'enabledReader',
        Cell: EnabledCell,
        width: 200
      },
      lastUsedCell: {
        title: __('dashboard.components.modal.modalReaders.LastUsedDate'),
        Cell: LastUsedCell,
        width: 200,
        maxWidth: 200,
        align: 'right'
      },
    }
    configColumns.map(item => {
      return modalReadersColumns.push(defaultColumns[item])
    })
    return modalReadersColumns
  }

  useEffect(() => {
    const resources = {
      list: (token, subsidiaryId) => ({
        endpoint: `readers/findBySubsidiary`,
        method: 'GET',
        params: {
          token,
          subsidiaryId
        },
        cache: true,
        parseResponse: response => response.data.data
      })
    }

    fetchResource(resources.list(token, subsidiaryId))
      .onSuccess(data => setItems(data))
      .onFailure(error => setError(error))
  }, [items, token, subsidiaryId])

  return (
    <DashboardModal
      showModal={isOpen}
      hideModal={() => toggle('false')}
      headerText={__('dashboard.components.modal.modalReaders.modalTitle')}
      modalClass="readers-modal"
    >
      {error && (
        <div className="dashboard-card-message">
          <h3 className="text-danger">{error}</h3>
        </div>
      )}
      {items.length < 1 ? <Loader /> : (
        <FlexTable
          data={items}
          noData={__('dashboard.sections.merchantReaders.notHaveReaders')}
          cellHeight={48}
          columns={getReadersColumns()}
        />
      )}
    </DashboardModal>
  )
};

export default ReadersModal;
