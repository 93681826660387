import React,{useEffect,useState} from "react";
import __ from "utils/i18n";
import { Row, Col } from 'reactstrap';
import accountTypeLabel from "data/accountTypeLabel";

import {fetchResource} from "../../../../../api-provider";
import { getBanksById} from "../resources";
import {useSelector} from "react-redux";
import Loader from "components/Loader";

const AccountDetailsFull = ({ data }) => {
  const [isFetching, setIsFeching] = useState(false);
  const token = useSelector((state) => state.api?.token);

  const [bank, setBank] = useState('');

  useEffect(()=>{
    setIsFeching(true);
    fetchResource(getBanksById(token,data.bankId)).onSuccess((data2)=>{
        setBank(data2.data.attributes.name);
        setIsFeching(false);
      }

    ).onFailure((error)=>{
        console.log(error);
        setIsFeching(false);
      }
    );
  },[data.bankId, token]);
  
  const bankAccountNumber = data.cbu || data.accountNumber;
  const fiscalIdNumber = data.idFiscal;
  const typeName = (accountTypeLabel.find(( {value} ) => value === data.bankAccountType)).name;

  return(
<>
    {isFetching ? (
      <div>
        <Loader isLoading={isFetching} />
      </div>
    ) : (
        <>
      <Row className={"bank-detail-types"}>
        <Col lg="4" sm="6" className={"bank-detail-item-type"}>
          <strong>{__("dashboard.components.modal.bank")}: </strong>
        </Col>
        <Col lg={{ span: 7, offset: 1 }} sm="6" className={"bank-detail-item-type"}>
          <span>{bank}</span>
        </Col>
      </Row>
      <Row className={"bank-detail-types"}>
        <Col lg="4" sm="6" className={"bank-detail-item-type"}>
          <strong>{__("dashboard.components.modal.bankType")}: </strong>
        </Col>
        <Col lg={{ span: 7, offset: 1 }} sm="6" className={"bank-detail-item-type"}>
          <span>{typeName}</span>
        </Col>
      </Row>
      <Row className={"bank-detail-types"}>
        <Col lg="4" sm="6" className={"bank-detail-item-type"}>
          <strong>{__("dashboard.components.modal.bankAccountNumber")}: </strong>
        </Col>
        <Col lg={{ span: 7, offset: 1 }} sm="6" className={"bank-detail-item-type"}>
          <span>{bankAccountNumber}</span>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className={'mt-2 my-sm-2'}>
          <small>{__("dashboard.components.modal.bankLabelFiscalIdNumber")} {fiscalIdNumber}</small>
        </Col>
      </Row>
        </>
      )}
</>
  );
}

export default AccountDetailsFull;
