import React from 'react';
import { PageSizeComponent } from "./PageSizeComponent";

export const PageSizeDropdown = ({ meta, handlePageSize }) => {

  const { page: {
    perPage = 20,
  } = {} } = meta;


  const checkPageSize = (page_size) => {
    if (page_size !== perPage) handlePageSize(page_size);
  }

  return (
    <PageSizeComponent
      perPage={perPage}
      handlePageSize={checkPageSize}
    />
  )
}