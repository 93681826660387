import React from 'react'
import { Col, Row, Button } from 'reactstrap'
import ProductImageModal from './ProductImageModal'
import __ from 'utils/i18n'

class ImageInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      name: this.props.data.productName || '',
      value: this.props.value
    }
  }

  componentWillReceiveProps(a) {
    this.setState({
      name: a.data.productName,
      value: {
        url: a.value.url,
        color: a.value.color
      }
    })
  }

  showModal() {
    this.setState({ showModal: true })
  }

  hideModal() {
    this.setState({ showModal: false })
  }

  handleSave(values) {
    this.props.onChange(values)
  }

  render() {
    const imageClasses = {
      backgroundColor: this.state.value.color ? '#' + this.state.value.color : ''
    }

    return (
      <div>
        <ProductImageModal
          key={this.state.value.url + this.state.value.color}
          name="productModal"
          showModal={this.state.showModal}
          hideModal={this.hideModal.bind(this)}
          onSave={values => { this.handleSave(values) }}
          url={this.state.value.url}
          color={this.state.value.color} />
        <Row>
          <Col
            className="catalog__product--image-actions"
            md="12">
            <span
              style={this.state.value.url ? {} : imageClasses}
              className="catalog__product--image"
              onClick={this.showModal.bind(this)}>
              {this.state.value.url && (
                <img
                  className="img-cover"
                  src={this.state.value.url}
                  alt="Product"
                  height="68px"
                  width="68px"
                />
              )}
              {this.state.name && (
                <span>{this.state.name.charAt(0)}</span>
              )}
              {(!this.state.value.url && !this.state.name) && (
                <i className="mdi mdi-image mdi-36px"/>
              )}
            </span>
            <Button
              className="catalog__product--edit-image float-right"
              type="button"
              onClick={this.showModal.bind(this)}>
              <i className="mdi mdi-pencil" /> {__('inputs.image.editImage')}
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ImageInput
