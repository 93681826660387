import React from 'react';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import __ from 'utils/i18n';

export const ConfirmModal = ({
  showModal = false,
  submitAction,
  closeAction,
  list = []
}) => {
  return (
    <DashboardModal
      name='confirmModal'
      modalClass='ecommerceOrders-modal'
      headerText={`${list.length > 1 ?
        __('dashboard.sections.ecommerce.orders.modals.confirmOrdersTitle') :
        __('dashboard.sections.ecommerce.orders.modals.confirmOrderTitle')}
        `}
      showModal={showModal}
      hideModal={closeAction}
      submitAction={submitAction}
      submitColor='success'
      hideSeparator={true}
      cancelText={__('actions.return')}
      submitText={__('actions.accept')}>

      <div className='ecommerceOrders-confirm-main'>
        <span className='ecommerceOrders-confirm-message'>
          {`${list.length > 1 ?
            __('dashboard.sections.ecommerce.orders.modals.confirmOrdersSubTitle') :
            __('dashboard.sections.ecommerce.orders.modals.confirmOrderSubTitle')}
          `}
        </span>
      </div>

    </DashboardModal>
  )
}