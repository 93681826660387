import React, { useContext } from 'react';
import __ from 'utils/i18n';
import { DropdownItem } from 'reactstrap';
import { PermissionsContext } from 'contexts/permissionsContext';

export const UploadDocumentation = ({ onClick }) => {
  const { userPermissions } = useContext(PermissionsContext);
  
  const isUploadDocumentationEnabled = userPermissions?.includes("user_menu_upload_documentation");

  return (
    <>
      {isUploadDocumentationEnabled && (
        <DropdownItem className="nav-menu-item" onClick={onClick}>
          <i className="mdi mdi-18px mdi-file" /><span>{__('dashboard.components.uploadDocumentation')}</span>
        </DropdownItem>
      )}
    </>
  )
}
