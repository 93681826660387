import React, { useState, useEffect, useContext } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { PermissionsContext } from 'contexts/permissionsContext';
import { SimpleForm, TextField, DateField } from 'components/forms';
import { validateFields } from 'components/forms/validators';
import __ from 'utils/i18n';
import { getConfig } from 'config';
import { validEmail, validBirthdate } from 'components/forms/validators';
import { APIRequest } from 'api-provider';
import { getFields } from '../../_shared/fields';
import { saveClient } from "../../_shared/resources";
import { DeleteModal } from "../DeleteModal";
import gtmEvents from 'utils/gtmEvents';

export const ClientPanel = (props) => {

  const { data, token, action } = props;
  const [clientData, setClientData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { userPermissions } = useContext(PermissionsContext);
  const isDeleteClientEnabled = userPermissions?.includes('clients_action_delete');

  const fields = getFields();

  const showModal = (status) => {
    setShowDeleteModal(status)
  }

  const deleteSuccess = () => {
    props.onDelete().onSuccess((response) => {
      showModal(false)
      props.hidePanel();
    })
  }

  const editFailure = (error) => {
    console.log('Error saving!')
    console.log(error)
  }

  useEffect(() => {
    fields.forEach(field =>
      (field.name === 'email') && (field.disabled = !!clientData.id)
    )
  }, [clientData.id, fields]);

  useEffect(() => {
    setClientData(data);
  }, [data]);

  return (
    <div>
      <DeleteModal
        clientId={clientData.id}
        deleteSuccess={deleteSuccess}
        showModal={showDeleteModal}
        hideModal={showModal}
        token={token}
      />
      <APIRequest
        resource={saveClient(action, token)}
        onSuccess={() => {
          setClientData(clientData);
          props.onSave();
          props.hidePanel();
          gtmEvents.newClientAction();
        }}
        onFailure={editFailure}>
        {request => (
          <SimpleForm
            defaultValues={clientData}
            validate={validateFields([
              { field: 'email', required: 1 },
              { field: 'email', validate: value => value && validEmail()(value), emailMaxLength: 50 },
              { field: 'birthdate', validate: value => value && validBirthdate()(value) },
              { field: 'firstName', required: 1 },
              { field: 'lastName', required: 1 }
            ])}
            validateOnSubmit={true}
            reset={!props.showDetails}
            onSubmit={(values) => {
              request.fetch(values)
            }}
          >
            {({ values, touched, isFetching }) => {
              return (
                <div className="panel-flex">
                  <div>
                    <div>
                      <label htmlFor={"email"} className="form-label">
                        {__("dashboard.sections.clients.form.email.label")} </label>
                      <TextField
                        id={"email"}
                        name={"email"}
                        type={"text"}
                        maxLength={50}
                        placeholder={__("dashboard.sections.clients.form.email.placeholder")}
                        disabled={clientData.id}
                      />
                    </div>
                    <div>
                      <label htmlFor={"firstName"} className="form-label">
                        {__("dashboard.sections.clients.form.firstName.label")} </label>
                      <TextField
                        id={"firstName"}
                        name={"firstName"}
                        type={"text"}
                        placeholder={__("dashboard.sections.clients.form.firstName.placeholder")}
                      />
                    </div>
                    <div>
                      <label htmlFor={"lastName"} className="form-label">
                        {__("dashboard.sections.clients.form.lastName.label")} </label>
                      <TextField
                        id={"lastName"}
                        name={"lastName"}
                        type={"text"}
                        placeholder={__("dashboard.sections.clients.form.lastName.placeholder")}
                      />
                    </div>
                    <div>
                      <label htmlFor={"phone"} className="form-label">
                        {__("dashboard.sections.clients.form.phone.label")} </label>
                      <TextField
                        id={"phone"}
                        name={"phone"}
                        type={"text"}
                        maxLength={15}
                        onInput={(e) => {
                          if (e.target.value.length) {
                            e.target.value = e.target.value.slice(0, getConfig("customConfig.ecommerceStore.phoneLength"));
                            e.target.value = e.target.value.replace(getConfig("customConfig.ecommerceStore.phoneRegexValidation"), "");
                          }
                        }}
                        placeholder={__("dashboard.sections.clients.form.phone.placeholder")}
                      />
                    </div>
                    <div>
                      <label htmlFor={"birthdate"} className="form-label">
                        {__("dashboard.sections.clients.form.birthdate.label")} </label>
                      <DateField
                        id={"birthdate"}
                        name={"birthdate"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <label htmlFor={"address"} className="form-label">
                        {__("dashboard.sections.clients.form.address.label")} </label>
                      <TextField
                        id={"address"}
                        name={"address"}
                        type={"text"}
                        placeholder={__("dashboard.sections.clients.form.address.placeholder")}
                      />
                    </div>
                    <div>
                      <label htmlFor={"city"} className="form-label">
                        {__("dashboard.sections.clients.form.city.label")} </label>
                      <TextField
                        id={"city"}
                        name={"city"}
                        type={"text"}
                        placeholder={__("dashboard.sections.clients.form.city.placeholder")}
                      />
                    </div>
                    <div>
                      <label htmlFor={"province"} className="form-label">
                        {__("dashboard.sections.clients.form.province.label")} </label>
                      <TextField
                        id={"province"}
                        name={"province"}
                        type={"text"}
                        placeholder={__("dashboard.sections.clients.form.province.placeholder")}
                      />
                    </div>
                  </div>
                  <div>
                    <Row>
                      {clientData.id && isDeleteClientEnabled &&
                        <Col md="6">
                          <Button
                            color="secondary"
                            type="button"
                            block={true}
                            onClick={() => { setShowDeleteModal(true) }}
                            disabled={isFetching}>
                            {__('actions.delete')}
                          </Button>
                        </Col>
                      }
                      <Col md={clientData.id && isDeleteClientEnabled ? "6" : "12"}>
                        <Button
                          color="success"
                          type="submit"
                          block={true}
                          disabled={!touched}
                        >
                          {clientData.id ? __('actions.save') : __('actions.create')}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            }}
          </SimpleForm>
        )}
      </APIRequest>
    </div>
  )
}
