import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import __ from 'utils/i18n';

export const Footer = ({
  buttonId,
  isFetching,
  showDeleteModal,
}) => (
  <Row>
    {buttonId && (
      <Col md='6'>
        <Button
          color='secondary'
          type='button'
          block={true}
          onClick={() => showDeleteModal(true)}
          disabled={isFetching}
        >
          {__('actions.delete')}
        </Button>
      </Col>
    )}

    <Col md={buttonId ? '6' : '12'}>
      <Button color='success' type='submit' block={true} disabled={isFetching}>
        {buttonId ? __('actions.save') : __('actions.create')}
      </Button>
    </Col>
  </Row>
);
