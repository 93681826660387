import { combineReducers } from 'redux'

import salesReducer from './sections/Sales/reducer'
import statsReducer from './sections/Stats/reducer'
import ecommerceReducer from './sections/Ecommerce/reducer'
import errorReducer from 'store/errorReducer'
import discoveryReducer from 'components/Discovery/reducer'
import compraClickReducer from './sections/CompraClick/reducer';

export default combineReducers({
  sales: salesReducer,
  stats: statsReducer,
  ecommerce: ecommerceReducer,
  discovery: discoveryReducer,
  errors: errorReducer,
  compraClick: compraClickReducer
})
