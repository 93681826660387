import React from 'react';
import MaskedInput from 'react-text-mask';

const MaskedDateInput = ({ parser, className, value, onChange, ...rest }) => (
  <MaskedInput
    {...rest}
    value={(parser && parser(value)) || value || ''}
    className={`form-control ${className}`}
    mask={[/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    placeholder='DD/MM/AAAA'
    onChange={(event) => onChange && onChange(event.target.value)}
  />
);

export default MaskedDateInput;
