import React from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';

export const Amounts = ({ batch }) => {
  return(
    <Table className="no-border bold-td-right">
      <tbody>
        <tr>
          <td>
            <span style={{ fontWeight: 'bold' }}>{__('dashboard.sections.batchClose.panel.salesAmount')}</span>
          </td>
          <td>
            <span style={{ fontWeight: 'bold' }}>{__.currency((batch.salesAmount), batch.currencyCode)}</span>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
