import React, {useEffect, useState} from 'react';
import DashboardPanel from 'containers/Dashboard/components/DashboardPanel';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'components/informed';
import { Button, Col, Row } from 'reactstrap';
import __ from 'utils/i18n';
import { setSelectedSubsidiaries as setSelectedSubsidiariesAction } from 'containers/Dashboard/sections/Sales/actions';

export const FiltersSubsidiariesWidget = ({
  expanded,
  subsidiaries,
  showSubsidiaries,
  onSave, setParentSubsidiaries,
  ...props
}) => {
  const [selectedSubsidiaries, setSelectedSubsidiaries] = useState(subsidiaries);
  const [error, setError] = useState(false);
  const subsidiariesStore = useSelector((state) => state.login.user.subsidiaries);
  const [filteredSubsidiaries, setFilteredSubsidiaries] = useState(null);
  const dispatch = useDispatch();
  const key = "user-subsidiary-selection";
  // guardar seleccion de sucursal del usuario
  const saveToLocalStorage = (value) => {
    localStorage.setItem(key, JSON.stringify(value))
  }
  const getFromLocalStorage = () => {
    let allIDs = subsidiariesStore?.map(s=>s.subsidiaryId);
    let data = JSON.parse(localStorage.getItem(key));
    return data?.filter ? data.filter(element => allIDs?.includes(element)) : null;
  }

  const search = () => {
    if (selectedSubsidiaries.length === 0) {
      setError(__("dashboard.sections.sales.searchErrorSubsidiary"));
      return false;
    }
    dispatch(setSelectedSubsidiariesAction(selectedSubsidiaries));
    onSave(selectedSubsidiaries);
  };

  useEffect(() => {
    const value = getFromLocalStorage();
    if (value){
      setSelectedSubsidiaries(value);
      setFilteredSubsidiaries(value);
      setParentSubsidiaries(value);
    } else {
      setFilteredSubsidiaries(selectedSubsidiaries);
    }
    //eslint-disable-next-line
  },[])

  const allSubsidiariesSelected = () => {
    return subsidiariesStore?.length === selectedSubsidiaries?.length;
  };

  const updateSubsidiaries = (isChecked, item) => {
    let mutableSubsidiary = [...selectedSubsidiaries];
    if (!isChecked) {
      mutableSubsidiary.splice(selectedSubsidiaries.indexOf(item.subsidiaryId), 1);
    } else {
      mutableSubsidiary.push(item.subsidiaryId);
    }
    setSelectedSubsidiaries(mutableSubsidiary);
    saveToLocalStorage(mutableSubsidiary);
    setError(mutableSubsidiary.length === 0);
  };

  const allSubsidiaries = (isChecked) => {
    const subsidiariesSelected = isChecked
      ? subsidiariesStore.map((item) => item.subsidiaryId)
      : [];
    saveToLocalStorage(subsidiariesSelected);
    setSelectedSubsidiaries(subsidiariesSelected);
    setError(!isChecked);
  };

  const cancelSubsidiarySelection = () => {
    setSelectedSubsidiaries(filteredSubsidiaries);
    saveToLocalStorage(filteredSubsidiaries);
    showSubsidiaries(false);
  };

  return (
    <>
      {subsidiaries &&
        subsidiariesStore?.length > 1 && (
        <Button
          onClick={() => { showSubsidiaries(true) }}>
          {subsidiaries.length ? (
            <>
              {subsidiaries.length} {__("dashboard.sections.sales.selectedSubsidiaries")}
            </>
          ) : (
            <>{__("dashboard.sections.sales.allSubsidiaries")}</>
          )}
          
        </Button>
      )}

      <DashboardPanel
        expanded={expanded}
        panelHeading={__("dashboard.sections.sales.subsidiaries")}
        hidePanel={cancelSubsidiarySelection}
      >
        <div className="panel-flex">
          <div>
            <label>{__("dashboard.sections.sales.subsidiaryBySale")}</label>
            <Checkbox
              label={__("dashboard.sections.sales.allSubsidiaries")}
              onChange={(event) => {
                allSubsidiaries(event);
              }}
              fieldState={{ value: allSubsidiariesSelected() }}
            />
            <div>
              {subsidiariesStore?.map((item, index) =>
                  <Checkbox
                    fieldState={{value:selectedSubsidiaries.includes(item.subsidiaryId)}}
                    label={item.subsidiaryName}
                    key={index}
                    id={item.subsidiaryId}
                    onChange={(e) => {
                      updateSubsidiaries(e, item);
                    }}
                  />
              )}
            </div>
            {error && <div className="request-error">{error}</div>}
          </div>

          <Row>
            <Col md="6">
              <Button
                color="secondary"
                type="button"
                block={true}
                onClick={cancelSubsidiarySelection}
              >
                {__("actions.cancel")}
              </Button>
            </Col>
            <Col md={"6"}>
              <Button
                onClick={() => search()}
                color="success"
                block={true}
                type="submit"
              >
                {__("actions.search")}
              </Button>
            </Col>
          </Row>
        </div>
      </DashboardPanel>
    </>
  );
};
