import React from 'react';
import moment from 'moment';
import { Badge } from 'reactstrap';

import FlexTable from 'components/FlexTable';
import __ from 'utils/i18n';

export const OrdersList = ({orders, showDetails, handleCheckboxChange}) => {

  // Cells
  const OrderNumber = ({ original }) => {
    const { number } = original
    return number ? number : '-'
  }

  const OrderDate = ({ original }) => {
    const { createdAt } = original;
    let date = '-';
    if (createdAt) date = moment(createdAt).format("LLL")
    return date;
  }

  const OrderStatus = ({ original }) => {
    const { status } = original;
    let labelStatus = '-';
    if (status) labelStatus = __(`dashboard.sections.ecommerce.orders.status.${status}`)
    return (
      <Badge pill className={`ecommerceOrders-badge-${status}`}>
        {labelStatus}
      </Badge>
    );
  }

  const OrderTotal = ({ original }) => {
    const { totalAmount, currency } = original;
    return totalAmount ? __.currency(totalAmount, currency) : '-'
  }

  const Checkbox = ({ original }) => {
    if (original.status !== "SUCCESS") return "";
    const checkbox = <input
      id={`checkbox-${original.id}`}
      name="checkOrder"
      onChange={e => handleCheckboxChange(e.target.value, e.target.checked)}
      type='checkbox'
      value={original.id}
    />
    return checkbox;
  }


  const getOrdersColumns = () => {
    return [
      {
        Header: '',
        Cell: Checkbox,
        cancelClick: true
      },
      {
        Header: __('dashboard.sections.ecommerce.orders.headers.number'),
        Cell: OrderNumber
      },
      {
        Header: __('dashboard.sections.ecommerce.orders.headers.date'),
        Cell: OrderDate
      },
      {
        Header: __('dashboard.sections.ecommerce.orders.headers.status'),
        Cell: OrderStatus
      },
      {
        Header: __('dashboard.sections.ecommerce.orders.headers.total'),
        Cell: OrderTotal
      },
    ]
  }

  return (
    <>
    <FlexTable
      data={orders}
      columns={getOrdersColumns()}
      onRowClick={({ index }) => showDetails(orders[index])}
    />
  </>
  )
}