import React, { useState } from "react";
import "../../../../../../../../styles/newStyles/MerchantCredentials.scss";
import { SimpleForm } from "components/forms";
import { TextField } from "components/forms/fields";
import i18n from "utils/i18n";
import { useSelector } from "react-redux";
import LoaderContainer from "../../components/LoaderContainer";
import { encryptCredential, login } from "containers/Login/resources";
import { fetchResource } from "api-provider";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import {tenantId} from "../../../../../../../../config/envConfig";
import moment from "moment";

const DeleteWorkflowsModal = ({ clientId, setIsOpen, isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.api.token);
  const email = useSelector((state) => state.login.user.email);
  const [errorMessage, setErrorMessage] = useState(i18n(""));

  const updateCurrentSession = (data) => {
    // Guardar el timestamp de la sesión en localStorage
    const sessionTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    localStorage.setItem('sessionTimestamp', sessionTimestamp);

    // Guardar permisos de usuario, flags de características y autenticación en localStorage
    localStorage.setItem(`geop_permissions_${tenantId}`, JSON.stringify(data?.userPermissions));
    localStorage.setItem(`geop_feature_flags_${tenantId}`, JSON.stringify(data?.featureFlags));
    localStorage.setItem(`geop_oauth_${tenantId}`, JSON.stringify(data?.serverAuth));
  }

  const deleteCredentials = () => {
    fetchResource(deleteCredentialsResource)
      .onSuccess((success) => {
        setIsLoading(false);
        setIsOpen(false);
        window.location.reload();
      })
      .onFailure((error) => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setErrorMessage("");
    setIsLoading(true);
    const encryptedUser = encryptCredential(email);
    const encryptedPassword = encryptCredential(values.password);
    
    fetchResource(login(encryptedUser, encryptedPassword))
      .onSuccess((success) => {
        updateCurrentSession(success);
        deleteCredentials();
      })
      .onFailure((error) => {
        if (error.length > 0) {
          setErrorMessage(error);
        } else {
          setErrorMessage(
            i18n(
              "dashboard.sections.merchantCredentials.step.password.errorMessage"
            )
          );
        }
        setIsLoading(false);
      });
  };

  const deleteCredentialsResource = () => {
    return {
      endpoint: `/api/v1/applications/credentials/${clientId}`,
      headers: {
        "Access-Control-Allow-Origin": true,
        "X-Authentication-Token": token,
      },
      proxy: false,
      method: "DELETE",
    };
  };

  const loadingStepTexts = {
    title: i18n(
      "dashboard.sections.merchantCredentials.step.loaderDelete.title"
    ),
    subTitle: i18n(
      "dashboard.sections.merchantCredentials.step.loaderDelete.subtitle"
    ),
  };

  const retryModal = () => {
    setIsOpen(!isOpen);
    window.location.reload();
  };

  return (
    <Modal
      id="modal-credential"
      className="credentials-modal"
      isOpen={isOpen}
      zIndex={100}
      centered
      size="lg"
    >
      <ModalHeader
        className="credentials-modal-header"
        toggle={() => retryModal()}
      ></ModalHeader>
      <ModalBody className="credentials-modal-body">
        <div className="credentials-modal-body-steps">
          <SimpleForm onSubmit={handleSubmit}>
            {isLoading ? (
              <LoaderContainer loadingTexts={loadingStepTexts} />
            ) : (
              <div className="credentials-modal-login-step">
                <h3 className="credentials-modal-title mb-5">
                  {i18n(
                    "dashboard.sections.merchantCredentials.step.password.title"
                  )}
                </h3>
                <label
                  htmlFor={"password"}
                  className="credentials-modal-label login-step-label"
                >
                  {i18n(
                    "dashboard.sections.merchantCredentials.step.password.passwordForm"
                  )}
                </label>
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  className="credentials-modal-input login-step-input"
                />
                <div className="text-danger text-position">{errorMessage}</div>
                <div className="credentials-modal-button-container">
                  <Button
                    className="btn btn-primary credentials-modal-step-button credentials-modal-step-button-btn-login mx-auto"
                    color="primary"
                    type="submit"
                    retryModal={retryModal}
                  >
                    {i18n("login.button")}
                  </Button>
                </div>
              </div>
            )}
          </SimpleForm>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteWorkflowsModal;
