import React from 'react';
import {
  Row,
  Col,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { fetchResource } from 'api-provider';
import __ from 'utils/i18n';
import { importProducts } from '../../../_shared/resources';
import { useMutation } from '@tanstack/react-query';

export const Step2 = ({ data, token, subsidiaryId, goStep }) => {
  const { isLoading, mutate: onImport } = useMutation({
    mutationFn: () => fetchResource(importProducts(data.importFileName, token, subsidiaryId)),
    onSuccess: (response) => goStep('uploadImages', response.data)
  });

  if (!data) return null;

  return (
    <div>
      <button
        type='button'
        class='close hidden-modal'
        aria-label='Close'
        onClick={onImport}
      >
        <span aria-hidden='true'>×</span>
      </button>
      <ModalHeader>
        {`${__('dashboard.sections.catalog.productsImport.importCatalog')} ${__(
          'dashboard.sections.catalog.productsImport.step2'
        )}`}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md='12'>
            <h4 className='import-modal__subtitle'>
              <i className='mdi mdi-file' />
              {__('dashboard.sections.catalog.productsImport.elementsImports')}
            </h4>
            {data.createQuantity && (
              <p>
                {__('dashboard.sections.catalog.productsImport.createMessage', {
                  count: data.createQuantity,
                })}
              </p>
            )}
            {data.updateQuantity && (
              <p>
                {__('dashboard.sections.catalog.productsImport.updateMessage', {
                  count: data.updateQuantity,
                })}
              </p>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onImport}>
          {__('dashboard.sections.catalog.productsImport.cancel')}
        </Button>
        <Button
          disabled={isLoading}
          color='success'
          onClick={onImport}
        >
          {__('dashboard.sections.catalog.productsImport.import')}
        </Button>
      </ModalFooter>
    </div>
  );
};
