import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import __ from 'utils/i18n';
import { forEach, sortBy } from 'lodash';
import FlexTable from 'components/FlexTable';
import TableHeaderActions from 'components/TableHeaderActions';
import Loader from 'components/Loader';
import FullTextSearch from 'components/FullTextSearch';
import DashboardPanel from 'containers/Dashboard/components/DashboardPanel';
import { CategoryPanel } from '../CategoryPanel';
import { getCategoryColumns } from '../../_shared/columns';
import { PermissionsContext } from 'contexts/permissionsContext';
import gtmEvents from 'utils/gtmEvents';
import { getConfig } from 'config';

let configCatColumns = getConfig('tableColumns.categoriesColumns')

const flatten = (map, acum, parents) => {
  forEach(map, function (el) {
    el.item.deep = parents;
    acum.push(el.item);
    el.item.hasChildren = !!el.children.length;
    if (el.item.hasChildren) {
      el.children = sortBy(el.children, 'item.categoryName');
      flatten(el.children, acum, parents.concat(el.item));
    }
  });
};

const sortByNameAndChilds = (categories) => {
  let childrenMap = {};
  let map = [];

  categories.forEach((category) => {
    let item = {
      item: category,
      children: childrenMap[category.categoryId] || [],
    };
    childrenMap[category.categoryId] = item.children;

    if (category.parentCategoryId) {
      if (!childrenMap[category.parentCategoryId]) {
        childrenMap[category.parentCategoryId] = [];
      }
      childrenMap[category.parentCategoryId].push(item);
    } else {
      map.push(item);
    }
  });

  map = sortBy(map, 'item.categoryName');

  let categoriesList = [];

  flatten(map, categoriesList, []);

  return categoriesList;
};

export const CategoriesContent = ({
  isFetching,
  error,
  reload,
  data,
  fetchCategories,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [items, setItems] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const { userPermissions } = useContext(PermissionsContext);
  const isAddCategoryEnabled = userPermissions?.includes('categories_action_add');
  const isEditCategoryEnabled = userPermissions?.includes('categories_action_edit');

  const handleDetails = (data) => {
    if (isEditCategoryEnabled) {
      setShowDetails(true);
      setSelectedCategoryId((data && data.categoryId) || '');
    }
  };

  const getSelectedCategory = () => {
    return (
      data.find((category) => category.categoryId === selectedCategoryId) || {}
    );
  };

  useEffect(() => {

    gtmEvents.cataloguePageView('pageCategory')

    setCategories(sortByNameAndChilds(data));
  }, [data, setCategories]);

  return (
    <div
      className={classnames({
        'dashboard-card': true,
        'panel-expanded': showDetails,
      })}
    >
      <DashboardPanel
        expanded={showDetails}
        panelHeading={
          getSelectedCategory().categoryName
            ? `${__('dashboard.sections.catalog.categories.edit')} ${getSelectedCategory().categoryName
            }`
            : __('dashboard.sections.catalog.categories.newCategory')
        }
        hidePanel={() => setShowDetails(false)}
      >
        <CategoryPanel
          isFetching={isFetching}
          data={getSelectedCategory()}
          categories={categories}
          onSave={fetchCategories}
          onDelete={fetchCategories}
          hidePanel={() => setShowDetails(false)}
          showDetails={showDetails}
        />
      </DashboardPanel>

      <div className='dashboard-card-header'>
        <FullTextSearch
          keys={['categoryName']}
          items={categories}
          onChange={(items) => setItems(items)}
        />
        {isAddCategoryEnabled && (
          <TableHeaderActions
            createItem={{
              name: __('dashboard.sections.catalog.categories.newCategory'),
              action: handleDetails,
            }}
          />
        )}
      </div>

      <div className='dashboard-card-body'>
        {isFetching && <Loader />}
        {error && (
          <div className='dashboard-card-message'>
            <h3 className='text-danger'>{error}</h3>
            <Button color='secondary' onClick={reload}>
              {__('dashboard.sections.catalog.categories.retry')}
            </Button>
          </div>
        )}
        {!items.length && !isFetching && (
          <div className='dashboard-card-message'>
            <i className='mdi mdi-minus-circle' />
            <h3>
              {__('dashboard.sections.catalog.categories.withoutCategories')}
            </h3>
            <p>
              {__('dashboard.sections.catalog.categories.notFoundCategories')}
            </p>
          </div>
        )}
        {!isFetching && !error && !!items.length && (
          <FlexTable
            data={items}
            columns={getCategoryColumns(configCatColumns)}
            onRowClick={({ index }) => handleDetails(items[index])}
          />
        )}
      </div>
    </div>
  );
};
