export const getBatchClose = ( token, payload ) => {
  return {
    endpoint: "/api/v1/batches",
    method: 'GET',
    headers: {
      'x-authentication-token': token,
    },
    cache: true,
    params: payload,
    proxy: false,
    parseResponse: (response) => response.data.data,
  };
}
