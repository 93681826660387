import React from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';

export const Total = ({ data }) => {
  const { total, currency } = data;

  return (
    <Table className='no-border table-header'>
      <tbody>
        <tr>
          <td>
            <span>
              <b>{__('dashboard.sections.deposits.totalToReceive')}</b>
            </span>
          </td>
          <td>
            <b>{__.currency(total, currency.code)}</b>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
