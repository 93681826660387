import React from 'react';
import { SelectField, SimpleForm } from 'components/forms';
import __ from 'utils/i18n';

export const SortingCriteria = ({ sortingCriteria, handleSortingCriteria }) => {
  const options = [
    { value: 'ASC', label: 'Ascendente' },
    { value: 'DESC', label: 'Descendente' },
  ];

  return (
    <div className='statusDropdownContainer'>
      <SimpleForm
        defaultValues={{
          sorting_criteria: sortingCriteria ? sortingCriteria : 'DESC',
        }}
      >
        <SelectField
          name='sorting_criteria'
          placeholder={
            sortingCriteria
              ? sortingCriteria
              : __('dashboard.sections.compraClick.sorting.dropdownCriteria')
          }
          options={options.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
          value={sortingCriteria}
          onChange={(value) => handleSortingCriteria(value)}
        />
      </SimpleForm>
    </div>
  );
};
