import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchResource } from "api-provider";
import {
  setEcommerce,
  clearEcommerce,
} from "containers/Dashboard/sections/Ecommerce/actions";
import { getProducts, findStore } from "../../_shared/resources";
import { ProductsContent } from "./ProductsContent";
import { PermissionsContext } from "contexts/permissionsContext";
import gtmEvents from "utils/gtmEvents";
import { useQuery } from '@tanstack/react-query';

export const Products = () => {
  const { api } = useSelector((state) => state);
  const { accountId, subsidiaryId, catalogEditor, roleUser } = useSelector((state) => state.login.user);
  const { token } = useSelector((state) => state.api);

  const { featureFlags } = useContext(PermissionsContext);
  const isEcommerceEnabled = featureFlags?.includes("ecommerce_section");

  const requestEcommerce = {};

  const dispatch = useDispatch();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['getProducts'],
    queryFn: () => fetchResource(getProducts(subsidiaryId, token)),
  });

  useEffect(() => {
    if (!api) return;

    gtmEvents.cataloguePageView('pageProduct');
    requestEcommerce.resume && requestEcommerce.resume.cancel();
    requestEcommerce.resume =
      isEcommerceEnabled &&
      fetchResource(findStore(token, roleUser, accountId), {}, api)
        .onSuccess((data) => {
          if (data) {
            dispatch(setEcommerce(data));
          }
        })
        .onFailure((error) => {
          dispatch(clearEcommerce());
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountId,
    api,
    dispatch,
    isEcommerceEnabled,
    roleUser,
    token,
    requestEcommerce.resume,
  ]);

  return (
    <ProductsContent
      fetchProducts={refetch}
      isFetching={isLoading}
      data={data || []}
      error={error}
      subsidiaryId={subsidiaryId}
      catalogEditor={catalogEditor}
    />
  );
};
