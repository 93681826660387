import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import i18n from 'utils/i18n'
import { logout } from "./actions";
import { getConfig } from "config";

const Logout = (props) =>  {

  const dispatch = useDispatch();
  const redirectTo = getConfig("customConfig.logout.redirectTo") || "/";
  const userEmail = useSelector((state) => state.login?.user?.email);
  const isAuthenticated = useSelector((state) => state.login?.isAuthenticated);

  useEffect( () => {
    dispatch(logout());
  }, [dispatch]) 
    
  return (
    <div className="container" style={{ margin: 40 }}>
      { isAuthenticated ?
        <h2 className="text-center">{i18n('logout.Goodbye') + ' '} {userEmail}</h2> :
        <Redirect to={redirectTo}/>
      }

    </div>
  );
  
}

export default Logout;
