import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEqual, isUndefined, get } from 'lodash';
import { fetchResource as fetchRemoteResource } from './actions';

class APIRequest extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      payload: {},
      success: false,
      response: null,
      isFetching: false,
    };
  }

  componentDidMount() {
    const { fetch, resource, payload } = this.props;

    if (fetch === true) {
      this.fetchResource(resource, payload);
    }
  }

  componentWillReceiveProps({ fetch, resource, payload }) {
    if (!fetch || isEqual(resource, this.props.resource)) {
      return;
    }

    this.fetchResource(resource, payload);
  }

  cancelRequest() {
    this.currentFetch.cancel();
    this.currentFetch = null;
  }

  onFetchSuccess = (data, payload) => {
    const { onSuccess } = this.props;

    this.setState({
      error: false,
      success: true,
      response: data,
      isFetching: false,
    });

    if (!isUndefined(onSuccess)) {
      onSuccess(data, payload);
    }
  };

  onFetchFailure = (error) => {
    const { onFailure } = this.props;

    if(error?.response?.status === 401) {
      this.props.dispatch({
        type: 'dashboard/SET_DASHBOARD_ERROR_MESSAGE',
        payload: { message: 'Sesión expirada' }
      })
      this.props.history.push('/logout');
    }

    const responseContent = error?.response?.data;
    const jsonApiErrorMessage = get(responseContent, 'errors[0].detail');
    const legacyErrorMessage = get(responseContent, 'response.message');
    const message = jsonApiErrorMessage || legacyErrorMessage || undefined;

    this.setState({
      isFetching: false,
      response: null,
      error:message,
    })

    if (!isUndefined(onFailure)) {
      onFailure(message || error);
    }

  };

  fetchResource(resource, payload) {
    this.setState({ isFetching: true, payload });

    const { api } = this.props;

    if (this.currentFetch) {
      this.cancelRequest();
    }

    this.currentFetch = fetchRemoteResource(resource, payload, api)
      .onSuccess((data) => {
        this.onFetchSuccess(data, payload);
      })
      .onFailure(this.onFetchFailure);

    return this.currentFetch;
  }

  render() {

    const props = {
      ...this.props,
      ...this.state,
      fetch: (payload) => this.fetchResource(this.props.resource, payload),
      cancel: () => this.cancelRequest(),
    };

    const { children, ...rest } = props;
    return (typeof children === 'function') ? children(rest) : children;
  }
}

const ConnectedAPIRequest = connect(({ api }) => ({ api }))(APIRequest)

export default withRouter(ConnectedAPIRequest);
