import React from "react";
import { PermissionsContext } from "contexts/permissionsContext";
import { AmountField } from "./components/AmountField";
import { PaymentChannelField } from "./components/PaymentChannelField";

export const RenderFields = ({showAmount, showPaymentChannel, applicatedFields, setApplicatedFields}) => {
    const { featureFlags } = React.useContext(PermissionsContext);

    return (
        <>
            {featureFlags.includes("filters_amount_field") &&
                showAmount && (
                    <AmountField
                        applicatedFields={applicatedFields}
                        setApplicatedFields={setApplicatedFields}
                    />
                )}
            {featureFlags.includes("filters_payment_channel_field") &&
                showPaymentChannel && (
                    <PaymentChannelField
                        applicatedFields={applicatedFields}
                        setApplicatedFields={setApplicatedFields}
                    />
                )}
        </>
    );
};