export const getResume = (subsidiaryId, currencyCode, token) => (payload) => ({
  endpoint: "stats/getResume.json",
  method: "POST",
  headers: {
    "X-Authentication-Token": token,
  },
  params: {
    subsidiaryId,
    currencyCode,
  },
  parseResponse: (response) => response.data.data,
  cache: true,
});

export const getSales = (subsidiaryId, currencyCode, token) => (payload) => ({
  endpoint: "stats/sales",
  method: "POST",
  headers: {
    "X-Authentication-Token": token,
  },
  params: {
    subsidiaryId,
    currencyCode,
  },
  cache: true,
  formData: payload,
})
