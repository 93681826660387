import React from 'react'
import { Button } from 'reactstrap'
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import { getProductsColors } from 'utils';
import { getConfig } from 'config'
import __ from 'utils/i18n'

export default class ProductImageModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showDeleteModal: false,
      file: null,
      url: this.props.url || null,
      color: this.props.color || null,
      deleteProductImage: false,
      initialState: { ...this.props },
      imageError: false
    }
  }

  componentWillReceiveProps() {

  }

  showDeleteModal() {
    this.setState({ showDeleteModal: true })
  }

  hideDeleteModal() {
    this.setState({ showDeleteModal: false })
  }

  hideModal() {
    this.setState({ modified: false })
    this.props.hideModal()
  }

  changeColor(newColor) {
    this.setState({ modified: true })
    this.setState({
      color: newColor
    })
  }

  changeImage(newImage) {
    this.setState({ modified: true })
    this.setState({
      file: newImage[0],
      url: URL.createObjectURL(newImage[0]),
      deleteProductImage: false
    })
  }

  onImageDrop(images) {
    const { accepted, rejected } = images;

    if (accepted.length) {
      accepted.forEach(file => {
        const image = new Image();
        image.addEventListener('load', () => {
          if ((image.width >= 350) && (image.height >= 350)) {
            this.changeImage(accepted);
            this.setState({
              imageError: false
            })
          } else {
            this.setState({
              imageError: true
            })
          }
        });
  
        image.src = URL.createObjectURL(file)
      })
    }

    if (rejected.length) {
      const maxSize = getConfig('customConfig.catalog.products.imageMBMaxSize')
      alert(__('inputs.image.alertSize', { maxSize }))
    }
  }

  deleteProductImage() {
    this.setState({
      modified: true,
      file: null,
      url: null,
      deleteProductImage: true
    })

    this.hideDeleteModal()
  }

  saveChanges() {
    this.props.onSave({
      url: this.state.url || '',
      file: this.state.file || null,
      color: this.state.color || '',
      deleteProductImage: this.state.deleteProductImage || null
    })

    this.setState({
      showDeleteModal: false,
      file: null,
      url: null,
      color: null,
      deleteProductImage: false
    })

    this.hideModal()
  }

  render() {
    const colors = getProductsColors();
    const mbToBMagnitude = 1024 * 1024;
    const maxSize = getConfig('customConfig.catalog.products.imageMBMaxSize');
    const maxSizeBytes = maxSize * mbToBMagnitude;

    const dropzoneClasses = classNames({
      'dropzone': true,
      'dropzoneHasPreview': !!this.state.url
    })

    let dropzoneRef

    return (
      <div>
        <DashboardModal
          name="deleteImage"
          headerText={__('inputs.image.deleteImageProduct')}
          showModal={this.state.showDeleteModal}
          hideModal={this.hideDeleteModal.bind(this)}
          submitAction={this.deleteProductImage.bind(this)}
          cancelText={__('actions.cancel')}
          submitText={__('actions.delete')}>
          {__('inputs.image.confirmDeleteImage')}
        </DashboardModal>

        <DashboardModal
          name="editImage"
          modalClass="edit-image-modal"
          headerText={__('inputs.image.editImageProduct')}
          showModal={this.props.showModal}
          hideModal={this.hideModal.bind(this)}
          submitAction={this.saveChanges.bind(this)}
          cancelText={__('actions.cancel')}
          submitText={__('actions.save')}
          submitDisabled={!this.state.modified || this.state.fetching}>
          <div className="catalog__product--image-edit">
            <div className="dropzone-container">
              <Dropzone
                className={dropzoneClasses}
                ref={(node) => { dropzoneRef = node }}
                activeClassName="dropzoneActive"
                rejectClassName="dropzoneReject"
                accept="image/jpeg, image/png, image/png, image/bmp"
                maxSize={maxSizeBytes}
                onDrop={(accepted, rejected) => {
                  this.onImageDrop({ accepted, rejected })
                }}>
                {({ getRootProps, getInputProps }) => (
                  <>
                    {this.state.url ?
                      <div className="dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <img
                          className="img-cover"
                          alt="Product"
                          src={this.state.url}
                          height="100px"
                          width="100px"
                        />
                      </div>
                      :
                      <div className="dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <i className="mdi mdi-image" />
                      </div>
                    }
                  </>
                )}
              </Dropzone>
              <button
                className="edit-icon"
                onClick={() => { dropzoneRef.open() }}><i className="mdi mdi-light mdi-pencil" /></button>
            </div>
            {this.state.url && <div className="dropzone__buttons">
              <Button
                color="secondary"
                type="button"
                onClick={this.showDeleteModal.bind(this)}>{__('inputs.image.deleteImage')}</Button>
            </div>}
          </div>
          <div>
            <p className="small-text">{__.bb('inputs.image.selectImageProduct', { maxSize })}</p>
            {this.state.imageError && (<p className="error-text">{__.bb('validators.smallDimension')}</p>)}
          </div>
          <div>
            {colors.map((color, index) => (
              <button
                key={index}
                style={{ backgroundColor: '#' + color }}
                className={classNames({
                  'catalog__product--color': true,
                  'selected-color': this.state.color === color
                })}
                onClick={() => this.changeColor(color)}>
                {this.state.color === color ? <span><i className="mdi mdi-light mdi-check mdi-24px" /></span> : null}
              </button>
            ))}
          </div>
        </DashboardModal>
      </div>
    )
  }
}
