
import { validations } from 'components/forms/validators';

export const getFields = () => {
  // TODO refactorizar componente para usar los campos directo en el formulario
  // const fields = getConfig("dashboard.clients.fieldsPanel")
  const fields = [
    {
      type: 'text',
      name: 'email',
    },
    {
      type: 'text',
      name: 'firstName',
    },
    {
      type: 'text',
      name: 'lastName',
    },
    {
      type: 'text',
      name: 'phone',
    },
    {
      type: 'text',
      name: 'birthdate',
    },
    {
      type: 'text',
      name: 'address',
    },
    {
      type: 'text',
      name: 'city',
    },
    {
      type: 'text',
      name: 'province',
    },
  ];

  fields.forEach((item, index) =>
    (item.name === 'email') && (
      fields[index].validate = validations({ required: 1, email: 1 })
    )
    (item.name === 'fullName' ||
      item.name === 'firstName' ||
      item.name === 'lastName') && (
      fields[index].validate = validations({ required: 1, name: 1 })
    )
    (item.name === 'birthdate') && (
      fields[index].validate = validations({ birthdate: 1 })
    )
  );
  return fields;
}
