import React, { useRef } from 'react';
import Flatpickr from "react-flatpickr";
import { Button } from 'reactstrap';
import { asField } from 'informed';
import moment from "moment";

const DateTime = asField(({ fieldState, fieldApi, forwardedRef, label, field, disabled, onChange, smallText }) => {
  const fp = useRef(null);

  return(
    <div className="position-relative form-group">
      <label className="">{label}</label>
      <div className="input-group">
        <Flatpickr ref={fp}
          className="form-control"
          options={{
            altFormat: "d/m/Y H:i",
            altInput: true,
            dateFormat: "d/m/Y H:i",
            defaultDate: fieldState.value,
            enableTime: true,
            time_24hr: true,
            defaultHour: moment().format('H'),
            defaultMinute: moment().format('m'),
          }}
          onChange={(selectedDates, dateStr, instance) => {
            fieldApi.setValue(dateStr, field);
            if (onChange) {
              onChange(dateStr);
            }
          }}
          disabled={disabled}
        />
        <Button
          color="primary"
          type="button"
          onClick={()=>fp.current.flatpickr.clear()}>
          X
        </Button>
      </div>

      {fieldState.error ? (
        <small className="text-danger form-text text-muted">{fieldState.error}</small>
      ) : null}

      {smallText ? (
        <small className="text-danger form-text text-muted">{smallText}</small>
      ) : null}
    </div>
  )
});

export default DateTime
