import { apiRequestPromise } from "api-provider";

export const handleClose = async ({
  userId,
  tenantId,
  token,
  detailsData,
  applyFilter,
  setBatchModal,
}) => {
  let data = {
    userId,
  };

  if (detailsData) {
    data.paymentMethodId = detailsData.installmentId;
  }

  try {
    await apiRequestPromise({
      method: "POST",
      url: "/api/v1/batches/close",
      data,
      headers: {
        "x-authentication-token": token,
        "x-client-id": tenantId,
      },
    });
    applyFilter();
  } catch (e) {
    console.log("Ocurrió un error:", e);
  }
  setBatchModal(false);
};
