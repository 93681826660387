import React from 'react'
import { Table } from 'reactstrap'
import __ from 'utils/i18n'

export const BatchInfo = ({ batch }) => {
  return(
    <Table className="no-border">
      <tbody>
        <tr>
          <td>{__('dashboard.sections.batchClose.panel.terminalId')}</td>
          <td>{batch.terminalId}</td>
        </tr>
        <tr>
          <td>{__('dashboard.sections.batchClose.panel.internalClousureNumber')}</td>
          <td>{batch.internalClosureNumber}</td>
        </tr>
        <tr>
          <td>{__('dashboard.sections.batchClose.panel.batch')}</td>
          <td>{batch.authorizationCode}</td>
        </tr>   
      </tbody>
    </Table>
  )
}
