import React from 'react';
import { useSelector } from 'react-redux';
import { fetchResource } from 'api-provider';
import { getCategories } from '../../_shared/resources';
import { CategoriesContent } from './CategoriesContent';
import { useQuery } from '@tanstack/react-query';

export const Categories = () => {
  const { subsidiaryId } = useSelector((state) => state.login.user);
  const { token } = useSelector((state) => state.api);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['getCategories'],
    queryFn: () => fetchResource(getCategories(subsidiaryId, token))
  });

  return (
    <CategoriesContent
      fetchCategories={refetch}
      isFetching={isLoading}
      data={data || []}
      error={error}
      subsidiaryId={subsidiaryId}
    />
  );
};
