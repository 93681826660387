import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { buildTooltip, Chart } from './base';
import { getChartsColors } from 'utils';

export class DonutChart extends React.Component {

  static propTypes = {
    chartConfig: PropTypes.object,
    limit: PropTypes.number,
    noData: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number
    }))
  }

  static defaultProps = {
    limit: 4,
    data: [],
    metric: 'value',
    formatter: x => x
  }

  render() {

    const { title, data, limit, metric, formatter, noData } = this.props

    if ((!data || !data.length) && noData) {
      return <div>{noData}</div>
    }

    const chart = new Chart()

    chart.setConfig({
      "chart.type": "pie",
      "chart.height": 150,
      "chart.margin": [5, 5, 5, 5],
      "chart.spacing": [5, 5, 5, 5],
      "chart.plotBackgroundColor": null,
      "chart.plotBorderWidth": null,
      "chart.plotShadow": false,
      "tooltip.shared": false,
      "customTooltip": ({ x, y, total, percentage }) => (
        buildTooltip({
          title: x,
          value: `
            <div>${percentage.toFixed(2)} %</div>
            `
        })
      ),
      "plotOptions.pie": {
        innerSize: "60%",
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        }
      },
      "series": [{
        colorByPoint: true,
        data: data.slice(0, limit).map(item => ({
          name: item.name,
          y: Number(item[metric])
        })),
      }]
    })

    chart.setConfig(this.props.chartConfig)

    return (
      <div className="chart-container">
        {title && (
          <div className="chart-header">
            <div className="chart-title">
              {title}
            </div>
          </div>
        )}

        <div className="chart-content">
          <Row className="chart-donut">
            <Col lg="4" md="12" className="chart-donut-graph">
              {chart.render()}
            </Col>
            <Col lg="8" md="12" className="chart-legend">
              {data.slice(0, limit).map((item, index) => (
                <div key={index} className="chart-legend-item">
                  <span
                    className="chart-legend-index"
                    style={{ backgroundColor: getChartsColors()[index] }}>
                    {index + 1}
                  </span>
                  <span className="chart-legend-name" title={item.name}>{item.name}</span>
                  <span className="chart-legend-value">{formatter(item[metric])}</span>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
