import React, { useState, useContext } from 'react';
import classnames from 'classnames';
import { sample } from 'lodash';
import { Button } from 'reactstrap';
import { PermissionsContext } from 'contexts/permissionsContext';
import FlexTable from 'components/FlexTable';
import TableHeaderActions from 'components/TableHeaderActions';
import Loader from 'components/Loader';
import FullTextSearch from 'components/FullTextSearch';
import DashboardPanel from 'containers/Dashboard/components/DashboardPanel';
import { ProductPanel } from '../ProductPanel/index';
import { ProductsImport } from '../DashboardImport/ProductsImport';
import { getProductsColumns } from '../../_shared/columns';
import { getProductsColors } from 'utils';
import __ from 'utils/i18n';
import { getConfig } from 'config';

export const ProductsContent = ({
  subsidiaryId,
  catalogEditor,
  data,
  isFetching,
  error,
  reload,
  fetchProducts,
}) => {
  const [showImport, setShowImport] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [items, setItems] = useState(data);

  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const isAddProductEnabled = userPermissions?.includes('products_action_add');
  const isEditProductEnabled = userPermissions?.includes('products_action_edit');
  const isProductImportEnabled = featureFlags?.includes('product_import');
  const isStockControlEnabled = featureFlags?.includes('stock_control');

  let configColumns = getConfig('tableColumns.productsColumns');

  if (!isStockControlEnabled) {
    configColumns = configColumns.filter((x) => x !== 'availableQuantity');
  }

  const handleDetails = (data) => {
    if (isEditProductEnabled) {
      setShowDetails(true);
      setSelectedProductId((data && data.productId) || '');
    }
  };
  
  const handleCloseImport = () => {
    setShowImport(false);
    fetchProducts();
  };

  const getSelectedProduct = () => {
    return (
      data.find((product) => product.productId === selectedProductId) || {
        backgroundColor: sample(getProductsColors()),
      }
    );
  };

  return (
    <div
      className={classnames({
        'dashboard-card': true,
        'panel-expanded': showDetails,
      })}
    >
      <DashboardPanel
        expanded={showDetails}
        panelHeading={
          getSelectedProduct().productName
            ? `${__('dashboard.sections.catalog.products.edit')} ${getSelectedProduct().productName
            }`
            : __('dashboard.sections.catalog.products.newProduct')
        }
        hidePanel={() => setShowDetails(false)}
      >
        <ProductPanel
          isFetching={isFetching}
          data={getSelectedProduct()}
          subsidiaryId={subsidiaryId}
          onSave={fetchProducts}
          onDelete={ fetchProducts}
          hidePanel={() => setShowDetails(false)}
          showDetails={showDetails}
        />
      </DashboardPanel>

      <ProductsImport showImport={showImport} onClose={handleCloseImport} />

      <div className='dashboard-card-header'>
        <FullTextSearch
          keys={['productName', 'categoryName', 'productPrice']}
          items={data}
          onChange={(items) => setItems(items)}
        />
        {isProductImportEnabled && (
          <Button onClick={() => setShowImport(true)}>
            {__('dashboard.sections.catalog.products.importExport')}
          </Button>
        )}
        {catalogEditor && isAddProductEnabled && (
          <TableHeaderActions
            createItem={{
              name: __('dashboard.sections.catalog.products.newProduct'),
              action: (data) => handleDetails(data),
            }}
          />
        )}
      </div>

      <div className='dashboard-card-body'>
        {isFetching && <Loader />}
        {error && (
          <div className='dashboard-card-message'>
            <h3 className='text-danger'>{error}</h3>
            <Button color='secondary' onClick={reload}>
              {__('dashboard.sections.catalog.products.retry')}
            </Button>
          </div>
        )}
        {!items.length && !isFetching && !error && (
          <div className='dashboard-card-message'>
            <i className='mdi mdi-minus-circle' />
            <h3>{__('dashboard.sections.catalog.products.withoutProducts')}</h3>
            <p>{__('dashboard.sections.catalog.products.notFoundProducts')}</p>
          </div>
        )}
        {!isFetching && !error && !!items.length && (
          <FlexTable
            data={items}
            columns={getProductsColumns(configColumns)}
            onRowClick={({ index }) => handleDetails(items[index])}
          />
        )}
      </div>
    </div>
  );
};
