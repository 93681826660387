

import { ACTIONS } from './constants'

const ecommerceReducer = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.SET_ECOMMERCE:
      return { ...state, ...action.ecommerce };
    case ACTIONS.CLEAR_ECOMMERCE:
      return null;
    default:
      return state;
  }
};

export default ecommerceReducer;