import React from 'react';
import moment from 'moment';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import __ from 'utils/i18n';
import { Badge } from 'reactstrap';
import Loader from 'components/Loader'


const statusIcos = {
  COMPLETED: 'check-all',
  SUCCESS: 'check',
  PENDING: 'clock',
  EXPIRED: 'close',
  FAILED: 'close',
  FAIL_CHECKOUT: 'close',
}

export const DetailsModal = ({
  showModal = false,
  closeAction,
  submitAction,
  order = {},
}) => {
  const {
    number,
    paymentIntentionId,
    status,
    items = [],
    createdAt,
    totalAmount,
    currency
  } = order;

  return (
    <DashboardModal
      name='detailsOrderModal'
      modalClass='ecommerceOrders-modal ecommerceOrders-details-modal'
      headerText={`${__('dashboard.sections.ecommerce.orders.order')} # ${number ? number : ''}`}
      showModal={showModal}
      hideModal={closeAction}
      submitAction={submitAction}
      submitColor='success'
      showFooter={status === "SUCCESS"}
      cancelText={__('actions.return')}
      hideSeparator={true}
      submitIcon= "mdi mdi-check-all"
      submitText={__('actions.completeOrder')}>
      {(!order.id) &&
        <div className='ecommerceOrders-details-loader'>
          <Loader />
        </div>
      }
      {(order && order.id) &&
        <div className='ecommerceOrders-details-main'>
          <div className='ecommerceOrders-details-header'>
            <span className='ecommerceOrders-icon'><i className='mdi mdi-calendar' />{moment(createdAt).format("LLL")}</span>
            <span className='ecommerceOrders-icon'><i className='mdi mdi-credit-card' />{paymentIntentionId}</span>
          </div>
          <div className='ecommerceOrders-details-status'>
            <Badge className={`ecommerceOrders-status-badge ecommerceOrders-badge-${status}`}>
              <span className='ecommerceOrders-icon'>
                <i className={`mdi mdi-${statusIcos[status]}`} />
                {__(`dashboard.sections.ecommerce.orders.status.${status}`)}
              </span>
            </Badge>
          </div>
          <div className='ecommerceOrders-details-items-list'>
            {Boolean(items.length) &&
              <>
                <hr />
                <ul className='ecommerceOrders-details-list'>
                  {items.map(item => (
                    <li key={item.id} className='ecommerceOrders-details-list-item'>
                      <span className='ecommerceOrders-icon'><i className='mdi mdi-cube' />{`${item.name} x ${item.unitPrice.quantity || 0}`}</span>
                      <span className='ecommerceOrders-icon'>{__.currency(item.totalPrice, currency)}</span>
                    </li>
                  ))}
                </ul>
                <hr />
              </>
            }
            {Boolean(!items.length) &&
              <p>{__(`dashboard.sections.ecommerce.orders.empty`)}</p>
            }
          </div>
          <div className='ecommerceOrders-details-total'>
            <div className='ecommerceOrders-icon' ><i className='mdi mdi-cash-multiple' />{__(`dashboard.sections.ecommerce.orders.upperTotal`)}</div>
            <div>{__.currency(totalAmount, currency)}</div>
          </div>
        </div>
      }
    </DashboardModal>
  )
}