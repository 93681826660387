import React from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';

export const Summary = ({ sale, config }) => {
  const { summary, qr = [], showSummary } = config;
  const hasQr = sale.qr || sale?.terminal?.type === "QR" || null;
  return (
    <Table className='no-border bold-td-right'>
      <tbody>
        {showSummary(sale) && (
          <>
            {summary.map((item, index) => {
              const hasItem = item.method(sale) && item.method(sale) !== '';
              return hasItem ? (
                <tr key={index}>
                  <td>
                    <span>{__(item.label)}</span>
                  </td>
                  <td className={sale.payment.cardNumber ? 'show-data' : ''}>{item.method(sale)}</td>
                </tr>
              ) : null;
            })}
          </>
        )}
        {hasQr && (
          <>
            {qr.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>{__(item.label)}</span>
                </td>
                <td>{item.method(sale)}</td>
              </tr>
            ))}
          </>
        )}
      </tbody>
    </Table>
  );
};
