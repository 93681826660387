import React, { useContext } from "react";
import __ from "utils/i18n";
import { Button, Col, Row } from "reactstrap";
import { PermissionsContext } from "contexts/permissionsContext";

export const FAQS = ({ FAQs, setFAQs, setShowModal, setActiveFAQ }) => {
  const handleEdit = (i) => {
    const newArr = [...FAQs];
    const activeFAQ = newArr.find((_, index) => index === i);
    setActiveFAQ({ activeFAQ, index: i, toEdit: true });
    setShowModal("faqsModal");
  };

  const handleDelete = (i) => {
    const newArr = [...FAQs];
    const remainingFAQs = newArr.filter((_, index) => index !== i);
    setFAQs(remainingFAQs);
  };

  const styles = {
    actions: { backgroundColor: "#f5f5f5" },
    actionButtons: { color: "#666", textDecoration: "none" },
    sectionTitle: { fontSize: "1.5em", fontWeight: "bold" },
    faqRow: { borderBottom: "1px solid #D4D4D4" },
  };
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const showFAQs =
    featureFlags?.includes("ecommerce_stores_faqs") &&
    userPermissions?.includes("ecommerce_stores_faqs");

  return (
    <>
      {showFAQs ? (
        <div className="ecommerceForm-panel">
          <div className="ecommerceForm-mainTitle-container">
            <label className="ecommerceForm-mainTitle ecommerceForm-primary">
              {__("store.form.fields.FAQs")}
            </label>
            <label className="ecommerceForm-mainSubtitle">
              {__("store.form.fields.loadFAQs")}
            </label>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} className="mb-5">
              <Row className="align-items-center mb-5">
                <Col xs={12} sm={12} md={6}>
                  <h2 style={styles.sectionTitle}>
                    {__("store.form.fields.FAQlist")}
                  </h2>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    className="d-flex ml-auto"
                    color="primary"
                    onClick={() => (
                      (setActiveFAQ(null), setShowModal("faqsModal"))
                    )}
                  >
                    <svg
                      className="mr-3"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.5 10C0.5 9.44772 0.947715 9 1.5 9H9V1.5C9 0.947715 9.44772 0.5 10 0.5C10.5523 0.5 11 0.947715 11 1.5L11 9H18.5C19.0523 9 19.5 9.44772 19.5 10C19.5 10.5523 19.0523 11 18.5 11H11V18.5C11 19.0523 10.5523 19.5 10 19.5C9.44771 19.5 9 19.0523 9 18.5L9 11H1.5C0.947715 11 0.5 10.5523 0.5 10Z"
                        fill="white"
                      />
                    </svg>
                    <span className="ms-3">{__("store.form.fields.FAQ")}</span>
                  </Button>
                </Col>
              </Row>
              {FAQs?.length > 0 ? (
                <Row className="py-3" style={styles.actions}>
                  <Col xs={12} sm={12} md={8} className="px-4">
                    {__("store.form.fields.questions")}
                  </Col>
                  <Col xs={12} sm={12} md={4} className="px-4 text-right">
                    {__("store.form.fields.actions")}
                  </Col>
                </Row>
              ) : null}
              {FAQs.length > 0
                ? FAQs.map((faq, i) => (
                    <>
                      <Row
                        id={"faq-" + i}
                        key={i}
                        className="d-flex align-items-center px-0"
                        style={styles.faqRow}
                      >
                        <Col xs={12} sm={12} md={6}>
                          {faq.faqTitle}
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={6}
                          className="d-flex justify-content-end"
                        >
                          <Button
                            className="d-flex"
                            style={styles.actionButtons}
                            color="link"
                            onClick={() => handleEdit(i)}
                          >
                            <svg
                              className="mr-3"
                              width="22"
                              height="24"
                              viewBox="0 0 22 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.5 23.0834V19.051H21.5V23.0834H0.5ZM2.6 17.0348V13.2544L12.1025 4.13105L16.04 7.91144L6.5375 17.0348H2.6ZM4.7 15.0186H5.645L13.1 7.91144L12.1025 6.95374L4.7 14.1113V15.0186ZM17.2212 6.80253L13.2837 3.02214L15.1737 1.20755C15.3663 1.00593 15.6112 0.909148 15.9087 0.917213C16.2062 0.92595 16.4512 1.02273 16.6437 1.20755L19.1112 3.57659C19.3037 3.76141 19.4 3.9926 19.4 4.27017C19.4 4.54706 19.3037 4.78632 19.1112 4.98794L17.2212 6.80253Z"
                                fill="#888888"
                              />
                            </svg>
                            <span className="ms-3">{__("actions.edit")}</span>
                          </Button>
                          <Button
                            className="d-flex"
                            style={styles.actionButtons}
                            color="link"
                            onClick={() => handleDelete(i)}
                          >
                            <svg
                              className="mr-3"
                              width="20"
                              height="22"
                              viewBox="0 0 20 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.16669 21.5C3.52502 21.5 2.97591 21.2717 2.51935 20.8152C2.06202 20.3578 1.83335 19.8083 1.83335 19.1667V4H0.666687V1.66667H6.50002V0.5H13.5V1.66667H19.3334V4H18.1667V19.1667C18.1667 19.8083 17.9384 20.3578 17.4819 20.8152C17.0245 21.2717 16.475 21.5 15.8334 21.5H4.16669ZM6.50002 16.8333H8.83335V6.33333H6.50002V16.8333ZM11.1667 16.8333H13.5V6.33333H11.1667V16.8333Z"
                                fill="#888888"
                              />
                            </svg>
                            <span className="ms-3">{__("actions.delete")}</span>
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ))
                : null}
            </Col>
          </Row>
        </div>
      ) : null}
    </>
  );
};
