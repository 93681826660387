import React from 'react'
import classNames from 'classnames'
import Dropzone from 'react-dropzone';
import { RadioGroup } from 'react-form';
import moment from 'moment'
import MaskedInput from 'react-text-mask'
import { i18n } from 'utils/i18n';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import { APIRequest, fetchResource } from 'api-provider'
import { SimpleForm } from 'components/forms';
import RadioInput from 'components/forms/inputs/RadioInput';

import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, } from 'reactstrap'


const resources = {
	ownerFileRequest: ({ file, user }) => {
		if (file !== null) {
			return ({
				endpoint: 'account/owner-file',
				method: 'POST',
				formData: {
					attachment: file,
					token: user.token
				}
			});
		} else {
			return false;
		}
	},
	withholdingTaxRequest: ({ file, user }) => {
		if (file !== null) {
			return ({
				endpoint: 'account/withholding-tax',
				method: 'POST',
				formData: {
					attachment: file,
					token: user.token
				}
			});
		} else {
			return false;
		}
	},
	withholdingTaxExclusionRequest: ({ file, user, dateFrom, dateTo }) => {

		if (file !== null) {
			return ({
				endpoint: 'account/withholding-tax-exclusion',
				method: 'POST',
				formData: {
					attachment: file,
					token: user.token,
					dateFrom: dateFrom,
					dateTo: dateTo
				}
			});
		} else {
			return false;
		}
	},
}

class DocumentationModal extends React.Component {

	state = {
		updateImage: false,
		modified: false,
		file: null,
		fileType: null,
		toUploadFileName: null,
		dateFrom: null,
		dateTo: null,
		url: this.props.user.profileImageUrl || null,
		resetPasswordStatus: null,
		resetPassword: false,
		isFetching: false,
		fileToUpload: null,
		user: this.props.user,
		userDefaultValues: {
			'email': this.props.user?.email,
			'language': this.props.user?.language,
			'exchangeRate': this.props.user?.currencies[0]?.exchangeRate
		}
	}


	formatBirthdate(original) {
		if (!original) {
			return ''
		}
		if (moment(original).isValid()) {
			return moment(original).format('YYYY-MM-DD')
		}
		return original
	}

	setValues(values) {
		this.setState({
			userDefaultValues: { ...values }
		})
	}

	setFileValues(accepted) {
		this.setState({
			file: accepted[0],
			toUploadFileName: accepted[0].name
		})
	}

	cleanFile() {
		this.setState({
			file: null,
			toUploadFileName: null
		})
	}

	changeFileToUpdate(fileCode) {
		this.setState({
			fileToUpload: fileCode
		})
	}

	changeDate(date, key) {
		if (key === 'dateFrom') {
			this.setState({
				dateFrom: date
			})
		}
		if (key === 'dateTo') {
			this.setState({
				dateTo: date
			})
		}

	}

	handleFileUpload(files) {
		const accepted = files.accepted;
		const rejected = files.rejected;

		if (accepted.length) {
			this.setFileValues(accepted);
		}

		if (rejected.length) {
			alert(i18n('dashboard.index.formatImage'))
		}
	}

	uploadFile() {
		const { file, fileToUpload, dateFrom, dateTo } = this.state;
		const { user } = this.props;
		let resourceName = resources.ownerFileRequest;
		let data = { file, user };
		if (fileToUpload === 'withholdingTax') {
			resourceName = resources.withholdingTaxRequest;
		}

		if (fileToUpload === 'withholdingTaxExclusion') {
			resourceName = resources.withholdingTaxExclusionRequest;
			data = { file, user, dateFrom, dateTo };
		}
		fetchResource(resourceName, data, this.props.api)
			.onSuccess(response => {
				this.setState({
					isFetching: false,
					resetPassword: true
				})
				this.cleanFile();
			})
			.onFailure(error => {
				if (error.includes('code 404') ||
					error.includes('code 500') ||
					error.includes('Ocurrió un error')) {
					alert(i18n('dashboard.index.errorForm'))
				} else {
					alert(error)
				}
				this.setState({
					isFetching: false,
					resetPasswordStatus: 'ERROR'
				})
			})
	}

	render() {
		const { closeDocumentationModal, isOpen } = this.props;
		const { toUploadFileName, resetPasswordStatus, fileToUpload } = this.state;

		// let dropzoneRef;
		const dropzoneClasses = classNames({
			'dropzone': true,
			'dropzoneHasPreview': !!this.state.url
		})

		return <div>
			<DashboardModal
				name="resetPassword"
				headerText={'¡Su archivo se ha Cargado exitosamente!'}
				showModal={this.state.resetPassword}
				hideModal={() => { this.setState({ resetPassword: false }) }}
				cancelText="Ok">
				{''}
			</DashboardModal>

			<APIRequest
				onSuccess={(data) => this.setImage(data)} >
				{request => (
					<Modal
						isOpen={isOpen}
						toggle={() => closeDocumentationModal()}
						size="lg"
						className="dashboard-modal config-modal">
						<SimpleForm
							defaultValues={this.state.userDefaultValues}
							onChange={({ values }) => this.setValues(values)}
							onSubmit={() => {
								request.fetch()
							}}>
							{form => (
								<div>
									<ModalHeader>{i18n("dashboard.components.modal.uploadDocumentation.header")}</ModalHeader>
									<ModalBody>
										<div className="dashboard-modal-subtitle">{i18n("dashboard.components.modal.uploadDocumentation.subtitle")}</div>
										<FormGroup>
											<RadioGroup field="type">
												<RadioInput label={i18n("dashboard.components.modal.uploadDocumentation.ownerFile")} value={'ownerFile'} onChange={() => this.changeFileToUpdate('ownerFile')} />
											</RadioGroup>
											<RadioGroup field="type">
												<RadioInput label={i18n("dashboard.components.modal.uploadDocumentation.withholdingTax")} value={'withholdingTax'} onChange={() => this.changeFileToUpdate('withholdingTax')} />
											</RadioGroup>
											<RadioGroup field="type">
												<RadioInput label={i18n("dashboard.components.modal.uploadDocumentation.withholdingTaxExclusion")} value={'withholdingTaxExclusion'} onChange={() => this.changeFileToUpdate('withholdingTaxExclusion')} />
											</RadioGroup>
										</FormGroup>
										{fileToUpload === 'ownerFile' &&
											<>
												<div className="dashboard-modal-subtitle">{i18n("dashboard.components.modal.uploadDocumentation.ownerFileFile")}</div>
												<div className="dashboard-card-item">
													<div className="dashboard-card-item-body">
														<Row className="no-margin">
															<Col>
																<Dropzone
																	className={dropzoneClasses}
																	// ref={(node) => {
																	// 	dropzoneRef = node;
																	// }}
																	activeClassName="dropzoneActive"
																	rejectClassName="dropzoneReject"
																	accept="image/jpeg, image/png, application/pdf"
																	maxSize={1e+9}
																	onDrop={(accepted, rejected) => {
																		this.handleFileUpload({ accepted, rejected })
																	}}>{
																		({ getRootProps, getInputProps }) => {
																			if (toUploadFileName) {
																				return (
																					<div {...getRootProps()}>
																						<input {...getInputProps()} style={{
																							background: 'white',
																							borderRadius: 5,
																							border: '2px dashed rgb(0, 135, 247)',
																							borderImage: 'none',
																							maxWidth: 500,
																							marginLeft: 'auto',
																							marginRight: 'auto'
																						}} />
																					</div>);
																			} else {
																				return (
																					<div {...getRootProps()}>
																						<input {...getInputProps()}
																							style={{
																								background: 'white',
																								borderRadius: 5,
																								border: '2px dashed rgb(0, 135, 247)',
																								borderImage: 'none',
																								maxWidth: 500,
																								marginLeft: 'auto',
																								marginRight: 'auto'
																							}} />
																					</div>
																				);
																			}
																		}
																	}
																</Dropzone>
															</Col>
														</Row>
														<Row className="no-margin">
															<Col>
																<Button disabled={request.isFetching}
																	type="button"
																	style={{ float: 'right' }}
																	onClick={() => this.uploadFile()}
																	color="primary">
																	{i18n("actions.save")}
																</Button>
																{resetPasswordStatus === 'ERROR' ? 'Error aqui' : null}
															</Col>
														</Row>
													</div>
												</div>
											</>
										}
										{fileToUpload === 'withholdingTax' &&
											<>
												<div className="dashboard-modal-subtitle">{i18n("dashboard.components.modal.uploadDocumentation.withholdingTaxFile")}</div>
												<div className="dashboard-card-item">
													<div className="dashboard-card-item-body">
														<Row className="no-margin">
															<Col>
																<Dropzone
																	className={dropzoneClasses}
																	// ref={(node) => {
																	// 	dropzoneRef = node;
																	// }}
																	activeClassName="dropzoneActive"
																	rejectClassName="dropzoneReject"
																	accept="image/jpeg, image/png, application/pdf"
																	maxSize={1e+9}
																	onDrop={(accepted, rejected) => {
																		this.handleFileUpload({ accepted, rejected })
																	}}>{
																		({ getRootProps, getInputProps }) => {
																			if (toUploadFileName) {
																				return (
																					<div {...getRootProps()}>
																						<input {...getInputProps()} style={{
																							background: 'white',
																							borderRadius: 5,
																							border: '2px dashed rgb(0, 135, 247)',
																							borderImage: 'none',
																							maxWidth: 500,
																							marginLeft: 'auto',
																							marginRight: 'auto'
																						}} />
																					</div>);
																			} else {
																				return (
																					<div {...getRootProps()}>
																						<input {...getInputProps()} style={{
																							background: 'white',
																							borderRadius: 5,
																							border: '2px dashed rgb(0, 135, 247)',
																							borderImage: 'none',
																							maxWidth: 500,
																							marginLeft: 'auto',
																							marginRight: 'auto'
																						}} />
																					</div>
																				);
																			}
																		}
																	}
																</Dropzone>
																<Button disabled={request.isFetching}
																	type="button"
																	style={{ float: 'right' }}
																	onClick={() => this.uploadFile()}
																	color="primary">
																	{i18n("actions.save")}
																</Button>
																{resetPasswordStatus === 'ERROR' ? 'Error' : null}
															</Col>
														</Row>
													</div>
												</div>
											</>
										}
										{fileToUpload === 'withholdingTaxExclusion' &&
											<>
												<div className="dashboard-modal-subtitle">{i18n("dashboard.components.modal.uploadDocumentation.withholdingTaxExclusionFile")}</div>
												<div className="dashboard-card-item">
													<div className="dashboard-card-item-body">
														<Row className="no-margin">
															<Col>
																<div className="position-relative form-group">
																	<label className="">{i18n("dashboard.components.modal.uploadDocumentation.from")}</label>
																	<div className="input-group">
																		<MaskedInput
																			className="form-control"
																			mask={[/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
																			placeholder="DD/MM/AAAA"
																			onChange={(e) => {
																				this.changeDate(this.formatBirthdate(e.target.value), 'dateFrom')
																			}}
																		/>
																	</div>
																</div>
															</Col>
															<Col>
																<div className="position-relative form-group">
																	<label className="">{i18n("dashboard.components.modal.uploadDocumentation.to")}</label>
																	<div className="input-group">
																		<MaskedInput
																			className="form-control"
																			mask={[/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
																			placeholder="DD/MM/AAAA"
																			onChange={(e) => {
																				this.changeDate(this.formatBirthdate(e.target.value), 'dateTo')
																			}}
																		/>
																	</div>
																</div>
															</Col>
														</Row>
														<Row className="no-margin">
															<Col>
																<Dropzone
																	className={dropzoneClasses}
																	// ref={(node) => {
																	// 	dropzoneRef = node;
																	// }}
																	activeClassName="dropzoneActive"
																	rejectClassName="dropzoneReject"
																	accept="image/jpeg, image/png, application/pdf"
																	maxSize={1e+9}
																	onDrop={(accepted, rejected) => {
																		this.handleFileUpload({ accepted, rejected })
																	}}>{
																		({ getRootProps, getInputProps }) => {
																			if (toUploadFileName) {
																				return (
																					<div {...getRootProps()}>
																						<input {...getInputProps()} style={{
																							background: 'white',
																							borderRadius: 5,
																							border: '2px dashed rgb(0, 135, 247)',
																							borderImage: 'none',
																							maxWidth: 500,
																							marginLeft: 'auto',
																							marginRight: 'auto'
																						}} />
																					</div>);
																			} else {
																				return (
																					<div {...getRootProps()}>
																						<input {...getInputProps()} style={{
																							background: 'white',
																							borderRadius: 5,
																							border: '2px dashed rgb(0, 135, 247)',
																							borderImage: 'none',
																							maxWidth: 500,
																							marginLeft: 'auto',
																							marginRight: 'auto'
																						}} />
																					</div>
																				);
																			}
																		}
																	}
																</Dropzone>
																<Button
																	disabled={request.isFetching}
																	type="button"
																	style={{ float: 'right' }}
																	onClick={() => this.uploadFile()}
																	color="primary">
																	{i18n("actions.save")}
																</Button>
																{resetPasswordStatus === 'ERROR' ? 'Error' : null}
															</Col>
														</Row>
													</div>
												</div>
											</>
										}
									</ModalBody>
									<ModalFooter>
										<Button
											color="secondary"
											onClick={() => closeDocumentationModal()}>
											{i18n("actions.close")}
										</Button>
									</ModalFooter>
								</div>
							)}
						</SimpleForm>
					</Modal>
				)}
			</APIRequest>
		</div>
	}
}
export default DocumentationModal;
