import React, { useContext } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import __ from 'utils/i18n';
import Loader from 'components/Loader';
import { PermissionsContext } from 'contexts/permissionsContext';

export const PanelFooter = ({
  productId,
  isFetching,
  showDeleteModal,
  touched,
  state,
}) => {
  const { userPermissions } = useContext(PermissionsContext);
  const isDeleteProductEnabled = userPermissions?.includes('products_action_delete');

  return (
    <Row>
      {isFetching ? (
        <Loader absolute={false} />
      ) : (
        <>
          {!!productId && isDeleteProductEnabled && (
            <Col md='6'>
              <Button
                color='secondary'
                type='button'
                block={true}
                onClick={() => {
                  showDeleteModal(true);
                }}
              >
                {__('actions.delete')}
              </Button>
            </Col>
          )}

          <Col md={productId && isDeleteProductEnabled ? '6' : '12'}>
            <Button
              color='success'
              type='submit'
              block={true}
              disabled={
                (isEmpty(touched) || isFetching) &&
                state === false
              }
            >
              {productId ? __('actions.save') : __('actions.create')}
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
};
