import React from 'react'
import { get } from 'lodash'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

export class FlexTable extends React.Component {
  static defaultProps = {
    showHeader: true
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  onRowClick = ({index})=> {
    this.props.onRowClick && this.props.onRowClick({index})
  }

  renderCell({ column, value, showIcon, showImage, columnIndex, typeCell='cell', key}) {
    let Cell = Td;
    if(typeCell === 'header'){
      Cell = Th;
    }
    const index = columnIndex;
    return (
      <Cell key={key} >
        <div onClick={() => !column.cancelClick && this.onRowClick({index}) }>
          { (column?.icon && showIcon) && <i className={'ft-icon mdi mdi-' + column?.icon}/> }
          { (column?.image && showImage) && <img src={column?.image} className='ft-icon' alt=""/>}
          { value }
        </div>
      </Cell>
    )
  };

  renderHeader({ columns }) {
    return (
      <Thead>
      <Tr>
        { columns.map((column, index) => (
          this.renderCell({ key:index, column, value:(column?.Header || column?.title), typeCell:'header' })
        ))}
      </Tr>
      </Thead>
    )
  };

  renderRow({ columns, values, columnIndex, onClick, ...rest }) {

    return (
      <Tr key={columnIndex} onClick={()=> onClick && onClick(columnIndex)} className='highlight-into'>
        { columns.map((column, index) => (
          this.renderCell({
            key: index,
            columnIndex,
            column,
            showIcon: true,
            showImage: true,
            value: column?.Cell ?
              column?.Cell({ original:values, index: columnIndex }) :
              column?.value ?
                column?.value(values) :
                get(values, column?.name),
            rest:rest
          })
        ))}
      </Tr>
    )
  };

  render() {
    const { columns, data, showHeader, onClick, ...rest } = this.props
    return (
      <Table key={data} className="flex-table" {...rest}>

        { showHeader && this.renderHeader({ columns }) }

        <Tbody>
        { data.map((item, index) => (
          this.renderRow({
            key: index,
            columns,
            values: data[index],
            columnIndex: index,
            onClick: onClick || null
          })
        ))}
        </Tbody>
      </Table>
    )
  }
};

export default FlexTable
