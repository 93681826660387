import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

export const PaginatorComponent = ({ pages, hasPrevious, current, hasNext, last, handlePagination }) => {

  const FirstButton = <i className='mdi mdi-chevron-double-left' />;
  const PreviousButton = <i className='mdi mdi-chevron-left' />;
  const NextButton = <i className='mdi mdi-chevron-right' />;
  const LastButton = <i className='mdi mdi-chevron-double-right' />;

  return (
    <Pagination>
      <PaginationItem disabled={!hasPrevious}>
        <PaginationLink
          onClick={() => handlePagination(1)}
          className="paginationLink"
          children={FirstButton}
        />
      </PaginationItem>
      
      <PaginationItem disabled={!hasPrevious}>
        <PaginationLink
          onClick={() => handlePagination(current - 1)}
          className="paginationLink"
          children={PreviousButton}
        />
      </PaginationItem>

      {pages.map(page => (
        <PaginationItem key={page.number} active={page.number === current}>
          <PaginationLink
            onClick={() => handlePagination(page.number)}
            className="paginationLink"
          >
            {page.number}
          </PaginationLink>
        </PaginationItem>
      ))}

      <PaginationItem  disabled={!hasNext}>
        <PaginationLink
          onClick={() => handlePagination(current + 1)}
          className="paginationLink"
          children={NextButton}
        />
      </PaginationItem>

      <PaginationItem  disabled={!hasNext}>
        <PaginationLink
          onClick={() => handlePagination(last)}
          className="paginationLink"
          children={LastButton}
        />
      </PaginationItem>
    </Pagination>
  )
}
