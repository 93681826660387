import React from 'react';
import __ from 'utils/i18n';
import SendInvoiceButton from 'components/SendInvoiceButton';

export const Send = ({ sale }) => {

  const {
    id,
    email,
    userId,
    operationId,
    subsidiaryId,
    invoiceNumber,
    status,
    success,
  } = sale;
  
  return (
    <>
      {(status === 'approved' && success === 'OK') && (
        <SendInvoiceButton
          block
          size='md'
          color='success'
          title={__('dashboard.sections.sales.sendReceipt')}
          data={{
            id,
            email,
            userId,
            operationId,
            subsidiaryId,
            invoiceNumber,
          }}
        />
      )}
    </>
  );
};
