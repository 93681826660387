import React from "react";
import {
    Col,
    Row,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
  import __ from "utils/i18n";
import gtmEvents from "utils/gtmEvents";
import { useMutation } from "@tanstack/react-query";
import PaymentLinkForm from "../PaymentLinkForm";
import { createOrder } from "api/orders-service";
import { fetchResource } from "api-provider";
import { useSelector } from "react-redux";
import { tenantId, tenantName } from "config/envConfig";
import TagManager from "react-gtm-module";
import envConfig from "config/envConfig";
import { getConfig } from "config";

const CreateStep = (props) => {
    const {
      form,
      paymentLink,
      setPaymentLink,
      hideModal,
      navigation,
      setError
    } = props;

    const currency = useSelector((state) => state.login.user.currencies[0].code);
    const { user } = useSelector((state) => state.login);
    const { token } = useSelector((state) => state.api);
    const { subsidiaryId } = useSelector((state) => state.login.user);
      
    const { isLoading } = useMutation({
      mutationFn: (order) => createOrder(order)
    });

    const getResource = () => {
      const paymentLinkPayload = {
        amount: paymentLink.price,
        currencyCode: currency,
        userId: user.userId, 
        description: paymentLink.name
      };

      const orderPaymentLinkPayload = {
        data: {
          attributes: {
            appId: envConfig.tenantName,
            source: "dashboard_payment_link",
            currency: currency,
            userUuid: user.uuid,
            accountId: user.merchantId,
            subsidiaryId: user.subsidiaryId,
            items:[
              {
                name: paymentLink.name,
                unitPrice: {
                  currency: currency,
                  amount: paymentLink.price * 100
                },
                quantity: 1
              }
            ],
            redirect_url: {
              success: "https://www.google.com/?q=success",
              failed: "https://www.google.com/?q=failed"
            }
          }
        }
      };


      if (getConfig('customConfig.paymentLink.type') === 'orders') {
        const authServer = JSON.parse(localStorage.getItem(`geop_oauth_${envConfig.tenantId}`));
        const resources = {
          createOrder: (payload) => ({
            endpoint: `/api/v1/paymentlink/orders`,
            method: 'POST',
            proxy: false,
            data: orderPaymentLinkPayload,
            headers: {
              'Authorization': `${authServer.token_type} ${authServer.access_token}`
            }
          })
        };

        return resources.createOrder;
      } else {
        const resources = {
          createPaymentLink: (payload) => ({
            endpoint: `/api/v1/paymentlink/${subsidiaryId}`,
            method: 'POST',
            proxy: false,
            data: paymentLinkPayload,
            headers: {
              'X-Authentication-Token': token,
            }
          })
        };
        return resources.createPaymentLink;
      }
    }
  
    const onSubmit = async () => {
      navigation.go(1);
      gtmEvents.paymentLinkCreationIntention();
  
      const tagManagerArgs = {
        dataLayer: {
            tenantId,
            project: tenantName,
            event: 'dashboard_payment_link_create_intention'
        },
        dataLayerName: 'dataLayer'
      }
      
      TagManager.dataLayer(tagManagerArgs);
      
      const resource = getResource();

      fetchResource(resource).onSuccess((data) => {
        let checkoutUrl = data.data.url || data.successUrl;
        if (getConfig('customConfig.paymentLink.type') === 'orders') { 
          checkoutUrl = data.data.links[0].checkout;
        } 
        setPaymentLink((prevState) => ({
          ...prevState,
          url: checkoutUrl,
        }));
        
        gtmEvents.paymentLinkCreationSuccess('id');
        navigation.go(2);
      }
      ).onFailure((e) => {
        gtmEvents.paymentLinkCreationFailure();
        setError(e);
        navigation.go(3)});
        
    }

    return (
      <div className="payment-link-modal" >
        <ModalHeader toggle={hideModal}>
          {__.t("dashboard.components.paymentLink.header")}
        </ModalHeader>
  
        <ModalBody>
          <Row>
            <Col>
              <p className="payment-link-modal__description">
                {__.t("dashboard.components.paymentLink.description")}
              </p>
              <PaymentLinkForm onChange={form.onChange} name={form.name} price={form.price} />
            </Col>
          </Row>
        </ModalBody>
  
        <div className="modal-separator">
          <hr />
        </div>
  
        <ModalFooter>
          <Button
            color="secondary"
            className="payment-link-modal__button payment-link-radius"
            onClick={hideModal}
            disabled={isLoading}
          >
            {__.t("actions.cancel")}
          </Button>
  
          <Button
            color="primary"
            className="payment-link-modal__button payment-link-radius"
            onClick={onSubmit}
            disabled={!paymentLink.isValid || isLoading}
          >
            {isLoading
              ? `${__.t("states.loading")}...`
              : __.t("dashboard.components.paymentLink.form.submit")}
          </Button>
        </ModalFooter>
      </div>
    );
};
export default CreateStep;
