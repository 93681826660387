import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import Loader from "components/Loader";

import { fetchResource } from "api-provider";
import __ from "utils/i18n";

import { PermissionsContext } from "contexts/permissionsContext";
import { DepositsReportWidget } from "./components/DepositsReport";
import { getConfig } from "config";
import { getDeposits } from "./_shared/resources";
import { DepositsTable } from "./components/DepositsTable";
import gtmEvents from "utils/gtmEvents";
import FiltersForm from "components/Filters/FiltersForm";

export const Deposits = () => {
  const [filters, setFilters] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [deposits, setDeposits] = useState([]);
  const [error, setError] = useState(false);

  const { api } = useSelector((state) => state);
  const { subsidiaryId } = useSelector((state) => state.login.user);
  const { token } = useSelector((state) => state.api);

  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const showTaxMessage = getConfig("customConfig.deposits.showTaxMessage");
  const taxMessage = getConfig("customConfig.deposits.taxMessage");
  const isDepositsFilterEnabled = featureFlags?.includes('filters_deposits');
  const isExportDepositsEnabled =
    featureFlags?.includes('deposit_action_export_deposits') &&
    userPermissions?.includes('deposit_action_export_deposits');

  useEffect(() => {
    gtmEvents.salesPageView('pageDeposits');
    const payload = isDepositsFilterEnabled ? filters : null;

    setIsFetching(true);
    fetchResource(getDeposits(subsidiaryId, token, payload))
      .onSuccess(({ data }) => {
        setDeposits(data);
        setIsFetching(false);
      })
      .onFailure((err) => {
        setError(true);
        setIsFetching(false);
      });
  }, [subsidiaryId, token, api, filters, isDepositsFilterEnabled]);

  return (
    <div className="dashboard-card">
      <div className="dashboard-card-header flex-row-reverse">
        {isDepositsFilterEnabled ? (
          <FiltersForm
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={setFilters}
          >
            {isExportDepositsEnabled && (
              <DepositsReportWidget
                filters={{ subsidiaryId }}
                token={token}
              />
            )}
          </FiltersForm>
        ) : (
          <>
            {isExportDepositsEnabled && (
              <DepositsReportWidget
                filters={{ subsidiaryId }}
                token={token}
              />
            )}
          </>
        )}
      </div>
      <div className="dashboard-card-body">
        {showTaxMessage && taxMessage && (
          <div className="alert alert-warning">
            <span className="mdi mdi-alert-circle"></span> {taxMessage}
          </div>
        )}
        {!isFetching && (deposits.length === 0 || error) && (
          <div className="dashboard-card-message">
            <i className="mdi mdi-minus-circle" />
            <h3>{__("dashboard.sections.stats.withoutDeposits")}</h3>
            <p>{__("dashboard.sections.stats.notFoundDeposits")}</p>
          </div>
        )}
        {deposits.length > 0 && <DepositsTable data={deposits} />}
        {isFetching && <Loader />}
      </div>
    </div>
  );
};
