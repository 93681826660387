import axios from 'axios';
import Cache from 'ttl-cache-js';
import objectHash from 'object-hash';
import { ACTIONS } from './constants';
import queryString from 'query-string';
import objectToFormdata from 'object-to-formdata';
import { isFunction, get, pickBy, hasIn } from 'lodash';
import { backendUrl, tenantId } from 'config/envConfig';
import { decodeJWT, checkAuthServerToken } from 'contexts/helpers';

const requestCache = Cache()

axios.interceptors.request.use(async (config) => {
  // Prevent token validation during login (token hasn't been generated)
  // and refreshToken requests (avoids infinite loop)
  if (
    !config.url.includes('v1/login') &&
    !config.url.includes('oauth/refreshToken') &&
    !config.url.includes('v1/applications/credentials')
  ) {
    const featureFlags = decodeJWT(`geop_feature_flags_${tenantId}`);
    const jwtEnabled = featureFlags?.includes("auth_server_enabled") || featureFlags?.includes("keycloak_enabled");

    if (jwtEnabled) {
      const newToken = await checkAuthServerToken();

      if (newToken) {
        config.headers['X-Authentication-Token'] = `${newToken?.token_type} ${newToken?.access_token}`;
      }
    }
  }

  return config;
});

class FetchResult {
  constructor(promise, cancelSource) {
    this.promise = promise
    this.cancelSource = cancelSource
  }

  cancel(message) {
    this.cancelSource.cancel(message || 'Request cancelled')
  }

  then(resolve) {
    this.promise.then(resolve)
    return this
  }

  catch(reject) {
    this.promise.catch(reject)
    return this
  }

  onSuccess(resolve) {
    this.promise.then(resolve)
    return this
  }

  onFailure(reject) {
    this.promise.catch(reject)
    return this
  }

  finally(onFinally) {
    this.promise.finally(onFinally)
    return this
  }
}

function getResource(resourceCreator, payload) {
  return isFunction(resourceCreator) ?
    resourceCreator(payload) :
    resourceCreator;
}

function executeRequest(params) {
  const {
    config, data, cancelSource, resource, reject, hash, resolve, token
  } = params;

  let header = {
    ...config.headers,
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-client-id': tenantId
  };

  token ? (header['X-Authentication-Token'] = token) : (header = config.headers);

  axios.request({
    data,
    url: config.url,
    method: config.method,
    headers: config.headers,
    cancelToken: cancelSource.token,
    withCredentials: true,
  })
    .then((response) => {

      let responseData = response.data;

      if (resource.parseResponse) {
        try {
          responseData = resource.parseResponse ? resource.parseResponse(response) : response.data;
        } catch (err) {
          reject('Parse response error', hash)
          return;
        }
      }

      if (resource.cache) {
        requestCache.set(hash, responseData, 1000 * (resource.ttl || 60))
      }

      resolve(responseData)
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return;
      }

      if (hasIn(error, 'response.status') && error.response.status === 401) {
        reject(error, hash);
      }

      const responseContent = error?.response?.data;
      const jsonApiErrorMessage = get(responseContent, 'errors[0].detail');
      const legacyErrorMessage = get(responseContent, 'response.message') || get(responseContent, 'message') || get(responseContent, 'detail');

      const message = jsonApiErrorMessage || legacyErrorMessage || error.message;
      reject(message, hash);
    });
}



export const fetchResource = (resourceCreator, payload, api) => {
  const resource = getResource(resourceCreator, payload);

  const queryParams = resource.params ? (`?${queryString.stringify(resource.params)}`) : '';

  let endpoint = resource.baseUrl || `${backendUrl}/proxy/api/`;

  if (resource.proxy === false) {
    endpoint = backendUrl;
  }

  const config = {
    method: resource.method || 'GET',
    url: `${endpoint}${resource.endpoint}${queryParams}`,
    formData: pickBy(resource.formData, v => v !== undefined),
    data: resource.data,
    headers: {
      ...resource.headers,
      'x-client-id': tenantId
    },
  };

  let token;

  if (api && api.token && api.token.length && !config.data && !config.formData.token) {
    token = api.token;
  }

  const hash = resource.cache ? objectHash(config) : '';
  const cancelSource = axios.CancelToken.source();

  const promise = new Promise((resolve, reject) => {

    if (resource.cache && !resource.force) {
      const response = requestCache.get(hash)
      if (response) {
        return resolve(response, hash);
      }
    }

    const data = config.data ? config.data : objectToFormdata(config.formData);

    executeRequest({
      config, data, cancelSource, resource, reject, hash, resolve, token
    });
  })

  return new FetchResult(promise, cancelSource)
}

export const setToken = token => ({
  type: ACTIONS.SET_TOKEN,
  token,
})

export const setEndpoint = endpoint => ({
  type: ACTIONS.SET_ENDPOINT,
  endpoint,
})
