import React from 'react';
import { DonutChart } from './StaffDonut';
import { CardChart } from '../_shared/CardChart';
import __ from 'utils/i18n';

export const StaffCard = ({ title, mode, item = {} }) => {

  const sortStaff = (a, b) => {
    return b.amount - a.amount;
  }

  const orderData = (data) => {
    if (!data) return [];
    const orderedStaff = data.sort((a, b) => sortStaff(a, b));
    return orderedStaff;
  }
  
  const orderedData = orderData(item.value);

  return (
    <CardChart title={__(title)}>
      <DonutChart
        noData={`${__("dashboard.sections.home.noProducts")} ${mode?.metricSuffix}`}
        data={orderedData}
        metric="amount"
        formatter={(value) => __.number(value)}
      />
    </CardChart>
  );
}