import React, { Fragment } from 'react';
import { Form } from 'informed';


const renderChildren = (children, props) => {
  return typeof children === 'function'
    ? children(props)
    : React.Children.map(children, child =>
      React.cloneElement(child, { ...props })
    );
}


const FormPanel = ({values,  children, onSubmit, className})=>{
  return(
    <Form
      validate={()=>{return false}}
      className={className}
      initialValues={values}
      key={values.id}
      onSubmit={(data) => {
        onSubmit(data);
      }}
    >
      {({ formState }) =>{
        return(
          <Fragment>
            { renderChildren(children, {
              ...formState
            })}

          </Fragment>
        )
      }
      }
    </Form>
  )
};

FormPanel.defaultProps = {
  values: []
};

export default FormPanel;