import React from "react";
import { Table } from 'reactstrap';
import __ from 'utils/i18n';
import moment from "moment";


export const Summary = ({ batch }) => {



  return(
    <Table className="no-border bold-td-right">
      <tbody>
        <tr>
          <td><i className="mdi mdi-16px mdi-credit-card"></i> {batch.name}</td>
        </tr>
        <tr>
          <td><i className="mdi mdi-16px mdi-account"></i> {__('dashboard.sections.batchClose.panel.aditionalNumber')}{batch.aditionalNumber}</td>
        </tr>
        <tr>
          <td><i className="mdi mdi-16px mdi-calendar"></i>{__('dashboard.sections.batchClose.panel.endDate')}{moment(batch.dateTime).format('LL HH:mm')}</td>
        </tr>
        <tr>
          <td>
            <span style={{
              backgroundColor: 'grey',
              borderRadius: '100px',
              width: '10px',
              height: '10px',
              border: '2px solid red',
              display: 'inline-block'}}
            />
            {__('dashboard.sections.batchClose.panel.byUserName')}{batch.userName}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
