import React from "react";
import { Button } from "reactstrap";
import { Redirect } from "react-router-dom";
import PasswordField from "components/PasswordField";
import __ from "utils/i18n";
import { MainLoader } from "components/MainLoader";
import { APIRequest } from "api-provider";
import { SimpleForm } from "components/forms";
import { validateFields } from "components/forms/validators";
import "../../../styles/newStyles/PasswordForm.scss"
import { getConfig } from "config";
import loginLogo from "assets/brand-logo-login.svg";
import brandLogo from "assets/brand-logo.svg"

const resources = {
  createPassword: ({ password, key }) => ({
    endpoint: "users/createPassword.json",
    method: "POST",
    formData: {
      token: key,
      password: password,
      confirmationPassword: password,
      channel: "web",
      deviceId: "web",
      deviceModel: "web",
      deviceOS: "web",
    },
  }),
};

const CreatePassword = (props) => {
  const key = props.match.params.token;

  const validationRequirementsValue = getConfig("customConfig.createOrChangePasswordMinLengthValue");
  const validationRequirements = getConfig("customConfig.createOrChangePasswordReqsField");
  const validationRequirementsSubmit = getConfig("customConfig.createOrChangePasswordReqsSubmit");
  const isCustomLogin = getConfig('customConfig.showCustomLogin');

  if (key) {
    return (
      <APIRequest resource={resources.createPassword}>
        {(request) => (
          <MainLoader isLoading={request.isFetching}>
    
            <SimpleForm
              onSubmit={(values) => {
                setTimeout(() => {
                  request.fetch({ ...values, key });
                }, 100);
              }}
              validateOnSubmit={true}
              validate={validateFields(validationRequirementsSubmit)}
            >
              {(formApi) => (
                <div className="password-form-login-container">
                  <div className="login-logo mx-auto">
                    <img src={isCustomLogin ? brandLogo : loginLogo} alt="Logo"/>
                  </div>
                  <h2 className="password-form-login-welcome">
                    {__("createPassword.createPassword")}
                  </h2>

                  {request.success && <Redirect to="/" />}
                  {!request.success && (
                    <div>
                      {request.error && (
                        <div className="text-danger password-form-text-center">
                          {request.error}
                        </div>
                      )}

                      <PasswordField
                        field="password"
                        label={__("createPassword.newPassword")}
                        requirements={validationRequirements(validationRequirementsValue)}
                        minLengthValue={validationRequirementsValue}
                      />

                      <div>
                        <Button block color="primary" type="submit">
                          {__("createPassword.createPassword")}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </SimpleForm>      
          </MainLoader>
        )}
      </APIRequest>
    );
  }

  return null;
};

export default CreatePassword;

