const initialState = {
    status_code: null,
    message: null,
}

const errorReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return initialState
    }

    switch (action.type) {
        case 'dashboard/SET_DASHBOARD_ERROR_MESSAGE': {
            return {
                status_code: action.payload.status_code || null,
                message: action.payload.message,
            }
        }
        default:
            return state
    }

}

export default errorReducer;