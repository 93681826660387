import TagManager from "react-gtm-module";
import {tenantId, tenantName} from "config/envConfig";

const initializeEvent = () => {
    const eventArguments = getEventPayload('initialize');

    TagManager.dataLayer(eventArguments);
}

// When payment button is clicked
const paymentLinkButtonClicked = () => {
    const eventArguments = getEventPayload('dashboard_payment_link_button_click');


    console.log(TagManager);
    TagManager.dataLayer(eventArguments);
}

// When payment link generated url is copied
const paymentLinkUrlCopied = () => {
    const eventArguments = getEventPayload('dashboard_payment_link_url_copied');

    TagManager.dataLayer(eventArguments);
}

// When payment link creation has failure
const paymentLinkCreationIntention = () => {
    const eventArguments = getEventPayload('dashboard_payment_link_create_intention');

    TagManager.dataLayer(eventArguments);
}

// When payment link creation has failure
const paymentLinkCreationFailure = () => {
    const eventArguments = getEventPayload('dashboard_payment_link_create_failed');

    TagManager.dataLayer(eventArguments);
}

// When payment link is successfully created
const paymentLinkCreationSuccess = (paymentLinkId) => {
    const eventArguments = getEventPayload('dashboard_payment_link_create_success', {orders_uuid: paymentLinkId});

    TagManager.dataLayer(eventArguments);
}

// When home is viewing
const homePageView = (homeTab) => {
    const eventArguments = getEventPayload('dashboard_page_view_home', {type: homeTab});

    TagManager.dataLayer(eventArguments);
    
}

// When Sales is viewing
const salesPageView = (salesTab) => {
    const eventArguments = getEventPayload('dashboard_page_view_sales', {type: salesTab});

    TagManager.dataLayer(eventArguments);

}

// When Click on the button download withholdings
const withholdingsDownloadButtonClick = () => {
    const eventArguments = getEventPayload('dashboard_download_button_click');

    TagManager.dataLayer(eventArguments);

}

// When Statistics is viewing
const statsPageView = (statsTab) => {
    const eventArguments = getEventPayload('dashboard_page_view_statistics', {type: statsTab});

    TagManager.dataLayer(eventArguments);

}

// When Catalogue is viewing
const cataloguePageView = (catalogueTab) => {
    const eventArguments = getEventPayload('dashboard_page_view_catalogue', {type: catalogueTab});

    TagManager.dataLayer(eventArguments);

}

// When create new product is clicked
const newProductAction = () => {
    const eventArguments = getEventPayload('dashboard_new_prod_button_click');

    TagManager.dataLayer(eventArguments);

}

// When create new category is clicked
const newCategoryAction = () => {
    const eventArguments = getEventPayload('dashboard_new_category_button_click');

    TagManager.dataLayer(eventArguments);

}

// When Client is viewing
const clientPageView = () => {
    const eventArguments = getEventPayload('dashboard_page_view_client');

    TagManager.dataLayer(eventArguments);

}

// When new client is clicked
const newClientAction = () => {
    const eventArguments = getEventPayload('dashboard_new_client_button_click');

    TagManager.dataLayer(eventArguments);

}

// When Merchant is viewing
const merchantPageView = () => {
    const eventArguments = getEventPayload('dashboard_page_view_merchant');

    TagManager.dataLayer(eventArguments);

}

// When new user is clicked
const newUserAction = () => {
    const eventArguments = getEventPayload('dashboard_new_user_button_click');

    TagManager.dataLayer(eventArguments);

}

// When Credentials is viewing
const credentialsPageView = (stepCredentials) => {
    const eventArguments = getEventPayload('dashboard_page_view_credentials', {type: stepCredentials});

    TagManager.dataLayer(eventArguments);

}

const getEventPayload = (eventName, customArgs = {}, defaultDataLayerName = 'geoDatalayer') => {
    return {
        dataLayer: {
            tenantId,
            project: tenantName,
            event: eventName,
            ...customArgs
        },
        dataLayerName: defaultDataLayerName
    };
}
const gtmData = {
    initializeEvent,
    paymentLinkButtonClicked,
    paymentLinkCreationIntention,
    paymentLinkCreationFailure,
    paymentLinkCreationSuccess,
    paymentLinkUrlCopied,
    homePageView,
    salesPageView,
    withholdingsDownloadButtonClick,
    statsPageView,
    cataloguePageView,
    newProductAction,
    newCategoryAction,
    clientPageView,
    newClientAction,
    merchantPageView,
    newUserAction,
    credentialsPageView
};

export default gtmData;
