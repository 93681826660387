import React, { useState } from 'react';
import RadioInput from 'components/forms/inputs/RadioInput';
import { RadioGroup } from 'react-form';
import { SimpleForm } from 'components/forms';
import { getConfig } from 'config';
import { ModalField } from './ModalField';
import __ from 'utils/i18n';

export const PaymentChannelField = ({
  applicatedFields,
  setApplicatedFields,
}) => {
  const inputMode = applicatedFields?.inputMode;
  const [selectedOption, setSelectedOption] = useState(inputMode || null);
  const options = getConfig('customConfig.filters.paymentChannel.terminalPaymentOptions');
  const translationKey = 'dashboard.components.filterForm.fields.paymentChannel';
  const title = __(translationKey + '.title');
  const description = __(translationKey + '.description');

  const applyFilters = () => {
    setApplicatedFields({
      ...applicatedFields,
      inputMode: selectedOption,
    });
  };

  const cleanFilters = () => {
    setSelectedOption(null);
    setApplicatedFields({
      ...applicatedFields,
      inputMode: null,
    });
  };

  return (
    <ModalField
      field="payment-channel"
      title={title}
      description={description}
      fieldIsActive={inputMode}
      applyFilters={applyFilters}
      cleanFilters={cleanFilters}
    >
      <div className="filters-payment-channel-field">
        <SimpleForm
          values={{ type: selectedOption }}
          onChange={({ values }) => setSelectedOption(values.type)}
        >
          <RadioGroup field="type">
            {options.map((option, i) => {
              const label = __(
                `${translationKey}.options.${option.toLowerCase()}`
              );
              return (
                <RadioInput key={i} label={label} value={option} />
              );
            })}
            <RadioInput
              label={__(`${translationKey}.options.all`)}
              value={null}
            />
          </RadioGroup>
        </SimpleForm>
      </div>
    </ModalField>
  );
};
