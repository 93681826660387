import React from 'react';
import classNames from 'classnames';

const BigRadioInput = ({ title, value, selectedValue = true, children, onChange, disabled }) => {
  return (
    <div className="big-radio"  onClick={()=> !disabled && onChange(selectedValue)}>

      <div className={classNames({
        'radio-out': true,
        'selected': value === selectedValue,
        'disabled': disabled
      })}>
        <div className="radio-in"/>
      </div>
      { title && <span className="form-section-title">{title}</span> }
      { children }
    </div>
  )
}

export default BigRadioInput;