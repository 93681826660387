import React from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';

export const Dcc = ({ sale, config }) => {
  const {
    exchangeRate,
    exchangeFee,
    cardHolderCurrencyAlphaCode,
    cardHolderAmount,
  } = config.dcc || {};

  return (
    <>
      {!!sale.paidWithDcc && (
        <Table className='no-border'>
          <tbody>
            <tr>
              <td>{exchangeRate.label}</td>
              <td>
                1 {cardHolderCurrencyAlphaCode.value(sale)} =
                {exchangeRate.value(sale)} {__.currencyName(sale.currency)}
              </td>
            </tr>
            <tr>
              <td>{exchangeFee.label}</td>
              <td>{exchangeFee.value(sale)} %</td>
            </tr>
            <tr>
              <td>{cardHolderCurrencyAlphaCode.label}</td>
              <td>{cardHolderCurrencyAlphaCode.value(sale)}</td>
            </tr>
            <tr>
              <td>{cardHolderAmount.label}</td>
              <td>
                {cardHolderCurrencyAlphaCode.value(sale)}
                {cardHolderAmount.value(sale)}
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};
