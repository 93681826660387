import React, { useState } from 'react';
import classnames from 'classnames';
import { CardTitle } from 'reactstrap';
import VisibilitySensor from 'react-visibility-sensor';
import { getConfig } from 'config';
import __ from 'utils/i18n';
import Loader from 'components/Loader';
import FlexTable from 'components/FlexTable';
import DashboardPanel from 'containers/Dashboard/components/DashboardPanel';
import { SalePanel } from '../SalePanel';
import { SubsidiaryCell } from './SubsidiaryCell';
import { SaleDescriptionCell } from './DescriptionAndStatusCell';
import { SalePaymentCell } from './PaymentCell';
import { SaleCreationDate } from './CreationDateCell';
import { TotalAmountCell } from './TotalAmountCell';
import { CashbackAmountCell } from './CashbackAmountCell';
import { InstallmentTypeCell } from './InstallmentTypeCell';
import { OperationCode } from './OperationCodeCell';
import { StatusCell } from './BatchStatusCell';
import { terminalPayment } from './TerminalPaymentCell';

export const getSalesColumns = (subsidiaries) => {
  const configColumns = getConfig('tableColumns.salesColumns');
  const salesColumns = [];

  const defaultColumns = {
    subsidiary: {
      Header: 'sucursal',
      Cell: SubsidiaryCell(subsidiaries),
    },
    description: {
      id: 'productName',
      Header: __('dashboard.sections.sales.description'),
      Cell: SaleDescriptionCell,
    },
    payment: {
      Header: __('dashboard.sections.sales.paymentMethodType'),
      Cell: SalePaymentCell,
    },
    date: {
      Header: __('dashboard.sections.sales.date'),
      Cell: SaleCreationDate,
    },
    amount: {
      Header: __('dashboard.sections.sales.amount'),
      Cell: TotalAmountCell,
    },
    cashback: {
      Header: __('dashboard.sections.sales.cashback'),
      Cell: CashbackAmountCell,
    },
    deferredPayments: {
      Header: __('dashboard.sections.sales.deferredPayments'),
      Cell: InstallmentTypeCell,
    },
    operationCode: {
      Header: __('dashboard.sections.sales.operationCode'),
      Cell: OperationCode,
    },
    status: {
      Header: __('dashboard.sections.sales.status'),
      Cell: StatusCell,
    },
    terminalPayment: {
      Header:__('dashboard.sections.sales.terminalPayment'),
      Cell: terminalPayment,
    },
  };

  configColumns.forEach((item) => salesColumns.push(defaultColumns[item]));

  return salesColumns;
};

export const SalesTable = ({
  data,
  isFetching,
  hasMore,
  getMore,
  error,
  subsidiariesStore,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [detailsData, setDetailsData] = useState({});

  const handleDetails = (data) => {
    if (
      data.type !== 'ADJUSTMENT' &&
      data.type !== 'COMMISSION' &&
      data.type !== 'ADJUSTMENT_DEBIT' &&
      data.type !== 'ADJUSTMENT_CREDIT'
    ) {
      setShowDetails(true);
      setDetailsData(data);
    }
  };

  return (
    <div
      className={classnames({
        'panel-expanded': showDetails,
        'table-container': true,
      })}
    >
      <DashboardPanel
        panelHeading={`${
          detailsData.showInvoiceNumber
            ? __('dashboard.sections.sales.bill')
            : __('dashboard.sections.sales.receipt')
        } 
            ${detailsData.operationCode}`}
        expanded={showDetails}
        hidePanel={() => setShowDetails(false)}
      >
        {showDetails && <SalePanel data={detailsData} />}
      </DashboardPanel>
      {data.length > 0 && (
        <>
          {data.some((item) => item.status === 'rejected') && (
            <CardTitle tag='div'>
              {__('dashboard.sections.sales.rejected.description')}
            </CardTitle>
          )}
          <FlexTable
            data={data}
            columns={getSalesColumns(subsidiariesStore)}
            onRowClick={({ index }) => handleDetails(data[index])}
          />
        </>
      )}
      <VisibilitySensor onChange={(visible) => visible && !error && getMore()}>
        <div style={{ height: 1, width: '100%' }} />
      </VisibilitySensor>
      {hasMore && <div className='loader-row' data-testid="loader">{isFetching && <Loader />}</div>}
    </div>
  );
};
