import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import queryString from 'query-string';
import { getConfig } from "config";
import __ from 'utils/i18n';
import { Field, Form } from 'components/forms';
import { backendUrl, tenantId } from 'config/envConfig';
import ButtonLoader from 'containers/Dashboard/components/ButtonLoader/ButtonLoader';

const options = getConfig('customConfig.deposits.options');

export const DepositsReportWidget = ({ token, subsidiaryId }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const downloadReport = (values) => {
    setIsLoading(true)
    const query = queryString.stringify({
      subsidiary: values.subsidiary,
      downloadType: values.format,
    });

    fetch(`${backendUrl}/proxy/api/deposits/download?${query}`, {
      method: 'GET',
      headers: {
        'x-client-id': tenantId,
        'x-Authentication-Token': token,
        'x-request-download': 'stream'
      },
    })
    .then(response => {
      const reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        }
      })
    })
    // Create a new response out of the stream
    .then(stream => new Response(stream))
    .then(response => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `deposits_${Date.now()}.${values.format}`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }).finally(()=> {setIsLoading(false)});
  }
  
  return (
    <Form
      defaultValues={{
        subsidiary: subsidiaryId,
        format: 'xls',
      }}
    >
      {(form) => (
        <div>
          <Button className="buttom-radius" onClick={() => setShowModal(true)}>
            {__('actions.export')}
          </Button>
          <Modal
            isOpen={showModal}
            toggle={() => setShowModal(false)}
          >
            <ModalHeader>
              {__('dashboard.sections.sales.transactionReport')}
            </ModalHeader>
            <ModalBody>
              <Field
                block
                field='format'
                label={__('dashboard.sections.sales.form.format.label')}
                type='select'
                options={options.formats}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => setShowModal(false)}>
                {__('actions.cancel')}
              </Button>
              <ButtonLoader
                text={__("actions.download")}
                isLoading={isLoading}
                onClick={() => {
                  downloadReport(form.values)
                }}
              />
              
            
            </ModalFooter>
          </Modal>
        </div>
      )}
    </Form>
  );
};
