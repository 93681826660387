import React from 'react';
import ReactDOM from 'react-dom';
import { CoreApp } from './App';
import { i18n } from './utils/i18n';
import numeral from 'numeral';
import numberLocales from './locales/numeral.es';
import TagManager from 'react-gtm-module';
import locales from './locales';
import 'moment/locale/es';
import { gtmId, gtmAuth, gtmName, tenantGtmId } from 'config/envConfig';
import gtmEvents from 'utils/gtmEvents';

const userLanguage = localStorage.getItem('locale') || 'es';

i18n.initialize(userLanguage);
i18n.addResources('es', 'translations', locales.es)
i18n.addResources('en', 'translations', locales.en)

numeral.locale('es')
numeral.register('locale', 'es', numberLocales);

if (tenantGtmId.includes("GTM")) {
  console.log(`tenant_gtm_loaded: ${tenantGtmId}`)
  TagManager.initialize({gtmId: tenantGtmId});
}


TagManager.initialize({
  gtmId: gtmId,
  auth: gtmAuth,
  preview: gtmName,
  dataLayerName: 'geoDatalayer'
});

gtmEvents.initializeEvent();

ReactDOM.render(
  <React.StrictMode>
      <CoreApp />
  </React.StrictMode>,
  document.getElementById('root')
);
