import React from "react";
import __ from "utils/i18n";

import { getConfig } from "config";

export const StoreOptionAvailable = () => {
  const brandName = getConfig("customConfig.brandName");

  return (
    <div className="alert alert-danger">
      <label className="mdi mdi-alert-circle"></label>
      {__(
        [
          "store.panel.storeOptionAvailable",
          "store.panel.storeOptionAvailableDefault",
        ],
        { brandName }
      )}
    </div>
  );
};
