import React, { useState, useEffect, useContext, useReducer } from 'react';
import classNames from 'classnames';
import { Button, Col } from 'reactstrap';
import { isNull } from 'lodash';
import { useSelector } from 'react-redux';
import { PermissionsContext } from 'contexts/permissionsContext';
import {
  ConfirmModal,
  SuccessModal,
  DeleteProductModal,
  ImportModal,
  ShareModal,
  Table,
  Panel,
  SortingDropdowns,
  Filters
} from './components';
import __ from 'utils/i18n';
import { fetchResource } from 'api-provider';
import { Paginator } from 'components/Paginator';
import TableHeaderActions from 'components/TableHeaderActions';
import DashboardPanel from 'containers/Dashboard/components/DashboardPanel';
import { PageSizeDropdown } from 'components/PageSizeDropdown';
import { batchDeleteCompraClick, getCompraClick, getExtraInfo } from './resources';
import { handlePagination, handleSorting } from './handlers';

const CheckboxAll = ({ handleSelectAll, isCheckAll }) => {
  return (
    <div>
      <span>
        <input
          id={`selectAll`}
          name='checkOrder'
          type='checkbox'
          onChange={handleSelectAll}
          checked={isCheckAll}
        />
      </span>
    </div>
  )
};

export const CompraClick = (props) => {
  const token = useSelector((state) => state.api.token);
  const { subsidiaryId, countryTimezone } = useSelector((state) => state.login.user);

  const initialPagination = {
    page_size: 10,
    page: 1
  };

  const initialSorting = {
    sorting_field: 'createdAt',
    sorting_criteria: 'DESC'
  };

  const initialCheckboxList = [];

  const handleCheckboxList = (list, action) => {
    switch (action.type) {
      case 'add':
        return [...list, action.payload.payment_buttons_ids];
      case 'remove':
        let filtered = list.filter(paymentButtonId => paymentButtonId !== action.payload.payment_buttons_ids);
        return filtered;
      case 'clear':
        return [];
      case 'all':
        return data.map(paymentButton => parseInt(paymentButton.id));
      default:
        return list;
    };
  };

  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetails, setShowDetails] = useState(props.showDetails || false);
  const [detailsData, setDetailsData] = useState(props.compraClickData || {});
  const [dataRow, setDataRow] = useState({});
  const [action, setAction] = useState('create');
  const [showCreateButton, setShowCreateButton] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [meta, setMeta] = useState({});
  const [links, setLinks] = useState({});
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [deletedCount, setDeletedCount] = useState(0);

  const [pagination, setPagination] = useReducer(handlePagination, initialPagination);
  const [sorting, setSorting] = useReducer(handleSorting, initialSorting);
  const [checkboxList, setCheckboxList] = useReducer(handleCheckboxList, initialCheckboxList);

  const { userPermissions, featureFlags } = useContext(PermissionsContext);

  const isImportEnabled =
    featureFlags?.includes('compra_click_action_import') &&
    userPermissions?.includes('compra_click_action_import');

  const handleSelectAll = () => {
    if (isCheckAll) {
      setCheckboxList({ type: 'clear' });
      setIsCheckAll(false);
    } else {
      setCheckboxList({ type: 'all' });
      setIsCheckAll(true);
    }
  };

  useEffect(() => {
    setIsFetching(true);
    setData([]);

    fetchResource(getCompraClick(token, subsidiaryId, filters, sorting, pagination))
      .onSuccess((response) => {
        const { data, meta, links } = response.data;
        if (data) setData(data);
        if (meta) setMeta(meta);
        if (links) setLinks(links);
        setIsFetching(false);

      }).onFailure(error => {
        console.log(error);
      })
    setIsCheckAll(false);
    setCheckboxList({ type: 'clear' });
  }, [showCreateButton, filters, sorting, reload, pagination, subsidiaryId, token]);

  useEffect(() => {
    fetchResource(getExtraInfo(token, subsidiaryId))
      .onSuccess((response) => setShowCreateButton(response?.data?.paymentButtonCanCreate))
      .onFailure((error) => console.log(error))
  }, [showCreateButton, subsidiaryId, token]);

  const selectRow = (index, action) => {
    setShowDetails(true);
    setDetailsData(!isNull(index) ? data[index] : {});
    setAction(action);
  };

  const onDeleteSuccess = () => {
    setShowDeleteModal(false);
    setReload(!reload);
  };

  const hidePanel = () => {
    setShowDetails(false);
    setDetailsData({ id: null });
  };

  const onCloseImport = () => {
    setShowImport(false);
    setReload(!reload);
  };

  const handleBulkPaymentButtonDelete = () => {
    let array = Object.keys(checkboxList)
      .map(function (key) {
        return checkboxList[key];
      });

    fetchResource(batchDeleteCompraClick(array, token, subsidiaryId))
      .onSuccess(() => {
        setShowConfirmModal(false);;
        setDeletedCount(checkboxList.length);
        setShowSuccessModal(true)
        setReload(!reload);
      })
      .onFailure(err => alert('UPS!! Algo salió mal'))
  };

  const handleCheckboxChange = (paymentButtonId) => {
    const id = parseInt(paymentButtonId);
    if (checkboxList.includes(id)) {
      if (isCheckAll) {
        setIsCheckAll(false);
      }
      setCheckboxList(
        { type: 'remove', payload: { payment_buttons_ids: id } }
      )
    } else {
      setCheckboxList(
        { type: 'add', payload: { payment_buttons_ids: id } }
      );
    }
  };

  const handleApplyFilters = (filters) => {
    setFilters(filters);
    setPagination({ type: 'page', payload: 1 });
  };

  return (
    <>
      <div className={'payment-buttons'}>
        <ShareModal show={showShareModal} set={setShowShareModal} data={dataRow} />

        <DeleteProductModal
          buttonId={dataRow.id}
          deleteSuccess={onDeleteSuccess}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          subsidiaryId={subsidiaryId}
          token={token}
        />

        {isImportEnabled && (
          <ImportModal
            showImport={showImport}
            onClose={onCloseImport}
            subsidiaryId={subsidiaryId}
            token={token}
          />
        )}

        <div
          className={classNames({
            'dashboard-card': true,
            'panel-expanded': showDetails
          })}>
          <DashboardPanel
            panelHeading={
              detailsData?.id
                ? __('dashboard.sections.compraClick.panelHeadingEdit')
                : __('dashboard.sections.compraClick.panelHeading')
            }
            expanded={showDetails}
            hidePanel={hidePanel}
            checkboxListLength={checkboxList.length}
            setFilters={setFilters}
            handleReload={() => setReload(!reload)}
            meta={meta}
            isFetching={isFetching}
          >
            <Panel
              data={detailsData}
              action={action}
              onSave={() => setReload(!reload)}
              hidePanel={() => setShowDetails(false)}
              expirationDateFormat={'DD/MM/YYYY HH:mm'}
              countryTimezone={countryTimezone}
            />
          </DashboardPanel>

          <div className={'dashboard-card-header'}>
            <Col sm='6' xs='12'>
              <Filters handleApplyFilters={handleApplyFilters} />
            </Col>

            <Col sm='6' xs='12'>
              <div className='buttons-actions'>
                {isImportEnabled && (
                  <Button onClick={() => setShowImport(true)}>
                    {__('dashboard.sections.compraClick.import.button')}
                  </Button>
                )}

                <Button
                  disabled={checkboxList <= 0}
                  onClick={() => setShowConfirmModal(true)}>
                  <i className='mdi mdi-delete' style={{ fontSize: '1.4rem' }} />
                  {__('dashboard.sections.compraClick.delete.button')}
                </Button>

                {showCreateButton && (
                  <TableHeaderActions
                    createItem={{ name: __('dashboard.sections.compraClick.newButton'), action: () => selectRow(null, 'create') }}
                  />
                )}
              </div>

              <div className={'buttons-sorting'}>
                <span style={{ paddingTop: '0.5em', fontWeight: 'bold' }}>{__('dashboard.sections.compraClick.sorting.dropdownField')}</span>
                <SortingDropdowns setSorting={setSorting} />

                <PageSizeDropdown
                  meta={meta}
                  handlePageSize={(page_size) =>
                    setPagination({ type: 'page_size', payload: { page_size } })
                  }
                />
              </div>

              <Col sm='12' xs='12'>
                {Boolean(meta && links && meta.page && meta.page.total > meta.page.perPage) &&
                  <div className='paginatorContainer' >
                    <Paginator
                      meta={meta}
                      links={links}
                      handlePagination={(page) => setPagination({ type: 'page', payload: { page } })}
                    />
                  </div>
                }
              </Col>
            </Col>
          </div>

          {showConfirmModal &&
            <ConfirmModal
              showModal={showConfirmModal}
              closeAction={() => setShowConfirmModal(false)}
              submitAction={handleBulkPaymentButtonDelete}
              list={checkboxList}
            />
          }

          {showSuccessModal &&
            <SuccessModal
              showModal={showSuccessModal}
              closeAction={() => setShowSuccessModal(false)}
              count={deletedCount}
            />
          }

          <Table
            setDataRow={setDataRow}
            selectRow={selectRow}
            rows={data}
            setShowShareModal={setShowShareModal}
            setShowDeleteModal={setShowDeleteModal}
            isFetching={isFetching}
            handleCheckboxChange={handleCheckboxChange}
            checkboxList={checkboxList}
            isCheckAll={isCheckAll}
            checkboxAll={<CheckboxAll handleSelectAll={handleSelectAll} isCheckAll={isCheckAll} />}
            expirationDateFormat={'DD/MM/YYYY HH:mm'}
            countryTimezone={countryTimezone}
          />
        </div>
      </div>
    </>
  );
};
