import React, { useContext } from 'react';
import __ from 'utils/i18n';
import { DropdownItem } from 'reactstrap';
import { PermissionsContext } from 'contexts/permissionsContext';
import { helpUrl } from 'config/envConfig';

export const HelpLink = () => {
  const { userPermissions, featureFlags } = useContext(PermissionsContext);

  const isHelpLinkEnabled =
    helpUrl &&
    featureFlags?.includes("user_menu_help_link") &&
    userPermissions?.includes("user_menu_help_link");

  return (
    <>
      {isHelpLinkEnabled && (
        <a href={helpUrl} target="_blank" rel="noopener noreferrer">
          <DropdownItem className="nav-menu-item">
            <i className="mdi mdi-18px mdi-help-circle" />
            <span>{__("dashboard.components.help")}</span>
          </DropdownItem>
        </a>
      )}
    </>
  );
};
