import React from 'react';
import __ from 'utils/i18n';


const NameCell = ({ original }) => {
  const { name, imageUrl } = original;
  const imageClasses = {
    backgroundColor: original.backgroundColor
      ? "#" + original.backgroundColor
      : "",
  };
  return (
    <div className="catalog__product--name">
      <span
        style={imageUrl ? {} : imageClasses}
        className="product-donut-image"
      >
        {imageUrl ? (
          <img
            className={"img-cover " + imageClasses}
            src={imageUrl}
            alt="Product"
            height="35px"
            width="35px"
          />
        ) : (
          <p>{name.charAt(0)}</p>
        )}
      </span>
      <span>{name}</span>
    </div>
  );
};

const QuantityCell = ({ original }) => {
  const { quantity } = original;
  return quantity ? quantity : "-";
};

export const getColumns = () => ([

  {
    Header: __('charts.staff.legend.name'),
    Cell: NameCell,
  },
  {
    Header: __("charts.staff.legend.quantity"),
    Cell: QuantityCell,
  }
]);