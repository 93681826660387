import React, { useState } from 'react';
import __ from 'utils/i18n';
import { APIRequest } from 'api-provider';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import DashboardSectionTabs from 'containers/Dashboard/components/dashboardSection/DashboardSectionTabs';
import { Iframe } from './Iframe';
import { SendEmail } from './SendEmail';
import { ShareSocialNetwork } from './ShareSocialNetwork';
import { deleteCompraClick } from '../../../resources';

const tabTitle = (icon, label) => (
  <label className='tab-title'>
    <i className={icon} />
    <span>{label}</span>
  </label>
);

const iframeTab = (data, hideModal) => () => <Iframe data={data} hideModal={hideModal} />;

const shareTab = (data, hideModal) => () => <ShareSocialNetwork data={data} hideModal={hideModal} />;

const emailTab = (data, hideModal) => () => <SendEmail data={data} hideModal={hideModal} />;

const ModalBody = ({ data, hideModal }) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: tabTitle(
        'mdi mdi-code-tags',
        __('dashboard.sections.compraClick.actionButton.tabs.insertInWeb')
      ),
      component: iframeTab(data, hideModal),
    },
    {
      title: tabTitle(
        'mdi mdi-share-variant',
        __('dashboard.sections.compraClick.actionButton.tabs.share')
      ),
      component: shareTab(data, hideModal),
    },
    {
      title: tabTitle(
        'mdi mdi-email-outline',
        __('dashboard.sections.compraClick.actionButton.tabs.sendEmail')
      ),
      component: emailTab(data, hideModal),
    },
  ];

  const TabComponent = tabs[activeTab].component;

  return (
    <div className='dashboard-section'>
      <div className='dashboard-section-header tabs-header-modal'>
        <DashboardSectionTabs
          defaultTab={0}
          onChange={(index) => setActiveTab(index)}
          tabs={tabs}
        />
      </div>
      <div className='dashboard-section-content'>
        <TabComponent hideModal={hideModal} />
      </div>
    </div>
  );
};

export const ShareModal = ({
  buttonId,
  deleteSuccess,
  show,
  set,
  subsidiaryId,
  token,
  data,
}) => {
  return (
    <APIRequest
      resource={deleteCompraClick(subsidiaryId, buttonId, token)}
      onSuccess={() => {
        deleteSuccess();
        set(false);
      }}
    >
      {(request) => (
        <DashboardModal
          name='deleteProductModal'
          headerText={__('dashboard.sections.compraClick.actionButton.title')}
          modalClass='modal-share-button'
          showModal={show}
          showFooter={false}
          hideSeparator={true}
          hideModal={() => set(false)}
          submitAction={() => request.fetch({ buttonId })}
          submitDisabled={request.isFetching}
          submitColor='danger'
          submitText={__('actions.delete')}
          cancelText={__('actions.cancel')}
        >
          <ModalBody data={data} hideModal={set} />
        </DashboardModal>
      )}
    </APIRequest>
  );
};
