import React from "react";

import __ from "utils/i18n";
import { DashboardModal } from "containers/Dashboard/components/DashboardModal";

export const DeleteLogoModal = ({showModal, setShowModal, setValue, setUpdatedImage, setCroppedImage}) => {

  const handleRemoveLogo = (setValue) => {
    setUpdatedImage(null);
    setCroppedImage(null);
    setValue("logo", null);
    setShowModal(null);
  };
  
  return (
    <DashboardModal
      name="deleteLogo"
      headerText={__("store.logo.deleteLogoModalTitle")}
      showModal={showModal === "deleteLogo"}
      hideModal={() => setShowModal(false)}
      submitAction={() => handleRemoveLogo(setValue)}
      submitColor="danger"
      cancelText={__("actions.cancel")}
      submitText={__("actions.delete")}
    >
      {__("store.logo.deleteLogoModalSubtitle")}
    </DashboardModal>
  );
};
