export const tableColumns = {
  "agreementsColumns": ["name", "agreementNumber", "issuer", "subsidiary"],
  "buttonsColumns": ["description", "quatity", "status", "amount", "actions"],
  "clientsColumns": ["firstName", "email", "birthdate"],
  "depositColumns": ["availableDate", "deposit", "status"],
  "productsColumns": ["name", "category", "availableQuantity", "totalAmount"],
  "categoriesColumns": ["name","items"],
  "readersColumns": [
    "serialNumber",
    "type",
    "subsidiaryName",
    "batteryStatusCell",
    "lastUsedCell",
  ],
  "modalReadersColumns": [
    "serialNumber",
    "subsidiaryName",
    "enabledReader",
    "batteryStatusCell",
    "lastUsedCell",
  ],
  salesColumns: [
    "description",
    // "payment",
    "date",
    "amount",
    "deferredPayments",
    // "subsidiary",
  ],
  "subsidiariesColumns": ["name", "address", "sellers"],
  "usersColumns": ["name", "email", "role", "local", "state"],
  "withholdingTaxColumns": [
    "period",
    "vat",
    "profits",
    "juridisctionTax",
    "juridisctionTaxSirtac",
    "withholdingAmount",
    "certificates",
  ],
  "batchCloseColumns": ["name", "batch", "transactions", "date", "amount", "status", "action"],
  userFields: [
    'email',
    'firstName',
    'lastName',
    'document',
    'phone',
    'roleId',
    'subsidiaryId',
    'statusId',
  ]
};

