import React from 'react';
import __ from 'utils/i18n';
import { getChartsColors } from 'utils';
import {getConfig} from "../../../../../../config";
const NameCell = ({ original, index }) => {
  const name = original.name;

  return (
    <>
      <span
        className="staff-legend-index"
        style={{ backgroundColor: getChartsColors()[index] }}>
        {index + 1}
      </span>
      <span className="chart-legend-name" title={name}>{name}</span>
    </>
  );
};

const AmountCell = ({ original }) => {
  const amount = original.amount;
  const customMoneySymbol = getConfig("customConfig.currencies[0].symbol") ?? "$";
  const customMoneyNumber = getConfig("customConfig.currencies[0].number") ?? "032";
  const customMoneyDigits = getConfig("customConfig.currencies[0].digits") ?? 2;

  const format =parseInt(customMoneyDigits) === 0 ? "rounded" : "decimal";

  return amount
    ? `${__.currency(amount, customMoneyNumber, format, "-", customMoneySymbol)}`
    : "-";
};

const SalesCell = ({ original }) => {
  const quantity = original.quantity;
  return quantity ? quantity : "-";
};

export const getColumns = () => ([
  {
    Header: __('charts.staff.legend.seller'),
    Cell: NameCell,
  },
  {
    Header: __("charts.staff.legend.amount"),
    Cell: AmountCell,
  },
  {
    Header: __("charts.staff.legend.quantity"),
    Cell: SalesCell,
  }
]);
