import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { get, sumBy } from "lodash";
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import AggregatedSales from "../Stats/charts/AggregatedSales";
import { StartSectionContent } from "./components/startSection";
import __ from "utils/i18n";
import { Select } from "components/informed";
import DashboardSectionTabs from "containers/Dashboard/components/dashboardSection/DashboardSectionTabs";
import { completeDays } from "utils";
import { PermissionsContext } from "contexts/permissionsContext";
import gtmEvents from "utils/gtmEvents";

const LastDaysSalesChart = ({ sales, days, currency, noData }) => {
  const data = completeDays(get(sales, "byDate", []), {
    startDate: moment().subtract(days - 1, "d"),
    endDate: moment(),
  });
  const totalCount = sumBy(data, "count");
  return (
    <AggregatedSales
      noData={noData}
      data={totalCount && data}
      currency={currency}
      aggregator={{
        key: (item) => moment(item.date).format("YYYY-MM-DD"),
        metrics: {
          category: (items, key) => moment(key).format("D MMM"),
          count: (items) => sumBy(items, "count"),
          amount: (items) => sumBy(items, "amount"),
        },
      }}
    />
  );
};

const SalesByHourChart = ({ resume, currency, noData, mode }) => {
  const day = mode.endDate || moment();
  const salesByHour = get(
    resume,
    ["transactionsByDate", day.format("YYYY-MM-DD")],
    {}
  );

  const data = Object.keys(salesByHour).map((key) => {
    return {
      ...salesByHour[key],
      hour: parseInt(key, 10),
    };
  });

  const totalCount = sumBy(data, "quantity");
  return (
    <AggregatedSales
      noData={noData}
      data={totalCount && data}
      currency={currency}
      aggregator={{
        key: (item) => Math.floor(parseFloat(item.hour) / 2) * 2,
        keys: [...Array(12).keys()].map((k) => k * 2),
        metrics: {
          category: (items, key) => key,
          title: (items, key) => `${key} a ${key + 2} hs`,
          count: (items) => sumBy(items, "quantity"),
          amount: (items) => sumBy(items, "amount"),
        },
      }}
    />
  );
};


const StartSection = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [currency, setCurrency] = useState(props.defaultCurrency);

  
  useEffect(()=> {
    switch (activeTab) {
      case 0:
        gtmEvents.homePageView('pageToday')
      break
      case 1:
        gtmEvents.homePageView('page7d')
      break
      case 2:
        gtmEvents.homePageView('page30d')
      break
      default:
    }
  }, [activeTab]);


  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const isTodayMetricsEnabled =
    featureFlags?.includes("home_today_metrics") &&
    userPermissions?.includes("home_today_metrics");
  const isLastWeekMetricsEnabled =
    featureFlags?.includes("home_last_week_metrics") &&
    userPermissions?.includes("home_last_week_metrics");
  const isLastMonthMetricsEnabled =
    featureFlags?.includes("home_last_month_metrics") &&
    userPermissions?.includes("home_last_month_metrics");

  const currencies = props.currencies
    ? props.currencies.map((item) => ({
        value: item.code,
        label: item.symbol,
      }))
    : [];

  const defaultCurrency = currencies.find(
    (currency) => currency.value === props.defaultCurrency
  );

  const shouldChangeCurrencyStats = featureFlags?.includes("multicurrency");

  const newModes = {
    today: isTodayMetricsEnabled && {
      key: "today",
      title: __("dashboard.sections.home.periods.today.title"),
      metricSuffix: __("dashboard.sections.home.periods.today.suffix"),
      salesTitle: __("charts.salesByHour.title"),
      salesChart: SalesByHourChart,
      days: 1,
    },
    lastDays: isLastWeekMetricsEnabled && {
      key: "lastDays",
      title: __("dashboard.sections.home.periods.lastDays.title", { n: 7 }),
      metricSuffix: __("dashboard.sections.home.periods.lastDays.suffix", {
        n: 7,
      }),
      salesTitle: __("charts.salesByDay.title"),
      salesChart: LastDaysSalesChart,
      days: 7,
    },
    lastMonth: isLastMonthMetricsEnabled && {
      key: "lastMonth",
      title: __("dashboard.sections.home.periods.lastDays.title", { n: 30 }),
      metricSuffix: __("dashboard.sections.home.periods.lastDays.suffix", {
        n: 30,
      }),
      salesTitle: __("charts.salesByDay.title"),
      salesChart: LastDaysSalesChart,
      days: 30,
    },
  };

  return (
    <div className="dashboard-section">
      <div className="dashboard-section-header currency-header">
        <Row>
          <Col md={10} xs={10}>
            <DashboardSectionTabs
              defaultTab={activeTab}
              onChange={(index) => setActiveTab(index)}
              tabs={Object.keys(newModes).map((key, index) => ({
                title: newModes[key].title,
              }))}
            />
          </Col>
          <Col md={2} xs={2} className="align-right">
            {shouldChangeCurrencyStats && currencies.length && (
              <Select
                field="currency"
                options={currencies}
                defaultValue={defaultCurrency}
                onChange={(currency) => setCurrency(currency)}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="dashboard-section-body">
        <div className="dashboard-section-content">
          <StartSectionContent
            mode={Object.entries(newModes)[activeTab][1]}
            currencyCode={currency}
            history={props.history}
          />
        </div>
      </div>
    </div>
  );
};

const startSectionMapStateToProps = (state) => ({
  currencies: get(state, "login.user.currencies"),
  defaultCurrency: get(
    state,
    "login.user.currencyFormat.CURRENCY_DEFAULT_CODE"
  ),
});

const ConnectedStartSection = connect(startSectionMapStateToProps)(
  StartSection
);

export default withRouter(ConnectedStartSection);
