/**
 * Created by rodrigo on 6/19/17.
 */

export const ACTIONS = {
  REQUEST_START: 'apiRequestPromise/REQUEST_START',
  REQUEST_SUCCESS: 'apiRequestPromise/REQUEST_SUCCESS',
  REQUEST_FAILURE: 'apiRequestPromise/REQUEST_FAILURE',
  REQUEST_CANCELLED: 'apiRequestPromise/REQUEST_CANCELLED',
  REQUEST_CACHED: 'apiRequestPromise/REQUEST_CACHED',
  SET_ENDPOINT: 'apiRequestPromise/SET_ENDPOINT',
  SET_TOKEN: 'apiRequestPromise/SET_TOKEN',
}

export default ACTIONS
