import React from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';
import dateAdapter from 'utils/dateAdapter';
import moment from 'moment';

export const SaleInfo = ({ sale, config }) => {
  const { saleInformation, recharge } = config;
  const isWinningCustomer = sale.promo && sale.promo.winner === 'CLIENT';
  const hasRecharge = sale.recharge || null;
  const isAnnulment = sale.canceled || null;
  const hasReferenceNumber = sale.payment.referenceNumber && sale.payment.referenceNumber.length > 0;

  return (
    <>
      {isWinningCustomer && (
        <Table className='no-border'>
          <tbody>
            <tr>
              <td style={{ width: '100%' }}>
                <span className='badge badge-primary'>
                  <i className='mdi mdi-gift' />
                  {__('dashboard.sections.sales.winner')}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
      <Table className='no-border table'>
        <tbody>
          {saleInformation.map((item) => (
            <tr>
              {item.type === 'map' ? (
                <>
                  {
                    (sale.latitude && sale.longitude) && (
                      <td>
                        <a
                          className='sale-map-link'
                          target='_blank'
                          href={`https://maps.google.com/?ie=UTF8&hq=&q=${item.latitude(
                            sale
                          )},${item.longitude(sale)}&ll=${item.latitude(
                            sale
                          )},${item.longitude(sale)}&z=15`}
                          rel='noopener noreferrer'
                        >
                          <i className='mdi mdi-map-marker' />
                          {__('dashboard.sections.sales.showMap')}
                        </a>
                      </td>
                    )
                  }
                </>
              ) : (
                <td>
                  <i className={item.icon} />
                  {item.type === 'date'
                    ? dateAdapter(item.method(sale), 'LL HH:mm')
                    : item.method(sale)}
                </td>
              )}
            </tr>
          ))}
          {hasReferenceNumber && (
            <>
              <tr>
                  <td>
                      <i className="mdi mdi-circle-small"/>
                      {__('dashboard.sections.sales.transactionPaymentReferenceNumber')}
                    </td>
                    </tr>
                    <tr>
                    <td>
                      <p>
                          <code style={{overflowWrap: 'break-all'}}>
                              {sale.payment.referenceNumber}
                          </code>
                      </p>
                  </td>
              </tr>
              </>
          )}
          {hasRecharge && (
            <>
              {recharge.map((item) => (
                <tr>
                  <td>
                    <i className={item.icon} />
                    {item.type === 'currency'
                      ? `${__(
                        'dashboard.sections.sales.commissionForReload'
                      )} ${__.currency(item.method(sale), sale.currency)}`
                      : item.method(sale)}
                  </td>
                </tr>
              ))}
            </>
          )}
          {isAnnulment && (
            <tr>
              <td>
                <i className='mdi mdi-checkbox-blank-circle-outline' />
                {__('dashboard.sections.sales.canceled')}
              </td>
              <td>
                {moment(sale.payment.annulation.created).format('YYYY-MM-DD')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
