import { loginPublicKey } from "config/envConfig";
import { backendUrl, tenantId } from 'config/envConfig';
import { getConfig } from "config";
import forge from 'node-forge';


const isOauthLoginEnabled = getConfig("featureFlags.isOauthLoginEnabled");
const loginChannel = getConfig('customConfig.loginChannel');

export const encryptCredential = (value) => {
  const publicKey = forge.pki.publicKeyFromPem(loginPublicKey);
  const buffer = forge.util.createBuffer(value, 'utf8');
  const encrypted = publicKey.encrypt(buffer.getBytes(), 'RSA-OAEP', {
    md: forge.md.sha256.create(), // Using SHA-256 for the OAEP mask generation function
    mgf1: { md: forge.md.sha256.create() } // Specify MGF1 parameters
  });
  return forge.util.encode64(encrypted);
}

export const login = (user, password, token) => {
  const headers = {
    "Access-Control-Allow-Origin": true,
  };

  const formData = {
    email: user,
    password: password,
    channel: loginChannel,
    deviceId: "web",
    deviceModel: "web",
    deviceOS: "web",
  };

  if (isOauthLoginEnabled) headers['oauth'] = isOauthLoginEnabled;
  if (token) formData['token'] = token;

  return {
    endpoint: "/api/v1/login",
    headers,
    proxy: false,
    method: "POST",
    formData
  };
};

export const isValidToken = (token) => {
  return {
    endpoint: "/api/v1/isValidToken",
    headers: {
      "Access-Control-Allow-Origin": true,
      "x-authentication-token": token,
    },
    proxy: false,
    method: "GET",
  };
};

export const refreshToken = (authServer) => {
  return {
    data: '',
    url: `${backendUrl}/api/v1/oauth/refreshToken`,
    method: 'POST',
    headers: {
      'x-refresh-token': authServer?.refresh_token,
      'x-client-id': tenantId
    }
  };
};

export function loginCallback(userUuid) {
  return {
    endpoint: `/api/v1/login/callback?uuid=${userUuid}`,
    headers: {
      "Access-Control-Allow-Origin": true
    },
    proxy: false,
    method: "GET"
  };
}
