import React, { useState, useContext } from 'react';
import numeral from 'numeral';
import i18next from 'i18next';
import NumberFormat from 'react-number-format';
import { Field } from 'react-form';
import {
  InputGroup,
  InputGroupAddon,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import classNames from 'classnames';
import { isEmpty, get } from 'lodash';
import { connect } from 'react-redux';
import { getConfig } from 'config';
import __ from 'utils/i18n';
import { PermissionsContext } from "contexts/permissionsContext";

const AmountInput = (props) => {
  const { value, className, type, name, userCurrencies } = props;
  const [isDropdownOpen, setIsDropwdownOpen] = useState(false);
  const { featureFlags } = useContext(PermissionsContext);
  const showDecimals = getConfig('customConfig.currencyDecimals');
  const maxAmountLimit = getConfig('customConfig.maxAmountLimit');

  const formatCurrencies = userCurrencies
    ? userCurrencies.map((item) => ({
      value: item.code,
      label: item.symbol,
    }))
    : null;

  const currencies = formatCurrencies || getConfig('customConfig.currencies', []);
  const isMultiCurrenciesEnabled = featureFlags?.includes('multicurrency');

  const showCurrencies = currencies && currencies.length > 1 && isMultiCurrenciesEnabled;

  let delimiters = numeral.locales[i18next.language].delimiters;

  const localSettingsNumeral = Object.entries(numeral.locales).filter(
    (x) => x[0].indexOf(`${i18next.language}-`) !== -1
  );

  if (!isEmpty(localSettingsNumeral)) {
    delimiters = localSettingsNumeral[0][1].delimiters;
  }

  const { thousands, decimal, precision } = delimiters;

  let formattedValuePlaceholder = showDecimals ?
    __.number(value && value.amount, 'rounded') || `0${decimal}00` :
    __.number(value && value.amount, 'rounded');

  const onChangeAmount = (amount) => {
    const rawAmount = amount
      .split(decimal)[0]
      .replace(new RegExp(thousands, 'g'), '');

    if (rawAmount.length <= maxAmountLimit) {
      props.onChange({
        ...props.value,
        amount,
      });
    }
  };

  const onChangeCurrency = (currency) => {
    props.onChange({
      ...props.value,
      currency: currency.value,
    });
  };

  return (
    <Field field={name} pure={false}>
      {({ setValue, value, setTouched }) => {
        return (
          <InputGroup>
            <NumberFormat
              className={classNames('form-control input-amount', className)}
              value={value.amount}
              thousandSeparator={thousands}
              decimalSeparator={decimal}
              decimalScale={precision}
              allowNegative={false}
              placeholder={formattedValuePlaceholder}
              onChange={(e) => {
                setTouched();
                onChangeAmount(e.target.value);
              }}
            />
            {type === 'amount' && (
              <>
                {showCurrencies ? (
                  <InputGroupAddon addonType="prepend">
                    {isMultiCurrenciesEnabled ? (
                      <ButtonDropdown
                        isOpen={isDropdownOpen}
                        toggle={() =>
                          setIsDropwdownOpen((prevState) => !prevState)
                        }
                      >
                        <DropdownToggle color="secondary" caret>
                          {__.currency(
                            false,
                            value && value.currency,
                            'decimal',
                            ' '
                          )}
                        </DropdownToggle>
                        <DropdownMenu right>
                          {currencies.map((currency, index) => {
                            return (
                              <DropdownItem
                                key={index}
                                onClick={() => {
                                  onChangeCurrency(currency);
                                  setTouched();
                                }}
                              >
                                {__.currency(
                                  false,
                                  currency.value,
                                  'decimal',
                                  ' '
                                )}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </ButtonDropdown>
                    ) : (
                      <span className="input-group-text">
                        {__.currency(
                          false,
                          value && value.currency,
                          'decimal',
                          ' '
                        )}
                      </span>
                    )}
                  </InputGroupAddon>
                ) : (
                  <div className="center-prefix-number input-group-prepend  center-prefix-currency">
                    <span>
                      {__.currency(false, currencies[0].value, 'decimal', ' ')}
                    </span>
                  </div>
                )}
              </>
            )}
            {type === 'percentage' && (
              <div className="center-prefix-number input-group-prepend">
                <span style={{ fontSize: '18px' }}>%</span>
              </div>
            )}
          </InputGroup>
        );
      }}
    </Field>
  );
};

const mapStateToProps = (state) => ({
  userCurrencies: get(state, 'login.user.currencies', null),
});

export default connect(mapStateToProps)(AmountInput);
