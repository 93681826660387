import React from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';

export const ExtraItems = ({ data }) => {
  const { withholdingTaxes, commission, gross, currency } = data;

  return (
    <Table className='no-border'>
      <tbody>
        <tr>
          <td>
            <span>Subtotal</span>
          </td>
          <td>{__.currency(gross, currency.code)}</td>
        </tr>
        <tr>
          <td>
            <span>{commission.name}</span>
          </td>
          <td>-{__.currency(commission.value, currency.code)}</td>
        </tr>
        {withholdingTaxes.map((tax, index) => {
          return (
            <tr key={index}>
              <td>
                <span>{tax.name}</span>
              </td>
              <td>-{__.currency(tax.value, currency.code)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
