import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import FacebookShareLink from 'react-facebook-share-link';
import TwitterShareLink from 'react-twitter-share-link';
import __ from 'utils/i18n';
import { checkoutUrl } from 'config/envConfig';
import { Text } from 'components/informed';
import { copyToClipboard } from 'utils';

const ModalFooter = ({ hideModal }) => {
  const copyHtmlCode = () => {
    copyToClipboard(document.getElementById('social-link').value);
  };

  return (
    <div className='modal-footer'>
      <Button color='secondary'
        onClick={() => hideModal(false)}>
        {__('actions.close')}
      </Button>

      <Button color='success' type='submit' onClick={copyHtmlCode}>
        {__('dashboard.sections.compraClick.actionButton.copyUrl')}
      </Button>
    </div>
  );
};

export const ShareSocialNetwork = ({ data, hideModal }) => {
  const link = `${checkoutUrl}${data.token}`;

  return (
    <div>
      <Row>
        <Col>
          <Text
            id='social-link'
            initialValue={link}
            field='url'
            type='text'
            label='URL'
            help={__('dashboard.sections.compraClick.socialLink.help')}
          />
        </Col>
      </Row>
      <Row>
        <Col md='6'>
          <FacebookShareLink link={link}>
            {link => (
              <Button
                className='facebook-share'
                onClick={() => window.open(link, 'ventana1', 'width=300,height=400,scrollbars=NO')}>
                <i className='mdi mdi-facebook' />
                Facebook
              </Button>
            )}
          </FacebookShareLink>
        </Col>
        <Col md='6' className='text-right'>
          <TwitterShareLink link={link}>
            {link => (
              <Button
                className='twitter-share'
                onClick={() => window.open(link, 'ventana1', 'width=300,height=400,scrollbars=NO')}>
                <i className='mdi mdi-twitter' />
                Twitter
              </Button>
            )}
          </TwitterShareLink>
        </Col>
      </Row>

      <ModalFooter hideModal={hideModal} />
    </div>
  );
};
