import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import queryString from "query-string";
import { getConfig } from "config";
import __ from "utils/i18n";
import { Field, Form } from "components/forms";
import { CustomField } from "components/forms/fields";
import DateRangeInput from "components/forms/inputs/DateRangeInput";
import { backendUrl, tenantId } from "config/envConfig";
import ButtonLoader from "containers/Dashboard/components/ButtonLoader/ButtonLoader";

// TODO refactorizar componente para usar estos campos directamente
const options = getConfig('customConfig.sales.options');

export const TransactionsReportWidget = ({ filters, token }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { filterSubsidiaries } = useSelector((state) => state.dashboard.sales.filters);

  const downloadReport = (values) => {
    setIsLoading(true);
    const query = queryString.stringify({
      subsidiaryId: values.subsidiary,
      subsidiaries: values.filterSubsidiaries?.join(),
      startDate: moment(values.dateRange.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.dateRange.endDate).format("YYYY-MM-DD"),
      transactionTypes: values.type,
      status: values.status,
      users: values.users,
      downloadType: values.format,
    });
    
    fetch(`${backendUrl}/api/v1/batches/report?${query}`, {
      method: "GET",
      headers: {
        "x-client-id": tenantId,
        "x-Authentication-Token": token,
        "x-request-download": "stream",
      },
    })
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `batch_${Date.now()}.${values.format}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Form
      defaultValues={{
        subsidiary: filters.subsidiaryId,
        filterSubsidiaries: filterSubsidiaries,
        dateRange: {
          startDate: filters.startDate,
          endDate: filters.endDate,
        },
        type: "",
        status: "",
        users: "",
        format: "xls",
      }}
    >
      {(form) => (
        <>
          <Button className="buttom-radius" onClick={() => setShowModal(true)}>
            {__("actions.export")}
          </Button>
          <Modal
            className="reportWidgetModal"
            isOpen={showModal}
            toggle={() => setShowModal(false)}
          >
            <ModalHeader>
              {__("dashboard.sections.sales.transactionReport")}
            </ModalHeader>
            <ModalBody>
              <div className="text-center">
                <CustomField field="dateRange">
                  <DateRangeInput />
                </CustomField>
              </div>
              <Field
                block
                field="format"
                label={__("dashboard.sections.sales.form.format.label")}
                placeholder={__(
                  "dashboard.sections.sales.form.format.placeholder"
                )}
                type="select"
                options={options.formats}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                className="buttom-radius"
                onClick={() => setShowModal(false)}
              >
                {__("actions.cancel")}
              </Button>
              <ButtonLoader
                text={__("actions.download")}
                isLoading={isLoading}
                onClick={() => {
                  downloadReport(form.values);
                }}
              />
            </ModalFooter>
          </Modal>
        </>
      )}
    </Form>
  );
};
