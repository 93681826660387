import React, { useState, useEffect, useContext } from "react";
import {
  InputGroup,
  InputGroupAddon,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NumberFormat from "react-number-format";
import classnames from "classnames";
import { asField } from 'informed';
import { isEmpty, isNumber } from 'lodash';
import i18next from 'i18next';
import numeral from 'numeral';
import { PermissionsContext } from "contexts/permissionsContext";
import __ from 'utils/i18n';

const localSettingsNumeral = () => {
  return (
    Object.entries(numeral.locales).filter(x => x[0].indexOf(`${i18next.language}-`) !== -1)
  )
}

const CurrencyAddon = ({ value, onChangeCurrency, disabled, shouldDisplayListCoins, currencies }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { featureFlags } = useContext(PermissionsContext);
  const isMultiCurrenciesEnabled = featureFlags?.includes('multicurrency');

  const showCurrencies = currencies && currencies.length > 1;

  useEffect(() => {
    !value?.currency && onChangeCurrency({ currency: currencies && currencies[0].code });
  }, [currencies, onChangeCurrency, value]);

  return (
    <>
      {(showCurrencies && currencies) &&
        <InputGroupAddon addonType="prepend">
          {(isMultiCurrenciesEnabled)
            ? <ButtonDropdown
              className="btn-currency"
              isOpen={openDropdown}
              toggle={() => setOpenDropdown(!openDropdown)}
            >
              <DropdownToggle
                disabled={disabled || !shouldDisplayListCoins}
                color="secondary"
                caret
              >
                {__.currency(
                  false,
                  value ? value.currency : currencies[0].code,
                  "decimal",
                  " "
                )}
              </DropdownToggle>
              <DropdownMenu right>
                {currencies.map((currency, index) => {
                  return (<DropdownItem
                    key={index}
                    onClick={() => onChangeCurrency({ currency: currency.code })}>
                    {__.currency(false, currency.code, 'decimal', ' ')}
                  </DropdownItem>)
                })}
              </DropdownMenu>
            </ButtonDropdown>
            : (
              <span className="input-group-text">
                {__.currency(false, currencies[0].code, "decimal", " ")}
              </span>
            )}
        </InputGroupAddon>
      }
      {(!showCurrencies && currencies) &&
        <div className="center-prefix-number input-group-prepend">
          <span>{__.currency(false, currencies[0].code, "decimal", " ")}</span>
        </div>
      }
    </>
  );
};

const PercentageSymbol = () => {
  return (
    <div className="center-prefix-number input-group-prepend">
      <span style={{ 'fontSize': '18px' }}>%</span>
    </div>
  );
};

const Amount = asField(({ fieldState, fieldApi, forwardedRef, type, shouldDisplayListCoins, onChange, defaultValue, ...props }) => {

  const { value } = fieldState;
  const { label, currencies, showLabel = true } = props;
  const [amount, setAmount] = useState(value);
  const delimiters = isEmpty(localSettingsNumeral())
    ? numeral.locales[i18next.language].delimiters
    : localSettingsNumeral[0][1].delimiters;
  const { thousands, decimal, precision } = delimiters;

  useEffect(() => {
    fieldApi.setValue(amount);
  });

  const onChangeAmount = (values) => {
    setAmount(prevState => ({ ...prevState, ...values }));
    fieldApi.setValue({ ...amount, ...values });
    onChange && onChange({ ...amount, ...values })
  };

  return (
    <div className="position-relative form-group">
      {(showLabel) &&
        <label className="">{label}</label>
      }

      <InputGroup>
        <NumberFormat
          className={classnames({
            "form-control": true,
            "has-error": fieldState.error
          })}
          thousandSeparator={thousands}
          decimalSeparator={decimal}
          decimalScale={precision}
          allowNegative={false}
          value={isNumber(amount) ? amount : amount?.amount}
          onValueChange={(values) => onChangeAmount(values)}
          {...props}
        />
        {(type === 'amount') &&
          <CurrencyAddon
            value={amount}
            onChangeCurrency={onChangeAmount}
            disabled={props.disabled}
            shouldDisplayListCoins={shouldDisplayListCoins}
            currencies={currencies}
          />
        }
        {(type === 'percentage') && 
          <PercentageSymbol />
        }
      </InputGroup>
      {fieldState.error
        ? <small className="text-danger form-text text-muted">{fieldState.error}</small>
        : null
      }
      
    </div>
  )
})

Amount.defaultProps = {
  disabled: false,
  type: "text",
  label: "",
  shouldDisplayListCoins: true,
};

export default Amount;
