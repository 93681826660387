import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { APIRequest } from 'api-provider';
import { getUsers } from './_shared/resources';
import { UsersContent } from './components/UsersContent';
import gtmEvents from 'utils/gtmEvents';

export const Users = () => {
  const { token } = useSelector(state => state.api);

  useEffect(()=> {
    gtmEvents.merchantPageView();
  },[]);

  return (
    <APIRequest
      resource={getUsers(token)}
      fetch>
      {request => (
        <UsersContent
          fetchUsers={request.fetch}
          isFetching={request.isFetching}
          data={request.response || []}
          error={request.error}
          reload={request.reload}
        />
      )}
    </APIRequest>
  )
}
