import React from "react";
import {
    Container,
    Col,
    Row,
    ModalBody,
  } from "reactstrap";
import Loader from "components/Loader";
import __ from "utils/i18n";
function LoadingStep(props) {
    return (
      <ModalBody>
        <Container>
          <Row>
            <Col className="payment-link-modal__loading-step__container">
              <Loader absolute={false} />
              <h5 className="modal-title payment-link-modal__steps__title">
                {__.t("dashboard.components.paymentLink.loading.title")}
              </h5>
              <p>
                {__.t("dashboard.components.paymentLink.loading.description")}
              </p>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    );
  }
  
  
  
  export default LoadingStep;