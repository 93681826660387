import React, { useContext } from "react";
import { Row } from "reactstrap";
import { getConfig } from "config/index";
import { PermissionsContext } from "contexts/permissionsContext";

import { SaleInfo } from "./SaleInfo";
import { Dcc } from "./Dcc";
import { Items } from "./Items";
import { ExtraItems } from "./ExtraItems";
import { Send } from "./Send";
import { Summary } from "./Summary";
import { Terminal } from "./Terminal";
import { Contact } from "./Contact";

export const TransactionReceiptView = ({ data }) => {
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const isSendReceiptEnabled =
    featureFlags?.includes("sales_action_send_receipt") &&
    userPermissions?.includes("sales_action_send_receipt");

  const config = getConfig("customConfig.sales.transactionReceipt");

  const sections = [SaleInfo, Terminal, Items, ExtraItems, Dcc, Summary, Contact];

  return (
    <Row className="transaction">
      <div className="panel-flex">
        <div>
          {sections.map((Section, i) => (
            <Section key={i} sale={data} config={config} />
          ))}
        </div>
        {isSendReceiptEnabled && (
          <div>
            <Send sale={data} />
          </div>
        )}
      </div>
    </Row>
  );
};
