import React, { useContext } from "react";
import __ from "utils/i18n";

import DashboardTabbedSection from "containers/Dashboard/components/dashboardSection/DashboardTabbedSection";
import DashboardSectionTab from "containers/Dashboard/components/dashboardSection/DashboardSectionTab";

import { EcommercePanel } from "./components/EcommerceForm";
import OrdersPanel from "./components/OrdersPanel";
import { PermissionsContext } from "contexts/permissionsContext";

export const EcommerceSection = () => {
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const isEcommercePanelEnabled =
    featureFlags?.includes("ecommerce_main") &&
    userPermissions?.includes("ecommerce_main");
  const isOrderPanelEnabled =
    featureFlags?.includes("ecommerce_orders") &&
    userPermissions?.includes("ecommerce_orders");

  return (
    <DashboardTabbedSection>
      {isEcommercePanelEnabled && (
        <DashboardSectionTab
          path="main"
          title={__("dashboard.sections.ecommerce.tabs.general")}
          component={EcommercePanel}
        />
      )}

      {isOrderPanelEnabled && (
        <DashboardSectionTab
          path="orders"
          title={__("dashboard.sections.ecommerce.tabs.orders")}
          component={OrdersPanel}
        />
      )}
    </DashboardTabbedSection>
  );
};
