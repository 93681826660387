import __ from 'utils/i18n';

const getDescription = (original) => {
  if (original.showName) return original.showName;
  if (original.referenceTransactionId)
    return (
      __('dashboard.sections.sales.refund') +
      ' ' +
      original.referenceTransactionId
    );
  if (!original.items.length)
    return __('dashboard.sections.sales.whithoutDescription');
  if (original.items.length > 1)
    return __.unit(original.items.length, 'products');
  if (!original.items[0].productName)
    return __('dashboard.sections.sales.whithoutDescription');
  return original.items[0].productName;
};

export default getDescription
