import React from 'react';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';

export const Terminal = ({ sale, config }) => {

  //const config = getConfig("customConfig.sales.transactionReceipt.salesTerminalPayment");
  const isTerminalPaymentEnabled = config.salesTerminalPayment;

  return(
    <>
    {isTerminalPaymentEnabled && (
    <Table className="no-border">
      <tbody>
        <tr>
          <td>{__('dashboard.sections.sales.terminalPayment')}</td>
          <td>{sale.terminal.type}</td>
        </tr>
      </tbody>
    </Table>
    )}
    </>
  );
};
