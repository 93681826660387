import React from 'react';
import { useSelector } from 'react-redux';
import __ from 'utils/i18n';
import { APIRequest } from 'api-provider';
import DashboardTabbedSection from 'containers/Dashboard/components/dashboardSection/DashboardTabbedSection';
import DashboardSectionTab from 'containers/Dashboard/components/dashboardSection/DashboardSectionTab';
import { ClientsContent } from './components/ClientsContent';
import { getClients } from './_shared/resources';
import gtmEvents from 'utils/gtmEvents';

export const Clients = () => {
  const { subsidiaryId } = useSelector(state => state.login.user);
  const { token } = useSelector(state => state.api);

  gtmEvents.clientPageView();

  return (
    <DashboardTabbedSection>
      <DashboardSectionTab title={__('dashboard.sections.clients.title')}>
        <APIRequest resource={getClients(subsidiaryId, token)} fetch>
          {request => (
            <ClientsContent
              fetchClients={request.fetch}
              isFetching={request.isFetching}
              data={request.response || []}
              error={request.error}
              subsidiaryId={subsidiaryId}
              token={token}
            />
          )}
        </APIRequest>
      </DashboardSectionTab>
    </DashboardTabbedSection>
  )
}