import __ from 'utils/i18n';
import getPaymentCard from './resources/getPaymentCard';

export const SalePaymentCell = ({ original }) => {
  const { payment } = original;
  const paymentMethod = __(
    `dashboard.sections.sales.${payment.paymentMethodType}`,
    ''
  );

  const paymentCard = getPaymentCard(original);

  return (
    <div className="d-flex flex-column">
      <>
        <strong>{paymentMethod}</strong>
      </>
      {paymentCard && <span>{paymentCard}</span>}
    </div>
  );
};
