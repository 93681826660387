
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'reactstrap'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { sumBy } from 'lodash'

import { completeDays } from 'utils'
import { aggregateBy } from 'utils'

import { buildTooltip } from 'components/charts'
import { ComparativeLineChart } from 'components/charts'
import { formatComparison } from 'components/charts/ComparativeLineChart'

import __ from 'utils/i18n'

export class SalesByDate extends React.Component {

  static propTypes = {
    data: PropTypes.array,
    comparativeData: PropTypes.any,
  }

  modes = [
    {
      id: "day",
      label: __('dashboard.sections.stats.ranges.day'),
      aggregator: {
        key: item => moment(item.date).format("YYYY-MM-DD"),
        metrics: {
          category: items => moment(items[0].date).format("DD MMM"),
          count: items => sumBy(items, 'count'),
          amount: items => sumBy(items, 'amount')
        }
      }
    },
    {
      id: "week",
      label: __('dashboard.sections.stats.ranges.week'),
      aggregator: {
        key: item => moment(item.date).format("YYYY-WW"),
        metrics: {
          category: items => __.daterange(
            moment(items[0].date),
            moment(items.slice(-1)[0].date)
          ),
          count: items => sumBy(items, 'count'),
          amount: items => sumBy(items, 'amount')
        }
      }
    },
    {
      id: "month",
      label: __('dashboard.sections.stats.ranges.month'),
      aggregator: {
        key: item => moment(item.date).format("YYYY-MM"),
        metrics: {
          category: items => __.daterange(
            moment(items[0].date),
            moment(items.slice(-1)[0].date)
          ),
          count: items => sumBy(items, 'count'),
          amount: items => sumBy(items, 'amount')
        }
      }
    }
  ]

  metrics = [
    {
      id: "amount",
      title: __('dashboard.sections.stats.totals.totalSales'),
      formatter: amount => __.currency(amount, this.props.currency, 'auto')
    },
    {
      id: "count",
      title: __('dashboard.sections.stats.totals.totalPayments'),
      formatter: count => __.unit(count, 'payments')
    }
  ]

  state = {
    mode: this.modes[0],
    metric: this.metrics[0],
  }

  setMetric(metric) {
    this.setState({ metric })
  }

  setMode(mode) {
    this.setState({ mode })
  }

  tooltipFormatter({ item, comparativeItem, metric }) {
    if (comparativeItem) {
      return buildTooltip({
        title: `
          ${comparativeItem.category}
          <b> vs. </b>
          ${item.category}
        `,
        value: `
          <div>${metric.formatter(item[metric.id])}</div>
          <div class="secondary">
            ${metric.formatter(comparativeItem[metric.id])}
          </div>
          <div class="comparsion">
            ${formatComparison(item[metric.id], comparativeItem[metric.id])}
          </div>
        `
      })
    }
    else if (metric.id === "amount") {
      return buildTooltip({
        title: item.category,
        value: `
          <div>${__.currency(item.amount, this.props.currency, 'auto')}</div>
          <div class="small">${__.unit(item.count, 'payments')}</div>
        `
      })
    }
    else {
      return buildTooltip({
        title: item.category,
        value: metric.formatter(item[metric.id])
      })
    }
  }

  render() {

    const { mode, metric } = this.state
    let { date, comparativeDate, data, comparativeData } = this.props

    data = data && aggregateBy((
      date ?
        completeDays(this.props.data, date) :
        this.props.data
      ),
      mode.aggregator
    )

    comparativeData = comparativeData && aggregateBy((
        comparativeDate ?
          completeDays(this.props.comparativeData, comparativeDate) :
          this.props.comparativeData
      ),
      mode.aggregator
    ).slice(0, data.length)

    if (!mode) {
      return <span>{__('dashboard.sections.stats.GraphModeError')}</span>
    }

    if (!metric) {
      return <span>{__('dashboard.sections.stats.GraphMetricError')}</span>
    }

    return (
      <div className="chart-container">
        <div className="chart-header">
          <div className="chart-title">
            <UncontrolledDropdown>
              <DropdownToggle className="btn-text" caret>
                { metric.title }
              </DropdownToggle>
              <DropdownMenu>
                { this.metrics.map(metric => (
                  <DropdownItem
                    key={metric.id}
                    onClick={() => this.setMetric(metric)}>
                    { metric.title }
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div className="chart-control">
            <ButtonGroup>
              { this.modes.map(mode => (
                <Button
                  key={mode.id}
                  onClick={() => this.setMode(mode)}
                  active={mode.id === this.state.mode.id}>
                  { mode.label || mode.id }
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </div>
        <div className="chart-content">
          <ComparativeLineChart
            data={data}
            comparativeData={comparativeData}
            metric={metric.id}
            formatter={value => __.number(value, 'short')}
            tooltip={({ index }) => this.tooltipFormatter({
              metric: metric,
              item: data[index],
              comparativeItem: comparativeData && comparativeData[index],
            })}
          />
        </div>
      </div>
    )
  }
}

export default SalesByDate
