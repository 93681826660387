import { combineReducers } from 'redux'
import { combineForms } from 'react-redux-form'

import { ACTIONS } from './constants'

const filtersForm = {
  descriptionSearch: '',
}

export default combineReducers({
  forms: combineForms({
    filters: filtersForm
  }, 'dashboard.sales.forms'),

  filters: (state = [], action) => {
    switch(action.type) {
      case ACTIONS.FILTER_SUBSIDIARIES:
        return ({ subsidiaries: [...action.subsidiaries] });
      default:
        return state;
    }
  },
})
