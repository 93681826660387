import React from 'react';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import __ from 'utils/i18n';

export const SuccessModal = ({ showModal = false, closeAction, count = 0 }) => {
  return (
    <DashboardModal
      name='confirmModal'
      modalClass='ecommerceOrders-modal'
      headerText={__('dashboard.sections.compraClick.modals.successTitle', {
        count: count,
      })}
      showModal={showModal}
      hideModal={closeAction}
      submitColor='success'
      hideSeparator={true}
      cancelText={__('actions.accept')}
    >
      <div className='ecommerceOrders-confirm-main'>
        <span className='ecommerceOrders-confirm-message'>
          {__('dashboard.sections.compraClick.modals.successSubTitle', {
            count: count,
          })}
        </span>
      </div>
    </DashboardModal>
  );
};
