
export const getWithholdingTax = ({ subsidiaryId, token }) => ({
  endpoint: 'withholdingTax',
  method: 'GET',
  params: {
    subsidiaryId: subsidiaryId,
  },
  headers: {
    "X-Authentication-Token": token,
  }
})
