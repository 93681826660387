import React from 'react'
import classNames from 'classnames'

import { Field } from 'react-form'
import { FormGroup, InputGroup } from 'reactstrap'

import { CustomField, TextInput } from 'components/forms/index'
import { InputGroupIconButton } from 'components/InputGroupIconButton'

class PasswordField extends React.Component {

  state = {
    passIsVisible: false
  }

  switchPassVisibility() {
    this.setState(prevState => (
      { passIsVisible: !prevState.passIsVisible }
    ));

    this.password.focus()
  }

  render() {
    return (
      <Field field={this.props.field}>
        {({ value, setValue, setTouched, error, touched }) => (
          <div>
            <FormGroup
              className={classNames({
                'has-error': touched && error,
              })}>
              <CustomField name="password">
                {props => (
                  <InputGroup className="default-input-group input-with-addon">
                    <TextInput
                      {...props}
                      name="password"
                      className="light-blue"
                      getRef={(input) => { this.password = input }}
                      type={this.state.passIsVisible ? 'text' : 'password'}
                      placeholder={this.props.placeholder}
                      value={value}
                      onBlur={() => setTouched()}
                    />
                    <InputGroupIconButton
                      icon={this.state.passIsVisible ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}
                      type="button"
                      className="addon"
                      onClick={() => this.switchPassVisibility()}
                    >
                    </InputGroupIconButton>
                  </InputGroup>
                )}
              </CustomField>
            </FormGroup>
            {this.props.requirements && (<div className="input-requirements"> {
              this.props.requirements.map(({ test, label }, index) => (
                <div
                  key={index}
                  className={classNames({
                    "input-requirement": true,
                    "valid": test(value)
                  })}>
                  {label}
                </div>
              ))
            }
            </div>
            )}
          </div>
        )}
      </Field>
    )
  }
}

export default PasswordField