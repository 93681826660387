const statsEndpoints = {
  sales: (subsidiaryId, token) => payload => ({
    endpoint: 'stats/sales',
    method: 'POST',
    headers: {
      "X-Authentication-Token": token,
    },
    params: {
      subsidiaryId: subsidiaryId
    },
    formData: {...payload, token}
  }),

  products: props => ({
    endpoint: 'stats/topProducts',
    method: 'POST',
    headers: {
      "X-Authentication-Token": props.token,
    },
    params: {
      subsidiaryId: props.subsidiaryId
    },
    formData: {
     ...props
    },
    
    parseResponse: response => {
      return {
        ...response.data,
        byAmount: response.data.byAmount.map(item => ({
          ...item,
          count: parseInt(item.count)
        })),
        byCount: response.data.byCount.map(item => ({
          ...item,
          count: parseInt(item.count)
        }))
      }
    }
  }),

  staff: props => ({
    endpoint: 'stats/topUsers',
    method: 'POST',
    headers: {
      "X-Authentication-Token": props.token,
    },
    params: {
      subsidiaryId: props.subsidiaryId
    },
    formData: {
      ...props
    },
    parseResponse: response => {
      return {
        ...response.data,
        byAmount: response.data.byAmount.map(item => ({
          ...item,
          count: parseInt(item.count)
        })),
        byCount: response.data.byCount.map(item => ({
          ...item,
          count: parseInt(item.count)
        }))
      }
    }
  }),

  customers: props => ({
    endpoint: 'stats/topCustomers',
    method: 'POST',
    headers: {
      "X-Authentication-Token": props.token,
    },
    params: {
      subsidiaryId: props.subsidiaryId
    },
    formData: {
      ...props
    },
    parseResponse: response => {
      return {
        ...response.data,
        byAmount: response.data.byAmount.map(item => ({
          ...item,
          count: parseInt(item.count)
        })),
        byCount: response.data.byCount.map(item => ({
          ...item,
          count: parseInt(item.count)
        }))
      }
    }
  }),

  compra_click: props => ({
    endpoint: 'stats/topButtons',
    method: 'POST',
    headers: {
      "X-Authentication-Token": props.token,
    },
    params: {
      subsidiaryId: props.subsidiaryId
    },
    formData: {
      ...props
    },
    parseResponse: response => {
      return {
        ...response.data,
        byAmount: response.data.byAmount.map(item => ({
          ...item,
          count: parseInt(item.count)
        })),
        byCount: response.data.byCount.map(item => ({
          ...item,
          count: parseInt(item.count)
        }))
      }
    }
  })
}

export default statsEndpoints;
