import React from "react";
import { useState } from "react";
import { SimpleForm } from "components/forms";
import { TextField } from "components/forms/fields";
import { Button } from "reactstrap";
import i18n from "utils/i18n";

import "../../../../../../../../styles/newStyles/MerchantCredentials.scss";
import gtmEvents from "utils/gtmEvents";

export const NameStep = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);

  
  const handleChange = (credentialNameInputValue) => {
    if (credentialNameInputValue.length > 2) {
      props.setCredentialName(credentialNameInputValue);
      setIsDisabled(false);
    } else {
      props.setCredentialName(null);
      setIsDisabled(true);
    }
  };
  
  const handleSubmit = () => {
    gtmEvents.credentialsPageView('createsCredentials');
    props.navigation.go(1);
  };

  return (
    <div className="credentials-modal-body-steps">
      <SimpleForm onSubmit={handleSubmit}>
        <>
          <>
            <div className="credentials-modal-login-step">
              <h3 className="credentials-modal-title login-step-title">
                {i18n("dashboard.sections.merchantCredentials.step.name.title")}
              </h3>
              <label
                htmlFor={"password"}
                className="credentials-modal-label login-step-label"
              >
                {i18n(
                  "dashboard.sections.merchantCredentials.step.name.subtitle"
                )}
              </label>
              <TextField
                type="text"
                className="inner-content-form-textfield"
                name="credentials-name"
                maxLength={50}
                onChange={handleChange}
              />
            </div>
            <div className="credentials-modal-button-container">
              <Button
                className="btn btn-primary credentials-modal-step-button credentials-modal-step-button-btn-login mx-auto"
                color="primary"
                type="submit"
                disabled={isDisabled}
              >
                {i18n(
                  "dashboard.sections.merchantCredentials.step.name.button"
                )}
              </Button>
            </div>
          </>
        </>
      </SimpleForm>
    </div>
  );
};
