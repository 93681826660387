import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import __ from 'utils/i18n';
import { DropdownItem } from 'reactstrap'
import { PermissionsContext } from 'contexts/permissionsContext';

export const Subsidiaries = ({ onClick }) => {
  const subsidiaries = useSelector((state) => state.login?.user?.subsidiaries);
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const isMultisubsidiaryEnabled = featureFlags?.includes("multisubsidiary") && userPermissions?.includes("multisubsidiary");

  return (
    <>
      { isMultisubsidiaryEnabled && subsidiaries &&
        <DropdownItem className="nav-menu-item" onClick={onClick}>
          <i className="mdi mdi-18px mdi-bank" /><span>{__('dashboard.components.changeSubsidiary')}</span>
        </DropdownItem>
      }
    </>
  );
}
