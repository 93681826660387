import React from 'react';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import { APIRequest } from 'api-provider';
import { deleteUser } from '../../_shared/resources';
import __ from 'utils/i18n';

export const DeleteModal = ({ id, token, deleteSuccess, showModal, hideModal }) => {
  return (
    <APIRequest resource={deleteUser(id, token)} onSuccess={deleteSuccess}>
      {(request) => (
        <DashboardModal
          name='deleteProductModal'
          headerText={__('dashboard.sections.merchantUsers.headerText')}
          showModal={showModal}
          hideModal={() => hideModal(false)}
          submitAction={() => request.fetch({ id })}
          submitDisabled={request.isFetching}
          submitColor='danger'
          submitText={__('actions.delete')}
          cancelText={__('actions.cancel')}
        >
          {__('dashboard.sections.merchantUsers.confirmDeleteUser')}
        </DashboardModal>
      )}
    </APIRequest>
  );
};
