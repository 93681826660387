import React, { useState } from 'react';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';
import BigRadioInput from 'components/forms/inputs/BigRadio';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import __ from "utils/i18n";
import { editUser } from 'containers/Login/actions';
import {persistStore} from 'redux-persist';
import {applyMiddleware, compose, createStore} from 'redux';
import rootReducer from 'reducers';
import thunk from "redux-thunk";
import {createLogger} from "redux-logger";

const SubsidiariesModal = ({isOpen, toggle}) => {
  const subsidiariesStore = useSelector((state) => state.login.user.subsidiaries) || [];
  const { subsidiaryId } = useSelector((state) => state.login.user);
  const [subsidiaries, setSubsidiaries] = useState(subsidiariesStore.map((item) => {
    return(
      {
        subsidiaryId: item.subsidiaryId,
        subsidiaryName: item.subsidiaryName,
        checked : item.subsidiaryId === subsidiaryId
      }
    );
  }));
  const [currentSubsidiary, setCurrentSubsidiary] = useState(getSelectedSubsidiary());
  const { user } = useSelector((state) => state.login);

  function getSelectedSubsidiary() {
    // en el caso de que existan cambios de sucursales.
    const subsidiary = subsidiaries.find( s => s.checked );
    if (subsidiary) return subsidiary;
    // en el caso de solo haya una sucursal
    return subsidiariesStore[0];
  }

  const setSubsidiaryId = () => {
    if (user.subsidiaryId === currentSubsidiary.subsidiaryId) {
      toggle(false);
    } else {
      user.subsidiaryId = currentSubsidiary.subsidiaryId;
      user.subsidiaryName = currentSubsidiary.subsidiaryName;

      const store = createStore(
        rootReducer,
        undefined,
        compose(
          applyMiddleware(thunk, createLogger()),
        )
      );
      persistStore(store, null, () => {
        store.dispatch(editUser(user));
        setTimeout(() => window.location.reload(), 500);
      });
    }
  };


  const selectedSubsidiary = (currentIndex) => {
    const subsidiary = {...subsidiaries[currentIndex]};
    const newSubsidiaries = subsidiaries.map((item, index)=>{
      item.checked = index === currentIndex;
      return item;
    });
    setSubsidiaries([...newSubsidiaries]);
    setCurrentSubsidiary(subsidiary);
  };


  return(
    <DashboardModal
      name="deleteProductModal"
      headerText={__('dashboard.components.changeSubsidiary')}
      showModal={isOpen}
      showFooter={true}
      hideSeparator={false}
      hideModal={() => toggle(false)}
      submitAction={() =>{ setSubsidiaryId() }}
      submitColor="danger"
      submitText={__('actions.apply')}
      cancelText={__('actions.cancel')}>

      {subsidiaries.map((item, index)=>
          <Row key={index}>
            <Col md="12">
              <BigRadioInput
                value={item.checked}
                onChange={() =>{ selectedSubsidiary(index) }}
                title={item.subsidiaryName}
              />
            </Col>
          </Row>
      )}
    </DashboardModal>
  )
};

export default SubsidiariesModal;
