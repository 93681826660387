import { merge } from 'lodash';
import es from './es.json';
import en from './en.json';

import variantEs from './variant_es.json'
import variantEn from './variant_en.json'


const locales = merge(es, variantEs);
const localen = merge(en, variantEn);

const translations = {
  es: {
    translations: locales
  },
  en: {
    translations: localen
  }
}

export default translations;
