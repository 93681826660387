import React, { useEffect } from "react";
import Loader from "components/Loader";
import gtmEvents from "utils/gtmEvents";

function LoaderContainer(props){
  useEffect(()=>{
    gtmEvents.credentialsPageView('generatingCredentials');
  },[])
  return (
    <div className="credentials-modal-loader-step">
      <Loader absolute={false} />
      <h5 className="credentials-modal-title">
        {props.loadingTexts.title}
      </h5>
      <p className="credentials-modal-subtitle">
        {props.loadingTexts.subTitle}
      </p>
    </div> 
  );
}
  
export default LoaderContainer;

