import React, { useState, useEffect } from 'react';
import __ from 'utils/i18n';
import { ActionButton } from '../';
import Loader from 'components/Loader';
import FlexTable from 'components/FlexTable';
import { getPaymentButtonColumns } from './columns';

export const Table = ({
  isFetching,
  error,
  setDataRow,
  selectRow,
  rows,
  setShowShareModal,
  setShowDeleteModal,
  handleCheckboxChange,
  checkboxList,
  checkboxAll,
  expirationDateFormat,
  countryTimezone,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(rows);
  }, [rows, checkboxList]);

  const getActions = () => row => {
    return (
      <span className='pull-content'>
        <button
          className='payment-button-actions btn-secondary'
          onClick={() => setShowShareModal(true)}
        >
          <i className='mdi mdi-share' />
        </button>

        <ActionButton
          index={row.index}
          show={setShowShareModal}
          clone={selectRow}
          delete={setShowDeleteModal}
        />
      </span>
    );
  };

  return (
    <div className='dashboard-card-body'>
      {(!data.length && isFetching) && <Loader />}

      {error && (
        <div className='dashboard-card-message'>
          <span>{error}</span>
        </div>
      )}

      {(!data.length && !isFetching) && (
        <div className='dashboard-card-message'>
          <i className='mdi mdi-minus-circle' />
          <h3>{__('dashboard.sections.compraClick.noCompraClicksTitle')}</h3>
          <p>{__('dashboard.sections.compraClick.noCompraClicksMessage')}</p>
        </div>
      )}
      
      {(!!data.length && !isFetching) && (
        <FlexTable
          data={data}
          columns={getPaymentButtonColumns(
            setShowShareModal,
            handleCheckboxChange,
            checkboxList,
            countryTimezone,
            expirationDateFormat,
            checkboxAll,
            getActions,
            selectRow,
            setShowDeleteModal
          )}
          cellHeight={48}
          onRowClick={({ index }) => selectRow(index, 'update')}
          onClick={(index) => setDataRow(data[index])}
        />
      )}
    </div>
  );
};
