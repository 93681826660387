import React from 'react';
import { Alert, Col, Row, Table } from 'reactstrap';
import { ImportResults } from './ImportResults';
import __ from 'utils/i18n';

export const UploadResults = ({
  uploadOk,
  results,
  token,
  subsidiaryId,
}) => {
  return (
    <div>
      {uploadOk && (
        <ImportResults
          data={results}
          subsidiaryId={subsidiaryId}
          token={token}
        />
      )}
      {!uploadOk && results && Array.isArray(results) ? (
        <Row>
          <Col md='12'>
            <Alert color='warning'>
              <i className='mdi mdi-alert' />
              {__('dashboard.sections.compraClick.import.upload.error')}
            </Alert>
            <Table>
              <thead>
                <tr>
                  <th>
                    {__(
                      'dashboard.sections.compraClick.import.upload.table.line'
                    )}
                  </th>
                  <th>
                    {__(
                      'dashboard.sections.compraClick.import.upload.table.errors'
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {results.map((error, key) => (
                  <tr>
                    <td>{error.row}</td>
                    <td>{error.message}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md='12'>
            <Alert color='warning'>
              <i className='mdi mdi-alert' />
              {__('dashboard.sections.compraClick.import.error')}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};
