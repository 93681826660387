
import React from 'react'
import { Input } from 'reactstrap'

const CheckboxInput = (props) => (
  <Input
    type="checkbox"
    disabled={props.disabled}
    readOnly={props.readOnly}
    checked={props.value || false}
    onChange={e => props.onChange && props.onChange(e.target.checked)}
    onBlur={props.onBlur}
    onFocus={props.onFocus}
  />
)

export default CheckboxInput
