import React from 'react'
import { Input } from 'reactstrap'

const NumberInput = ({ parser, value, onChange, field, className, ...rest }) => (
	<Input
		{...rest}
		type="number"
		max={99}
		className={className}
		value={(parser && parser(value)) || value || ''}
		onChange={event => onChange && onChange(event.target.value)}
	/>
)

export default NumberInput
