export const getStaff = (subsidiaryId, token) => (payload) => ({
  endpoint: 'users/getSubUsers.json',
  method: 'POST',
  headers: {
    "X-Authentication-Token": token,
  },
  params: {
    subsidiaryId: subsidiaryId,
  },
  cache: true,
  formData: payload,
  parseResponse: response => (response.data.data)
})
