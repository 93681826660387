import React from 'react'
import { Button } from 'reactstrap';
import __ from "utils/i18n";

export const Header = ({ isFormEnabled, values, storeState, isEditStoreEnabled, request, setShowModal }) => {

    return (
        <>
            <div className="ecommerceForm-information-container">
                <div>
                    <span className="ecommerceForm-main-information">
                        {__("store.panel.information")}
                    </span>
                    <a
                        href="#/"
                        onClick={() => setShowModal("infoModal")}
                        className="ecommerceForm-information-link"
                    >
                        {__("store.panel.informationLink")}
                    </a>
                </div>
                <Button
                    color="success"
                    type="submit"
                    className="ecommerceForm-saveButton"
                    disabled={
                        isEditStoreEnabled && (request.isFetching ||
                            !(storeState || isFormEnabled(values.isEnabled)))
                    }
                >
                    {__("actions.save")}
                </Button>
            </div>
        </>
    )
}