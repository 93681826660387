import React from "react";
import '../../../../../../styles/newStyles/navPhoneHelp.scss'
import __ from 'utils/i18n'
import ventaExpressLogo from '../../../../../../assets/venta_express.svg'

export const NavHelpPhone = () => {

  return(
    <div className="nav nav-help-phone navbar-right d-none d-md-flex flex-row align-items-center">
      <div className="logo d-none d-xl-inline-block">
        <img src={ventaExpressLogo} alt="Logo Venta Express" />
      </div>
      <div className="phone">
        <span>{__('dashboard.navbar.phone.label')}</span>
        <a href={`tel:+${__('dashboard.navbar.phone.value').replace(/\s/g, "")}`}>{__('dashboard.navbar.phone.value')}</a>
      </div>
    </div>
  );
}
