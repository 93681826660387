const getPaymentCard = (original) => {
  const paymentMethod = original.payment.paymentMethodType;

  if (paymentMethod === 'CREDIT' || paymentMethod === 'DEBIT') {
    return original.payment.name;
  }

  return '';
};

export default getPaymentCard;
