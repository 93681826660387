
import React from 'react'
import PropTypes from 'prop-types'
import { isFunction, get } from 'lodash'

const accessData = (data, key) => (
  isFunction(key) ? key(data) : get(data, key)
)

export class TableChart extends React.Component {

  static propTypes = {
    data: PropTypes.array,
    columns: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.func,
      width: PropTypes.number
    }))
  }

  renderRow(columns, getValue, key) {
    return (
      <div className="chart-table-row" key={key}>
        { columns.map((col, index) => (
          <div
            className="chart-table-cell"
            style={{ width: col.width }}>
            { getValue(col) }
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { data, columns } = this.props
    return (
      <div className="chart-table">
        { this.renderRow(columns, col => col.title, "header")}
        { data.map((item, index) => (
          this.renderRow(columns, col => accessData(item, col.value), index)
        ))}
      </div>
    )
  }
}