import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { getConfig } from 'config';
import __ from 'utils/i18n';

function getBankAccountIdentifierType(data = {}) {

  if(!isUndefined(data.type)) {

    return data.type.toUpperCase();
  }

  const defaultType = getConfig('customConfig.bankAccountIdentifierKeyName');
  const defaultIdentifierName = __('dashboard.components.modal.bankAccountIdentifier');
  const alternativeIdentifierName = __('dashboard.components.modal.bankAccountAlternativeIdentifier');

  return (!isUndefined(data[defaultType])) ? defaultIdentifierName : alternativeIdentifierName;
}

function removeUndefinedKeys(obj) {
  return omitBy(obj, isUndefined);
}

export {
  getBankAccountIdentifierType,
  removeUndefinedKeys,
}
