import React from 'react';
import { get } from 'lodash';
import { Badge, Table } from 'reactstrap';
import __ from 'utils/i18n';

const getStatusBadge = (value) => {
  const states = {
    PENDING: { color: 'secondary', label: __('states.pending') },
    SUCCESS: { color: 'info', label: __('states.paid') },
    REJECTED: { color: 'danger', label: __('states.fail') },
    TRANSFERRING: { color: 'success', label: __('states.transferring') },
  };

  if (!value) {
    return <span className='pull-right'>{__('states.withoutState')}</span>;
  }

  const status = value.toUpperCase();
  const state = get(states, status, {});
  const color = state.color || null;
  const label = state.label || null;

  return (
    <span className='pull-right'>
      <Badge color={color}>{label}</Badge>
    </span>
  );
};

export const DepositInformation = ({ data }) => {
  const { availableDate, transactionCount, state } = data;

  return (
    <Table className='no-border'>
      <tbody>
        <tr>
          <td>
            <i className='mdi mdi-calendar' />
            <span>{__('dashboard.sections.deposits.availableDate')} </span>
            {__.stringToDate(availableDate, 'LL')}
          </td>
        </tr>
        <tr>
          <td>
            <i className='mdi mdi-file-document-box' />
            <span>
              {__('dashboard.sections.deposits.totalTransactions')}: 
              {transactionCount}
            </span>
          </td>
        </tr>
        <tr>
          <td>{getStatusBadge(state)}</td>
        </tr>
      </tbody>
    </Table>
  );
};
