import classNames from "classnames";
import Dropzone from "react-dropzone";
import __ from "utils/i18n";
import React from "react";

const CropImage = ({ url, acceptImage, maxSize, errorMessageAlert }) => {
  const dropZoneClasses = classNames({
    dropzone: true,
    dropzoneHasPreview: !!url,
  });

  return (
    <div className="dropzone-container">
      <Dropzone
        className={dropZoneClasses}
        activeClassName="dropzoneActive"
        rejectClassName="dropzoneReject"
        accept="image/jpeg, image/png"
        maxSize={maxSize}
        onDrop={(accepted, rejected) => {
          accepted.length &&
            acceptImage(accepted[0], URL.createObjectURL(accepted[0]));
          rejected.length && alert(__(errorMessageAlert));
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <div className="dropzone">
              <input {...getInputProps()} />

              {url ? (
                <img
                  className="img-cover"
                  alt="Product"
                  src={url}
                  height="100px"
                  width="100px"
                />
              ) : (
                <i className="mdi mdi-image" />
              )}

            </div>
            <span className="edit-icon">
              <i className="mdi mdi-light mdi-pencil" />
            </span>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

CropImage.defaultProps = {
  maxSize: 1e6,
};

export default CropImage;
