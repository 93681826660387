import React, { useState } from 'react';
import { CredentialItem } from './CredentialItem';
import DeleteWorkFlowsModal from '../modal/workflows/DeleteWorkflowsModal';
import RegenerateWorkFlowsModal from '../modal/workflows/RegenerateWorkflowsModal';

export const CredentialList = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [clientId, setClientId] = useState(null);
  
  return (
      <div className="credentials-list-content">
        {props.items.map((element, index) => (<CredentialItem
          setClientId={setClientId}
          clientId={clientId}
          showRegenerateModal={showRegenerateModal}
          setShowRegenerateModal={setShowRegenerateModal}
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          item={element} 
          index={index}
          key={index} />))}
        <DeleteWorkFlowsModal 
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          clientId={clientId}
        />
        <RegenerateWorkFlowsModal
          isOpen={showRegenerateModal}
          setIsOpen={setShowRegenerateModal}
          clientId={clientId}
        />
      </div>
  )
}



