
import React, { useState, useEffect } from "react";
import __ from 'utils/i18n';
import { APIRequest } from 'api-provider';
import { DashboardModal } from 'containers/Dashboard/components/DashboardModal';

export const DeleteModal = ({ clientId, deleteSuccess, showModal, hideModal, token }) => {
  const [deleteError, setDeleteError] = useState(undefined);

  useEffect(() => {
    setDeleteError(undefined);
  }, [clientId]);

  const resource = {
    endpoint: 'buyers/deleteBuyer.json',
    method: 'POST',
    headers: {
      "X-Authentication-Token": token,
    },
    formData: {
      customerId: clientId,
      buyerId: clientId,
      channel: 'web',
      deviceId: 'web',
      deviceModel: 'web',
      deviceOS: 'web',
    }
  };

  return (
    <APIRequest
      resource={resource}
      onSuccess={deleteSuccess}>
      {request => (
        <DashboardModal
          name="deleteProductModal"
          headerText={__('dashboard.sections.clients.modal.headerText')}
          showModal={showModal}
          hideModal={() => { hideModal(false) }}
          submitAction={() => request.fetch({ clientId, token }).catch(e => e && setDeleteError(e.message || e))}
          submitDisabled={!!deleteError || request.isFetching}
          submitColor="danger"
          submitText={__('actions.delete')}
          cancelText={__('actions.cancel')}>
          {__('dashboard.sections.clients.modal.confirmDeleteClient')}

          {deleteError && <div className="request-error">{deleteError}</div>}
        </DashboardModal>
      )}
    </APIRequest>
  )
}