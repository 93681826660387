import React from 'react';
import numeral from 'numeral';
import __ from 'utils/i18n';

const ProductName = ({ original }) => {
  const name = original.productName ? original.productName : '-';
  const image = original.productImageUrl ? original.productImageUrl : null;
  const imageClasses = {
    backgroundColor: original.backgroundColor
      ? `#${original.backgroundColor}`
      : '',
  };

  return (
    <div className='catalog__product--name'>
      <span
        style={original.productImageUrl ? {} : imageClasses}
        className='catalog__product--image'
      >
        {original.productImageUrl ? (
          <img
            className={`img-cover ${imageClasses}`}
            src={image}
            alt='Product'
            height='45px'
            width='45px'
          />
        ) : (
          <span>{original.productName.charAt(0)}</span>
        )}
      </span>
      <span>{name}</span>
    </div>
  );
};

const ProductCategory = ({ original }) => {
  const category = original.categoryName;
  return category ? category : '-';
};

const ProductAvailableQuantity = ({ original }) => {
  const availableQuantity = original.stockAvailableQuantity;

  if (original.stockControl && parseInt(original.stockControl) === 1) {
    return availableQuantity ? numeral(availableQuantity).format('0,0') : '0';
  }

  return availableQuantity ? availableQuantity : '';
};

const TaxesCell = ({ original }) => {
  const productData = original;
  const { taxes } = productData;
  let taxesPercentageSum = (taxes && taxes.length > 0) ? taxes.reduce((total, tax) => Number(total + tax.amount), 0) : undefined;

  if (!taxesPercentageSum) {
    return '-';
  }

  return __.number(taxesPercentageSum * 100, 'decimal') + '%';
};

const ProductTotalAmountCell = ({ original }) => {
  const total = original.productPrice;
  return total ? __.currency(total, original.currencyCode) : '-';
};

const ProductTotalGrossAmountCell = (data) => {
  const productData = data.original;
  const { taxes, productPrice, currencyCode } = productData;
  let taxesPercentageSum = taxes
    ? taxes.reduce((total, tax) => Number(total + tax.value), 0)
    : 0;
  const total = __.number(productPrice * (1 + taxesPercentageSum), 'decimal');

  return total ? __.currency(total, currencyCode) : '-';
};

export const getProductsColumns = (configColumns) => {
  const productsColumns = [];

  let defaultColumns = {
    name: {
      Header: __('dashboard.sections.catalog.products.name'),
      width: 250,
      Cell: ProductName,
    },
    category: {
      Header: __('dashboard.sections.catalog.products.category'),
      width: 150,
      maxWidth: 300,
      Cell: ProductCategory,
    },
    availableQuantity: {
      Header: __('dashboard.sections.catalog.products.availableQuantity'),
      width: 150,
      maxWidth: 300,
      Cell: ProductAvailableQuantity,
    },
    taxesCell: {
      Header: __('dashboard.sections.catalog.products.percentage'),
      width: 50,
      maxWidth: 200,
      align: 'right',
      Cell: TaxesCell
    },
    totalAmount: {
      Header: __('dashboard.sections.catalog.products.totalAmount'),
      width: 50,
      maxWidth: 200,
      align: 'right',
      Cell: ProductTotalAmountCell,
    },
    totalGrossAmount: {
      Header: __('dashboard.sections.catalog.products.totalAmount'),
      width: 50,
      maxWidth: 200,
      align: 'right',
      Cell: ProductTotalGrossAmountCell,
    },
  };

  configColumns.forEach((item) => productsColumns.push(defaultColumns[item]));

  return productsColumns;
};

const CategoryName = ({ original }) => {
  const name = original.categoryName;
  const folderClass = original.hasChildren
    ? 'mdi-folder'
    : 'mdi-folder-outline';
  const separators = original.deep.map((value, index) => {
    return <span key={index} className='tree-separator' />;
  });

  return (
    <span>
      {separators}
      <i className={`tree-category-folder mdi ${folderClass}`} />
      {name}
    </span>
  );
};

const CategoryQuantity = ({ original }) => {
  const quantity = original.productsQuantity;
  return quantity || '-';
};

export const getCategoryColumns = (configCatColumns) => {
  const categoryColumns = [];
  
  let defaultCatColumns = {
    name: {
      Header: __('dashboard.sections.catalog.categories.table.header.name'),
      width: 250,
      align:'left',
      Cell: CategoryName,
    },
    items: {
      Header: __('dashboard.sections.catalog.categories.table.header.items'),
      width: 50,
      maxWidth: 100,
      align: 'right',
      Cell: CategoryQuantity,
    },
  };

  configCatColumns.forEach((item)=> categoryColumns.push(defaultCatColumns[item]));

  return categoryColumns;
};
