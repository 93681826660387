
import React from 'react'

import { aggregateBy } from 'utils'

import { buildTooltip } from 'components/charts'
import { ComparativeLineChart } from 'components/charts'
import { formatComparison } from 'components/charts/ComparativeLineChart'
import { getSalesChartColor } from 'utils';
import __ from 'utils/i18n'

export class AggregatedSales extends React.Component {

  tooltipFormatter = ({ item, comparativeItem }) => {

    const { currency } = this.props

    let title = item.title || item.category
    let comparativeTitle = comparativeItem && (comparativeItem.title || comparativeItem.category)
    if (comparativeTitle && (comparativeTitle !== title)) {
      title += `<b> vs. </b>${comparativeTitle}`
    }

    if (comparativeItem) {
      return buildTooltip({
        title,
        value: `
          <div>${__.currency(item.amount, currency, 'auto')}</div>
          <div class="secondary">
            ${__.currency(comparativeItem.amount, currency, 'auto')}
          </div>
          <div class="comparison">
            ${formatComparison(item.amount, comparativeItem.amount)}
          </div>
        `
      })
    }
    else {
      return buildTooltip({
        title,
        value: `
          <div>${__.currency(item.amount, currency, 'auto')}</div>
          <div class="small">${__.unit(item.count, 'payments')}</div>
        `
      })
    }
  }

  render() {

    const { title, aggregator, noData, type } = this.props

    if ((!this.props.data || !this.props.data.length) && noData) {
      return <React.Fragment>{noData}</React.Fragment>
    }

    const data = aggregateBy(this.props.data, aggregator)
    const comparativeData = aggregateBy(this.props.comparativeData, aggregator).slice(0, data.length)

    return (
      <div className="chart-container">
        {title && (
          <div className="chart-header">
            <div className="chart-title">
              {title}
            </div>
          </div>
        )}
        <div className="chart-content">
          <ComparativeLineChart
            type={type || 'column'}
            data={data}
            comparativeData={comparativeData}
            metric="amount"
            formatter={value => __.number(value, 'short')}
            chartConfig={{
              "tooltip.shared": false,
              "chart.height": 250,
              "colors": getSalesChartColor(),
            }}
            tooltip={({ index }) => {
              return this.tooltipFormatter({
                metric: "amount",
                item: data[index],
                comparativeItem: comparativeData && comparativeData[index],
              })
            }}
          />
        </div>
      </div>
    )
  }
}

export default AggregatedSales
