import React from "react";
import { Input } from "reactstrap";

const TextInput = ({ parser, value, onChange, field, className, ...rest }) => (
  <Input
    {...rest}
    className={className}
    value={(parser && parser(value)) || value || ""}
    onChange={(event) => onChange && onChange(event.target.value)}
  />
);

export default TextInput;
