
import React from 'react'
import __ from 'utils/i18n'
import { Badge } from 'reactstrap'

const StateBadge = ({ state }) => {
  return ({
    "approved": <Badge color="info">{__('states.approved')}</Badge>,
    "disapproved": <Badge color="danger">{__('states.disapproved')}</Badge>,
    "pending": <Badge color="default">{__('states.pending')}</Badge>,
    "enabled": <Badge color="success">{__('states.enabled')}</Badge>,
    "disabled": <Badge color="danger">{__('states.disabled')}</Badge>,
  })[state]
}

export default StateBadge