import { getConfig } from "config";
import isCBUFromOrigin from "utils/isCBUFromOrigin";

export const useAutomaticCasshout = (CBUBankAddress, accountType) => {
  const bankEntity = getConfig("customConfig.bankEntity");

  const isAutomaticCashoutEnabled = getConfig(
    "customConfig.automaticCashout.isEnabled"
  );

  const mustHaveCBUFromOrigin = getConfig(
    "customConfig.automaticCashout.mustHaveCBUFromOrigin"
  );
  const isEnabledAccountType = getConfig(
    "customConfig.automaticCashout.enabledAccountTypes"
  )
    ? /*if key enabledAccountTypes is null or empty array all accountTypes are valid */
      getConfig("customConfig.automaticCashout.enabledAccountTypes").length ===
        0 ||
      getConfig("customConfig.automaticCashout.enabledAccountTypes").includes(
        accountType
      )
    : true;

  const showAutomaticCashout =
    isAutomaticCashoutEnabled &&
    (mustHaveCBUFromOrigin
      ? isCBUFromOrigin(CBUBankAddress, bankEntity)
      : true) &&
    isEnabledAccountType;

  return {
    showAutomaticCashout,
  };
};
