import React from "react";
import { useSelector } from "react-redux";
import { Discovery } from "components/Discovery";
import __ from "utils/i18n";

export const PaymentDiscovery = () => {
  const { paymentLink: showPaymentDiscovery = false } = useSelector(
    (state) => state.dashboard.discovery
  );
  const steps = [
    {
      title: __("discovery.paymentLink.title"),
      content: __("discovery.paymentLink.message"),
      showCloseButton: false,
      disableBeacon: true,
      placement: "bottom",
      target: ".payment-link",
    },
  ];
  // TODO eliminar showDiscovery y su implementación
  const showDiscovery = localStorage.getItem('showDiscovery')
  return (
    <>
    {showDiscovery &&
      <Discovery run={showPaymentDiscovery} steps={steps} discoveryId="paymentLink" />
    }
    </>
  );
};
