import React, { useContext } from "react";
import { TransparentButton } from "components/forms/fields";
import { getConfig } from "config";
import __ from "utils/i18n";
import { PermissionsContext } from "contexts/permissionsContext";
import { Row, Col } from 'reactstrap';
import AccountDetailsFull from "./AccountDetailsFull";
import AccountDetailsDefault from "./AccountDetailsDefault";

const AccountDetails = (props) => {

  const { data, toggleForm } = props;

  const { userPermissions } = useContext(PermissionsContext);
  const isEditBankAccountEnabled = userPermissions?.includes('bank_account_modal_action_edit');

  const isBankAccountSelectFormActive = getConfig('customConfig.showBankAccountSelectForm');
  
  return (
    <div className="bank-account-modal-details">
      <Row className={"bank-account-detail-wrapper"}>
        <Col lg="9" md="12" className={'bank-account-row-detail'}>
          { isBankAccountSelectFormActive ? <AccountDetailsFull data={data} /> : <AccountDetailsDefault data={data}/> }
        </Col>
        <Col lg="3" md="12">
          {isEditBankAccountEnabled && (
            <div className="bank-account-modal-button">
              <TransparentButton onClick={toggleForm}>
                {__("dashboard.components.modal.changeBankAccount")}
              </TransparentButton>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AccountDetails;
