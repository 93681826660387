import React from 'react';

import { MaskedTextField } from 'components/forms/fields'
import { SimpleForm } from 'components/forms'

export const InputSearch = ({ setFilters }) => {

  const handleOrderSearchInput = (event) => {
      const order_number = event;
      const numericOrderNumber = order_number.replace(/[^0-9]/g, '');
      if (numericOrderNumber.length >= 9) {
        setFilters({ type: 'order_number', payload: { order_number } });
      } else if (!order_number) {
        setFilters({ type: 'remove', payload: { keys: ['order_number'] } });
      }
  }

  return (
    <div className="ecommerceOrders-inputSearch">
      <SimpleForm>
        <MaskedTextField
          field="searchInput"
          placeholder="0-00000000-00"
          mask={[/\d/, '-', /\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/, '-', /\d/,/\d/]}
          onChange={handleOrderSearchInput}
        />
      </SimpleForm>
    </div>
  )
}
