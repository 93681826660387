import React from 'react'
import { Radio } from 'react-form';

const RadioInput = ({ label, value, ...rest }) => (
  <React.Fragment >
    <label className="radio-input" htmlFor={value}>
      <Radio
        id={value}
        value={value}
        {...rest}
      />
      <span className="radio-btn" />
      <span className="radio-label">{label}</span>
    </label>
  </React.Fragment>
)

export default RadioInput;
