import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { DateRangeInput } from "components/forms/inputs";
import { Checkbox } from 'components/informed';
import { getConfig } from 'config';
import moment from "moment";
import "../../styles/newStyles/Button.scss";
import __ from "utils/i18n";
import useFields from "./fields/useFields";

export const FiltersForm = (props) => {
  const [filters, setFilters] = useState({})
  const { startDate, endDate, showReverted, onChange, children, showAmount, showPaymentChannel } = props;

  const { fields, renderFields } = useFields({ showAmount, showPaymentChannel });

  useEffect(() => {
    if (fields) {
      const newFilters = {
        ...filters,
        ...fields
      }
      const filteredFilters = Object.fromEntries(
        Object.entries(newFilters)
          .filter(([key, value]) => value !== null)
      );
      applyFilters(filteredFilters);
    }
    // eslint-disable-next-line
  }, [fields])


  const applyFilters = (newFilters) => {
    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
      onChange(newFilters);
    }
  };

  const setDateRange = ({ startDate, endDate }) => {
    applyFilters({
      ...filters,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });
  };

  const handleShowReverted = (showReverted) => {
    if (showReverted) {
      applyFilters({
        ...filters,
        showReverted
      });
    } else {
      delete filters.showReverted;
    }
  };

  const dateRangeInputValue = {
    startDate:
      startDate || moment().subtract(1, "month").format("YYYY-MM-DD"),
    endDate: endDate || moment().format("YYYY-MM-DD"),
  };

  const isShowReversedFilter = getConfig('customConfig.sales.showReversedFilter');
  const actualPath = window.location.pathname;
  const isSalesSection = actualPath.search('sales/sales') > 0;

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between flex-wrap mb-1">
        <div>
          <p className="filters-title">
            {__("dashboard.components.filterForm.title")}
          </p>
        </div>
        <div className="d-flex">{children}</div>
      </div>
      <div className="d-flex flex-wrap flex-row align-items-center mb-2">
        <DateRangeInput
          past
          value={dateRangeInputValue}
          onInit={(value) => setDateRange(value)}
          onChange={setDateRange}
        />
        {renderFields()}
      </div>
      {isShowReversedFilter && isSalesSection && (
        <div className="mt-4">
          <Checkbox
            label={__('dashboard.sections.sales.showReversed')}
            fieldState={{ value: showReverted }}
            onChange={(value) => handleShowReverted(value)}
          />
        </div>
      )}
    </div>
  );
}


export default FiltersForm;
