import React, { useState } from 'react';
import Toggle from 'react-toggle';

export const SwitchInput = ({ label, value, onChange, ...rest }) => {
  const [checked, setChecked] = useState(value || false);

  return (
    <div className="switch-input">
      <span>{label}</span>
      <Toggle
        {...rest}
        checked={checked}
        onChange={e => {
          onChange && onChange(e.target.checked)
          setChecked(e.target.checked)
        }} />
    </div>
  );
}