export const getFields = (currencies) => {
  return ([
    {
      name: 'description',
    },
    {
      type: 'select',
      name: 'language',
      placeholder: 'Idioma',
      options: [
        {
          value: 'es',
          label: 'Español',
          code: 'es',
        },
        {
          value: 'en',
          label: 'Ingles',
          code: 'en',
        },
      ],
    },
    {
      type: 'select',
      name: 'currencyCodes',
      placeholder: 'Moneda',
      options: [
        ...(currencies.map((currency) => {
          return {
            value: currency.code,
            label: currency.es,
            code: currency.symbol
          };
        }))
      ],
    },
    {
      name: 'amount',
      type: 'number'
    },
    {
      name: 'maxElements',
      type: 'select',
      placeholder: 'Contabiliza Stock',
      options: [
        {
          value: 'infinity',
          label: 'Stock infinito',
          code: 'es',
        },
        {
          value: 'limited',
          label: 'Stock Limitado',
          code: 'en',
        },
      ],
    },
    {
      name: 'state',
      type: 'select',
      placeholder: 'Estado',
      options: [
        {
          value: 'available',
          label: 'Con stock'
        },
        {
          value: 'notAvailable',
          label: 'Sin stock'
        },
        {
          value: 'blockedApproved',
          label: 'Comprados'
        },
        {
          value: 'blockedDenied',
          label: 'Intentos Fallidos'
        },
        {
          value: 'expired',
          label: 'Expirado'
        },
      ]
    },
  ])
};
