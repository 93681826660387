import React from "react";
import loginLogo from "assets/brand-logo-login.svg";

export const LogoLogin = () => {
  return(
    <div className="login-logo mx-auto">
      <img src={loginLogo} alt="Logo"/>
    </div>
  )
}
