import React, { useContext } from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import iconRegenerate from "../../../../../../../assets/icon-regenerate.svg";
import iconTrash from "../../../../../../../assets/icon-trash.svg";

import credentialItem from "../../../../../../../assets/credentialItem.svg";

import i18n from "utils/i18n";
import { PermissionsContext } from "contexts/permissionsContext";

export const CredentialItem = (props) => {
  const { userPermissions } = useContext(PermissionsContext);
  const isRegenerateCredentialEnabled = userPermissions.includes(
    "credentials_action_edit"
  );
  const isDeleteCredentialEnabled = userPermissions.includes(
    "credentials_action_delete"
  );

  const showRegenerateModal = () => {
    props.setClientId(props.item.clientId);
    props.setShowRegenerateModal(true);
  };

  const showDeleteModal = () => {
    props.setClientId(props.item.clientId);
    props.setShowDeleteModal(true);
  };

  return (
    <Card className="credentials-card-item mb-3">
      <CardBody className="credentials-card-item-body">
        <div className="credentials-card-name-icon d-flex align-items-center">
          <CardImg
            src={credentialItem}
            width="20px"
            className="mr-5 "
          ></CardImg>
          <div className="credentials-card-name-warning d-flex flex-column">
            <CardTitle className="credentials-card-body-title mb-0">
              {props.item.name}
            </CardTitle>
            <CardText className="credentials-card-body-text text-danger mb-0"></CardText>
          </div>
        </div>
        <div className="credentials-card-item-body-btn-group">
          <Button
            disabled={!isRegenerateCredentialEnabled}
            className="credentials-card-item-body-btn-group-icon-label btn-regenerate"
            onClick={() => showRegenerateModal()}
          >
            <div className="credentials-card-icon-mask">
              <img
                src={iconRegenerate}
                alt=""
                className="credentials-card-btn-icon"
              />
            </div>
            {i18n(
              "dashboard.sections.merchantCredentials.credentialItem.buttonRegenerate"
            )}
          </Button>
          <Button
            disabled={!isDeleteCredentialEnabled}
            className="credentials-card-item-body-btn-group-icon-label btn-delete"
            onClick={() => showDeleteModal()}
          >
            <div className="credentials-card-icon-mask">
              <img
                src={iconTrash}
                alt=""
                className="credentials-card-btn-icon"
              />
            </div>
            {i18n(
              "dashboard.sections.merchantCredentials.credentialItem.button"
            )}
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};
