import React from 'react'

export const isLoading = () => {
  const loader = document.getElementById('loader')
  return loader && loader.className !== 'hidden'
}

export const showLoading = () => {
  const loader = document.getElementById('loader')
  if (loader) {
    loader.className = ''
  }
}

export const hideLoading = () => {
  const loader = document.getElementById('loader')
  if (loader) {
    loader.className = 'hidden'
  }
}

export class MainLoader extends React.Component {

  componentWillUnmount() {
    hideLoading()
  }

  render() {
    const wasLoading = isLoading()
    if (this.props.isLoading && !wasLoading) {
      showLoading()
    }
    if (!this.props.isLoading && wasLoading) {
      hideLoading()
    }
    return this.props.children || null
  }
}