import __ from 'utils/i18n'
import moment from 'moment'
import { isObjectLike, isFunction, isNumber, isEmpty, get } from 'lodash'
import { validateEmail } from 'utils'

export const validate = requirements => values => {

  return Object.keys(requirements).reduce((result, key) => {
    const requirement = Array.isArray(requirements[key]) ? requirements[key] : [requirements[key]]

    const error = requirement.find(req => req(values[key]))
    if (error) {
      result[key] = error
    }
    return result
  }, {})
}

export const test = (test) => (value, values) =>  {
  return test(value, values) || undefined
}

export const required = () => value => {
  const result = isNumber(value) || !isEmpty(value)
  return (!result) ? __('validators.required') : undefined
}

export const slug = () => value => {
  return !(/^[a-z0-9-]+$/.test(value)) && __('validators.slug')
}

export const isValidFileType = (validFileTypes = ['pdf']) => value => {
  if (!value) {
    return __('validators.required');
  }
  
  const result = value && validFileTypes.indexOf(value.split('.').reverse()[0]) >= 0;
  return (!result) && __('validators.invalidFileType')
}

export const isInteger = () => value => {
  return Number.isInteger(value) ? undefined : __('validators.integer')
}

export const validPrice = () => value => {
  const amount = value?.amount || value?.value || value;
  const result = parseInt(amount) > 0;
  return (!result) ? __('validators.higherAmount') : undefined
}

export const positiveInteger = () => value => {
  return (Number.isInteger(value) && value > 0) ? undefined : __('validators.positiveInteger')
}

export const minLength = (length) => value => {
  return !(value && (value.length >= length)) &&
    __('validators.minLength', {count: length})
}

export const maxLength = (length) => value => {
  return !(value && (value.length <= length)) &&
    __('validators.maxLength', {count: length})
}

export const minValue = () => (value, min) => {
  return !(value && (value >= min)) &&
      __('validators.minValue', {min: min})
}

export const maxValue = () => (value, max) => {
  return !(value && (value <= max)) &&
      __('validators.maxValue', {max: max})
}

export const emailMaxLength = (length) => value => {
  if (value && value.length > 0) {
    return !(value && (value.length <= length)) &&
      __('validators.emailMaxLength', {count: length})
  }
  return false;
}

export const lengthRange = ([minLength, maxLength]) => value => {
  return !(value && (value.length <= maxLength) && (value.length >= minLength)) &&
    __('validators.lengthRange', { minLength, maxLength })
}

export const validDate = () => value => {
  return (!(value && moment(value).isValid()) &&
    __('validators.date'))
}

export const validBirthdate = () => value => {
  const today = moment(),
      birth = moment(value, 'DD/MM/YYYY');

  if (!value) {
    return undefined;
  }
  return (birth.isValid() && birth.isBefore(today)) ? undefined : __('validators.date');
}

export const validEmail = () => value => {
  return !validateEmail(value) && __('validators.email')
}

export const validEmails = () => values => {
  const emails = Array.isArray(values) ? values : values.split(/[,; ]/);

  const invalidEmails = emails.filter(email => !validateEmail(email));

  return invalidEmails.length > 0 ? __('validators.email') : undefined;
};

export const validName = () => value => {
  if (!value || value.length === 0) {
    return undefined;
  }

  return value.trim().length === 0 ? __('validators.name') : undefined;
}

const validatorsByKey = {
  test: (test) => (value, values) => !test(value, values),
  validate: (validator) => validator,
  required,
  positiveInteger,
  validPrice,
  maxLength,
  emailMaxLength,
  minLength,
  lengthRange,
  isDate: validDate,
  isEmail: validEmail,
  areEmails: validEmail,
  isValidFileType,
  slug,
  minValue,
  maxValue,
  name: validName,
  birthdate: validBirthdate
};

export const validateFields = (validators) => {
  return (values) => {
    for (let i = 0; i < validators.length; i++) {
      const validator = validators[i]
      if (isObjectLike(validator)) {
        const { field, error, ...tests } = validator
        const value = get(values, field)
        const testError = Object.keys(tests).map(key => {
          const validator = validatorsByKey[key]
          const testValue = tests[key]
          if (!validator) {
            return false
          }
          return validator(testValue)(value, values, field, error)
        }).find(e => e)
        if (testError) {
          return { [field]:error||testError }
        }
      }
      else if (isFunction(validator)) {
        const testError = validator(values)
        if (testError) {
          return testError
        }
      }
      else {
        console.warn('Unknown validator: ' + validator)
      }
    }
  }
}



const validatorsMap = {
  email: validEmail,
  emails: validEmails,
  date: validDate,
  required,
  integer: isInteger,
  minLength,
  maxLength,
  positiveInteger,
  minValue,
  maxValue,
  name: validName,
  birthdate: validBirthdate
};


const getFirstError = list => list.find(e => e);

const validateField = (value, criteria) => Object.keys(criteria).map((key) => {
  const requiredValidator = validatorsMap[key];
  if (!requiredValidator) {
    return undefined;
  }
  return requiredValidator()(value, criteria[key]);
});

export const validations = criteria => (value) => {
  const errors = validateField(value, criteria);
  return errors && getFirstError(errors);
};
