import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Field as FormField } from 'react-form'

import AmountInput from './inputs/AmountInput'
import TextInput from './inputs/TextInput'
import MaskedTextInput from './inputs/MaskedTextInput'
import SelectInput from './inputs/SelectInput'
import ReInput from './inputs/ReInput'

const typeInputs = {
  re: ReInput,
  amount: AmountInput,
  text: TextInput,
  number: TextInput,
  maskedText: MaskedTextInput,
  select: SelectInput,
}

const text2plain = text => {
  return text.replace(/[^\w\d\sñáéíóúÁÉÍÓÚ´\-¿?¡!,.]/g, '')
}

class Field extends React.Component {

  static propTypes = {
    field: PropTypes.string.isRequired,
    input: PropTypes.element,
    inputProps: PropTypes.object,

    label: PropTypes.string,
    help: PropTypes.string,
    placeholder: PropTypes.string,

    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,

    parser: PropTypes.func,
    serializer: PropTypes.func,
  }

  render() {

    const {
      field, type, input, inputProps,
      label, help, placeholder,
      disabled, readOnly, onChange, parser, serializer,
      plain,
      ...rest
    } = this.props

    return (
      <FormField field={field}>
        {({ setValue, value, setTouched, error, touched, setError }) => {
          return(

          <div
            className={classNames({
              'form-group': true,
              'has-error': !disabled && error,
              'disabled': disabled
            })}>

            { label && (
              <label
                className={classNames({
                  'text-danger': !disabled && touched && error
                })}>
                {label}
              </label>
            )}

            { React.createElement(input || typeInputs[type] || TextInput, {
              type,
              readOnly,
              placeholder,
              ...rest,
              ...inputProps,
              onBlur: () => setTouched(true),
              value: serializer ? serializer(value) : value,
              onChange: val => {
                let value;
                value = parser ? parser(val, value) : val
                if (plain) {
                  value = text2plain(value)
                }
                onChange && onChange(value)
                setValue(value)
                setTouched(false)
                setError()
              }
            })}

            { !disabled && touched && error && (
              <small className="form-text text-danger">{error}</small>
            )}

            { !(!disabled && touched && error) && help && (
              <small className="form-text text-muted">{error}</small>
            )}

          </div>
        )}
        }
      </FormField>
    )
  }
}

export default Field
