import React from 'react';
import { keysIn, sumBy } from 'lodash';
import { Table } from 'reactstrap';
import __ from 'utils/i18n';
import {getConfig} from "../../../../../../../config";

export const ExtraItems = ({ sale, config }) => {
  const { items, totalAmount, currency } = config.items;

  const saleType =
    (sale.payment && sale.payment.method === 'recharge') || sale.refundId;

  const total = sale.refundId ? -totalAmount(sale) : totalAmount(sale);

  const extraItems = config.extraItems;
  const keys = keysIn(extraItems);
  const style = { width: '100%' };

  let sub = total;

  !!keys.length &&
    keys.map((key) =>
      typeof sale[key] !== 'undefined'
        ? (sub -= extraItems[key].value(sale))
        : null
    );

  const sum =
    sale.items && sale.items.length
      ? sumBy(items(sale), (item) => item.productPrice * item.quantity)
      : sub;

  const subTotal = saleType ? total : sum;

  const isRecharge =
    !!(sale.payment && sale.payment.method === 'recharge') || false;

  const isInstallment =
    !!(
      sale.installments > 1 ||
      (sale.payment && sale.payment.installments > 1)
    ) || false;

  const onePayment = !isRecharge && !isInstallment && sale.payment;

  const showQRSalesAsPCT = getConfig("customConfig.showQRSalesAsPCT");

  const qrDebit = sale?.terminal?.type === "QR" && sale?.payment?.method === "debit"

  let installment = 0;
  let installmentAlias = '';
  if (isInstallment) {
    installment = sale.installments || sale.payment.installments;
    installmentAlias = sale.installmentsAlias || installment;
  }

  let paymentMethod = '';

  if (onePayment) {
    paymentMethod =
      sale.payment.paymentMethodType === 'WALLET'
        ? __(`dashboard.sections.sales.${sale.payment.paymentMethodType.toLowerCase()}`)
        : __(`dashboard.sections.sales.${sale.payment.method}`);
    
      if (paymentMethod === 'dashboard.sections.sales.' && sale.payment.method === '') {
        paymentMethod =  __(`dashboard.sections.sales.${sale.type.toLowerCase()}`);
      }
  }
  if (isRecharge) {
    paymentMethod = __('dashboard.sections.sales.recharge');
  }
  if (isInstallment) {
    paymentMethod = !/^(\d| )+$/.test(installmentAlias)
      ? installmentAlias
      : __.unit(installment, 'installments');
  }
  if(showQRSalesAsPCT && qrDebit) {
    paymentMethod = "PCT"
  }

  return (
    <>
      {keys && !!keys.length && subTotal !== total && (
        <Table className='no-border'>
          <tbody>
            <tr>
              <td>
                <strong>{__('dashboard.sections.sales.subTotal')}</strong>
              </td>
              <td>
                <strong>{__.currency(subTotal, currency(sale))}</strong>
              </td>
            </tr>
            {keys &&
              keys.map((key, index) => {
                if (extraItems[key].value(sale) > 0) {
                  if (key === 'taxes') {
                    return sale[key].map((tax, i) => {
                      return (
                        <tr key={i}>
                          <td>{tax.name}</td>
                          <td>{__.currency(tax.amount, currency(sale))}</td>
                        </tr>
                      );
                    });
                  }

                  return (
                    <tr key={index}>
                      <td>{extraItems[key].label}</td>
                      {sale[key] && (
                        <td>
                          {__.currency(
                            extraItems[key].value(sale),
                            currency(sale)
                          )}
                        </td>
                      )}
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
          </tbody>
        </Table>
      )}
      <Table className='no-border no-border-child'>
        <tbody>
          <tr>
            <td>
              <span className='text-uppercase text-bold'>
                {__('dashboard.sections.sales.total')}
              </span>
            </td>
            <td>
              <span className='text-uppercase text-bold'>
                {__.currency(total, currency(sale))}
              </span>
            </td>
          </tr>
          <tr>
            <td style={style}>
              <span className='badge badge-light'>{paymentMethod}</span>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
